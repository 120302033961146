import React, { ReactElement, useEffect } from "react";
import { classNames, Menu, TextField, TextFieldProps } from "core";
import {
  FloatingPortal,
  useDismiss,
  useFloating,
  useInteractions,
} from "@floating-ui/react";
import { useComponentId } from "../../hooks/useComponentId";
import { MenuContext, MenuProvider, useMenu } from "../Menu/MenuContext";
import { useMenuKeyboardEvents } from "../../hooks/useMenuKeyboardEvents";
import { useChildComponentId } from "../../hooks/useChildComponentId";

export interface AutocompleteProps<T> extends TextFieldProps {
  value?: string;
  data: T[];
  onSelectItem: (item: T) => void;
  handleSearchData: (query: string, data: T[]) => T[];
  renderItem: (item: T) => ReactElement;
  styles?: {
    Menu?: string;
    Input?: string;
  };
}

const AutocompleteInner = <T extends unknown>({
  value,
  onChange,
  handleSearchData,
  data,
  onSelectItem,
  renderItem,
  placeholder,
  id,
  styles,
}: AutocompleteProps<T>) => {
  const [isOpen, setIsOpen] = React.useState(false);
  const [query, setQuery] = React.useState("");

  // if this component is being used in controlled mode we copy props.value to query
  useEffect(() => {
    if (value !== undefined) {
      setQuery(value);
    }
  }, [value]);

  const { refs, floatingStyles, context } = useFloating({
    placement: "bottom-start",
    open: isOpen,
    onOpenChange: setIsOpen,
  });

  const dismiss = useDismiss(context);
  const { getReferenceProps, getFloatingProps } = useInteractions([dismiss]);
  const menuContext = useMenu();
  const { setFocusedIndex } = menuContext;

  const filteredData = handleSearchData(query, data);

  const textFieldId = useComponentId(id, "Autocomplete");
  const menuId = useChildComponentId(textFieldId, "Menu");

  const handleKeyDown = useMenuKeyboardEvents({
    handleEscapeKeyDown: (e) => {
      if (isOpen) {
        e.stopPropagation(); // prevent event from propagating and closing outer modals
        setIsOpen(false);
      }
    },
    enabled: isOpen,
  });

  useEffect(() => {
    if (!isOpen) {
      // we need to reset the focusedIndex each time the menu is closed
      setFocusedIndex(-1);
    }
  }, [isOpen, setFocusedIndex]);

  return (
    <>
      <TextField
        {...getReferenceProps()}
        value={query}
        onChange={(event) => {
          setQuery(event.target.value);
          onChange?.(event);
          setIsOpen(true);
        }}
        className={classNames("w-96", styles?.Input)}
        placeholder={placeholder}
        ref={refs.setReference}
        role="combobox"
        id={textFieldId}
        aria-expanded={isOpen}
        aria-owns={menuId}
        aria-haspopup="listbox"
        aria-autocomplete="list"
        onFocus={() => {
          if (query) {
            setIsOpen(true);
          }
        }}
        onKeyDown={handleKeyDown}
      />
      <FloatingPortal>
        {isOpen && (
          <Menu
            ref={refs.setFloating}
            style={floatingStyles}
            className={classNames(
              "z-50 mt-2 w-auto origin-top-right overflow-y-scroll",
              styles?.Menu
            )}
            variant={"elevated"}
            role="listbox"
            id={menuId}
            {...getFloatingProps()}
          >
            {filteredData.length === 0 && query !== "" ? (
              <div className="relative cursor-default select-none px-4 py-2 text-gray-600">
                Nothing found.
              </div>
            ) : (
              filteredData.map((item: T, index) => (
                <MenuContext.Provider
                  key={index}
                  value={{
                    ...menuContext,
                    parentId: menuId,
                    // we need to pass specific props to each menu item, so we render
                    // a unique context provider for each one with the correct props saved
                    // <MenuItem> then calls getMenuItemProps internally and passes them to its rendered content
                    getMenuItemProps: () => ({
                      role: "option",
                      tabIndex: -1,
                      onClick: () => {
                        setIsOpen(false);
                        setQuery("");
                        onSelectItem(item);
                      },
                    }),
                  }}
                >
                  {renderItem(item)}
                </MenuContext.Provider>
              ))
            )}
          </Menu>
        )}
      </FloatingPortal>
    </>
  );
};

const Autocomplete = <T extends unknown>(props: AutocompleteProps<T>) => {
  return (
    <MenuProvider>
      <AutocompleteInner {...props} />
    </MenuProvider>
  );
};

export default Autocomplete;
