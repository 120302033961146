import { UserType, HebbiaAdminType } from "source/Types";
import axios from "../axios";

export const Users = {
  get: () => axios.get("/v2/users"),
  update: (body: Partial<UserType>) => axios.put("/v2/users", body),
  create: ({
    name,
    auth0Id,
    picture,
    email,
  }: {
    name: string;
    auth0Id: string;
    picture: string;
    email: string;
  }) =>
    axios.post("/v2/users", {
      name: name,
      auth0_id: auth0Id,
      picture: picture,
      email: email,
    }),
  provision: (
    email: string,
    orgId?: string,
    getPasswordResetLink?: boolean,
    password?: string,
    sendInvite?: boolean
  ) =>
    axios.post("/v2/users/provision", {
      email,
      org_id: orgId,
      get_password_reset_link: getPasswordResetLink,
      password,
      send_password_reset_invite: sendInvite,
    }),
  admin: (): Promise<HebbiaAdminType | undefined> =>
    axios.get("/v2/users/admin").then((res) => res.data.admin),
  toggleAdmin: () => axios.post("/v2/users/admin"),
};
