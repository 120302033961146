import { useSelector } from "react-redux";
import { getUser } from "source/redux/user";
import { RepoType } from "source/Types";

export const useCanViewRepo = (repo?: RepoType | null) => {
  const user = useSelector(getUser);
  return (
    repo &&
    (user?.platform_role === "admin" ||
      repo.role === "viewer" ||
      repo.role === "editor" ||
      repo.role === "owner")
  );
};

export const useCanEditRepo = (repo?: RepoType | null) => {
  const user = useSelector(getUser);
  return (
    repo &&
    (user?.platform_role === "admin" ||
      repo.role === "editor" ||
      repo.role === "owner")
  );
};

export const useUserHasOwnerPermissions = (
  repo: RepoType | null | undefined
) => {
  const user = useSelector(getUser);
  return repo && (user?.platform_role === "admin" || repo.role === "owner");
};

export const usePublicRepo = (repo?: RepoType | null) =>
  // a general public repo that no users should be able to modify
  repo && !repo.private;
