import {
  CustomRange,
  PresetFilterSelection,
} from "source/components/library/DateSelect/utils";
import { ReportSortOrder } from "source/components/matrix/types/reports.types";
import {
  BackendReportFilter,
  PublicDocType,
  ReportFilter,
  SimpleDocumentType,
} from "source/Types";
import { FastBuildSearchFilter } from "source/types/matrix/fastBuild.types";
import { CompanySourceFilter } from "source/types/matrix/matrixChat.types";

export enum SourceType {
  ORGANIZATION = "organization",
  PUBLIC_COMPANIES = "public",
  PERSONAL_UPLOADS = "personal",
  SHARED_WITH_ME = "shared",
  SHAREPOINT = "sharepoint",
  BOX = "box",
  S3 = "s3",
  AZURE_FILE_SHARE = "azure_file_share",
  THIRD_BRIDGE = "third_bridge",
}

export enum SearchType {
  FILE_NAMES = "substring",
  FILE_NAMES_BM25 = "bm25",
  SEMANTIC_SIMILARITY = "semantic",
  COMPANY_SEARCH = "company",
  KEYWORD = "keyword",
  PREFIX = "prefix",
}

export enum FilterType {
  FILTER = "filter",
}

export type SearchFilterType = SearchType | FilterType;

export type FilterMap = { [key: string]: ReportFilter | null };

export type FastBuildSearchFilterMap = {
  [key: string]: FastBuildSearchFilter | null;
};

export type PublicsFilterMap = {
  companyId: number | null;
  docTypes: PublicDocType[];
  dateRange: CustomRange;
  searchQuery: string;
  selectedDatePreset: PresetFilterSelection | null;
  companyOptions: CompanySourceFilter[] | null;
};

export type SortDirection = "desc" | "asc" | null;

export type SortOrder = "timestamp" | "relevance" | null;

export type SearchFilterResultType = {
  isLoading: boolean;
  data: MatrixDocSearchResult[];
  hasError?: boolean;
};

export type GetDocumentsPayload = {
  offset: number;
  limit: number;
  search_type?: SearchType;
  source_type: SourceType;
  query?: string;
  filters?: BackendReportFilter;
  sort_order?: ReportSortOrder;
};

// FE Version of GetDocumentsPayload
export type DocumentsParams = {
  offset?: number;
  limit?: number;
  search_type?: SearchFilterType;
  source_type: SourceType;
  query?: string;
  filters?: FilterMap | FastBuildSearchFilterMap;
  sort_order?: ReportSortOrder;
};

export type MatrixDocSearchResult = {
  doc: SimpleDocumentType;
  keyword_bolding?: string;
};

// Types for file upload
export type UploadFileType = "copyPaste" | "upload" | "url";

export enum SharepointResourceType {
  SITES = "sites",
  DRIVES = "drives",
  SHARED_WITH_ME = "shared_with_me",
}
