import React, { ReactNode } from "react";
import { classNames } from "core";

export type ListItemProps<T extends React.ElementType> = {
  as: T;
  className?: string;
  left?: ReactNode;
  right?: ReactNode;
  title?: ReactNode;
  description?: ReactNode;
  children?: ReactNode;
  onClick?: (event: React.MouseEvent<T, MouseEvent>) => void;
} & React.ComponentProps<T>;

const ListItem = React.forwardRef(
  <T extends React.ElementType>(
    {
      as = "li",
      className,
      onClick,
      left,
      right,
      title,
      description,
      children,
      ...rest
    }: ListItemProps<T>,
    ref: React.Ref<React.ElementRef<T>>
  ) => {
    const Component = as;

    return (
      <Component
        {...rest}
        ref={ref}
        className={classNames(
          "flex w-full items-center gap-2 rounded-md px-3 py-2 font-normal",
          {
            ["cursor-pointer hover:bg-gray-100 focus:bg-gray-100"]:
              Boolean(onClick),
          },
          className
        )}
        onClick={onClick}
      >
        {left}
        {children ? (
          children
        ) : (
          <div className={"flex flex-1 flex-col gap-1"}>
            <div className={"text-sm leading-5 text-gray-800"}>{title}</div>
            {description && (
              <div className={"text-xs leading-4 text-gray-500"}>
                {description}
              </div>
            )}
          </div>
        )}
        {right}
      </Component>
    );
  }
);

export default ListItem;
