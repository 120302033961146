import {
  DocumentListDateFilterType,
  DocumentListDisplayDataType,
  FolderItem,
  FolderItemType,
} from "source/types/document-list/documentList.types";
import { DocumentListGridDataType } from "source/types/document-list/grid.types";
import moment from "moment";

export const HOMEPAGE_DOCUMENT_LIST_KEY = "homepage-document-list";
export const MATRIX_DOCUMENT_LIST_KEY = "matrix-document-list";
export const DOCUMENT_LIST_COLORS_KEY = "document-list-colors";

export const DOCUMENT_LIST_COLOR_ARRAY: Record<string, string> = {
  slate: "#64748B",
  gray: "#6B7280",
  red: "#EF4444",
  orange: "#F97316",
  amber: "#F59E0B",
  yellow: "#EAB308",
  green: "#22C55E",
  teal: "#14B8A6",
  cyan: "#0EA5E9",
  sky: "#0EA5E9",
  blue: "#3B82F6",
  indigo: "#6366F1",
  violet: "#8B5CF6",
  purple: "#A855F7",
  pink: "#EC4899",
};

export const DEFAULT_DOCUMENT_LIST_COLOR = "violet";

export const PUBLICS_DOCUMENT_LIST_ID = "PUBLICS-ID";
export const WEB_SEARCH_DOCUMENT_LIST_ID = "WEB-SEARCH-ID";
export const THIS_MATRIX_ID = "THIS-MATRIX-ID";

export const THIS_MATRIX_DOCUMENT_LIST_DISPLAY_DATA_TYPE = {
  name: "This Matrix",
  id: THIS_MATRIX_ID,
  icon: "table_chart",
  color: "#737373",
};

export const DEFAULT_DOCUMENT_LIST_DATA: DocumentListDisplayDataType[] = [
  THIS_MATRIX_DOCUMENT_LIST_DISPLAY_DATA_TYPE,
  {
    name: "Public Filings",
    id: PUBLICS_DOCUMENT_LIST_ID,
    icon: "account_balance",
    color: "#737373",
  },
  {
    name: "Web",
    id: WEB_SEARCH_DOCUMENT_LIST_ID,
    icon: "language",
    color: "#737373",
  },
];

export const DOC_LIST_TRUNCATE_LIMT = 4;

export const DOC_LIST_PADDING_ROW_ID = "PADDING-DOCUMENT-ROW";

export const DOC_LIST_PADDING_ROW: DocumentListGridDataType = {
  id: DOC_LIST_PADDING_ROW_ID,
  name: "",
  parent_id: "",
  type: FolderItemType.DOCUMENT,
  path: "",
  filePath: [],
};

export const AGGRID_GROUP_COLUMN_ID = "ag-Grid-AutoColumn";
export const RENAME_INPUT_ID = "rename-input-";
export const RENAME_SIDEBAR_INPUT_ID = "rename-sidebar-input-";

export const STILL_INDEXING_TOOLTIP_LABEL =
  "This folder is still indexing and is not ready to be searched yet. Try again later.";

export const NUM_BARS_MAPPING: Record<number, { icon: string; color: string }> =
  {
    [1]: {
      icon: "signal_cellular_alt_1_bar",
      color: "#9ECAFF",
    },
    [2]: {
      icon: "signal_cellular_alt_2_bar",
      color: "#52A0FF",
    },
    [3]: {
      icon: "signal_cellular_alt",
      color: "#0576FF",
    },
  };

export const DOCUMENT_LIST_DATE_FILTER_MAPPING: Record<
  DocumentListDateFilterType,
  {
    label: string;
    valueGetter?: () => moment.Moment;
  }
> = {
  ["month"]: {
    label: "Past month",
    valueGetter: () => {
      return moment().subtract(1, "month");
    },
  },
  ["3 months"]: {
    label: "Past 3 months",
    valueGetter: () => {
      return moment().subtract(3, "months");
    },
  },
  ["6 months"]: {
    label: "Past 6 months",
    valueGetter: () => {
      return moment().subtract(6, "months");
    },
  },
  ["year"]: {
    label: "Past year",
    valueGetter: () => {
      return moment().subtract(1, "year");
    },
  },
  ["ytd"]: {
    label: "YTD",
    valueGetter: () => {
      return moment().startOf("year");
    },
  },
  ["all time"]: {
    label: "All time",
  },
  ["custom"]: {
    label: "Custom",
  },
};
