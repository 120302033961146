import {
  CURRENCY_TO_SYMBOL,
  DEFAULT_NUM_DECIMALS,
  SCALE_TO_NUMBER,
  SCALE_TO_SYMBOL,
} from "source/components/matrix/constants";
import React from "react";
import { removeStopwords } from "stopword";
import { ColumnViewConfiguration } from "source/components/matrix/types/reports.types";

export const pluralize = (
  count: number | null | undefined,
  noun: string,
  suffix = "s"
) => {
  return `${noun}${(count || 0) !== 1 ? suffix : ""}`;
};

export const validateEmail = (email: string): boolean => {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).trim().toLowerCase());
};

export const wordToTitleCase = (text: string) =>
  text.charAt(0).toUpperCase() + text.slice(1);

/**
 * Detects occurrences where lowercase letter is followed by a uppercase letter and inserts a space in between to make it more human readable e.g. "aBack" -> "a Back"
 */
export const cleanText = (s: string | undefined) => {
  if (!s) return "";

  const camelCaseConverted = s.replace(/([a-z])([A-Z])/g, "$1 $2");
  return camelCaseConverted.replace(/_/g, " ").replace(/\w\S*/g, (txt) => {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });
};

// Remove any non-emoji characters
export const cleanEmoji = (s: string) =>
  s.replace(/[^\p{Emoji}\p{Extended_Pictographic}, ]/gu, "");

// split body into multiple pages if it's too long
export const splitIntoWordChunksWithLineBreaks = (
  text: string,
  wordsPerChunk: number
): string[] => {
  const chunks: string[] = [];
  const lines = text.split(/\r?\n/); // Split text into lines by line breaks

  let currentChunk: string[] = [];
  let currentWordCount = 0;

  for (const line of lines) {
    const words = line.split(/\s+/); // Split line into words by whitespace

    // If adding this line would exceed the word limit, push the current chunk
    if (currentWordCount + words.length > wordsPerChunk) {
      chunks.push(currentChunk.join("\n")); // Join current chunk with line breaks and push to chunks
      currentChunk = []; // Start a new chunk
      currentWordCount = 0;
    }

    // Add the line to the current chunk
    currentChunk.push(line);
    currentWordCount += words.length;
  }

  // Push the last chunk if there are any remaining words
  if (currentChunk.length > 0) {
    chunks.push(currentChunk.join("\n"));
  }

  return chunks;
};

// TODO: Add proper error checking for URL
export const isUrl = (url) => {
  try {
    if (url.indexOf("https://") < 0 && url.indexOf("http://") < 0) {
      url = "https://" + url;
    }
    return Boolean(new URL(url));
  } catch (e) {
    return false;
  }
};

export const extractDomainFromUrl = (url: string) => {
  try {
    const domain = new URL(url).hostname;
    return domain.startsWith("www.") ? domain.slice(4) : domain;
  } catch (e) {
    return url;
  }
};

export const tokenizeWithSpans = (text: string, spans: any[]) => {
  let consumed_up_to = 0;
  const tokens: string[] = [];
  spans.forEach((span: [number, number]) => {
    const start_idx = span[0];
    const end_idx = span[1];

    // consume up to start idx if possible
    if (consumed_up_to < start_idx)
      tokens.push(text.slice(consumed_up_to, start_idx));

    // consume span
    tokens.push(text.slice(start_idx, end_idx));

    consumed_up_to = end_idx;
  });

  // consume what's remaining
  if (consumed_up_to < text.length)
    tokens.push(text.slice(consumed_up_to, text.length));

  return tokens;
};

const LIST_REGEX = /(\d+\.|\*|•|\d+\))\s?(.*?)(?=\d+\.|\*|•|\d+\)|$)/gs;
const REMOVE_LIST_ITEM_REGEX = /(\d+\.|\*|•|\d+\))\s?(.*?)/g;

/**
 * @param inputValue
 * @returns string array representation of inputValue, converts numbered/bulleted string -> array. With whitespace trimming and filtering of empty query lines.
 * eg "1. hello 2. world" => ["hello", "world"]
 * eg "   \n  hello     \n world \n\n" => ["hello", "world"]
 */
export const parseListInputToStringArray = (inputValue: string) => {
  if (inputValue === "") return null;
  const queryList = inputValue.replace(`\n`, "").match(LIST_REGEX);
  let queries: Array<string>;
  // If we couldn't split the numbered or bulleted list, split by new lines
  if (!queryList) {
    queries = inputValue.split(/\n/).map((query) => query.trim());
  } else {
    queries = queryList
      ?.map((query) => query.replace(REMOVE_LIST_ITEM_REGEX, "").trim())
      .filter((query) => query != "");
  }
  return queries.filter((query) => query != "");
};

export const parseCommaDelimitedStringToArray = (value: string) => {
  const cleanAndSplitValues = Array.from(
    new Set(
      value
        .trim()
        .split(",")
        .map((s) => s.trim())
    )
  );
  // remove any empty strings
  return cleanAndSplitValues.filter((value) => value.trim() !== "");
};

/**
 * @param inputValue
 * @returns reformats inputValue to have new lines if bullets/numbered list
 * e.g 1. hello 2. world =>
 * 1. hello
 * 2. world
 */
export const prettifyListInput = (inputValue: string) => {
  if (inputValue === "") return null;
  const queryList = inputValue.replace(`\n`, "").match(LIST_REGEX);
  // If we couldn't split the numbered or bulleted list, return original
  if (!queryList) return inputValue;
  return queryList.map((query) => query.replace(`\n`, "").trim()).join("\n");
};

export const toCamelCase = (str: string) =>
  str
    .replace(/(?:^\w|[A-Z]|\b\w)/g, (word, index) => {
      return index == 0 ? word.toLowerCase() : word.toUpperCase();
    })
    .replace(/\s+/g, "");

export const appendTxtExtension = (str: string) => {
  const extension = ".txt";

  if (str.endsWith(extension)) {
    return str;
  } else {
    return str + extension;
  }
};

// Cleans string so that / gets replaced -
export const cleanFileName = (str: string) => str.replace(/\//g, "-");

export const getFirstLineTruncated = (text: string, characterLimit: number) => {
  const lines = text.split("\n");
  for (const line of lines) {
    if (line.trim().length > 0) {
      return line.length > characterLimit
        ? line.trim().slice(0, characterLimit) + "..."
        : line.trim();
    }
  }
  return "";
};

export const replaceDashWithSlash = (str: string) => {
  // Replace all dashes with slashes
  return str.replace(/-/g, "/");
};

export const composeStringWithCommas = (arr: string[]): string => {
  if (arr.length === 0) return "";
  if (arr.length === 1) return arr[0] ?? "";
  if (arr.length === 2) return arr.join(" and ");
  return (
    arr.slice(0, arr.length - 1).join(", ") + " and " + arr[arr.length - 1]
  );
};

// takes in a string and trims it, removes any new line characters, and removes any leading or trailing special characters
export const sanitizeAndTrim = (text: string | undefined): string =>
  text
    ? text
        .trim()
        .replace(/\n/g, " ")
        .replace(/^["%';]+|["%';]+$/g, "")
        .trim()
    : "";

export const removeSpacesBeforePunctuation = (str: string) => {
  return str.replace(/(\s+)(?=[.,])/g, "");
};

export const keywordBold = (
  text: string,
  keywords?: string[],
  indices?: [number, number][]
): React.ReactElement => {
  if (indices) {
    const elements = indices.reduce<React.ReactElement[]>(
      (acc, [startIdx, endIdx], index) => {
        if (index === 0) {
          acc.push(<>{text.slice(0, startIdx)}</>);
        } else {
          const prevEndIdx = indices[index - 1]?.[1];
          acc.push(<>{text.slice(prevEndIdx, startIdx)}</>);
        }

        acc.push(
          <b className="font-semibold text-primary">
            {text.slice(startIdx, endIdx)}
          </b>
        );

        if (index === indices.length - 1) {
          acc.push(<>{text.slice(endIdx, text.length)}</>);
        }

        return acc;
      },
      []
    );

    return <>{elements}</>;
  }
  const textLower = text.toLowerCase();

  const normalizedText = textLower
    .normalize("NFD")
    .replace(/[\u0300-\u036f]/g, "");
  // sort keywords by decreasing length to prioritize longer keywords
  const sortedKeywords = removeStopwords(keywords).sort(
    (a, b) => b.length - a.length
  );

  const keywordMatch: string | undefined = sortedKeywords.find(
    (keyword) => normalizedText.indexOf(keyword.toLowerCase()) > -1
  );

  const keywordMatchIdx: number = keywordMatch
    ? normalizedText.indexOf(keywordMatch.toLowerCase())
    : -1;

  // basic greedy bolding that will complete the word if substring
  // e.g., if query is "certificate", the full word "certificates" will be bolded
  const endOfKeywordMatchIdx =
    keywordMatch && keywordMatchIdx !== -1
      ? keywordMatchIdx +
        keywordMatch.length +
        Math.max(
          normalizedText
            .slice(keywordMatchIdx + keywordMatch.length)
            .indexOf(" "),
          0
        )
      : -1;

  return keywordMatch ? (
    <>
      {keywordBold(text.slice(0, keywordMatchIdx), sortedKeywords)}
      <b className="font-semibold text-primary">
        {text.slice(keywordMatchIdx, endOfKeywordMatchIdx)}
      </b>
      {keywordBold(
        text.slice(endOfKeywordMatchIdx, text.length),
        sortedKeywords
      )}
    </>
  ) : (
    <>{text}</>
  );
};

const EXACT_MATCH_THRESHOLD = 2;
const SPECIAL_CHAR_REGEX = /['-_+@^$]/g;
export const multiKeywordBold = (
  text: string,
  search: string
): React.ReactElement => {
  // First split by whitespace and remove stop words
  const searchKeywords = search.split(/\s+/);
  const cleanedSearchKeywords: string[] = removeStopwords(searchKeywords);

  // Then identify the words in the original text to bold.
  const wordsToBold = text.split(/\s+/).filter((candidateWord) => {
    // Take candidate word and normalize to remove accent characters
    // Replace special characters to handle loreal -> L'Oreal
    const trimmedNormalizedWord = candidateWord
      .normalize("NFD")
      .replace(/[\u0300-\u036f]/g, "")
      .toLowerCase()
      .replaceAll(SPECIAL_CHAR_REGEX, "");

    return cleanedSearchKeywords.some((searchWord) => {
      if (searchWord.length > EXACT_MATCH_THRESHOLD) {
        // If the word is large, do a substring match
        return trimmedNormalizedWord.includes(
          searchWord.toLowerCase().replaceAll(SPECIAL_CHAR_REGEX, "")
        );
      } else {
        // If the word is small, do a exact match
        return (
          trimmedNormalizedWord ===
          searchWord.toLowerCase().replaceAll(SPECIAL_CHAR_REGEX, "")
        );
      }
    });
  });

  // Loop through words to bold, and bold them
  const elements: React.ReactElement[] = [];
  let startIdx = 0;
  wordsToBold.forEach((val) => {
    const wordStart = text.indexOf(val, startIdx);
    elements.push(<>{text.slice(startIdx, wordStart)}</>);
    elements.push(<b>{text.slice(wordStart, wordStart + val.length)}</b>);
    startIdx = wordStart + val.length;
  });
  elements.push(<>{text.slice(startIdx, text.length)}</>);
  return <>{elements}</>;
};

export const isStringArray = (value: string | any): boolean =>
  value &&
  Array.isArray(value) &&
  value.every((element) => typeof element === "string");

export const convertStringToStringArray = (
  value: string | any | undefined
): string[] => {
  if (value) {
    return isStringArray(value) ? value : [value];
  } else {
    return [];
  }
};

export const capitalizeWords = (str: string): string => {
  if (!str) return ""; // Return empty string if input is null or empty

  return str
    .split(" ")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");
};

export const hasWhiteSpace = (str: string) => /\s/g.test(str);

export const formatCurrency = (numberString: string, currency: string) => {
  const formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency,
  });
  const number = parseFloat(numberString.replace(/,/g, ""));
  if (isNaN(number)) return numberString;
  return formatter.format(number);
};

export const checkValidFloat = (value: string) => {
  // Check if the string contains unacceptable characters
  if (/[^0-9.,\s+-]/.test(value)) return false;
  // Check if the string contains any letters
  if (/[a-zA-Z]/.test(value)) return false;
  return !isNaN(parseFloat(value.replace(/,/g, "")));
};

export const range = (start, end) => {
  return Array.from({ length: end - start + 1 }, (_, i) => start + i);
};

export const parsePageRanges = (inputStr, maxNumPages) => {
  const validateAndParseRange = (range) => {
    if (range.includes("-")) {
      const [start, end] = range.split("-").map((num) => parseInt(num, 10));
      if (
        Number.isInteger(start) &&
        Number.isInteger(end) &&
        start > 0 &&
        end > 0
      ) {
        if (start <= end) {
          if (start > maxNumPages || end > maxNumPages) {
            throw new Error(`Range exceeds total number of pages: ${range}`);
          }
          return [start, end];
        } else {
          throw new Error(`Invalid range: ${range}`);
        }
      } else {
        throw new Error(`Invalid numbers in range: ${range}`);
      }
    } else {
      const num = parseInt(range, 10);
      if (Number.isInteger(num) && num > 0) {
        if (num > maxNumPages) {
          throw new Error(`Number exceeds total number of pages: ${range}`);
        }
        return [num, num];
      } else {
        if (!range.trim()) throw new Error("Empty range after comma");
        throw new Error(`Invalid number: ${range}`);
      }
    }
  };

  if (!inputStr) {
    throw new Error("Input string is empty");
  }

  const ranges: number[][] = [];
  const parts = inputStr.split(",");
  for (const part of parts) {
    ranges.push(validateAndParseRange(part.trim()));
  }

  return ranges;
};

export const floatToStringWithCommas = (num: number) => {
  const [integerPart, decimalPart] = num.toString().split(".");
  const formattedIntegerPart = integerPart?.replace(
    /\B(?=(\d{3})+(?!\d))/g,
    ","
  );
  return decimalPart
    ? `${formattedIntegerPart}.${decimalPart}`
    : formattedIntegerPart;
};

export const stringFloatToStringFloatWithCommas = (num: string) => {
  const [integerPart, decimalPart] = num.split(".");
  const [decimalPartFirst, decimalPartSecond] = decimalPart
    ? decimalPart.split(" ")
    : ["", ""];
  const formattedIntegerPart = integerPart?.replace(
    /\B(?=(\d{3})+(?!\d))/g,
    ","
  );
  const answer = decimalPart
    ? `${formattedIntegerPart}.${decimalPartFirst ?? ""} ${
        decimalPartSecond ?? ""
      }`
    : formattedIntegerPart;
  return answer?.trim();
};

export const formatScale = (answer: string, scale: string) => {
  // check to see if the unit is a currency
  const scaleValue = SCALE_TO_NUMBER[scale.toLowerCase()];
  const scaleSymbol = SCALE_TO_SYMBOL[scale.toLowerCase()];
  if (scaleValue !== undefined && scaleSymbol !== undefined) {
    // check if the answer is a number
    answer = answer.replace(/,/g, "");
    const answerNumber = parseFloat(answer);
    if (!isNaN(answerNumber)) {
      const scaledNumber = answerNumber / scaleValue;
      return `${floatToStringWithCommas(scaledNumber)} ${scaleSymbol}`;
    }
  }

  return `${answer} ${scale}`;
};

export const formatUnit = (answer: string, unit: string) => {
  const currencySymbol = CURRENCY_TO_SYMBOL[unit.toLowerCase()];
  if (currencySymbol) {
    // Check if the first character is a negative sign
    if (answer.startsWith("-")) {
      return `-${currencySymbol}${answer.slice(1)}`;
    }
    return `${currencySymbol}${answer}`;
  }

  return `${answer} ${unit}`;
};

export const formatViewConfig = (
  answer: string,
  columnViewConfig: ColumnViewConfiguration
) => {
  const { numeric_config, date_config } = columnViewConfig;

  const isNumeric =
    numeric_config &&
    !Object.keys(numeric_config).every((key) => !numeric_config[key]);

  if (isNumeric) {
    const formatNumericAnswer = (value: number, suffix: string) => {
      const precision = numeric_config.precision ?? DEFAULT_NUM_DECIMALS;
      return (
        stringFloatToStringFloatWithCommas(value.toFixed(precision) + suffix) ??
        answer
      );
    };

    answer = answer.replace(/,/g, "");
    const answerNumber = parseFloat(answer);

    switch (numeric_config.format) {
      case "thousands":
        return formatNumericAnswer(answerNumber / 1000, " K");
      case "millions":
        return formatNumericAnswer(answerNumber / 1000000, " M");
      case "billions":
        return formatNumericAnswer(answerNumber / 1000000000, " B");
      case "multiple":
        return formatNumericAnswer(answerNumber, "×");
      default:
        if (numeric_config.precision !== undefined) {
          answer = answerNumber.toFixed(numeric_config.precision);
        }
        return stringFloatToStringFloatWithCommas(answer) ?? answer;
    }
  }

  const isDate =
    date_config && !Object.keys(date_config).every((key) => !date_config[key]);

  if (isDate) {
    const parsedDate = new Date(answer);
    const padZero = (num: number) => num.toString().padStart(2, "0");

    // Convert the date to UTC
    const year = parsedDate.getUTCFullYear();
    const month = parsedDate.getUTCMonth() + 1;
    const day = parsedDate.getUTCDate();
    const monthLong = parsedDate.toLocaleString("en-US", {
      month: "long",
      timeZone: "UTC",
    });

    switch (date_config.format) {
      case "MM/DD/YYYY":
        return `${padZero(month)}/${padZero(day)}/${year}`;
      case "DD/MM/YYYY":
        return `${padZero(day)}/${padZero(month)}/${year}`;
      case "YYYY/MM/DD":
        return `${year}/${padZero(month)}/${padZero(day)}`;
      case "Month DD, YYYY":
        return `${monthLong} ${padZero(day)}, ${year}`;
      case "YYYY":
        return `${year}`;
      default:
        return answer;
    }
  }

  return answer;
};

export const formatOutputFormat = (answer: string, outputFormat: string) => {
  if (outputFormat === "percent") {
    answer = answer.replace(/,/g, "");
    const answerNumber = parseFloat(answer);
    return `${(answerNumber * 100).toFixed(2)}%`;
  }
  return answer;
};
