import React from "react";
import { Tooltip } from "source/components/shared/Tooltip";
import ActivityIndicator from "react-loader-spinner";
import { KeyValuePair } from "source/Types";
import { useQueryDoc } from "source/api/docs/useQueryDocs";
import DescriptionSharpIcon from "@mui/icons-material/DescriptionSharp";

type FilterChipProps = {
  keyValuePair: KeyValuePair;
  checkbox?: React.ReactNode;
  isLastMessage?: boolean;
};

export const DocFilterChip = ({ keyValuePair, checkbox }: FilterChipProps) => {
  const { data, isLoading } = useQueryDoc(keyValuePair.value);
  const doc = data?.doc;

  if (isLoading)
    return (
      <ActivityIndicator
        type="TailSpin"
        color="var(--text-light)"
        height={18}
        width={18}
      />
    );

  if (!doc) return <></>;

  return (
    <Tooltip title="Document" enterDelay={500}>
      <div className="flex flex-1 flex-nowrap items-center gap-2 bg-card px-2.5 py-2 text-center text-sm font-medium">
        {checkbox}
        <DescriptionSharpIcon fontSize="small" className="text-secondary" />
        <span>{doc.title}</span>
      </div>
    </Tooltip>
  );
};
