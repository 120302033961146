import { RepoIcon } from "source/components/shared/Icons/RepoIcon";
import React from "react";
import { RepoType } from "source/Types";
import { GigabarRow } from "../../GigabarRow";
import { useQuerySearchCompanyRepos } from "source/api/filter/useQueryFilters";
import { LoadingRow } from "source/components/sidebar/components/LoadingRow";
import { getFilterSearchKey, getReportFilterKey } from "source/redux/advanced";
import { COMPANY_SECTION_TITLE } from "..";
import { setRepo } from "source/api/repos/useQueryRepos";

type SearchedCompaniesProps = {
  searchValue?: string;
  bookmarkedRepos: RepoType[];
  handleRepoClick?: (repoId: string) => void;
};

export const SearchedCompanies = ({
  searchValue,
  bookmarkedRepos,
  handleRepoClick,
}: SearchedCompaniesProps) => {
  const sectionKey = getReportFilterKey(COMPANY_SECTION_TITLE);
  const searchKey = getFilterSearchKey("company");
  const { data, isLoading, isError } = useQuerySearchCompanyRepos(searchValue);
  const matchingBookmarkedRepos = bookmarkedRepos.filter((repo) =>
    data?.company_repos?.find((r) => r.id === repo.id)
  );
  const matchingNonBookmarkedRepos =
    data?.company_repos?.filter(
      (repo) => !bookmarkedRepos.find((r) => r.id === repo.id)
    ) ?? [];

  if (!searchValue?.length) return null;
  if (isError)
    return <div className="px-4 pb-2 text-xs">Error fetching companies</div>;
  if (isLoading) return <LoadingRow depth={0} />;
  if (!isLoading && !data?.company_repos.length)
    return <div className="px-4 pb-2 text-xs">No matching companies</div>;

  return (
    <>
      {matchingBookmarkedRepos.map((repo, idx) => (
        <GigabarRow
          key={`${repo.id}${idx}`}
          title={repo.name}
          id={repo.id}
          value={repo.ticker}
          icon={<RepoIcon repo={repo} fontSize="small" />}
          sectionKey={sectionKey}
          searchKey={searchKey}
          handleClick={handleRepoClick}
          showContextMenu={false}
          bookmarked={true}
        />
      ))}
      {matchingNonBookmarkedRepos.map((repo, idx) => (
        <GigabarRow
          key={`${idx}${repo.id}`}
          title={repo.name}
          id={repo.id}
          value={repo.ticker}
          icon={<RepoIcon repo={repo} fontSize="small" />}
          sectionKey={sectionKey}
          searchKey={searchKey}
          handleClick={handleRepoClick}
          showContextMenu={false}
          bookmarked={false}
          cacheSetter={() => setRepo(repo)}
        />
      ))}
    </>
  );
};
