import axios from "../axios";
import { BackendReportFilter } from "source/Types";
import { FiltersConfig } from "source/constants/filters";

export type GetPropertiesType = [
  {
    // Search key for weaviate
    key: string;
    // string representation of the type (str | int)
    type: string;
    // Human-readable description for tooltip or something (don't currently use)
    description: string;
    // Human-readable key
    display_key: string;
  },
];

export type GetPropertyValuesType = {
  key: string;
  type: string;
  display_key: string;
  description: string;
  values: {
    value: string;
    display_value: string;
  }[];
};

export type GetSheetFilterCounts = {
  doc_count: number;
};

export const AdvancedFilters = {
  getProperties: (orgId: string): Promise<GetPropertiesType> =>
    axios
      .get(`/v3/filters/keys`, { params: { org_id: orgId } })
      .then(({ data }) => {
        data.push({
          key: FiltersConfig.FILTER_KEY_DATE,
          type: "date",
          description: "",
          display_key: "Date",
        });
        return data;
      }),
  getPropertyValuesInfinite: (
    orgId: string,
    key: string,
    page: number,
    size: number
  ): Promise<GetPropertyValuesType> =>
    axios
      .get(`/v3/filters/values`, { params: { org_id: orgId, key, page, size } })
      .then(({ data }) => data),
  getTripletDocCount: (
    triplet: BackendReportFilter,
    orgId?: string
  ): Promise<GetSheetFilterCounts> =>
    axios
      .post("/v3/filters/doc_count", {
        org_id: orgId,
        sheet_filter: triplet,
      })
      .then(({ data }) => data),
};
