import { classNames } from "core";
import React, { forwardRef } from "react";

type CitationProps = {
  size?: "small" | "medium" | "large";
  citationText: string;
  startIcon?: React.ReactNode;
  styleOverrides?: string;
  onClick?: () => void;
};

export const Citation = forwardRef(
  (
    {
      size = "small",
      citationText,
      startIcon,
      styleOverrides,
      onClick,
    }: CitationProps,
    ref?: React.LegacyRef<HTMLButtonElement>
  ) => {
    const numDigits = citationText.length;
    const responsiveClassname =
      numDigits > 4
        ? "w-[35px]"
        : numDigits === 4
          ? "w-[29px]"
          : numDigits === 3
            ? "w-[23px]"
            : "w-[17px]";

    return (
      <button
        ref={ref}
        className={classNames(
          "m-0 h-5 min-w-[20px] overflow-clip rounded bg-brand-200 text-[11px] font-medium text-hebbiaBlue hover:bg-brand-300",
          size === "small" && responsiveClassname,
          size === "medium" && "min-w-[35px] max-w-[35px]",
          size === "large" &&
            "h-[26px] min-w-[50px] max-w-[50px] text-[12px] leading-[26px]",
          onClick && "cursor-pointer",
          styleOverrides
        )}
        onClick={onClick}
      >
        {startIcon ? (
          <div className="flex items-center justify-start">
            {startIcon}
            <span className="truncate">{citationText}</span>
          </div>
        ) : (
          <span className="truncate">{citationText}</span>
        )}
      </button>
    );
  }
);
