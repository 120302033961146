import dayjs from "dayjs";
import {
  DocumentsParams,
  FilterMap,
} from "source/components/matrix/menu/AddDocumentsModal/shared/types";
import {
  BackendReportFilter,
  DocumentType,
  ReportFilter,
  ReportFilterOperation,
  SimpleDocumentDriveDataType,
  SimpleDocumentType,
} from "source/Types";
import { convertToBackendReportFilter } from "source/components/matrix/utils";
import {
  getRandomSectionTitle,
  getReportFilterKey,
} from "source/redux/advanced";
import { FiltersConfig } from "source/constants/filters";
import { INTEGRATION_FILTER_KEYS } from "source/components/gigabar/components/FilterSection/utils";
import logger from "source/utils/logger";
import {
  ReduxReport,
  ReduxTab,
} from "../../components/matrix/types/reports.types";
import _ from "lodash";
import {
  FastBuildDocData,
  FastBuildSource,
  FastBuildStatus,
} from "../../types/matrix/fastBuild.types";
import { PRESET_UNIT_MAPPING } from "source/components/library/DateSelect/utils";
import { isFolder } from "source/utils/documents";
import { AddMatrixUserFailuresPayload } from "source/redux/matrix";

export const convertDateValue = (filter: ReportFilter) => {
  const now = dayjs();
  const { count, unit } = PRESET_UNIT_MAPPING[filter.values as string] ?? {
    count: undefined,
    unit: undefined,
  };
  return {
    key: filter.key,
    values: dayjs(now.subtract(count ?? 0, unit).format("MM/DD/YYYY")).format(
      "YYYY-MM-DDTHH:mm:ss[Z]"
    ),
    operation: ReportFilterOperation.GTEDATE,
  };
};

export const convertFilterMapToBEFilters = (
  filterMap: FilterMap
): BackendReportFilter => {
  // Clean up the filters
  // We keep both integration and mime filters together so need to split them up
  // Also need to convert 1 month -> date values + operations
  const cleanFilters = Object.values(filterMap).map((filter) => {
    if (filter?.key === FiltersConfig.FILTER_KEY_DATE) {
      return convertDateValue(filter);
    } else if (filter?.key === "type") {
      return {
        ...filter,
        key: INTEGRATION_FILTER_KEYS.includes(filter.values as string)
          ? FiltersConfig.INTEGRATION_FILTER_KEY
          : FiltersConfig.MIME_FILTER_KEY,
      };
    }
    return filter;
  });

  const values = cleanFilters.reduce(
    (values: BackendReportFilter[], filter) => {
      if (filter) {
        values = [...values, convertToBackendReportFilter(filter)];
      }
      return values;
    },
    []
  );

  const sectionTitle = getRandomSectionTitle();
  const sectionKey = getReportFilterKey(sectionTitle);

  return {
    key: null,
    values: [
      {
        key: sectionKey,
        values: values,
        operation: ReportFilterOperation.AND,
      },
    ],
    operation: ReportFilterOperation.AND,
  };
};

export const onDocumentsError = (
  err: any,
  orgId: string,
  report: ReduxReport | null,
  payload?: DocumentsParams,
  reportTab?: ReduxTab
) => {
  console.error(`Error fetching documents ${err}`);
  logger.error("Error fetching documents", {
    reportId: report?.id,
    orgId: orgId,
    tabId: reportTab?.tab_id,
    params: payload,
  });
};

// Failed doc if status = failed or document type and # passages = 0
export const isFailedDocument = (doc: DocumentType) => {
  // If fast build is successful, don't show as failed
  if (doc.fast_build_status === "success") return false;

  // If build status is not successful or if it's not folder and has no passages
  // then it's failed
  if (
    doc.build_status !== "success" ||
    (!isFolder(doc.mime) && !doc.npassages)
  ) {
    return true;
  }

  // Otherwise, it's not failed
  return false;
};

export const hasSelectedChildren = (
  lookupId: string,
  isRepo: boolean,
  documentDetailMap: { [key: string]: DocumentType },
  documentIds: string[]
) => {
  if (isRepo) {
    return documentIds.some((id) => {
      const doc = documentDetailMap[id];
      return doc?.repo_id === lookupId;
    });
  } else {
    return documentIds.some((id) => {
      const doc = documentDetailMap[id];
      return doc?.path?.includes(lookupId) || doc?.id === lookupId;
    });
  }
};

/**
 * Gets difference of docList - initialDocs, keeping duplicates
 */
export const getNewDocuments = (initialDocs: string[], docList: string[]) => {
  const countMap = initialDocs.reduce(
    (acc, docId) => ((acc[docId] = (acc[docId] ?? 0) + 1), acc),
    {}
  );
  return docList.filter((docId) => !(countMap[docId]-- > 0));
};

export type addNewSelectionsParams = {
  currentSelectionMap: {
    [key: string]: boolean;
  };
  docMap: {
    [key: string]: SimpleDocumentType;
  };
  newSelectionIds: string[];
  checked: boolean;
};
/**
 * Redux helper function
 * Add new Selection IDs to the current Add Docs Selection Map.
 * Check status of the IDs is determined by the checked parameter.
 * Uses docMap to clear selection state for children that have a current selection state
 */
export const addNewSelectionsToMap = ({
  currentSelectionMap,
  docMap,
  newSelectionIds,
  checked,
}: addNewSelectionsParams) => {
  // Add new selection IDs to current selection map
  const updates = newSelectionIds.reduce((map, id) => {
    map[id] = checked;
    return map;
  }, {});
  const newSelectionMap = {
    ...currentSelectionMap,
    ...updates,
  };
  const updatedIdSet = new Set(newSelectionIds);
  // Clear selection state for children of each new selection ID
  Object.keys(newSelectionMap).forEach((selectionId) => {
    if (updatedIdSet.has(selectionId)) return;
    const doc = docMap[selectionId];
    if (doc) {
      // If doc's repo is being updated
      if (updatedIdSet.has(doc.repo_id)) {
        // Clear true / false selection state
        delete newSelectionMap[selectionId];
      } // Check if doc's ancestors are being updated
      else {
        const intersection = doc.path?.filter((docId) =>
          updatedIdSet.has(docId)
        );
        intersection?.forEach((intersectionId) => {
          // Clear true / false selection state
          delete newSelectionMap[intersectionId];
        });
      }
    }
  });

  return newSelectionMap;
};

/**
 * Redux helper function
 * Returns true if childDoc is a child of parent ID, false otherwise.
 * Set parentIsRepo to true if parent ID is a Repo ID, false otherwise.
 */
export const isChildDoc = (
  parentId: string,
  parentIsRepo: boolean,
  childDoc?: SimpleDocumentType
) => {
  if (!childDoc) return false;
  if (parentIsRepo) return childDoc.repo_id === parentId;
  else return childDoc.path?.includes(parentId) ?? false;
};

/**
 * Redux helper function
 * Get selection state for a doc or repoId
 * Given selection map
 * And given boolean flags for whether some children are explicitly selected or deselected
 */
export const getSelectionState = (
  selectionId: string,
  currentSelectionMap: {
    [key: string]: boolean;
  },
  someChildrenDeselected: boolean,
  someChildrenSelected: boolean
) => {
  // If the item is explicitly selected or unselected
  if (currentSelectionMap[selectionId] !== undefined) {
    // id is checked but some children are unselected
    if (currentSelectionMap[selectionId] && someChildrenDeselected)
      return "indeterminate";
    // id is deselected but some children are selected
    if (
      currentSelectionMap[selectionId] !== undefined &&
      currentSelectionMap[selectionId] === false &&
      someChildrenSelected
    )
      return "indeterminate";
    // Otherwise return state directly
    return currentSelectionMap[selectionId] ? "checked" : "unchecked";
  }
};

export const calculateDocumentCount = (
  checked: boolean,
  prevDocCount: number,
  childrenDocCount: number,
  numChildrenDeselected?: number
) => {
  let docCount = prevDocCount;
  if (checked) {
    docCount = docCount + childrenDocCount;
  } else {
    docCount = docCount - (childrenDocCount - (numChildrenDeselected ?? 0)); // Don't double up on children that have already been removed
  }
  return Math.max(0, docCount);
};

/**
 * Calculates how many children have been explicitly selected/deselected for a given id
 */
export const calculateNumChildrenSelections = (
  checkSelections: {
    [key: string]: boolean;
  },
  id: string,
  documentDetailMap: {
    [key: string]: SimpleDocumentType;
  },
  repoId?: string,
  docCountMap?: { [id: string]: number }
) => {
  // Check children
  const explicitlySelectedChildren = Object.keys(checkSelections).filter(
    (key) =>
      key !== id &&
      key in checkSelections &&
      isChildDoc(id, !!repoId, documentDetailMap[key])
  );
  return explicitlySelectedChildren.reduce(
    (
      acc: {
        numChildrenSelected: number;
        numChildrenDeselected: number;
      },
      key
    ) => {
      const childrenCount = docCountMap ? docCountMap[key] : undefined;
      if (checkSelections[key]) acc.numChildrenSelected += childrenCount ?? 1;
      else if (
        checkSelections[key] !== undefined &&
        checkSelections[key] === false
      )
        acc.numChildrenDeselected += childrenCount ?? 1;
      return acc;
    },
    {
      numChildrenSelected: 0,
      numChildrenDeselected: 0,
    }
  );
};

export const getDocumentDetailFromRemoteId = (
  remoteId: string,
  documentDetailMap: {
    [key: string]: SimpleDocumentType<SimpleDocumentDriveDataType>;
  }
) => {
  return Object.values(documentDetailMap).find(
    (docDetail) => docDetail.data?.remote_id === remoteId
  );
};

export const getDocumentDetailFromAzureDocId = (
  azureDocId: string,
  documentDetailMap: {
    [key: string]: SimpleDocumentType;
  }
) => {
  return Object.values(documentDetailMap).find(
    (docDetail) => docDetail.id === azureDocId
  );
};

export const getFileNameFromPath = (name: string) => {
  if (name.includes("/")) return _.last(name.split("/")) ?? "";
  return name;
};

/**
 * @summary This function will bundle files into request chunks based on the size of the files.
 *          The files themselves won't be chunked, but the files will be grouped into chunks based on the total size of the files.
 * @returns An array of chunked files to make requests with
 */
export const chunkFilesAndDocIdsByFileSize = ({
  files,
  docIds,
  maxSize,
}: {
  files: File[];
  docIds?: string[];
  maxSize: number;
}) => {
  const fileChunks: File[][] = [];
  const documentIdChunks: string[][] = [];
  let currentFileChunk: File[] = [];
  let currentDocumentIdChunk: string[] = [];
  let currentChunkSize = 0;

  files.forEach((file, idx) => {
    const docId = docIds?.[idx];

    // Handle case where file is larger than the max size
    if (currentChunkSize === 0 && file.size > maxSize) {
      fileChunks.push([file]);
      documentIdChunks.push(docId ? [docId] : []);
    }
    // Add the file to the current chunk
    else if (currentChunkSize + file.size <= maxSize) {
      currentFileChunk.push(file);
      if (docId) currentDocumentIdChunk.push(docId);
      currentChunkSize += file.size;
    }
    // Start a new chunk
    else {
      fileChunks.push(currentFileChunk);
      documentIdChunks.push(currentDocumentIdChunk);
      currentFileChunk = [file];
      currentDocumentIdChunk = docId ? [docId] : [];
      currentChunkSize = file.size;
    }
  });

  // Add the last chunk
  if (currentFileChunk.length > 0) {
    fileChunks.push(currentFileChunk);
    documentIdChunks.push(currentDocumentIdChunk);
  }

  return { fileChunks, documentIdChunks };
};

/**
 * Determines sort order based on fast build status.
 * Failed docs go first, then pending / building docs, then successful docs.
 */
const getFastBuildStatusHierarchy = (status: FastBuildStatus) => {
  switch (status) {
    // Failed docs go first
    case FastBuildStatus.FAILED:
      return 0;
    // Then pending and building docs go next
    case FastBuildStatus.PENDING:
    case FastBuildStatus.BUILDING:
      return 1;
    // Successful docs go last
    case FastBuildStatus.SUCCESS:
      return 2;
    default:
      return 2;
  }
};

/**
 * Sorts fast build docs by status, and then in reverse order of when they were uploaded to the Matrix.
 */
export const sortFastBuildDocsByStatus = (docs: FastBuildDocData[]) => {
  return docs.sort(
    (a, b) =>
      getFastBuildStatusHierarchy(a.status) -
      getFastBuildStatusHierarchy(b.status)
  );
};

export const isValidSharepointSite = (
  siteAllowList: string[],
  siteId: string | null
) => siteAllowList.find((allowedSite) => siteId?.includes(allowedSite));

/**
 * Returns the document from doc list based on y
 * Assumes that y === index in report tools
 */
export const getLoadingDocumentIdFromY = (y: number, docs: string[]) => {
  return docs[y];
};

// Utility function to handle table data slicing
export const getSlicedData = (
  data: string[][],
  headerRow: number,
  startRow: number,
  endRow: number
): string[][] => {
  // Extract the header row
  const header = data[headerRow - 1];

  if (!header) return [];

  // if startRow is NaN, set it to headerRow + 1
  if (isNaN(startRow)) {
    startRow = headerRow + 1;
  }

  // Extract the sliced data from startRow to endRow (inclusive)
  const slicedData = data.slice(startRow - 1, endRow);

  // Return the header row followed by the sliced data
  return [header, ...slicedData];
};

export const getFastBuildSourceFromDoc = (doc?: SimpleDocumentType) => {
  // Sharepoint
  if (doc?.data?.remote_id) {
    return FastBuildSource.SHAREPOINT;
  }

  // Azure share
  if (doc?.data?.azure_file_share_id) {
    return FastBuildSource.AZURE_FILE_SHARE;
  }

  // Earnings transcripts
  if (doc?.data?.sp_event_id || doc?.data?.earnings_query_params?.id) {
    return FastBuildSource.SNP_EARNINGS;
  }

  // SEC filings
  if (
    doc?.data?.accession_number ||
    doc?.data?.filings_query_params?.accession_number
  ) {
    return FastBuildSource.SNP_FILINGS;
  }

  return undefined;
};

export const getUniqueFailedDocuments = (
  docs: AddMatrixUserFailuresPayload[]
): AddMatrixUserFailuresPayload[] => {
  return _.uniqBy(docs, "doc_id");
};
