import React from "react";
import CloseIcon from "@mui/icons-material/Close";

type ClearTargetButtonProps = {
  numTargets: number;
  handleClear: (e: React.MouseEvent) => void;
};
export const ClearTargetButton = ({
  numTargets,
  handleClear,
}: ClearTargetButtonProps) => {
  if (numTargets === 0) return null;

  return (
    <div
      className={`group/clearButton flex items-center justify-center gap-1 border border-border2 px-2 py-0.5 text-xs font-medium text-black hover:border-lightBlueBg`}
      onClick={handleClear}
    >
      <span className="text-black group-hover/clearButton:text-hebbiaBlue">
        {numTargets}
      </span>
      <span className="text-black group-hover/clearButton:text-hebbiaBlue">
        <CloseIcon className="text-xs" style={{ fontSize: "14px" }} />
      </span>
    </div>
  );
};
