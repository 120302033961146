import {
  filterOutRepos,
  filterToTickers,
  getCompanyRepos,
} from "source/components/gigabar/components/CompanySection/utils";
import { useSelector } from "react-redux";
import { RepoType } from "source/Types";
import { useQueryBookmarks } from "source/api/bookmarks/useQueryBookmarks";
import { useQueryFilterSpecificRepos } from "source/api/repos/useQueryRepos";
import { useQueryViewedCompanyRepos } from "source/api/views/useQueryViews";
import {
  getTargetAppliedFilters,
  getTargetCompanies,
  getTargetSuggestedFilters,
} from "source/redux/advanced";

export const useCompanyRepos = () => {
  // Get applied company repos
  const appliedFilters = useSelector(getTargetAppliedFilters);
  const appliedCompanyTickers = filterToTickers(appliedFilters);
  const appliedCompanyQuery = useQueryFilterSpecificRepos(
    appliedCompanyTickers
  );
  const appliedCompanyRepos = appliedCompanyQuery.data ?? [];

  // Get suggested company repos
  const suggestedFilters = useSelector(getTargetSuggestedFilters);
  const suggestedCompanyTickers = filterToTickers(suggestedFilters);
  const suggestedCompanyQuery = useQueryFilterSpecificRepos(
    suggestedCompanyTickers
  );
  const suggestedCompanyRepos = suggestedCompanyQuery.data ?? [];

  // Get bookmarked company repos
  const bookmarkedRepos = useQueryBookmarks().data?.repos ?? [];
  const bookmarkedCompanyRepos = filterOutRepos(
    getCompanyRepos(bookmarkedRepos),
    [appliedCompanyRepos, suggestedCompanyRepos]
  );

  // Get recent company repos
  const { data: recentRepos } = useQueryViewedCompanyRepos();

  // Filter out bookmarked and applied repos
  const recentCompanyRepos = filterOutRepos(recentRepos ?? [], [
    bookmarkedCompanyRepos,
    appliedCompanyRepos,
    suggestedCompanyRepos,
  ]);

  const companyTickers = useSelector(getTargetCompanies);

  // Filter out everything so we only have the searched company ids left
  const searchedCompanyTickers = filterOutReposByTicker(companyTickers, [
    bookmarkedCompanyRepos,
    appliedCompanyRepos,
    suggestedCompanyRepos,
    recentCompanyRepos,
  ]);

  // Get the searched company repos
  const searchedCompanyTickersQuery = useQueryFilterSpecificRepos(
    searchedCompanyTickers
  );
  const searchedCompanyRepos =
    searchedCompanyTickersQuery.data ?? ([].filter((d) => d) as RepoType[]);

  return {
    recentCompanyRepos,
    bookmarkedCompanyRepos,
    appliedCompanyRepos,
    suggestedCompanyRepos,
    searchedCompanyRepos,
  };
};

/** Filter out repos/docs by ids in the second from the first */
const filterOutReposByTicker = (
  objectsToKeep: string[],
  objectsToFilterOut: RepoType[][]
) => {
  const allRepos = objectsToFilterOut.flat();
  return objectsToKeep.filter(
    (object) => !allRepos.find((o) => o.ticker && o.ticker === object)
  );
};
