import React from "react";
import { classNames } from "../../index";

export interface SkeletonProps extends React.HTMLProps<HTMLDivElement> {
  width?: number | string;
  height?: number | string;
}

const Skeleton = (props: SkeletonProps) => {
  const { className, width, height, ...rest } = props;

  return (
    <div
      {...rest}
      className={classNames("loading-shine", "rounded", className)}
      style={{ width: width || "100%", height: height || "1rem" }}
    />
  );
};

export default Skeleton;
