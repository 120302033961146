import { RepoContextMenu } from "source/components/sidebar/components/RepoContextMenu";
import React from "react";
import { FilterType, RepoType } from "source/Types";
import { RepoIcon } from "source/components/shared/Icons/RepoIcon";
import { useGlobalNavigator } from "source/hooks/useSetRouter";
import { AppliedRow } from "../../AppliedRow";
import { getFilterSearchKey, getReportFilterKey } from "source/redux/advanced";
import { COMPANY_SECTION_TITLE } from "..";

type Props = {
  filters: FilterType[];
  repos: RepoType[];
  bookmarkedRepos: RepoType[];
  autoApplied: boolean;
  tooltipText: string;
};

export const AppliedCompanies = ({
  filters,
  repos,
  bookmarkedRepos,
  tooltipText,
  autoApplied,
}: Props) => {
  const sectionKey = getReportFilterKey(COMPANY_SECTION_TITLE);
  const searchKey = getFilterSearchKey("company");
  const { goToRepo } = useGlobalNavigator();

  const handleRepoClick = (repoId: string) => {
    goToRepo(repoId);
  };

  return (
    <>
      {repos.map((repo, idx) => {
        const matchingFilter = filters.find(
          (filter) => filter.value === repo.ticker
        );
        const isBookmarked = bookmarkedRepos.some((r) => r.id === repo.id);

        return (
          <RepoContextMenu repo={repo} key={`${repo.id}${idx}`}>
            <AppliedRow
              title={repo.name}
              filter={matchingFilter}
              id={repo.id}
              value={repo.ticker}
              icon={<RepoIcon repo={repo} fontSize="small" />}
              handleClick={handleRepoClick}
              showContextMenu={true}
              tooltipText={tooltipText}
              bookmarked={isBookmarked}
              autoApplied={autoApplied}
              sectionKey={sectionKey}
              searchKey={searchKey}
            />
          </RepoContextMenu>
        );
      })}
    </>
  );
};
