import {
  Bookmark,
  BookmarkBorder,
  BookmarkRemoveSharp,
  BookmarkSharp,
  DeleteSharp,
  EditSharp,
  FileDownloadDoneSharp,
  FolderOpenSharp,
  PersonAddAlt1Sharp,
  PsychologySharp,
  ReceiptLongSharp,
  RefreshSharp,
  SwapVertSharp,
} from "@mui/icons-material";
import { Divider } from "@mui/material";
import { notification } from "antd";
import moment from "moment";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import api from "source/api";
import {
  useCreateBookmarkMutation,
  useDeleteBookmarkMutation,
  useQueryBookmarks,
} from "source/api/bookmarks/useQueryBookmarks";
import { useUpdateRepoMutation } from "source/api/repos/useQueryRepos";
import { openPrettyAlert } from "source/utils/helpers";
import { useBuildStatus } from "source/hooks/build/useBuildStatus";
import { useCanEditOrg } from "source/hooks/permissions/useCanEditOrg";
import {
  useCanEditRepo,
  useCanViewRepo,
  usePublicRepo,
  useUserHasOwnerPermissions,
} from "source/hooks/permissions/useCanEditRepo";
import { useDeleteRepo } from "source/hooks/repos/useDeleteRepo";
import { useRemoveRepoMember } from "source/hooks/repos/useRemoveRepoMember";
import { useSidebar } from "source/hooks/useSidebar";
import { setBuildDownload } from "source/redux/downloadBuild";
import { getCurrentOrg } from "source/redux/organization";
import { getUser } from "source/redux/user";
import { RepoType } from "source/Types";
import {
  ContextMenu,
  ContextMenuContent,
  ContextMenuItem,
  ContextMenuLabel,
  ContextMenuTrigger,
} from "./ContextMenu";
import { useModal } from "source/components/modals/useModal";
import { ModalTypes } from "source/components/modals/modals.types";
import { useFeatureFlag } from "source/hooks/useFeatureFlag";

type Props = {
  repo: RepoType;
  children?: React.ReactNode;
  styleOverride?: React.CSSProperties;
};

export const RepoContextMenu = ({ repo, children, styleOverride }: Props) => {
  const dispatch = useDispatch();
  const currentOrg = useSelector(getCurrentOrg);
  const user = useSelector(getUser);
  const canEdit = useCanEditRepo(repo);
  const orgAdmin = useCanEditOrg();
  const isOwner = useUserHasOwnerPermissions(repo);
  const { focus } = useSidebar();
  const { handleBuild } = useBuildStatus(repo);
  const { handleShowConfirmAlert } = useDeleteRepo({ repo });
  const { handleShowConfirmAlert: handleRemoveSharedRepo } =
    useRemoveRepoMember({ repo, user: user ?? null });
  const bookmarksQuery = useQueryBookmarks();
  const isPublicRepo = usePublicRepo(repo ? repo : null);
  const updateRepoMutation = useUpdateRepoMutation();
  const createBookmarkMutation = useCreateBookmarkMutation();
  const deleteBookmarkMutation = useDeleteBookmarkMutation();
  const isShared =
    !repo.organization_id && repo.private && repo.role !== "owner";
  const shareModal = useModal(ModalTypes.ShareModal);
  const canView = useCanViewRepo(repo ? repo : null);
  const disableAllSharedContent = useFeatureFlag("disableAllSharedContent");

  const showShare = () => {
    shareModal.open({
      repo: repo,
      isPublicRepo: isPublicRepo,
      canView: canView,
    });
  };

  const handleUpdateOrg = async (orgId: string | null) => {
    const payload = {
      repoId: repo.id,
      body: {
        organization_id: orgId,
      },
    };
    if (!orgId && !repo.role) {
      payload["role"] = "viewer";
      api.repos.follow(repo.id, true);
    }
    updateRepoMutation.mutate(payload);
  };

  const handleDownload = async (flat: boolean) => {
    const { data } = await api.repos.download(repo.id, flat);
    if (data) dispatch(setBuildDownload(data));
  };

  const handleDirectoryDownload = async () => {
    const { data } = await api.repos.directoryDownload(repo.id);
    const blob = new Blob([data], { type: ".csv" });
    const link = document.createElement("a");
    link.href = window.URL.createObjectURL(blob);
    link.download = `${repo.name} Directory.csv`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const handleBuildClick = (generateMetadata?: boolean) =>
    openPrettyAlert({
      title: "Please Confirm",
      text: generateMetadata
        ? `Are you sure you want to hard build ${repo.name} and generate metadata?`
        : `Are you sure you want to hard build ${repo.name}?`,
      showConfirmButton: true,
      showCancelButton: true,
      confirmButtonText: generateMetadata ? "Generate" : "Hard Build",
      cancelButtonText: "Cancel",
      icon: "question",
    }).then((res) => {
      if (res.isConfirmed) {
        if (generateMetadata)
          handleBuild({ data: { generate_metadata: true } });
        else handleBuild();
      }
    });

  const handleBookmark = async (e) => {
    e.stopPropagation();

    if (bookmarksQuery.data?.repos?.find((r) => r.id === repo.id)) {
      deleteBookmarkMutation.mutate(repo.id);
      notification.success({
        message: "Removed Bookmark",
        placement: "bottomRight",
        icon: <BookmarkBorder />,
        closeIcon: <></>,
      });
    } else {
      createBookmarkMutation.mutate(repo.id);
      notification.success({
        message: "Bookmarked",
        placement: "bottomRight",
        icon: <Bookmark />,
        closeIcon: <></>,
      });
    }
  };

  return (
    <span style={{ ...styleOverride }}>
      <ContextMenu>
        <ContextMenuTrigger>{children}</ContextMenuTrigger>
        <ContextMenuContent onCloseAutoFocus={(e) => e.preventDefault()}>
          {!disableAllSharedContent && (
            <ContextMenuItem
              title="Share"
              icon={<PersonAddAlt1Sharp fontSize="small" />}
              onSelect={showShare}
            />
          )}
          {bookmarksQuery.data?.repos?.find((r) => r.id === repo.id) ? (
            <ContextMenuItem
              title="Remove bookmark"
              icon={<BookmarkRemoveSharp fontSize="small" />}
              onSelect={handleBookmark}
            />
          ) : (
            <ContextMenuItem
              title="Bookmark"
              icon={<BookmarkSharp fontSize="small" />}
              onSelect={handleBookmark}
            />
          )}
          {canEdit && (
            <ContextMenuItem
              title="Rename"
              icon={<EditSharp fontSize="small" />}
              onSelect={() => focus(`${repo.id}`)}
            />
          )}
          {!disableAllSharedContent &&
            !isPublicRepo &&
            currentOrg &&
            isOwner && (
              <ContextMenuItem
                title={`Move into ${
                  repo.organization_id ? "personal" : currentOrg.name
                }`}
                icon={<SwapVertSharp fontSize="small" />}
                onSelect={() =>
                  handleUpdateOrg(repo.organization_id ? null : currentOrg.id)
                }
              />
            )}
          <Divider />
          <ContextMenuItem
            title="Download"
            icon={<FileDownloadDoneSharp fontSize="small" />}
            onSelect={() => {
              handleDownload(true);
            }}
          />
          <ContextMenuItem
            title="Download with Folders"
            icon={<FolderOpenSharp fontSize="small" />}
            onSelect={() => {
              handleDownload(false);
            }}
          />
          <ContextMenuItem
            title="Download Directory"
            icon={<ReceiptLongSharp fontSize="small" />}
            onSelect={handleDirectoryDownload}
          />
          <Divider />
          {(user?.platform_role === "admin" ||
            user?.platform_role === "labeler") && (
            <>
              <ContextMenuItem
                title="Hard Build"
                icon={<RefreshSharp fontSize="small" />}
                onSelect={() => handleBuildClick(false)}
              />
              <ContextMenuItem
                title="Generate Metadata"
                icon={<PsychologySharp fontSize="small" />}
                onSelect={() => handleBuildClick(true)}
              />
            </>
          )}
          {isShared && (
            <ContextMenuItem
              title="Remove"
              icon={<DeleteSharp fontSize="small" />}
              onSelect={handleRemoveSharedRepo}
            />
          )}
          {(isOwner ||
            (orgAdmin &&
              repo.organization_id &&
              repo.organization_id === currentOrg?.id)) && (
            <ContextMenuItem
              title="Delete"
              icon={<DeleteSharp fontSize="small" />}
              onSelect={handleShowConfirmAlert}
            />
          )}
          {repo.last_built && (
            <ContextMenuLabel>
              Last updated {moment.utc(repo.last_built).fromNow()}
            </ContextMenuLabel>
          )}
        </ContextMenuContent>
      </ContextMenu>
    </span>
  );
};
