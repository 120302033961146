import { useDispatch, useSelector } from "react-redux";
import api from "source/api";
import { getTargetDocs } from "source/redux/advanced";
import { setBuildDownload } from "source/redux/downloadBuild";
import { DocumentType } from "source/Types";
import { api as authenticatedApi } from "source/api/authenticatedAxios";

export const useDownload = () => {
  const dispatch = useDispatch();
  const docsSelected = useSelector(getTargetDocs);

  const getBaseURL = (doc: DocumentType) => {
    if (doc.type === "local" && doc.source) return doc.source;
    else if (doc.doc_viewer_url) return doc.doc_viewer_url;
    return null;
  };

  const handleDownloadSource = async (doc: DocumentType) => {
    const baseURL = getBaseURL(doc);
    if (!baseURL) return;

    const { data } = await authenticatedApi.get(baseURL, {
      responseType: "blob",
    });

    // get extension from source as we have removed it from title
    const re = /(?:\.([^.]+))?$/;
    const ext = re.exec(baseURL)?.[1];

    // this allows us to download the file with the correct name
    const url = URL.createObjectURL(data);
    const a = document.createElement("a");
    a.download = doc.title + "." + ext;
    a.href = url;
    document.body.appendChild(a);
    a.click();
    a.remove();
  };

  const handleDownloadSingleDoc = async (repoId: string, docId: string) => {
    const { data } = await api.repos.download(repoId, true, [docId]);
    if (data) dispatch(setBuildDownload(data));
  };

  const handleDownloadDoc = async (doc: DocumentType, flat: boolean) => {
    const isDownloadableLeafDoc =
      doc.doc_viewer_url || // always default to this
      (doc.type != "sec" && doc.source); // magic link; exclude SEC to avoid parents

    if (
      flat && // if someone requests not flat, force through API
      isDownloadableLeafDoc &&
      !(docsSelected && docsSelected.length > 1) &&
      doc.type !== "earnings" &&
      getBaseURL(doc)
    ) {
      handleDownloadSource(doc);
      return;
    }

    const docsToDownload =
      docsSelected && docsSelected.length > 1 ? docsSelected : [doc.id];

    const { data } = await api.repos.download(
      doc.repo_id,
      flat,
      docsToDownload
    );
    if (data) dispatch(setBuildDownload(data));
  };

  return { handleDownloadSource, handleDownloadDoc, handleDownloadSingleDoc };
};
