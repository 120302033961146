import {
  UseMutationOptions,
  UseQueryOptions,
  useInfiniteQuery,
  useMutation,
  useQuery,
} from "@tanstack/react-query";
import api from "..";
import { QueryContextFromKeys } from "../utils";
import { queryClient } from "pages/_app";
import { sendReportsSlackError } from "source/components/matrix/utils";
import { useSelector } from "react-redux";
import { getUser } from "source/redux/user";
import {
  GetDocumentsPayload,
  SortOrder,
} from "source/components/matrix/menu/AddDocumentsModal/shared/types";
import { EXPANDED_SEARCH_LIMIT } from "source/components/matrix/menu/AddDocumentsModal/shared/config";
import {
  AddMatrixUserParams,
  EditMatrixUserParams,
  RemoveMatrixUserParams,
  UpdateShareSettingsParams,
  UpdateMatrixBookmakredParams,
  TemplateMetadataParams,
  MatrixMetadataResponse,
} from "./types";
import {
  AutoTitleReportParams,
  Report,
} from "source/components/matrix/types/reports.types";
import {
  MatrixListMetadata,
  ReportInfo,
} from "source/components/matrix/types/reports.types";
import { useFeatureFlag } from "source/hooks/useFeatureFlag";
import {
  clearAddMatrixUserStatus,
  setAddMatrixUserStatus,
} from "source/redux/matrix";
import { useAppDispatch } from "source/redux";
import { InvokeRestFunc } from "source/components/matrix/contexts/useSocketProtocol";
import { CellContent } from "../../components/matrix/types/cells.types";
import { filterNullishValues } from "../../utils/common/arrays";

// reports query key factory
// Single reports by reportId are intentionally managed in redux, not with react query.
export const reportsKeys = {
  all: [{ scope: "reports" }] as const,
  userReports: () =>
    [{ ...reportsKeys.all[0], level: "user", feature: "userReports" }] as const,
  promptLists: (orgId?: string) =>
    [
      { ...reportsKeys.all[0], orgId, level: "user", feature: "promptLists" },
    ] as const,
  documents: (orgId: string, params: GetDocumentsPayload) =>
    [
      {
        ...reportsKeys.all[0],
        orgId,
        params,
        feature: "documents",
      },
    ] as const,
  publicCompanies: (searchTerm: string) =>
    [
      {
        ...reportsKeys.all[0],
        searchTerm,
        feature: "publicCompanies",
      },
    ] as const,
  userRoles: (matrixId: string) => [
    { ...reportsKeys.all[0], matrixId, level: "user", feature: "userRoles" },
  ],
  allChildren: (
    orgId: string,
    params: {
      doc_ids?: string[];
      repo_ids?: string[];
      sort_order?: SortOrder;
      limit?: number;
    },
    clicked: boolean, // Do not remove this
    id: string // Do not remove this
  ) =>
    [{ ...reportsKeys.all[0], feature: "allChildren", orgId, params }] as const,
  allChildrenCount: (
    orgId: string,
    params: {
      doc_id?: string;
      repo_id?: string;
    }
  ) =>
    [
      { ...reportsKeys.all[0], feature: "allChildrenCount", orgId, params },
    ] as const,
  metadata: (matrixId: string) =>
    [
      { ...reportsKeys.all[0], matrixId, level: "user", feature: "metadata" },
    ] as const,
  shareSettings: (matrixId: string) =>
    [
      {
        ...reportsKeys.all[0],
        matrixId,
        level: "user",
        feature: "shareSettings",
      },
    ] as const,
  documentBuildStatus: (docIds: string[]) =>
    [
      {
        ...reportsKeys.all[0],
        docIds,
        feature: "buildStatus",
      },
    ] as const,
  userMatrixListMetadata: () =>
    [
      {
        ...reportsKeys.all[0],
        level: "user",
        feature: "userMatrixListMetadata",
      },
    ] as const,
  reportAndCells: ({
    reportId,
    versionId,
  }: {
    reportId: string;
    versionId?: string;
  }) =>
    [
      {
        ...reportsKeys.all[0],
        reportId,
        versionId,
        feature: "reportAndCells",
      },
    ] as const,
  bulkRunStatus: ({
    reportId,
    versionId,
  }: {
    reportId: string;
    versionId?: string;
  }) =>
    [
      {
        ...reportsKeys.all[0],
        reportId,
        versionId,
        feature: "bulkRunStatus",
      },
    ] as const,
  cellContent: (reportId: string, cellIds: string[]) =>
    [
      {
        ...reportsKeys.all[0],
        reportId,
        cellIds,
        feature: "cellContent",
      },
    ] as const,
  documentListsV0: (orgId?: string) =>
    [
      {
        ...reportsKeys.all[0],
        orgId,
        level: "user",
        feature: "documentLists",
      },
    ] as const,
  templateMetadata: (orgId?: string) =>
    [
      {
        ...reportsKeys.all[0],
        orgId,
        level: "user",
        feature: "/template-metadata",
      },
    ] as const,
  shareRequests: (matrixId?: string) =>
    [
      {
        ...reportsKeys.all[0],
        matrixId,
        level: "user",
        feature: "shareRequests",
      },
    ] as const,
};

// Typed reports key factory context
// QueryFunctionContext is an object that is passed as argument to the queryFn, this is simply a way of typing it
export type ReportsQueryContext = QueryContextFromKeys<typeof reportsKeys>;

// Get all reports (that the user has permission to see)
// Returns ReportInfo object (not full Report type)
export const useQueryReports = () =>
  useQuery({
    queryKey: reportsKeys.userReports(),
    queryFn: userReportsFetcher,
  });

const userReportsFetcher = async () => api.reports.getReports();

export const useQueryPublicPromptLists = () =>
  useQuery({
    queryKey: reportsKeys.promptLists(),
    queryFn: () => api.reports.getPublicPromptLists(),
    retry: 2,
  });

export const useQueryPromptLists = (orgId?: string) =>
  useQuery({
    queryKey: reportsKeys.promptLists(orgId),
    queryFn: promptListsFetcher,
    enabled: !!orgId,
    retry: 2,
  });

export const useQueryTemplateMetadata = (orgId?: string) =>
  useQuery({
    enabled: !!orgId,
    queryKey: reportsKeys.templateMetadata(orgId),
    queryFn: api.reports.getUserTemplateMetadata,
  });

export const promptListsFetcher = async ({
  queryKey: [{ orgId }],
}: ReportsQueryContext["promptLists"]) =>
  // check id at runtime because it can be `undefined`
  typeof orgId === "undefined"
    ? Promise.resolve(undefined)
    : api.reports.getPromptListsByOrgId(orgId);

type BuildReportBulkPayload = {
  reportId: string;
  orgId: string;
};

type GetPreviewReportPayload = {
  reportId: string;
  versionId: string;
};

export const useBuildBulkReportMutation = () => {
  const user = useSelector(getUser) ?? undefined;
  return useMutation({
    mutationFn: ({ reportId, orgId }: BuildReportBulkPayload) =>
      api.reports.buildBulkReport(reportId, orgId),
    // While mutationFn is called:
    onMutate: async ({ reportId, orgId }: BuildReportBulkPayload) =>
      // Not doing anything right now
      null,
    onError: (err, { reportId }, context) => {
      console.error(`Error with /build-large: ReportID: ${reportId}`);
      sendReportsSlackError({
        msg: "Error with /build-large",
        reportId,
        user,
      });
    },
  });
};

/** Mutation to optimistically delete a report from the cache */
export const useBatchDeleteReportMutation = () => {
  const user = useSelector(getUser) ?? undefined;
  return useMutation({
    mutationFn: (reportIds: string[]) =>
      api.reports.deleteReportBatch(reportIds),
    // When mutate is called:
    onMutate: async (reportIds) => {
      // Cancel any outgoing refetches
      // (so they don't overwrite our optimistic update)
      // Cancel all refetches related to repos (so we don't fetch the newly deleted repo)
      await queryClient.cancelQueries({ queryKey: reportsKeys.all });
      reportIds.forEach(async (curId) => {
        // Cancel all refetches related to this reportId
        await queryClient.cancelQueries({ queryKey: [{ curId }] });

        // Optimistically delete all things related to this reportId
        queryClient.removeQueries({ queryKey: [{ curId }] });
      });
    },
    // If the mutation fails,
    // use the context returned from onMutate to roll back
    onError: (err, reportIds: string[]) => {
      console.error(`Error with /delete: ReportIDs: ${reportIds}`);
      sendReportsSlackError({
        msg: "Error with /delete",
        user,
        batchDeleteReports: reportIds,
      });
    },
    // Always refetch after error or success:
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: reportsKeys.all });
    },
  });
};

/** Mutation to optimistically delete a report from the cache */
export const useDeleteReportMutation = () => {
  const user = useSelector(getUser) ?? undefined;
  return useMutation({
    mutationFn: (reportId: string) => api.reports.deleteReportById(reportId),
    // When mutate is called:
    onMutate: async (reportId) => {
      // Cancel any outgoing refetches
      // (so they don't overwrite our optimistic update)
      // Cancel all refetches related to repos (so we don't fetch the newly deleted repo)
      await queryClient.cancelQueries({ queryKey: reportsKeys.all });
      // Cancel all refetches related to this reportId
      await queryClient.cancelQueries({ queryKey: [{ reportId }] });

      // Snapshot the previous value of reports
      const previousReports = queryClient.getQueryData(
        reportsKeys.userReports()
      );

      // Optimistically delete all things related to this reportId
      queryClient.removeQueries({ queryKey: [{ reportId }] });

      // Return a context object with the snapshotted value
      return { previousReports };
    },
    // If the mutation fails,
    // use the context returned from onMutate to roll back
    onError: (err, reportId, context) => {
      queryClient.setQueryData(
        reportsKeys.userReports(),
        context?.previousReports
      );
      console.error(`Error with /delete: ReportID: ${reportId}`);
      sendReportsSlackError({ msg: "Error with /delete", reportId, user });
    },
    // Always refetch after error or success:
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: reportsKeys.all });
    },
  });
};

export const usePreviewReportMutation = () =>
  useMutation({
    mutationFn: async ({ reportId, versionId }: GetPreviewReportPayload) => {
      const previewReport = await api.reports.getReportById({
        reportId,
        versionId,
      });
      const ids = previewReport.tabs.reduce((idList: string[], tab) => {
        const tabCellIds = tab.cells
          .filter((cell) => !cell.disabled)
          .map((cell) => cell.id);
        const groupIds = (tab.grouping_content || [])?.map((cell) => cell.id);
        return [...idList, ...tabCellIds, ...groupIds];
      }, []);

      const cellResponse = await api.reports.fetchCellContent({
        reportId: previewReport.id,
        cellIds: ids,
        prune: true,
        isGiga: previewReport.is_giga ?? false,
      });

      return { previewReport, cellResponse };
    },
  });

export const useQueryDocuments = (
  orgId: string,
  payload: GetDocumentsPayload,
  enabled: boolean
) =>
  useInfiniteQuery({
    queryKey: reportsKeys.documents(orgId, payload),
    queryFn: queryDocuments,
    enabled,
    retry: false,
    getNextPageParam: (lastPage, pages) =>
      (lastPage?.length ?? 0) === EXPANDED_SEARCH_LIMIT
        ? pages.length
        : undefined,
  });

export const queryDocuments = async ({
  queryKey: [{ orgId, params }],
  pageParam = 0,
}: ReportsQueryContext["documents"]) =>
  typeof orgId === "undefined"
    ? Promise.resolve(undefined)
    : api.reports.getDocuments(orgId, {
        ...params,
        offset: pageParam * params.limit,
      });

export const useQueryMatrixUsers = (matrixId: string, enabled?: boolean) => {
  return useQuery({
    queryKey: reportsKeys.userRoles(matrixId),
    queryFn: () => api.reports.getMatrixUsers(matrixId),
    enabled,
  });
};

export const useRemoveMatrixUserMutation = (
  matrixId: string,
  onError?: (err) => void
) => {
  return useMutation({
    mutationFn: ({ matrixId, userId }: RemoveMatrixUserParams) =>
      api.reports.removeMatrixUser({ matrixId, userId }),
    onMutate: () => {
      queryClient.cancelQueries({
        queryKey: reportsKeys.userRoles(matrixId),
      });
    },
    onSettled: () => {
      queryClient.invalidateQueries(reportsKeys.userRoles(matrixId));
    },
    onError: (err) => {
      onError && onError(err);
    },
  });
};

export const useAddMatrixUserMutation = (
  matrixId: string,
  invokeRest: InvokeRestFunc,
  onSuccess?: () => void,
  onError?: (err) => void
) => {
  // Only use REST endpoint if doc permissions checks are enabled
  const shouldUseRestEndpoint =
    useFeatureFlag("enableMatrixDocPermissionsChecks") ?? false;
  const dispatch = useAppDispatch();
  return useMutation({
    mutationFn: async ({ matrixId, email, role }: AddMatrixUserParams) => {
      if (shouldUseRestEndpoint) {
        const res = await invokeRest("/sharing/add-user", { email, role });
        if (res["body"]?.["doc_permission_output"]["success"]) {
          dispatch(clearAddMatrixUserStatus());
        } else {
          dispatch(
            setAddMatrixUserStatus(res["body"]?.["doc_permission_output"])
          );
        }
        return res;
      } else return await api.reports.addMatrixUser({ matrixId, email, role });
    },
    onMutate: () => {
      queryClient.cancelQueries({
        queryKey: reportsKeys.userRoles(matrixId),
      });
    },
    onSettled: () => {
      queryClient.invalidateQueries(reportsKeys.userRoles(matrixId));
      // invalidate share requests for the matrix ID
      queryClient.invalidateQueries(reportsKeys.shareRequests(matrixId));
    },
    onSuccess: () => {
      onSuccess && onSuccess();
    },
    onError: (err) => {
      onError && onError(err);
    },
  });
};

export const useSetMatrixUserRoleMutation = (
  matrixId: string,
  invokeRest: InvokeRestFunc,
  onError?: (err) => void
) => {
  // Only use REST endpoint if doc permissions checks are enabled
  const useRestEndpoint =
    useFeatureFlag("enableMatrixDocPermissionsChecks") ?? false;
  const dispatch = useAppDispatch();
  return useMutation({
    mutationFn: async ({ matrixId, userId, role }: EditMatrixUserParams) => {
      if (useRestEndpoint) {
        const res = await invokeRest("/sharing/edit-user", {
          user_id: userId,
          role,
        });
        if (res["body"]?.["doc_permission_output"]["success"]) {
          dispatch(clearAddMatrixUserStatus());
        } else {
          dispatch(
            setAddMatrixUserStatus(res["body"]?.["doc_permission_output"])
          );
        }
        return res;
      } else
        return await api.reports.setMatrixUserRole({ matrixId, userId, role });
    },
    onMutate: () => {
      queryClient.cancelQueries({
        queryKey: reportsKeys.userRoles(matrixId),
      });
    },
    onSettled: () => {
      queryClient.invalidateQueries(reportsKeys.userRoles(matrixId));
    },
    onError: (err) => {
      onError && onError(err);
    },
  });
};

export const useSetMatrixBookmarkedMutation = () => {
  return useMutation({
    mutationFn: ({ reportId, bookmarked }: UpdateMatrixBookmakredParams) =>
      api.reports.bookmarkReportById(reportId, bookmarked),

    onMutate: async ({ reportId, bookmarked }) => {
      await queryClient.cancelQueries({ queryKey: reportsKeys.userReports() });

      const previousMatrixMeta =
        queryClient.getQueryData<MatrixMetadataResponse>(
          reportsKeys.metadata(reportId)
        );

      // Optimistically update the bookmarked value
      queryClient.setQueryData<MatrixMetadataResponse>(
        reportsKeys.metadata(reportId),
        (data) => {
          // This query may not even be mounted
          if (!data) return data;

          return {
            ...data,
            bookmarked,
          };
        }
      );

      const previousReports = queryClient.getQueryData<ReportInfo[]>(
        reportsKeys.userReports()
      );

      if (previousReports) {
        // Delay optimisitc updating of newly bookmarked matrix
        // in order for the MatrixSidebarContextMenu to close
        // before the update renders the changed keep/keep_off Icon
        setTimeout(() => {
          queryClient.setQueryData<ReportInfo[]>(
            reportsKeys.userReports(),
            previousReports.map((report) => {
              if (report.id === reportId) {
                return { ...report, bookmarked: bookmarked };
              }
              return report;
            })
          );
        }, 300);
      }

      return { previousReports, previousMatrixMeta };
    },
    onError: (err, variables, context) => {
      if (context?.previousReports) {
        queryClient.setQueryData(reportsKeys.all, context.previousReports);
      }

      if (context?.previousMatrixMeta) {
        queryClient.setQueryData(
          reportsKeys.metadata(variables.reportId),
          context.previousMatrixMeta
        );
      }
    },
  });
};

export const useQueryMatrixMetadata = (matrixId: string) =>
  useQuery({
    queryKey: reportsKeys.metadata(matrixId),
    queryFn: () => api.reports.getMatrixMetadata(matrixId),
    enabled: !!matrixId,
  });

export const useQueryShareSettings = (matrixId: string) =>
  useQuery({
    queryKey: reportsKeys.shareSettings(matrixId),
    queryFn: () => api.reports.getMatrixShareSettings(matrixId),
    enabled: !!matrixId,
  });

export const useShareSettingsMutation = (matrixId: string) => {
  return useMutation({
    mutationFn: ({
      matrixId,
      permissionLevel,
      createIfMissing,
    }: UpdateShareSettingsParams) =>
      api.reports.updateMatrixShareSettings({
        matrixId,
        permissionLevel,
        createIfMissing,
      }),
    onMutate: ({
      matrixId,
      permissionLevel,
      createIfMissing,
    }: UpdateShareSettingsParams) => {
      queryClient.cancelQueries({
        queryKey: reportsKeys.shareSettings(matrixId),
      });
      queryClient.setQueryData(reportsKeys.shareSettings(matrixId), {
        matrixId: matrixId,
        shareSettings: permissionLevel,
      });
    },
    onSettled: () => {
      queryClient.invalidateQueries(reportsKeys.shareSettings(matrixId));
    },
  });
};

// TODO: Remove with Public Companies 1.0
export const useQueryPublicCompanies = (
  searchTerm: string,
  enabled: boolean
) => {
  return useQuery({
    queryKey: reportsKeys.publicCompanies(searchTerm),
    queryFn: queryPublicCompanies,
    enabled,
    retry: false,
  });
};

// TODO: Remove with Public Companies 1.0
export const queryPublicCompanies = async ({
  queryKey: [{ searchTerm }],
}: ReportsQueryContext["publicCompanies"]) =>
  api.filters.filterPublicRepos({ title: searchTerm });

export const useQueryDocumentBuildStatus = ({
  docIds,
  enabled,
  refetchInterval,
}: {
  docIds: string[];
  enabled: boolean;
  refetchInterval?: number;
}) =>
  useQuery({
    queryKey: reportsKeys.documentBuildStatus(docIds),
    queryFn: handleDocumentBuildStatusFetch,
    enabled,
    refetchInterval,
  });

const handleDocumentBuildStatusFetch = async ({
  queryKey: [{ docIds }],
}: ReportsQueryContext["documentBuildStatus"]) =>
  api.reportsBuild.buildBulkStatus(docIds);

type RenameReportMutationParams = {
  reportId: string;
  oldReportName: string;
  newReportName: string;
};

export const useRenameReportMutation = (
  options: UseMutationOptions<Report, unknown, RenameReportMutationParams>
) =>
  useMutation<Report, unknown, RenameReportMutationParams>({
    ...options,
    mutationFn: ({ reportId, oldReportName, newReportName }) =>
      api.reports.renameReportById(reportId, newReportName),
  });

type GenerateTitleMutationParams = {
  reportId: string;
  oldReportName: string;
  params: AutoTitleReportParams;
};

export const useGenerateTitleMutation = (
  options: UseMutationOptions<
    { name: string | null },
    unknown,
    GenerateTitleMutationParams
  >
) =>
  useMutation<{ name: string | null }, unknown, GenerateTitleMutationParams>({
    ...options,
    mutationFn: ({ reportId, oldReportName, params }) =>
      api.reports.autoTitleReportName(reportId, params),
  });

export const useQueryReportsMetadata = (
  options?: UseQueryOptions<MatrixListMetadata, unknown, MatrixListMetadata>
) =>
  useQuery<MatrixListMetadata>({
    ...options,
    queryKey: reportsKeys.userMatrixListMetadata(),
    queryFn: api.reports.getMatrixListMetadata,
  });

export const useQueryReportAndCells = ({
  reportId,
  versionId,
  signal,
}: {
  reportId: string;
  versionId?: string;
  signal?: AbortSignal;
}) =>
  useQuery({
    // eslint-disable-next-line @tanstack/query/exhaustive-deps
    queryKey: reportsKeys.reportAndCells({ reportId, versionId }),
    queryFn: () =>
      api.reports.getReportAndCells({ reportId, versionId, signal }),
    retry: false,
  });

export const useQueryReportBulkRunStatus = ({
  reportId,
  versionId,
  enabled,
}: {
  reportId: string;
  versionId?: string;
  enabled?: boolean;
}) =>
  useQuery({
    queryKey: reportsKeys.bulkRunStatus({ reportId, versionId }),
    queryFn: () =>
      api.reports.getSheetsBuildStatus(reportId, { version_id: versionId }),
    enabled: false,
    retry: false,
  });

export const useQueryCellContent = (reportId: string, cellIds: string[]) =>
  useQuery({
    enabled: !!reportId,
    queryKey: reportsKeys.cellContent(reportId, cellIds),
    queryFn: async () => {
      const res = await api.reports.fetchCellContent({
        reportId,
        cellIds,
        prune: false,
        isGiga: true,
      });

      // ensure we return in the same order as cellIds is in
      const idToCell = res.reduce((map, cell) => {
        map[cell.id] = cell;
        return map;
      }, {});

      return filterNullishValues(
        cellIds.map((cellId) => {
          return idToCell[cellId];
        })
      );
    },
    retry: false,
  });

export const useUpdateTemplateMetadataMutation = (
  orgId?: string,
  options?: UseMutationOptions<any, unknown, TemplateMetadataParams>
) =>
  useMutation<any, unknown, TemplateMetadataParams>({
    ...options,
    mutationFn: (params) => api.reports.setUserTemplateMetadata(params),
    onMutate: ({ template_id, template_metadata }) => {
      queryClient.setQueryData<any>(
        reportsKeys.templateMetadata(orgId),
        (data) => {
          return {
            ...data,
            [template_id]: {
              ...data?.[template_id],
              pinned: template_metadata.pinned,
            },
          };
        }
      );
    },
  });

export const useQueryShareRequests = (matrixId?: string) =>
  useQuery({
    queryKey: reportsKeys.shareRequests(matrixId),
    queryFn: async () => {
      if (matrixId) return api.reports.getSharingRequests(matrixId);
    },
    enabled: !!matrixId,
  });
