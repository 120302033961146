import React from "react";
import CloseIcon from "@mui/icons-material/Close";
import { Button, ButtonProps } from "source/components/library/Button";
import { classNames } from "core";

interface Props {
  children?: React.ReactNode;
  title?: string | React.ReactNode;
  description?: string;
  headerAlignment?: string;
  onClose?: () => void;
  actions?: ButtonProps[];
  titleStyleOverride?: string;
  overrideStyles?: string;
}

export const ModalHeader = ({
  title,
  description,
  onClose,
  actions,
  headerAlignment = "horizontal",
  titleStyleOverride,
  overrideStyles,
}: Props) => (
  <div
    className={classNames(
      "flex items-center gap-2 border-b px-4 py-4.5 align-middle",
      title || description ? "justify-between" : "justify-end",
      overrideStyles
    )}
  >
    {(title || description) && (
      <div
        className={classNames(
          "flex justify-start",
          headerAlignment === "horizontal"
            ? "flex-row items-center gap-4"
            : "flex-col gap-2"
        )}
      >
        <div
          className={`${
            titleStyleOverride
              ? titleStyleOverride
              : "text-xl font-medium leading-7"
          }`}
        >
          {title}
        </div>
        <div className="text-xs font-normal leading-4">{description}</div>
      </div>
    )}
    {!!actions?.length && (
      <div className="flex flex-row gap-4">
        {actions?.map(
          ({ label, onClick, isDisabled, variant, startIcon, size }, idx) => (
            <Button
              key={`modal-header-${idx}`}
              label={label}
              onClick={onClick}
              isDisabled={isDisabled}
              variant={variant}
              startIcon={startIcon}
              size={size}
            />
          )
        )}
      </div>
    )}
    {onClose && (
      <div className="z-10 cursor-pointer items-center text-darkGray hover:bg-hoverIcon">
        <CloseIcon onClick={onClose} fontSize="medium" />
      </div>
    )}
  </div>
);
