import { Filters } from "./filter/filters";
import { Meta } from "./meta";
import { Orgs } from "./orgs/orgs";
import { Pins } from "./pins";
import { Search } from "./search";
import { Companies } from "./companies";
import { Users } from "./users/users";
import { ActivityFeed } from "./activityFeed";
import { Views } from "./views/views";
import { PermissionGroups } from "./permission-groups/permissionGroups";
import { Notifications } from "./notifications";
import { Selections } from "./selections";
import { Repos } from "./repos/repos";
import { Bookmarks } from "./bookmarks/bookmarks";
import { Docs } from "./docs/docs";
import { Alerts } from "./alerts/alerts";
import { Downloads } from "./downloads";
import { Chat } from "./chat/chat";
import { AdvancedFilters } from "./advancedfilters/advancedfilters";
import { Reports } from "./matrix/report";
import { ReportsChat } from "./reportsChat";
import { Synonyms } from "./synonyms/synonyms";
import { ViewConfigs } from "./viewConfigs/viewConfigs";
import { ReportsBuild } from "./matrix/reportsBuild";
import { FastBuild } from "./fastBuild";
import { DocumentListAPI } from "./document-list/documentList";
import { AgentsAPI } from "./agents/agents";

export default class api {
  static docs = Docs;
  static filters = Filters;
  static meta = Meta;
  static orgs = Orgs;
  static alerts = Alerts;
  static permissionGroups = PermissionGroups;
  static pins = Pins;
  static repos = Repos;
  static search = Search;
  static companies = Companies;
  static selections = Selections;
  static users = Users;
  static activityFeed = ActivityFeed;
  static views = Views;
  static bookmarks = Bookmarks;
  static notifications = Notifications;
  static downloads = Downloads;
  static chat = Chat;
  static advancedfilters = AdvancedFilters;
  static reports = Reports;
  static reportsBuild = ReportsBuild;
  static reportsChat = ReportsChat;
  static synonyms = Synonyms;
  static viewConfigs = ViewConfigs;
  static fastBuild = FastBuild;
  static documentList = DocumentListAPI;
  static agents = AgentsAPI;
}
