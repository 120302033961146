import { IngestFilesResponse } from "source/components/matrix/types/reports.types";
import axios from "../axios";
import {
  BoxSearchParams,
  BoxSearchResponse,
  FastBuildDocData,
  IngestFilesResponseV2,
} from "source/types/matrix/fastBuild.types";
import { EarningsTranscript, InvestorPresentation } from "source/Types";
import { SourceType } from "source/components/matrix/menu/AddDocumentsModal/shared/types";

export type AuthStatus = {
  logged_in: boolean;
};

export type DetectNonTextContentParams = {
  ids: string[];
};

/* Publics Types */
export type TranscriptIngestParams = {
  events: EarningsTranscript[];
  company_id?: number;
};

export type PresentationIngestParams = {
  presentations: InvestorPresentation[];
};

// Map of ticker to array of accession numbers
export type SecIngestParams = {
  documents: {
    accession_number: string;
    company_name: string;
    exhibit_type?: string;
  }[];
};

export type RetryFastBuildDoc = {
  id: string;
  password?: string;
};

export type RetryFastBuildParams = {
  reportId: string;
  orgId: string;
  docs: RetryFastBuildDoc[];
};

export const ReportsBuild = {
  buildBulkStatus: (docIds: string[]): Promise<FastBuildDocData[]> =>
    axios
      .post(`/sheets/build/bulk-status`, {
        doc_ids: docIds,
      })
      .then(({ data }) => data),
  getOAuthState: (orgId: string): Promise<string> =>
    axios
      .get(`/v2/oauth-state`, { params: { org_id: orgId } })
      .then(({ data }) => data),
  getUserAuthState: (
    integration_type: SourceType,
    org_id: string
  ): Promise<AuthStatus> =>
    axios
      .get(
        `/v2/user-auth-status`,
        integration_type ? { params: { integration_type, org_id } } : {}
      )
      .then(({ data }) => data),
  deleteUserAuth: (integration_type: SourceType, org_id: string) =>
    axios.delete(
      `/v2/user-auth`,
      integration_type
        ? { params: { integration_type, org_id } }
        : { params: { org_id } }
    ),
  asyncIngestAzureDocs: async (params: any): Promise<any> =>
    axios
      .post(`/sheets/build/async-ingest-file-share-docs`, params)
      .then(({ data }) => data),
  asyncIngestLocalDocs: async (
    reportId: string,
    orgId: string,
    docs: { file: File; requestId: string }[]
  ): Promise<IngestFilesResponseV2> => {
    const form = new FormData();

    docs.forEach((doc) => {
      if (doc.file) {
        form.append("files", doc.file);
        form.append("request_ids", doc.requestId);
      }
    });
    return axios
      .post(
        `/sheets/fastbuild/ingest-local-docs?sheet_id=${reportId}&org_id=${orgId}`,
        form,
        {
          headers: { "Content-Type": "multipart/form-data" },
        }
      )
      .then(({ data }) => data);
  },
  asyncIngestUrls: async (
    reportId: string,
    orgId: string,
    requestIds: string[],
    urls: string[]
  ): Promise<IngestFilesResponseV2> => {
    return axios
      .post(`/sheets/fastbuild/ingest-url`, {
        org_id: orgId,
        sheet_id: reportId,
        urls: urls.map((i) => ({ url: i })),
        request_ids: requestIds,
      })
      .then(({ data }) => data);
  },
  detectNonTextContent: async (
    params: DetectNonTextContentParams
  ): Promise<any> =>
    axios.post(`/sheets/build/detect-non-text-content`, params),

  asyncIngestTranscripts: async (
    orgId: string,
    sheetId: string,
    params: TranscriptIngestParams
  ): Promise<IngestFilesResponse> => {
    const response = axios.post(
      `/sheets/build/async-ingest-transcripts?org_id=${orgId}&sheet_id=${sheetId}`,
      params
    );
    return (await response).data;
  },
  asyncIngestInvestorPresentations: async (
    orgId: string,
    sheetId: string,
    params: PresentationIngestParams
  ): Promise<IngestFilesResponse> => {
    const response = axios.post(
      `/sheets/build/async-ingest-investor-presentations?org_id=${orgId}&sheet_id=${sheetId}`,
      params
    );
    return (await response).data;
  },
  asyncIngestSEC: async (
    orgId: string,
    sheetId: string,
    params: SecIngestParams
  ): Promise<IngestFilesResponse> => {
    const response = await axios.post(
      `/sheets/build/async-ingest-sec-docs?org_id=${orgId}&sheet_id=${sheetId}`,
      params
    );
    return response.data;
  },
  retryFastBuild: async (params: RetryFastBuildParams): Promise<any> =>
    axios.post(
      `/sheets/build/async-retry-docs?sheet_id=${params.reportId}&org_id=${params.orgId}`,
      { docs: params.docs }
    ),
  searchBox: (params: BoxSearchParams): Promise<BoxSearchResponse> =>
    axios
      .post(`/sheets/build/search-box`, {
        extension: params.extension,
        parent_folder_id: params.parentId,
        org_id: params.org_id,
        query: params.query,
      })
      .then(({ data }) => data),
};
