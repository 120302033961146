import { useQueryRepo } from "source/api/repos/useQueryRepos";
import { RepoType } from "source/Types";
import { useGetRouter } from "../useGetRouter";

export const useCurrentRepo = (
  allowOnDocPage?: boolean
): RepoType | undefined => {
  const { router } = useGetRouter();
  let id: string | undefined = "";
  if (router.pathname?.includes("/repos/")) id = router.query.id;

  const repoQuery = useQueryRepo(id);
  return repoQuery.data;
};
