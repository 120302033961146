import React, {
  DetailedHTMLProps,
  forwardRef,
  ReactNode,
  TableHTMLAttributes,
} from "react";
import { classNames } from "../../index";

export interface TableRowProps
  extends DetailedHTMLProps<
    TableHTMLAttributes<HTMLTableRowElement>,
    HTMLTableRowElement
  > {
  children?: ReactNode;
}

const TableRow = forwardRef<HTMLTableRowElement, TableRowProps>(
  (props: TableRowProps, ref) => {
    const { children, className, ...rest } = props;
    return (
      <tr {...rest} ref={ref} className={classNames(className)}>
        {children}
      </tr>
    );
  }
);

export default TableRow;
