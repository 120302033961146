import { SheetColumnMetadata } from "source/components/matrix/types/reports.types";
import { IDocumentMime } from "./Types";
import { ReportPrompt } from "./components/matrix/types/tools.types";
import { ModelType } from "./constants/llms";
import { Colors } from "./constants/colors";

export const isDev =
  !process.env.NEXT_PUBLIC_VERCEL_ENV ||
  process.env.NEXT_PUBLIC_VERCEL_ENV === "development";
export const POSTHOG_KEY = process.env.NEXT_PUBLIC_POSTHOG_KEY;
export const SEGMENT_ANALYTICS_KEY =
  process.env.NEXT_PUBLIC_SEGMENT_ANALYTICS_KEY;

const getBaseURL = () => {
  if (process.env.NEXT_PUBLIC_BASE_URL) return process.env.NEXT_PUBLIC_BASE_URL;
  if (process.env.NEXT_PUBLIC_VERCEL_URL) {
    const protocol = process.env.NEXT_PUBLIC_VERCEL_URL.includes("localhost")
      ? ""
      : "s";
    return `http${protocol}://${process.env.NEXT_PUBLIC_VERCEL_URL}`;
  } else {
    return "http://localhost:3000";
  }
};

export const BASE_URL = getBaseURL();

export const callbackUrl = `${BASE_URL}/callback`;

export const DOT_NEW_DOMAINS = ["hebbia.new", "matrix.new", "grids.new"];

export const TOP_BAR_TOP_HEIGHT = 70;
export const TOP_BAR_BOTTOM_HEIGHT = 40;
export const TOP_BAR_HEIGHT = TOP_BAR_TOP_HEIGHT + TOP_BAR_BOTTOM_HEIGHT;
// vertical space above/below doc viewer modal border
export const DOC_VIEWER_MODAL_TOP_HEIGHT = 32;
// vertical padding at top of doc viewer within modal
export const DOC_VIEWER_MODAL_PADDING_Y = 48;
export const DOC_VIEWER_V2_MODAL_PADDING_Y = 57; // Distance between pdf display and top of screen (+ 1px)
export const DOC_VIEWER_V2_MODAL_PADDING_X = 33; // Distance between pdf display and right of screen (+ 1px)
export const REPORTS_TOPBAR_HEIGHT = 62; // px
export const BOTTOM_BAR_HEIGHT = 50;
export const MIN_SIDEBAR_WIDTH = 250;
export const MAX_SIDEBAR_WIDTH = 750;
export const COLLAPSED_SIDEBAR_WIDTH = 0;
export const DOC_TAB_WIDTH = 8;
export const SIDEBAR_TOOLTIP_DELAY = 500;

export const MAIN_SEARCH_INPUT_ID = "search-input";
export const DOC_VIEWER_SEARCH_INPUT_ID = "doc-viewer-search-input";

export const EXTRACTIVE_QA_SEARCH_CONFIDENCE_THRESHOLD = 0.65;
export const GOOD_PAIRWISE_SCORE_THRESHOLD = 0.5;
export const MATCH_THRESHOLD = 0.3; // if no result has at least this much relevance, say there were no good results
export const STRONG_MATCH_THRESHOLD = 0.6; // for score bucketing
export const PAGE_SIZE = 32; // loads x more results at a time

export const REPORTS_PAGINATION = 20;

export const EXTRACTIVE_QA_THRESHOLD = 0.25;

export const HIDE_SIDEBAR_PATHS: string[] = ["/"];

export const ALWAYS_SHOW_TOGGLE_MIMES = [
  "hebbia/dropbox.folder",
  "hebbia/earnings",
  "application/vnd.google-apps.folder",
  "hebbia/local.folder",
  "hebbia/findox.folder",
  "hebbia/folder",
  "hebbia/onedrive",
  "hebbia/onedrive.folder",
  "hebbia/onedrive.shared",
  "hebbia/onenote.book",
  "hebbia/onenote.section",
  "hebbia/sharepoint.site",
  "hebbia/sec",
  "application/rss+xml",
  "application/xml",
  "hebbia/box.folder",
];

export const HEBBIA_ORG_ID = "aa917b84-7bd4-4431-b3a4-9175bd9d1876";
export const BAAM_ORG_ID = "0aa143ff-0e42-43fb-9393-8cd454d84f22";
export const SILVER_LAKE_ORG_ID = "d98e6446-915e-4e3a-add5-5ae61f4d3233";
export const OKAPI_ORG_ID = "941960ba-3c3d-4100-b020-a1eb3f5e93fb";
export const MORGAN_MORGAN_ORG_ID = "d35d4a2e-d408-4945-8ba8-8c695a613d51";

export const MORGAN_MORGAN_REPO_ID = "408c0964-a1b2-4192-ad32-18be93b1b205";
export const MATTER_ID_KEY = "matter_id";
export const APPROX_NUM_MORGAN_MORGAN_REPO = 3020462;

// FilterSection repos
export const SIXTH_STREET_VENDOR_MANAGEMENT_REPO_ID =
  "a9cb1860-89d7-4a60-9a57-dc2c44667974";
export const MORGAN_MORGAN_DEPOSITIONS_REPO_ID =
  "a6e46053-01cd-4109-af34-c82ece1256b7";
export const FILTERS_DEMO_REPO_ID = "ca3e4f5a-825f-4da4-92d4-5da1ded9ac4d";

export const ALL_TICKERS_REPO_ID = "ffdc7ecd-8dc8-45de-9a4c-532e28fa5edc";
export const APPROX_NUM_DOCS_ALL_TICKERS_REPO = 155016;
export const APPROX_NUM_SEC_ALL_TICKERS_REPO = 64746;
export const APPROX_NUM_EARNINGS_ALL_TICKERS_REPO = 59967;

export const PITCHBOOK_REPO_ID = "9fbe69d5-b62f-4024-a6bf-9caf9d6f50a1";
export const APPROX_NUM_DOCS_PITCHBOOK_REPO = 315995;

export const ABSTRACT_REPO = "5f50bc61-3000-4f9d-89fd-54f491ab072b";
export const FEDERAL_RESERVE_MINUTES_REPO_ID =
  "c34971a8-99ea-4bbf-9296-1b74f7792d27";
export const PUBLIC_SOURCES = [
  // FEDERAL_RESERVE_MINUTES_REPO_ID,
  ALL_TICKERS_REPO_ID,
  PITCHBOOK_REPO_ID,
];

export const MULTI_REPO_EXPERIENCE = [PITCHBOOK_REPO_ID, ALL_TICKERS_REPO_ID];
// Slack integration constants:
export const SLACK_WEBHOOK_URL =
  "https://hooks.slack.com/services/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";
export const REPORTS_SLACK_WEBHOOK_URL =
  "https://hooks.slack.com/services/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";
export const CHATDOCS_SLACK_WEBHOOK_URL =
  "https://hooks.slack.com/services/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";
export const REPORT_CELL_FEEDBACK_SLACK_WEBHOOK_URL =
  "https://hooks.slack.com/services/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";
export const ALERTS_PUBLICS_SLACK_WEBHOOK_URL =
  "https://hooks.slack.com/services/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";
export const DETECT_TABLES_SLACK_WEBHOOK_URL =
  "https://hooks.slack.com/services/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";
export const MAX_LINES = 3; // Number of lines of stack trace to display

export const SEE_MORE_THRESHOLD = 10;
export const SIDEBAR_PAGINATION = 100;
export const ADVANCED_FILTERS_PAGINATION = 10;
export const SEARCH_BAR_WIDTH = 1000;

// document type filters to allow on global search
export const ALLOWED_TYPE_FILTERS = [
  "sec",
  "earnings",
  // "box",
  // "findox",
  // "gdrive",
  // "local",
  // "web",
  // "tegus",
  // "onedrive",
  // "postgres",
  // "sharepoint",
  // "bipsync",
];

// mime type filters to allow on global search
export const ALLOWED_MIME_FILTERS: IDocumentMime[] = [
  "application/pdf",
  "application/vnd.ms-powerpoint",
  // "application/vnd.ms-excel",
  // "text/plain",
  // "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
];

export const DEFAULT_REPO_NAME = "Untitled";

export const SILVER_LAKE_VDR_REPO_ID = "07d8e047-d12d-49b0-8bfd-5fa326d0663f";

export const FORM_ADV_REPO_ID = "88d34813-9fd0-4481-855a-1b08b31f01b6";

export const FILTER_SECTION_PAGINATION = 3;
export const REPO_SECTION_PAGINATION = 5;

// Weaviate public document class name
export const PUBLIC_DOC_CLASS = "IndexPublicDocument";

export const HEBBIA_YEAR_KEY = "hebbia_year";

// Default to adding folder for new filter
export const DEFAULT_ADVANCED_FILTER_KEY = "repoId";

// sheets tool params constants
export const DEFAULT_TOOL_PARAM_MODEL: ModelType = "gpt-4o";
export const DEFAULT_TOOL_PARAM_OUTPUT_TYPE = "str";

/**
 * @deprecated This variable is deprecated. Please use getRetrieveColumnId instead
 */
export const SUMMARY_COL_NUM = 1;
export const SUMMARY_PROMPT: ReportPrompt = {
  x: SUMMARY_COL_NUM,
  static_column_id: "test",
  prompt:
    "Respond only with a summary of a document. Your summary should be no more than 17 words long, and mention document type (2 words), relevant parties (2 words), relevant dates (2 optional words) and any important takeaways, facts, and figures in that order.",
};
export const SUMMARY_COL_NAME = "Short Summary";
export const SUMMARY_COL_META: SheetColumnMetadata = {
  x: SUMMARY_COL_NUM,
  static_column_id: "test",
  name: SUMMARY_COL_NAME,
};

export const CUSTOM_DATE_PICKER_ID = "customDatePicker";
export const DATE_RANGE_PICKER_ID = "dateRangePicker";

export const SHOW_ELLIPSIS_LENGTH_THRESHOLD = 3;

export const KEY_LEFT = "ArrowLeft";
export const KEY_UP = "ArrowUp";
export const KEY_RIGHT = "ArrowRight";
export const KEY_DOWN = "ArrowDown";
export const KEY_PAGE_UP = "PageUp";
export const KEY_PAGE_DOWN = "PageDown";
export const KEY_PAGE_HOME = "Home";
export const KEY_PAGE_END = "End";

export const ARROW_KEYS = [KEY_LEFT, KEY_UP, KEY_RIGHT, KEY_DOWN];
export const DOCUMENT_LIMIT_FOR_TAKO = 15;

export const HIGHLIGHT_COLOR = Colors.lightPurpleHighlight;

export const DEFAULT_REDIRECT = "/matrix";
