import { useMutation, useQuery } from "@tanstack/react-query";
import { queryClient } from "pages/_app";
import { BookmarkType } from "source/Types";
import api from "..";
import { QueryContextFromKeys } from "../utils";

// bookmarks query key factory
export const bookmarksKeys = {
  all: [{ scope: "bookmarks" }] as const,
};

// Typed dates key factory context
type BookmarksQueryContext = QueryContextFromKeys<typeof bookmarksKeys>;

// fetcher function --> fetcher only accept keys from the queryKey factory
export const bookmarksFetcher = async () => api.bookmarks.getBookmarks();

export const useQueryBookmarks = () => {
  const bookmarksQuery = useQuery({
    queryKey: bookmarksKeys.all,
    queryFn: bookmarksFetcher,
  });

  return bookmarksQuery;
};

export const useCreateBookmarkMutation = () =>
  useMutation({
    mutationFn: (repoId: string) => api.bookmarks.bookmark(repoId),
    onSuccess: (bookmark: BookmarkType) => {
      // TODO: manually update this
      queryClient.invalidateQueries(bookmarksKeys.all);
    },
  });

export const useDeleteBookmarkMutation = () =>
  useMutation({
    mutationFn: (repoId: string) => api.bookmarks.delete(repoId),
    onSuccess: (data, repoId) => {
      queryClient.invalidateQueries(bookmarksKeys.all);
    },
  });
