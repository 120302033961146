import React from "react";
import { Checkbox } from "source/components/gigabar/components/GigabarRow";

type FilterCheckBoxProps = {
  handleCheck?: () => void;
  isChecked: boolean;
  shouldShow?: boolean;
};

export const FilterCheckbox = ({
  handleCheck,
  isChecked,
  shouldShow,
}: FilterCheckBoxProps) => {
  if (shouldShow)
    return (
      <span className="cursor-pointer" onClick={handleCheck}>
        <Checkbox checked={isChecked} />
      </span>
    );
  return <></>;
};
