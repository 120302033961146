import { useQuery, UseQueryOptions } from "@tanstack/react-query";
import api from "..";
import { UserType } from "source/Types";

type UserData = {
  user: UserType;
};

export const useQueryCurrentUser = (
  opts?: Omit<UseQueryOptions<any, unknown, UserData, any[]>, "initialData">
) => {
  return useQuery({
    ...opts,
    queryKey: [{ method: "GET", route: "/v2/users" }],
    queryFn: async () => {
      const { data } = await api.users.get();
      return data;
    },
  });
};
