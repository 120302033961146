import React from "react";
import { SidebarToggle } from "../SidebarToggle";
import { useSelector } from "react-redux";
import { ClearTargetButton } from "../ClearTargetButton";
import { useDispatch } from "react-redux";
import {
  clearAllAppliedFiltersByKey,
  clearReportFilterValues,
  getReportFilterKey,
  getTargetAppliedFilters,
  getTargetDisabledFilters,
  getTargetStringValuesBySectionKey,
} from "source/redux/advanced";
import { FilterList } from "./components/FilterList";

type FilterSectionProps = {
  title: string;
  filterMap: Record<string, string[]>;
};

/** Renders an individual filter section */
export const FilterSection = ({ title, filterMap }: FilterSectionProps) => {
  const sectionKey = getReportFilterKey(title);

  return (
    <SidebarToggle
      title={title}
      badge={
        <FilterSectionBadge filterMap={filterMap} sectionKey={sectionKey} />
      }
      defaultOpen={true}
    >
      <FilterList filterMap={filterMap} sectionKey={sectionKey} />
    </SidebarToggle>
  );
};

type FilterSectionBadgeProps = {
  filterMap: Record<string, string[]>;
  sectionKey: string;
};

const FilterSectionBadge = ({
  filterMap,
  sectionKey,
}: FilterSectionBadgeProps) => {
  const dispatch = useDispatch();
  const filterKeys = Object.keys(filterMap);
  const appliedFilters = useSelector(getTargetAppliedFilters);
  const disabledFilters = useSelector(getTargetDisabledFilters);
  const numAppliedFilters = appliedFilters
    .filter((a) => filterKeys.some((k) => a.key === k))
    .filter(
      (a) =>
        !disabledFilters.some((d) => d.value === a.value && d.key === a.key)
    ).length;
  const numTargetFilters =
    useSelector(getTargetStringValuesBySectionKey(sectionKey)).length +
    numAppliedFilters;

  const handleClear = (e: React.MouseEvent) => {
    e.stopPropagation();
    dispatch(clearReportFilterValues({ sectionKey }));
    filterKeys.forEach((filterKey) =>
      dispatch(clearAllAppliedFiltersByKey(filterKey))
    );
  };

  return (
    <ClearTargetButton
      numTargets={numTargetFilters}
      handleClear={handleClear}
    />
  );
};
