import React, { DetailedHTMLProps, forwardRef, HTMLAttributes } from "react";
import { classNames, MaterialSymbolsIcon } from "../../index";
import { MaterialSymbolsIconSize } from "core/components/Icon/MaterialSymbolsIcon";
import { TailwindColor } from "core";

export interface IconTagProps
  extends Omit<
    DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>,
    "children"
  > {
  color?: TailwindColor;
  icon?: string | null;
  iconSize?: MaterialSymbolsIconSize;
  tagSize?: "sm" | "md" | "lg" | "xl" | "xxl";
}

const IconTag = forwardRef<HTMLDivElement, IconTagProps>((props, ref) => {
  const {
    color = "violet",
    className,
    icon,
    iconSize = "sm",
    tagSize = "sm",
    ...rest
  } = props;
  const tagSizeClasses = {
    sm: "w-5 h-5",
    md: "w-8 h-8",
    lg: "w-10 h-10",
    xl: "w-12 h-12",
  };
  return (
    <div
      {...rest}
      className={classNames(
        "flex items-center justify-center rounded-[4px] bg-opacity-40 p-0.5",
        `text-${color}-700 bg-${color}-300`,
        tagSizeClasses[tagSize],
        className
      )}
    >
      {icon && <MaterialSymbolsIcon icon={icon} size={iconSize} filled />}
    </div>
  );
});

export default IconTag;
