import React, { createContext, ReactNode, useContext } from "react";

interface DocumentListModeContextValue {
  isModalMode: boolean;
  onClose?: () => void;
  isDocListsOpen: boolean;
}

const DocumentListModeContext = createContext<DocumentListModeContextValue>({
  isModalMode: false,
  isDocListsOpen: false,
});

interface Props {
  children: ReactNode;
  isModalMode: boolean;
  onClose?: () => void;
}

export const DocumentListModeProvider = ({
  children,
  isModalMode,
  onClose,
}: Props) => {
  return (
    <DocumentListModeContext.Provider
      value={{ isModalMode, onClose, isDocListsOpen: true }}
    >
      {children}
    </DocumentListModeContext.Provider>
  );
};

export const useDocumentListMode = () => useContext(DocumentListModeContext);
