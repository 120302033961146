/* eslint-disable react/prop-types */
import "antd/dist/antd.css";
import "emoji-mart/css/emoji-mart.css";
import "styles/globals.css";
import "core/css/LoadingShine.css";
import "styles/LoadingSuggestions.css";
import "styles/Sidebar.css";
import "styles/Swal.css";
import "typeface-inter";
import "tailwindcss/tailwind.css";
import "styles/emoji-mart.css";

import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file

// Import React PDF Viewer styles
import "styles/DocViewer.css";
import "source/components/markdown-editor/markdownEditor.css";

// PDF viewer css
import "@hebbia/pdfjs-dist/web/pdf_viewer.css";
import "source/components/deprecated-doc-modal/DeprecatedDocViewer/components/pdf/style/Highlight.css";
import "source/components/deprecated-doc-modal/DeprecatedDocViewer/components/pdf/style/MouseSelection.css";
import "source/components/deprecated-doc-modal/DeprecatedDocViewer/components/pdf/style/pdf_viewer.css";
import "source/components/deprecated-doc-modal/DeprecatedDocViewer/components/pdf/style/PdfHighlighter.css";
import "source/components/deprecated-doc-modal/DeprecatedDocViewer/components/pdf/style/Tip.css";
import "source/components/doc-viewer/style/PdfHighlighter.css";
import "source/components/matrix/modals/MatrixDocModal/chat/singleDocChat.css";

// Reports ag-grid
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import "styles/matrix.css";
import "styles/typographyOverrides.css";
import "styles/documentList.css";

import { UserProvider as Auth0Provider } from "@auth0/nextjs-auth0/client";
import Head from "next/head";
import React from "react";
import { Provider as ReduxProvider } from "react-redux";
import { useStore } from "source/redux";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import LogRocket from "logrocket";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import {
  QueryClient,
  QueryClientProvider,
  Hydrate,
} from "@tanstack/react-query";
import { datadogRum } from "@datadog/browser-rum";
import { datadogLogs } from "@datadog/browser-logs";
import { LicenseManager } from "ag-grid-enterprise";
import { ClientAuthBarrier } from "source/auth/ClientAuthBarrier";
import logger from "source/utils/logger";
import { usePosthogPageView } from "../source/hooks/tracking/usePosthogPageView";
import { LDClient } from "launchdarkly-js-client-sdk";
import { LDPreflightSetup } from "source/auth/LDPreflightSetup";
import { POSTHOG_KEY } from "source/constants";
import { API_ENV, BETA_ENV, PROD_ENV } from "source/envConstants";

declare global {
  interface Window {
    analytics: any;
    queryClient: any;
    ldClient: LDClient;
  }
}

// TODO: we may want to consider setting datadogLogs.logger.setHandler("console")
// when working in a dev environment, so that ddog logs are seen in console.
if (process.env.NEXT_PUBLIC_VERCEL_ENV === "production") {
  // Initialize datadog rum
  datadogRum.init({
    applicationId: "7255e257-341f-4713-9211-3599c0419c73",
    clientToken: "pubf277236efdacd50a2f2683901707d514",
    site: "datadoghq.com",
    service: "interdoc-next",
    env: "prod",
    // Specify a version number to identify the deployed version of your application in Datadog
    // version: '1.0.0',
    sessionSampleRate: 100,
    sessionReplaySampleRate: 20,
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel: "mask-user-input",
    allowedTracingUrls: [/https:\/\/.*\.hebbia\.ai/],
  });

  datadogRum.startSessionReplayRecording();
}

datadogLogs.init({
  clientToken: "pub88b93967b52709bec030f7098fdaac93",
  site: "datadoghq.com",
  service: "interdoc-next",
  env: process.env.NEXT_PUBLIC_VERCEL_ENV === "production" ? "prod" : "dev",
  // Specify a version number to identify the deployed version of your application in Datadog
  // version: '1.0.0',
  forwardErrorsToLogs: true,
  sessionSampleRate: 100,
});

if (
  process.env.NEXT_PUBLIC_VERCEL_ENV === "production" &&
  (API_ENV === PROD_ENV || API_ENV === BETA_ENV) &&
  typeof window !== "undefined"
) {
  import("posthog-js").then((posthog) => {
    if (POSTHOG_KEY) {
      // Initialize posthog
      posthog.posthog.init(POSTHOG_KEY, {
        api_host: "https://search.hebbia.ai/ingest",
      });
    }
  });
}

if (process.env.NEXT_PUBLIC_VERCEL_ENV === "production") {
  // Initialize logrocket
  LogRocket.init("7lppet/hebbia-production", {
    mergeIframes: true,
    ingestServer: "https://search.hebbia.ai/lr",
  });
} else {
  LogRocket.init("7lppet/hebbia-local", { mergeIframes: true });
}

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: Infinity,
      refetchOnWindowFocus: false,
      networkMode: "always",
    },
    mutations: {
      networkMode: "always",
    },
  },
});

// Uncomment this to directly access queryClient in the browser console (only do this in dev)
// if (typeof window !== "undefined") {
//   window.queryClient = queryClient;
// }

if (typeof window !== "undefined") {
  if (process.env.NEXT_PUBLIC_AG_GRID_LICENSE_KEY) {
    // Set AG Grid license key
    LicenseManager.setLicenseKey(process.env.NEXT_PUBLIC_AG_GRID_LICENSE_KEY);
  } else {
    logger.error("Unable to find AG Grid License key.");
  }
}

const App = ({ Component, pageProps }) => {
  const store = useStore(pageProps.state);
  usePosthogPageView();

  return (
    <>
      <Head>
        <link rel="shortcut icon" href="/images/favicon.ico" sizes="16x16" />
        <link
          href="https://fonts.googleapis.com/css2?family=Material+Symbols+Sharp:opsz,wght,FILL,GRAD@24,400,0..1,0"
          rel="stylesheet preconnect"
        />
        <link
          href="https://fonts.googleapis.com/css2?family=Material+Symbols+Outlined:opsz,wght,FILL,GRAD@24,400,0..1,0"
          rel="stylesheet preconnect"
        />
        <link
          href="https://fonts.googleapis.com/css2?family=Material+Symbols+Rounded:opsz,wght,FILL,GRAD@24,400,0..1,0"
          rel="stylesheet preconnect"
        />
      </Head>
      <QueryClientProvider client={queryClient}>
        <Hydrate state={pageProps.dehydratedState}>
          <ReduxProvider store={store}>
            <Auth0Provider>
              <DndProvider backend={HTML5Backend}>
                <ClientAuthBarrier>
                  <LDPreflightSetup>
                    <Component {...pageProps} />
                  </LDPreflightSetup>
                </ClientAuthBarrier>
              </DndProvider>
            </Auth0Provider>
          </ReduxProvider>
        </Hydrate>
        <ReactQueryDevtools initialIsOpen={false} position="bottom-right" />
      </QueryClientProvider>
    </>
  );
};

export default App;
