import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ReduxState } from ".";
import { BuildActionType, BuildStatusType } from "source/Types";

/*
This represents a build action we are tracking the status of
`doc` should represent the file / folder we are waiting on to complete
*/
export type ActiveBuildType = {
  docId?: string;
  repoId?: string;
  uploadId?: string;
  action: BuildActionType;
  status: BuildStatusType;
  eta?: number;
};

export type ActiveBuildReduxType = {
  history: ActiveBuildType[];
  isLoaded: boolean;
};

type UpsertPayload = {
  idx: number;
  item: Partial<ActiveBuildType>;
};

const initialState: ActiveBuildReduxType = {
  history: [] as ActiveBuildType[],
  isLoaded: false,
};

const getActiveBuilds = (state: ReduxState) => state.activeBuilds.history;
export const getPendingRepoBuildIds = createSelector(
  getActiveBuilds,
  (builds) =>
    builds
      .filter((b) => b.repoId && b.status === "processing")
      .map((b) => b.repoId) as string[]
);
export const getMaxETA = createSelector(getActiveBuilds, (builds) =>
  builds.reduce((prev, b) => (b.eta && b.eta > prev ? b.eta : prev), 0)
);

const getUpload = (state: ReduxState) => state.upload;
export const getActiveBuildsUploaded = createSelector(
  getActiveBuilds,
  getUpload,
  (builds, upload) =>
    builds
      .filter((it) => it.uploadId)
      .map((it) => upload[it.uploadId as string])
);

const activeBuildSlice = createSlice({
  name: "activeBuild",
  initialState,
  reducers: {
    insertActiveBuild: (
      state: ActiveBuildReduxType,
      action: PayloadAction<ActiveBuildType>
    ) => {
      state.history.push(action.payload);
      return state;
    },
    updateActiveBuild: (
      state: ActiveBuildReduxType,
      action: PayloadAction<UpsertPayload>
    ) => {
      const current = state.history[action.payload.idx];
      if (current)
        state.history[action.payload.idx] = {
          ...current,
          ...action.payload.item,
        };
      return state;
    },
    removeActiveRepoBuild: (
      state: ActiveBuildReduxType,
      action: PayloadAction<string>
    ) => {
      state.history = state.history.filter((b) => b.repoId !== action.payload);
      return state;
    },
  },
});

export const { insertActiveBuild, updateActiveBuild, removeActiveRepoBuild } =
  activeBuildSlice.actions;
export const activeBuildReducer = activeBuildSlice.reducer;
