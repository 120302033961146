export const isProd = process.env.NEXT_PUBLIC_VERCEL_ENV === "production";

export const LOCAL_ENV = "local";
export const QA_ENV = "qa";
export const STAGING_ENV = "staging";
export const PROD_ENV = "prod";
export const BETA_ENV = "beta";

// For Devs: Modify to the string after the ?? to point FE to Prod, Staging, QA, or Local
export const API_ENV = process.env.NEXT_PUBLIC_API_ENV ?? STAGING_ENV;
// export const API_ENV = process.env.NEXT_PUBLIC_API_ENV ?? LOCAL_ENV;
// export const API_ENV = process.env.NEXT_PUBLIC_API_ENV ?? QA_ENV;
// export const API_ENV = process.env.NEXT_PUBLIC_API_ENV ?? PROD_ENV;

const API_URL =
  {
    [LOCAL_ENV]: "127.0.0.1:1370",
    [QA_ENV]: "qa-api.hebbia.ai",
    [STAGING_ENV]: "staging-api.hebbia.ai",
    [PROD_ENV]: "prod-api.hebbia.ai",
    [BETA_ENV]: "prod-api.hebbia.ai",
  }[API_ENV] || "staging-api.hebbia.ai";

const protocolChar = API_ENV !== "local" ? "s" : "";

export const WEBSERVER_URL = `http${protocolChar}://${API_URL}`;
export const WEBSOCKET_URL = `ws${protocolChar}://${API_URL}`;
