import React from "react";
import {
  AddingColumnsMessageStep,
  DecomposingMessageStep,
  MatrixChatMessageStepType,
  MatrixChatMessageType,
} from "source/types/matrix/matrixChat.types";
import DOMPurify from "dompurify";
import parse, { Element } from "html-react-parser";
import { markedWithPreprocessing } from "source/utils/helpers";
import { MatrixGridApi } from "source/components/matrix/types/grid.types";
import { getYFromReportTableRow } from "source/utils/matrix/cells";
import {
  convertRangeCitationsToIndividualCitations,
  matchCitation,
  replaceTextWithCitations,
} from "../../../../utils/matrix/citations";
import { Ping } from "core";
import { ChatCitation } from "source/types/agents/agents.types";
import { DocViewerChatCitation } from "../../modals/MatrixDocModal/chat/DocViewerChatCitation";

export const isMultiLineText = (
  input: HTMLDivElement,
  defaultHeight: number
): boolean => {
  return input.scrollHeight > defaultHeight;
};

/**
 * Given a user selection, returns the y coordinates off of aggrid row indices
 */
export const getCoordinatesForRangeSelection = (
  maxRows: number,
  gridApi: MatrixGridApi | undefined,
  retrieveColumnId?: string | undefined
): number[] | undefined => {
  if (!gridApi) return undefined;
  let currRowIndex = 0;
  let maxRowIndex = maxRows;
  const validCoordinates: number[] = [];
  while (currRowIndex < maxRowIndex) {
    const node = gridApi.getDisplayedRowAtIndex(currRowIndex);
    // If row grouping is applied, there will be extra row indices to account for due to group nodes
    // increment maxRowIndex to account for these extra rows
    if (node?.group === true) {
      maxRowIndex++;
    }
    const y = node?.data
      ? getYFromReportTableRow({ row: node.data, retrieveColumnId })
      : undefined;
    if (y !== undefined) validCoordinates.push(y);
    currRowIndex++;
  }
  return validCoordinates;
};

export const generativeAnswerParser = (
  text: string | undefined,
  createCitationLink: (
    citationNumber: string,
    isInDropdown: boolean
  ) => JSX.Element,
  hideCitations?: boolean
) => {
  if (!text) return;
  const htmlMarkdown = markedWithPreprocessing(text);
  const purifiedHTML = DOMPurify.sanitize(htmlMarkdown);
  return parse(purifiedHTML, {
    replace: (domNode) => {
      if (domNode.nodeType === Node.TEXT_NODE) {
        const textNode = domNode as unknown as Text;
        let text = textNode.data;
        text = convertRangeCitationsToIndividualCitations(text);
        if (text.match(matchCitation)) {
          return (
            <>
              {replaceTextWithCitations(
                text,
                createCitationLink,
                hideCitations
              )}
            </>
          );
        }
      }
    },
  });
};

// inserts a string after the last content in a html string
// Uses regex to replace citation numbers with span elements
const replaceCitationNumbers = (htmlString) => {
  return htmlString.replace(/\[(\d+)\]/g, (match, p1) => {
    return `<span class="citation">[${p1}]</span>`;
  });
};

// Uses regex to find the spot immediately before all the end closing tags
const insertBeforeLastContentClosingTag = (htmlString, insertedString) => {
  return htmlString
    .replaceAll("\n", "")
    .replace(/(.+[^>]<\/)(.+)/, (match, p1, p2) => {
      // the first match group includes part of a closing tag "</"
      // so we need to slice that out and add back to the second match
      return `${p1.slice(0, -2)}${insertedString}</${p2}`;
    });
};

export const singleDocChatAnswerParser = (
  text: string | undefined,
  isLoading?: boolean,
  citations?: ChatCitation[]
) => {
  if (!text) return;
  const htmlMarkdown = markedWithPreprocessing(text);
  let purifiedHTML = DOMPurify.sanitize(htmlMarkdown);

  // Replace citation numbers with span elements
  purifiedHTML = replaceCitationNumbers(purifiedHTML);

  // If we're loading, insert a dummy div right after the last content
  // The div will get swapped out for our loading component in the parse to react phase
  const finalHtml = isLoading
    ? insertBeforeLastContentClosingTag(
        purifiedHTML,
        '<span class="loading"></span>'
      )
    : purifiedHTML;

  // convert the html to react nodes
  return parse(finalHtml, {
    replace: (domNode) => {
      if (domNode.nodeType === Node.ELEMENT_NODE) {
        const element = domNode as unknown as Element;

        // if this is our dummy loading div, replace it with <Ping>
        if (
          domNode.nodeType === Node.ELEMENT_NODE &&
          element.name === "span" &&
          element.attributes?.find((i) => i.name === "class")?.value ===
            "loading"
        ) {
          return <Ping className={"ml-1"} size={"sm"} />;
        }

        // Replace span citation with DocViewerChatCitation
        if (
          domNode.nodeType === Node.ELEMENT_NODE &&
          element.name === "span" &&
          element.attributes?.find((i) => i.name === "class")?.value ===
            "citation"
        ) {
          const child = element.children[0] as unknown as Text;
          const citationNumber = child?.data?.replace(/[[\]]/g, "");
          const inlineCitation = citations?.find(
            (citation) => citation.id === Number(citationNumber)
          );
          if (!inlineCitation) return null;
          return (
            <>
              {" "}
              <DocViewerChatCitation
                className={"align-text-top"}
                citation={inlineCitation}
                number={Number(citationNumber)}
                isSingleDocChat
              />
            </>
          );
        }
      }
    },
  });
};

export const getCitationCount = (text: string) => {
  text = convertRangeCitationsToIndividualCitations(text);
  const matches = Array.from(text.matchAll(matchCitation));
  return matches.length;
};

// Helper for getTotalMessageLengthForScroll
// Returns one plus the number of "chips" within a loading step
export const getMessageStepLength = (step: MatrixChatMessageStepType) => {
  const decompStep = step as DecomposingMessageStep | undefined;
  if (decompStep) return (decompStep.source_filters?.length ?? 0) + 1;
  const addingColsStep = step as AddingColumnsMessageStep | undefined;
  if (addingColsStep) return (addingColsStep.column_names?.length ?? 0) + 1;
  return 1;
};

// This is a proxy dependency for when there is a new token.
// When the sum of the length of content or number of loading steps changes,
// That is when we want to rerun scroll (debounced of course)
export const getTotalMessageLengthForScroll = (
  messages: MatrixChatMessageType[]
) => {
  const totalMessageLength = messages
    .map((msg) => msg.content.length)
    .reduce((a, b) => a + b, 0);
  const lastMessage = messages.length
    ? messages[messages.length - 1]
    : undefined;
  const messageStepLength =
    lastMessage?.steps
      .map((step) => getMessageStepLength(step))
      .reduce((a, b) => a + b, 0) ?? 0;
  return totalMessageLength + messageStepLength;
};

// Currently this is the only Matrix Chat step possible to undo
export const canUndoStep = (step: MatrixChatMessageStepType) => {
  return step.loading_step_type === "converting_column";
};

export const DEFAULT_AGENT_MESSAGE_ID = "system-placeholder-message";

export type GetDefaultAgentMessageProps = {
  name?: string;
};

export const getDefaultAgentMessage = ({
  name,
}: GetDefaultAgentMessageProps): MatrixChatMessageType => {
  const messageContent = `Hi${name ? ` ${name}` : ""}! Ask me anything and I will use the Matrix to answer.`;

  return {
    id: DEFAULT_AGENT_MESSAGE_ID,
    role: "system",
    content: messageContent,
    steps: [],
    loading: false,
    isFromPreviousSession: true,
  };
};

export const hasOnlyDefaultMessage = (messages?: MatrixChatMessageType[]) =>
  messages &&
  messages?.filter((message) => message.id === DEFAULT_AGENT_MESSAGE_ID)
    .length === messages?.length &&
  messages.length > 0;
