import { DocumentType, RepoType, ViewResponse } from "source/Types";
import axios from "../axios";

export type ViewedDocsResponseType = {
  // Array of 20 most recently viewed docs
  docs: DocumentType[];
};

// todo: support batch view
export const Views = {
  // TODO: Unbeat this route, get the repo_id the flub out of the route
  view: (
    repo_id?: string,
    org_id?: string,
    doc_id?: string
  ): Promise<ViewResponse> =>
    axios
      .post(`/v2/views/${repo_id}/view`, {
        org_id,
        doc_id,
      })
      .then(({ data }) => data),
  getViewedDocs: (params: {
    org_id?: string;
  }): Promise<ViewedDocsResponseType> =>
    axios
      .get(`/v2/views/nothing/get-viewed-docs`, {
        params,
      })
      .then(({ data }) => data)
      .catch(() => ({ docs: [] })),
  getViewedRepos: (): Promise<RepoType[]> =>
    axios.get(`/v2/repo-views`).then(({ data }) => data.repos),
  getViewedCompanyRepos: (): Promise<RepoType[]> =>
    axios.get(`/v2/repo-views/company-docs`).then(({ data }) => data.repos),
  getViewedPitchbookRepos: (): Promise<RepoType[]> =>
    axios.get(`/v2/repo-views/pitchbook-docs`).then(({ data }) => data.repos),
};
