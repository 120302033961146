import { useDispatch, useSelector } from "react-redux";
import api from "source/api";
import { openPrettyAlert } from "source/utils/helpers";
import { setRunModalTarget } from "source/redux/ui";
import { DocumentType, RepoType } from "source/Types";
import { useRepoDocsDict } from "../repos/useRepoDocsDict";
import { useCurrentDoc } from "./useCurrentDoc";
import { useDownload } from "./useDownload";
import { useGlobalNavigator } from "../useSetRouter";
import { useUploadSourceSuccess } from "../useUploadSourceSuccess";
import {
  useBuildDocMutation,
  useCreateFoldersMutation,
  useDeleteDocsMutation,
  useUpdateDocDataMutation,
} from "source/api/docs/useMutateDocs";
import { getTargetDocs } from "source/redux/advanced";
import { useModal } from "source/components/modals/useModal";
import { ModalTypes } from "source/components/modals/modals.types";

export const useDocContextMenu = (doc: DocumentType, repo?: RepoType) => {
  const dispatch = useDispatch();
  const docsSelected = useSelector(getTargetDocs);
  const repoDocsDict = useRepoDocsDict(repo?.id);
  const fullDocsSelected = docsSelected
    .map((id) => repoDocsDict[id])
    .filter((doc) => !!doc) as DocumentType[];
  const currentDoc = useCurrentDoc();
  const { handleSuccess, handleError } = useUploadSourceSuccess();
  const { handleDownloadDoc } = useDownload();
  const { goToRepo, goToRepoAddDocs } = useGlobalNavigator();
  const { mutate: deleteDocs } = useDeleteDocsMutation();
  const { mutate: docUpdateDataMutation } = useUpdateDocDataMutation();
  const { mutate: buildMutation } = useBuildDocMutation();
  const { mutate: createFolderMutation } = useCreateFoldersMutation();
  const shareModal = useModal(ModalTypes.ShareModal);

  const showShare = () => {
    shareModal.open({ doc: doc });
  };
  const handleDelete = async () => {
    try {
      deleteDocs({
        docId: doc.id,
        repoId: repo?.id,
      });

      // Clears search state and goes to /repo
      if (currentDoc) goToRepo(doc.repo_id);
    } catch (err: any) {
      console.error(err);
      openPrettyAlert({
        title: "Error Deleting Document",
        text: "Please try again later. Contact support@hebbia.ai if the problem persists.",
        icon: "error",
        error: err,
        url: window.location.href,
      });
    }
  };

  const handleRefresh = async () => {
    // Refresh RSS feed
    api.repos
      .uploadRSSFeed(doc.repo_id, doc.data.url, doc.id)
      .then(({ data }) => {
        handleSuccess(data.doc);
      })
      .catch((err) => {
        handleError(err?.response?.data?.message);
      });
  };

  const handleReupload = async () => {
    handleDelete();
    goToRepoAddDocs(doc.repo_id);
  };

  const handleBuild = async (isHardBuild: boolean) => {
    try {
      buildMutation({ docId: doc.id, hard: isHardBuild, repoId: repo?.id });
    } catch (err: any) {
      console.error(err);
      openPrettyAlert({
        title: "Document Indexing Error",
        text: "Unable to index this document at this time. Please try again later or contact support@hebbia.ai if the problem persists.",
        icon: "error",
        error: err,
        url: window.location.href,
      });
    }
  };

  const handleBuildClick = (isHardBuild: boolean) =>
    openPrettyAlert({
      title: "Please Confirm",
      text: `Are you sure you want to ${isHardBuild ? "hard" : "soft"} build ${
        doc.title
      }?`,
      showConfirmButton: true,
      showCancelButton: true,
      confirmButtonText: `${isHardBuild ? "Hard" : "Soft"} Build`,
      cancelButtonText: "Cancel",
      icon: "question",
    }).then((res) => {
      if (res.isConfirmed) {
        handleBuild(isHardBuild);
      }
    });

  const handleForceOCR = async () => {
    try {
      docUpdateDataMutation({
        docId: doc.id,
        data: { force_ocr: true },
        repoId: repo?.id,
        message: "ocr",
      });
    } catch (err: any) {
      console.error(err);
      openPrettyAlert({
        title: "Error Forcing OCR",
        text: "Unable to force OCR at this time. Please try again later or contact support@hebbia.ai if the problem persists.",
        icon: "error",
        error: err,
        url: window.location.href,
      });
    }
  };

  const handleForceTableExtraction = async () => {
    try {
      docUpdateDataMutation({
        docId: doc.id,
        data: { force_table_extraction: true },
        repoId: repo?.id,
        message: "tables",
      });
    } catch (err: any) {
      console.error(err);
      openPrettyAlert({
        title: "Error Forcing Table Extraction",
        text: "Unable to extract tables at this time. Please try again later or contact support@hebbia.ai if the problem persists.",
        icon: "error",
        error: err,
        url: window.location.href,
      });
    }
  };

  const handleOpenReport = () => {
    dispatch(setRunModalTarget({ repo, docs: fullDocsSelected }));
  };

  const handleAddFolder = async () => {
    createFolderMutation({ docId: doc.id, repoId: doc.repo_id });
  };

  return {
    showShare,
    handleDelete,
    handleRefresh,
    handleReupload,
    handleDownloadDoc,
    handleBuildClick,
    handleForceOCR,
    handleOpenReport,
    handleAddFolder,
    handleForceTableExtraction,
  };
};
