import { classNames } from "core";
import React from "react";

interface Props {
  children?: React.ReactNode;
  overrideStyles?: string;
}

export const ModalBody = ({ children, overrideStyles }: Props) => (
  <div className={classNames("flex w-full", overrideStyles)}>{children}</div>
);
