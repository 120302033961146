import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";
import LogRocket from "logrocket";
import { Dispatch } from "redux";
import api from "source/api";
import { UserType } from "source/Types";
import { ReduxState } from ".";

export type UserReduxType = {
  currentUserId: string | null;
  users: { [id: string]: UserType };
};

export const getCurrentUserId = (state: ReduxState) =>
  state.users.currentUserId;
export const getUsersDict = (state: ReduxState) => state.users.users;

export const getUsers = createSelector([getUsersDict], (users) =>
  Object.values(users)
);

export const getUser = createSelector(
  [getCurrentUserId, getUsersDict],
  (currentUserId, users) => {
    return currentUserId ? users?.[currentUserId] : undefined;
  }
);

export const getName = createSelector([getUser], (user) =>
  user?.name?.includes("@") ? undefined : user?.name?.split(" ")?.[0]
);

export const fetchUser = async (dispatch: Dispatch) => {
  const { data } = await api.users.get();
  dispatch(setUser(data.user));
  return data.user;
};

const userSlice = createSlice({
  name: "user",
  initialState: { users: {}, currentUserId: null } as UserReduxType,
  reducers: {
    setUser: (state: UserReduxType, action: PayloadAction<UserType | null>) => {
      if (action.payload) state.users[action.payload.id] = action.payload;
      state.currentUserId = action.payload?.id || null;
      if (action.payload?.id) {
        LogRocket.identify(action.payload?.id, {
          name: action.payload?.name,
          email: action.payload?.email,
          admin: action.payload?.org_role === "admin",
        });
      }
      return state;
    },
    upsertUsers: (state: UserReduxType, action: PayloadAction<UserType[]>) => {
      action.payload.forEach((user) => {
        state.users[user.id] = {
          ...state.users[user.id],
          ...user,
        };
      });
      return state;
    },
  },
});

export const { setUser, upsertUsers } = userSlice.actions;
export const userReducer = userSlice.reducer;
