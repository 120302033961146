import { queryClient } from "pages/_app";
import { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { docsKeys } from "source/api/docs/docsKeyFactory";
import { useQueryParentDocs } from "source/api/docs/useQueryDocs";
import { BatchKeysReposParams } from "source/api/filter/filters";
import { useQueryFilteredRepoDocs } from "source/api/filter/useQueryFilters";
import { getBuildFinished, setBuildFinished } from "source/redux/build";
import { FilterType, RepoType } from "source/Types";

type useFetchPaginatedDocsProps = {
  repo?: RepoType;
  filterTargets?: FilterType[];
  toggled?: boolean;
};

export const useFetchPaginatedParentDocs = ({
  repo,
  filterTargets,
  toggled,
}: useFetchPaginatedDocsProps) => {
  const dispatch = useDispatch();
  const buildFinished = useSelector(getBuildFinished);

  const { data, fetchNextPage, hasNextPage, isFetchingNextPage, isLoading } =
    useQueryParentDocs(repo?.id);

  const parentDocs = useMemo(() => {
    const docs = data?.pages?.map((doc) => doc?.docs ?? []).flat() ?? [];
    const unsupportedDocs =
      data?.pages?.map((doc) => doc?.failed_docs ?? []).flat() ?? [];
    return [...docs, ...unsupportedDocs];
  }, [data?.pages]);

  useEffect(() => {
    // Build is done, so refresh sidebar
    if (buildFinished) {
      queryClient.invalidateQueries(docsKeys.parentDocs(repo?.id));
      dispatch(setBuildFinished(false));
    }
  }, [buildFinished]);

  const params: BatchKeysReposParams = useMemo(() => {
    const keyValueFilters = filterTargets?.map((f) => ({
      key: f.key,
      value: f.value,
    }));

    return {
      filters: keyValueFilters ?? [],
      repo_ids: repo?.id ? [repo.id] : [],
      doc_ids: [],
      page: data?.pages.length ?? 0,
      size: 5,
      toggled,
    };
  }, [filterTargets, repo, data?.pages, toggled]);

  const filteredReposDocsQuery = useQueryFilteredRepoDocs(params);

  const hasMore = params.toggled
    ? (filteredReposDocsQuery.data?.length ?? 0) > 5
    : hasNextPage;

  return {
    parentDocs,
    hasNextPage,
    fetchNextPage,
    isFetchingNextPage,
    filteredReposDocsQuery,
    hasMore,
    isLoading,
  };
};
