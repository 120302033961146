import { Dialog } from "@headlessui/react";
import React, { forwardRef, ReactNode } from "react";
import { classNames } from "core";

export interface ModalHeaderProps
  extends Omit<React.HTMLProps<HTMLDivElement>, "title"> {
  title?: ReactNode;
  description?: ReactNode;
}

const ModalHeader = forwardRef<HTMLDivElement, ModalHeaderProps>(
  ({ title, description, className, ...rest }: ModalHeaderProps, ref) => {
    return (
      <div
        {...rest}
        className={classNames("flex flex-col gap-2 pl-6 pr-6 pt-6", className)}
        ref={ref}
      >
        {title && (
          <Dialog.Title className={"text-2xl font-semibold"}>
            {title}
          </Dialog.Title>
        )}
        {description && (
          <Dialog.Description className={"text-sm text-gray-500"}>
            {description}
          </Dialog.Description>
        )}
      </div>
    );
  }
);

export default ModalHeader;
