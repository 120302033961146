import { setActiveDocumentListId } from "../../redux/documentList";
import { useDispatch } from "react-redux";
import { useRouter } from "next/router";
import { useDocumentListMode } from "../../components/document-list/DocumentListModeContext";

export const useSetActiveDocumentList = () => {
  const dispatch = useDispatch();
  const router = useRouter();
  const { isModalMode, isDocListsOpen } = useDocumentListMode();

  return (documentListId: string | null, useReplace?: boolean) => {
    dispatch(setActiveDocumentListId(documentListId));
    if (isDocListsOpen && !isModalMode) {
      const path = documentListId ? `/browse?id=${documentListId}` : "/browse";
      if (useReplace) {
        router.replace(path);
      } else {
        router.push(path);
      }
    }
  };
};
