import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  AppliedDate,
  DocumentType,
  FilterType,
  GPTAnswerPinType,
  GroupByType,
  PinType,
  ResultType,
  SortKey,
  SuggestedFilterType,
  SuggestionType,
  SynonymAppliedType,
  TaskType,
} from "source/Types";
import { ReduxState } from ".";
import _ from "lodash";

export type WebsocketWithId = {
  id: string;
  ws: WebSocket;
};

export type SearchErrorWithId = {
  // webSocketId the error is from
  websocketId: string;
  error: string;
};

export const DATE_KEY_NAME = "_date_";
const DateRanges = ["week", "month", "quarter", "year"] as const;
export type DateRange = (typeof DateRanges)[number];

export const isDateRange = (arg: string | null): arg is DateRange | null => {
  if (arg === null) return false;
  return ["week", "month", "quarter", "year"].includes(arg);
};

export type SearchResponseType = {
  results: ResultType[];
  original_query?: string;
  spell_check_query?: string;
  exact_matches?: string[];
  update_spans?: any[];
  relevant_docs?: DocumentType[];
  answer?: string;
  query_suggestion?: string;
  answer_type?: string;
  suggestions?: SuggestionType[];
  splits?: any[];
  pins?: PinType[];
  generated_answer_pins?: GPTAnswerPinType[];
  continuation: boolean;
  word_counts: { [id: string]: number };
  index_meta?: { [key: string]: number };
  applied_start_date?: number;
  applied_end_date?: number;
  suggested_filters?: FilterType[];
  query_expansion_output?: string;
  sort_key: SortKey; // always returned; sometimes auto-applied by searcher
  ran_exact_match_search: boolean;
};

export type SummaryResponseType = {
  results: ResultType[];
};

export type SearchReduxType = {
  active: string | null;
  keywordActive: boolean;
  error: SearchErrorWithId | null;
  keywordError: SearchErrorWithId | null;
  isDebug: boolean;
  websocketLoadingController: string | null;
  searchResponseLoading: boolean;
  keywordResponseLoading: boolean;
  pageNumber: number;
  keywordPageNumber: number;
  originalQuery: string | null;
  spellCheckQuery: string | null;
  response: SearchResponseType | null;
  keywordResponse: SearchResponseType | null;
  pageHeadNodes: { [page: number]: ResultType };
  pageTailNodes: { [page: number]: ResultType };
  resultDocs: DocumentType[];
  suggestedFilters: SuggestedFilterType[] | null;
  appliedFilters: FilterType[] | null;
  disabledFilters: FilterType[] | null;
  debugQueryExpansion: string;
  debugHydeOutput: string;
  selectedDocId: string | null;
  selectedRepoId: string | null;
  selectedResultId: string | null;
  websockets: WebsocketWithId[];
  appliedDateFilter: AppliedDate | null;
  XBRLFinancials: { [key: string]: string };
  XBRLDocId: string | null;
  dateFilter: DateRange | null;
  disableDateAutoApplied: boolean;
  groupBy: GroupByType | null;
  noKeywords: boolean;
  // Indicates if the previously completed search ran exact match,
  // used for "download all exact matches" button
  ranExactMatchSearch: boolean;

  tasks: TaskType[]; // Tools used in search currently just for search step component
  taskIdsToDisplay: string[];

  // Search Lifecycle Variables
  // Vars to understand at what stage each search step is on
  searchRunning: boolean; // If the *entire* search pipeline is running (not just single search response)
  // True if keyword search was automatically toggled on or off during keyword search
  keywordAutoToggled: boolean;
  activeSynonyms: SynonymAppliedType;
};

export const getSearchResponse = (state: ReduxState) => state.search.response;
export const getKeywordResponse = (state: ReduxState) =>
  state.search.keywordResponse;
export const getSelectedDocId = (state: ReduxState) =>
  state.search.selectedDocId;
export const getSelectedResultId = (state: ReduxState) =>
  state.search.selectedResultId;
export const getSelectedRepoId = (state: ReduxState) =>
  state.search.selectedRepoId;
export const getSearchActive = (state: ReduxState) => state.search.active;
export const getKeywordSearchActive = (state: ReduxState) =>
  state.search.keywordActive;
export const getSearchDebug = (state: ReduxState) => state.search.isDebug;
export const getSearchError = (state: ReduxState) => state.search.error;
export const getSearchLoadingController = (state: ReduxState) =>
  state.search.websocketLoadingController;
export const getSearchResponseLoading = (state: ReduxState) =>
  state.search.searchResponseLoading;
export const getKeywordResponseLoading = (state: ReduxState) =>
  state.search.keywordResponseLoading;
export const getSearchPageNumber = (state: ReduxState) =>
  state.search.pageNumber;
export const getKeywordPageNumber = (state: ReduxState) =>
  state.search.keywordPageNumber;
export const getSearchWebsockets = (state: ReduxState) =>
  state.search.websockets;
export const getSearchResults = createSelector(
  getSearchResponse,
  (response) => response?.results || []
);
export const getKeywordSearchResults = createSelector(
  getKeywordResponse,
  (keywordResponse) => keywordResponse?.results || []
);
/**
 * Returns the docs that were retrieved by the previous search.
 */
export const getSearchRelevantDocs = (state: ReduxState) =>
  state.search.response?.relevant_docs || [];
export const getRanExactMatchSearch = (state: ReduxState) =>
  state.search.ranExactMatchSearch;
export const getSpellCheckQuery = (state: ReduxState) =>
  state.search.spellCheckQuery;
export const getSearchExactMatches = (state: ReduxState) =>
  state.search.response?.exact_matches;
export const getSearchOriginalQuery = (state: ReduxState) =>
  state.search.originalQuery;
export const getSearchAnswer = (state: ReduxState) =>
  state.search.response?.answer;
export const getSearchSplits = (state: ReduxState) =>
  state.search.response?.splits;
export const getSearchPins = (state: ReduxState) =>
  state.search.response?.pins || [];
export const getGPTPins = (state: ReduxState) =>
  state.search.response?.generated_answer_pins || [];
export const getSearchContinuation = (state: ReduxState) =>
  state.search.response?.continuation;
export const getSearchKeywordCounts = (state: ReduxState) =>
  state.search.response?.word_counts;
export const getSearchIndexMeta = (state: ReduxState) =>
  state.search.response?.index_meta;
export const getSearchSortKey = (state: ReduxState) =>
  state.search.response?.sort_key;
export const getSearchSuggestedFilters = (state: ReduxState) =>
  state.search.suggestedFilters;
export const getSearchSuggestions = createSelector(
  getSearchResponse,
  (response) => response?.suggestions || []
);
export const getSearchAppliedDateRange = (state: ReduxState): AppliedDate => {
  const start = state.search.appliedDateFilter?.startDate || null;
  const end = state.search.appliedDateFilter?.endDate || null;
  return { startDate: start, endDate: end };
};
export const getSearchDateFilter = (state: ReduxState): DateRange | null =>
  state.search.dateFilter;
export const getPageHeadNodes = (state: ReduxState) =>
  state.search.pageHeadNodes;
export const getPageTailNodes = (state: ReduxState) =>
  state.search.pageTailNodes;
export const getGenerativeQueryExpansion = (state: ReduxState) =>
  state.search.debugQueryExpansion;
export const getHydeOutput = (state: ReduxState) =>
  state.search.debugHydeOutput;
export const getSearchAppliedFilters = (state: ReduxState) =>
  state.search.appliedFilters;
export const getSearchXBRLFinancials = (state: ReduxState) =>
  state.search.XBRLFinancials;
export const getSearchXBRLDocId = (state: ReduxState) => state.search.XBRLDocId;
export const getSearchDisabledFilters = (state: ReduxState) =>
  state.search.disabledFilters;
export const getSearchDisableDateAutoApplied = (state: ReduxState) =>
  state.search.disableDateAutoApplied;
export const getSearchGroupBy = (state: ReduxState) => state.search.groupBy;
export const getSearchNoKeywords = (state: ReduxState) =>
  state.search.noKeywords;
export const getSearchAutoToggled = (state: ReduxState) =>
  state.search.keywordAutoToggled;
export const getTasks = (state: ReduxState) => state.search.tasks;
export const getTaskIdsToDisplay = (state: ReduxState) =>
  state.search.taskIdsToDisplay;

// Lifecycle
export const getSearchRunning = (state: ReduxState) =>
  state.search.searchRunning;

const getStateActiveSynonyms = (state: ReduxState) =>
  state.search.activeSynonyms;
export const getActiveSynonyms = createSelector(
  getStateActiveSynonyms,
  (activeSynonyms) => activeSynonyms || {}
);

const initialState: SearchReduxType = {
  active: null,
  keywordActive: false,
  error: null,
  keywordError: null,
  websocketLoadingController: null,
  searchResponseLoading: false,
  keywordResponseLoading: false,
  isDebug: false,
  pageNumber: 1,
  keywordPageNumber: 1,
  spellCheckQuery: null,
  originalQuery: null,
  response: null,
  keywordResponse: null,
  pageHeadNodes: {},
  pageTailNodes: {},
  resultDocs: [], // This is not to be used as a source of truth for docs, just grabbing info from search results
  suggestedFilters: [],
  appliedFilters: [],
  disabledFilters: [],
  debugQueryExpansion: "",
  debugHydeOutput: "",
  // used to highlight the corresponding docs for selected embed cards
  selectedDocId: null,
  selectedRepoId: null,
  selectedResultId: null,
  websockets: [],
  appliedDateFilter: null,
  XBRLFinancials: {},
  XBRLDocId: null,
  dateFilter: null,
  disableDateAutoApplied: false,
  groupBy: "doc",
  noKeywords: false,
  ranExactMatchSearch: false,
  tasks: [],
  taskIdsToDisplay: [],
  searchRunning: false,
  activeSynonyms: {
    updated_query: "",
    synonyms_applied: [],
  },
  keywordAutoToggled: false,
};

const searchSlice = createSlice({
  name: "search",
  initialState: initialState,
  reducers: {
    setSearchResponse: (
      state,
      action: PayloadAction<SearchResponseType | null>
    ) => {
      state.response = action.payload;
      state.searchResponseLoading = false;
      state.active = null;
      state.selectedResultId = null;
      state.selectedDocId = null;
      state.error = null;
      state.pageNumber = 1;
      state.groupBy = "doc";
      return state;
    },
    setKeywordResponse: (
      state,
      action: PayloadAction<SearchResponseType | null>
    ) => {
      state.keywordResponse = action.payload;
      state.keywordResponseLoading = false;
      state.keywordError = null;
      state.keywordPageNumber = 1;
      return state;
    },
    upsertSearchResponse: (
      state,
      action: PayloadAction<SearchResponseType | null>
    ) => {
      if (state.response !== null && action.payload) {
        const existingResultIds = state.response.results.map((r) => r.id);
        // First upsert results
        // action.payload.results.forEach(() =>)
        const newResult = action.payload.results.filter(
          (r) => !existingResultIds.includes(r.id)
        );
        // Second concat results
        //
        state.response.results = state.response.results.concat(newResult);
        state.response.results.sort((a, b) => b.relevance - a.relevance);
        return state;
      }
      state.response = action.payload;
      state.searchResponseLoading = false;
      state.active = null;
      state.selectedResultId = null;
      state.selectedDocId = null;
      state.error = null;
      state.pageNumber = 1;
      return state;
    },
    upsertKeywordSearchResponse: (
      state,
      action: PayloadAction<SearchResponseType | null>
    ) => {
      if (state.keywordResponse !== null && action.payload) {
        const existingResultIds = state.keywordResponse.results.map(
          (r) => r.id
        );
        const newResult = action.payload.results.filter(
          (r) => !existingResultIds.includes(r.id)
        );
        state.keywordResponse.results =
          state.keywordResponse.results.concat(newResult);
        state.keywordResponse.results.sort((a, b) => b.relevance - a.relevance);
        return state;
      }
      state.keywordResponse = action.payload;
      state.keywordResponseLoading = false;
      state.keywordError = null;
      state.keywordPageNumber = 1;
      return state;
    },
    setSearchResponseLoading: (state, action: PayloadAction<boolean>) => {
      state.searchResponseLoading = action.payload;
    },
    setKeywordResponseLoading: (state, action: PayloadAction<boolean>) => {
      state.keywordResponseLoading = action.payload;
    },
    setSpellCheckQuery: (state, action: PayloadAction<string | null>) => {
      state.spellCheckQuery = action.payload;
    },
    setOriginalQuery: (state, action: PayloadAction<string | null>) => {
      state.originalQuery = action.payload;
    },
    upsertWebsocket: (state, action: PayloadAction<WebsocketWithId>) => {
      state.websockets = [...state.websockets, action.payload];
      state.websocketLoadingController = action.payload.id;
      return state;
    },
    removeWebsocket: (state, action: PayloadAction<string>) => {
      state.websockets = state.websockets.filter(
        (c) => c.id !== action.payload
      );
      return state;
    },
    cancelControllers: (state) => {
      state.websocketLoadingController = null;
      state.searchResponseLoading = false;
      return state;
    },
    setSearchLoadingController: (
      state,
      action: PayloadAction<string | null>
    ) => {
      if (action.payload) {
        state.response = null;
        state.keywordResponse = null;
        state.active = null;
        state.selectedResultId = null;
      }
      state.websocketLoadingController = action.payload;
      return state;
    },
    setSearchActive: (state, action: PayloadAction<string | null>) => {
      state.active = action.payload;
      return state;
    },
    setKeywordSearchActive: (state, action: PayloadAction<boolean>) => {
      state.keywordActive = action.payload;
      return state;
    },
    setSearchDebug: (state, action: PayloadAction<boolean>) => {
      state.isDebug = action.payload;
      return state;
    },
    setSearchAnswer: (state, action: PayloadAction<string>) => {
      if (state.response) state.response.answer = action.payload;
      return state;
    },
    setSearchPageNumber: (state, action: PayloadAction<number>) => {
      state.pageNumber = action.payload;
      return state;
    },
    setKeywordSearchPageNumber: (state, action: PayloadAction<number>) => {
      state.keywordPageNumber = action.payload;
      return state;
    },
    setSearchError: (
      state,
      action: PayloadAction<SearchErrorWithId | null>
    ) => {
      state.error = action.payload;
      return state;
    },
    setKeywordSearchError: (
      state,
      action: PayloadAction<SearchErrorWithId | null>
    ) => {
      state.keywordError = action.payload;
      return state;
    },
    setRanExactMatchSearch: (state, action: PayloadAction<boolean>) => {
      state.ranExactMatchSearch = action.payload;
      return state;
    },
    setSearchSortKey: (state, action: PayloadAction<SortKey>) => {
      if (state.response) state.response.sort_key = action.payload;
      return state;
    },
    setSearchSuggestedFilters: (
      state,
      action: PayloadAction<FilterType[] | null>
    ) => {
      state.suggestedFilters = action.payload;
      return state;
    },
    setSearchAppliedFilters: (
      state,
      action: PayloadAction<FilterType[] | null>
    ) => {
      state.appliedFilters = action.payload;
      return state;
    },
    upsertSearchResults: (state, action: PayloadAction<ResultType[]>) => {
      if (state.response) {
        const curr = state.response.results || [];
        state.response.results = curr.concat(action.payload);
      }
      return state;
    },
    upsertKeywordResults: (state, action: PayloadAction<ResultType[]>) => {
      if (state.keywordResponse) {
        const curr = state.keywordResponse.results || [];
        state.keywordResponse.results = curr.concat(action.payload);
      }
      return state;
    },
    upsertSearchSuggestedFilters: (
      state,
      action: PayloadAction<FilterType[]>
    ) => {
      if (action.payload.length) {
        // Push to array if there are existing filters
        if (state.suggestedFilters) {
          action.payload.forEach((newFilter) => {
            // Avoid duplicating filters
            if (
              state.suggestedFilters &&
              !state.suggestedFilters.some(
                (filter) =>
                  filter.key === newFilter.key &&
                  filter.value === newFilter.value
              )
            )
              state.suggestedFilters.push(newFilter);
          });
        } else state.suggestedFilters = action.payload;
      }
      return state;
    },
    upsertSearchAppliedFilters: (
      state,
      action: PayloadAction<FilterType[]>
    ) => {
      if (action.payload.length) {
        // Push to array if there are existing filters
        if (state.appliedFilters) {
          action.payload.forEach((newFilter) => {
            // Avoid duplicating filters
            if (
              state.appliedFilters &&
              !state.appliedFilters.some(
                (filter) =>
                  filter.key === newFilter.key &&
                  filter.value === newFilter.value
              )
            )
              state.appliedFilters.push(newFilter);
          });
        } else state.appliedFilters = action.payload;
      }
      return state;
    },
    removeSearchAppliedFilter: (state, action: PayloadAction<FilterType>) => {
      state.appliedFilters =
        state.appliedFilters?.filter(
          (af) =>
            af.key !== action.payload.key && af.value !== action.payload.value
        ) ?? [];
      return state;
    },
    removeSearchDisabledFilter: (state, action: PayloadAction<FilterType>) => {
      // Remove filter from array
      state.disabledFilters =
        state.disabledFilters?.filter(
          (filter) => filter.value !== action.payload.value
        ) ?? null;
      return state;
    },
    upsertSearchDisabledFilters: (
      state,
      action: PayloadAction<FilterType[]>
    ) => {
      // Push to array if there are existing filters
      if (state.disabledFilters) {
        action.payload.forEach((newFilter) => {
          // Avoid duplicating filters
          if (
            state.disabledFilters &&
            !state.disabledFilters.some(
              (filter) =>
                filter.key === newFilter.key && filter.value === newFilter.value
            )
          )
            state.disabledFilters.push(newFilter);
        });
      } else state.disabledFilters = action.payload;
      return state;
    },
    setSearchDisabledFilters: (
      state,
      action: PayloadAction<FilterType[] | null>
    ) => {
      state.disabledFilters = action.payload;
      return state;
    },
    removeSearchResult: (state, action: PayloadAction<ResultType>) => {
      if (state.response?.results) {
        state.response.results = state.response.results.filter(
          (result) => result.id !== action.payload.id
        );
      }
      return state;
    },
    addSearchGPTAnswerPin: (state, action: PayloadAction<GPTAnswerPinType>) => {
      if (state.response?.generated_answer_pins) {
        state.response.generated_answer_pins.push(action.payload);
      }
      return state;
    },
    removeSearchPin: (state, action: PayloadAction<PinType>) => {
      if (state.response?.pins?.length) {
        state.response.pins = state.response.pins.filter(
          (pin) => action.payload.id !== pin.id
        );
      }
      return state;
    },
    removeSearchGPTAnswerPin: (
      state,
      action: PayloadAction<GPTAnswerPinType>
    ) => {
      if (state.response?.generated_answer_pins?.length) {
        state.response.generated_answer_pins =
          state.response.generated_answer_pins.filter(
            (pin) => action.payload.id !== pin.id
          );
      }
      return state;
    },
    upsertPageHeadNode: (state, action: PayloadAction<ResultType>) => {
      const result: ResultType = action.payload;
      if (result.page !== undefined) state.pageHeadNodes[result.page] = result;
      return state;
    },
    upsertPageTailNode: (state, action: PayloadAction<ResultType>) => {
      const result: ResultType = action.payload;
      if (result.page !== undefined) state.pageTailNodes[result.page] = result;
      return state;
    },
    setSelectedDocId: (state, action: PayloadAction<string | null>) => {
      state.selectedDocId = action.payload;
      return state;
    },
    setSelectedResultId: (state, action: PayloadAction<string | null>) => {
      state.selectedResultId = action.payload;
      state.active = action.payload;
      return state;
    },
    setSelectedRepoId: (state, action: PayloadAction<string | null>) => {
      state.selectedRepoId = action.payload;
      return state;
    },
    setSearchAppliedDateFilter: (
      state,
      action: PayloadAction<AppliedDate | null>
    ) => {
      state.appliedDateFilter = action.payload;
    },
    setSearchDateFilter: (state, action: PayloadAction<DateRange | null>) => {
      state.dateFilter = action.payload;
    },
    setSearchDebugQueryExpansion: (state, action: PayloadAction<string>) => {
      state.debugQueryExpansion = action.payload;
    },
    setSearchDebugHydeOutput: (state, action: PayloadAction<string>) => {
      state.debugHydeOutput = action.payload;
    },
    setSearchXBRLFinancials: (
      state,
      action: PayloadAction<Record<string, string>>
    ) => {
      state.XBRLFinancials = action.payload;
    },
    setSearchXBRLDocId: (state, action: PayloadAction<string | null>) => {
      state.XBRLDocId = action.payload;
    },
    setSearchDisableDateAutoApplied: (
      state,
      action: PayloadAction<boolean>
    ) => {
      state.disableDateAutoApplied = action.payload;
    },
    setSearchGroupBy: (state, action: PayloadAction<GroupByType | null>) => {
      state.groupBy = action.payload;
    },
    setSearchNoKeywords: (state, action: PayloadAction<boolean>) => {
      state.noKeywords = action.payload;
    },
    setTasks: (state, action: PayloadAction<TaskType[]>) => {
      state.tasks = action.payload;
    },
    upsertTasks: (state, action: PayloadAction<TaskType[]>) => {
      const tasksToUpsert = action.payload;
      const tasksToAdd = _.differenceBy(tasksToUpsert, state.tasks, "id");
      const upsertedTasks = state.tasks.map(
        (task) =>
          tasksToUpsert.find((upsertTool) => upsertTool.id === task.id) ?? task
      );
      state.tasks = [...upsertedTasks, ...tasksToAdd];
    },

    setTasksToDisplay: (state, action: PayloadAction<string[]>) => {
      state.taskIdsToDisplay = action.payload;
    },
    addTaskToDisplay: (state, action: PayloadAction<string>) => {
      if (!state.taskIdsToDisplay.includes(action.payload))
        state.taskIdsToDisplay = [...state.taskIdsToDisplay, action.payload];
    },
    removeTaskToDisplay: (state, action: PayloadAction<string>) => {
      state.taskIdsToDisplay = state.taskIdsToDisplay.filter(
        (taskId) => taskId !== action.payload
      );
    },
    setSearchRunning: (state, action: PayloadAction<boolean>) => {
      state.searchRunning = action.payload;
    },
    setSearchAutoToggled: (state, action: PayloadAction<boolean>) => {
      state.keywordAutoToggled = action.payload;
    },
    setActiveSynonyms: (state, action: PayloadAction<SynonymAppliedType>) => {
      state.activeSynonyms = action.payload;
    },
  },
});

export const {
  setSearchDebug,
  setSearchResponse,
  setKeywordResponse,
  upsertSearchResponse,
  upsertKeywordSearchResponse,
  setSearchResponseLoading,
  setKeywordResponseLoading,
  setSpellCheckQuery,
  setOriginalQuery,
  setSearchLoadingController,
  setSearchActive,
  setKeywordSearchActive,
  setSearchAnswer,
  setSearchPageNumber,
  setKeywordSearchPageNumber,
  setSearchError,
  setKeywordSearchError,
  setSearchSuggestedFilters,
  setSearchAppliedFilters,
  upsertSearchAppliedFilters,
  setRanExactMatchSearch,
  setSearchSortKey,
  upsertSearchSuggestedFilters,
  upsertSearchResults,
  upsertKeywordResults,
  removeSearchResult,
  removeSearchPin,
  upsertPageHeadNode,
  upsertPageTailNode,
  setSelectedDocId,
  setSelectedRepoId,
  upsertWebsocket,
  removeWebsocket,
  cancelControllers,
  setSelectedResultId,
  setSearchAppliedDateFilter,
  addSearchGPTAnswerPin,
  removeSearchGPTAnswerPin,
  setSearchDebugQueryExpansion,
  setSearchDebugHydeOutput,
  upsertSearchDisabledFilters,
  removeSearchDisabledFilter,
  setSearchDisabledFilters,
  setSearchXBRLDocId,
  setSearchXBRLFinancials,
  setSearchDateFilter,
  removeSearchAppliedFilter,
  setSearchDisableDateAutoApplied,
  setSearchGroupBy,
  setSearchNoKeywords,
  setTasks,
  upsertTasks,
  setTasksToDisplay,
  addTaskToDisplay,
  removeTaskToDisplay,
  setSearchRunning,
  setSearchAutoToggled,
  setActiveSynonyms,
} = searchSlice.actions;
export const searchReducer = searchSlice.reducer;
