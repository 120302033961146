import React, {
  DetailedHTMLProps,
  InputHTMLAttributes,
  useEffect,
  useState,
} from "react";
import AutosizeInput from "react-input-autosize";
import { classNames } from "core";

export type AutosizeTextFieldProps = DetailedHTMLProps<
  InputHTMLAttributes<HTMLInputElement>,
  HTMLInputElement
> & {
  onValueChange?: (v: string) => void;
  inputId?: string;
  inputClassName?: string;
  defaultValue: string;
};

export const AutosizeTextField = ({
  inputId,
  inputClassName,
  defaultValue,
  onValueChange,
  ...rest
}: AutosizeTextFieldProps) => {
  const [value, setValue] = useState("");
  const [prevValue, setPrevValue] = useState("");

  useEffect(() => {
    setValue(defaultValue);
    setPrevValue(defaultValue);
  }, [defaultValue]);

  return (
    <AutosizeInput
      autoComplete="off"
      id={inputId}
      value={value}
      inputClassName={classNames(
        "rounded text-sm font-medium p-1 border border-transparent text-gray-700 bg-inherit focus:outline-none hover:border-gray-300 focus:border-hebbiaBlue w-auto",
        inputClassName
      )}
      onChange={(e) => {
        setValue(e.target.value ?? "");
      }}
      onKeyDown={(e) => {
        // On enter key hit, trigger a blur event to unfocus the input +
        // submit the new name
        if (e.key === "Enter") (e.target as HTMLInputElement).blur();
        if (e.key === "a" && e.metaKey) (e.target as HTMLInputElement).select();
      }}
      onFocus={() => {
        setPrevValue(value);
      }}
      onBlur={async (e) => {
        const trimmedValue = value?.trim();
        if (!trimmedValue?.length) {
          setValue(prevValue);
          return;
        }
        if (onValueChange) onValueChange(trimmedValue);
      }}
      {...rest}
    />
  );
};
