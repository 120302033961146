import React, {
  ButtonHTMLAttributes,
  DetailedHTMLProps,
  forwardRef,
  ReactNode,
} from "react";
import { classNames } from "core";

export interface ButtonProps
  extends DetailedHTMLProps<
    ButtonHTMLAttributes<HTMLButtonElement>,
    HTMLButtonElement
  > {
  iconLeft?: ReactNode;
  iconRight?: ReactNode;
  children: ReactNode;
  variant?: "primary" | "outlined" | "text" | "error" | "error-secondary";
}

const Button = forwardRef<HTMLButtonElement, ButtonProps>((props, ref) => {
  const {
    children,
    iconRight,
    iconLeft,
    className,
    variant = "primary",
    ...rest
  } = props;

  return (
    <button
      {...rest}
      ref={ref}
      className={classNames(
        "group flex w-fit items-center justify-center gap-1 truncate rounded px-2 py-[6px] text-xs font-medium leading-4 transition disabled:cursor-default [&:disabled>svg]:opacity-60 [&>svg]:h-4 [&>svg]:w-4 [&>svg]:fill-current [&>svg]:text-inherit",
        variant === "primary" &&
          "shadow-xs bg-brand-500 text-white hover:bg-brand-600 active:bg-brand-500 disabled:bg-gray-100 disabled:text-gray-400",
        variant === "error" &&
          "shadow-xs bg-red-600 text-white hover:bg-red-700 active:bg-red-600 disabled:bg-gray-100 disabled:text-gray-400",
        variant === "outlined" &&
          "border border-gray-200 bg-white py-1.5 text-gray-600 shadow-sm outline-none hover:bg-gray-100 focus-visible:bg-gray-100 active:bg-opacity-60 disabled:border-opacity-60 disabled:bg-white disabled:text-opacity-60",
        variant === "text" &&
          "text-gray-500 outline-none hover:bg-gray-100 focus-visible:bg-gray-100 active:bg-opacity-60 disabled:bg-transparent disabled:text-opacity-60",
        variant === "error-secondary" &&
          "shadow-xs bg-gray-100 text-red-600 hover:bg-gray-200 active:bg-opacity-60 disabled:bg-gray-100 disabled:text-gray-400",
        className
      )}
    >
      {iconLeft}
      {children}
      {iconRight}
    </button>
  );
});

export default Button;
