import {
  PublicsSearchParams,
  PublicsFullTextSearchParams,
  EarningsSearchResponse,
  ResultType,
  SearchHistoryParams,
  SearchParams,
  SecSearchResponse,
} from "source/Types";
import axios from "./axios";

export const Search = {
  search: (params: SearchParams, signal?: AbortSignal) =>
    axios.post(`/search`, { ...params }, { signal }),
  log: (params: SearchHistoryParams, signal?: AbortSignal) =>
    axios.post(`/v2/log`, params, { signal }),
  downloadExactMatches: (params: SearchParams, signal?: AbortSignal) =>
    axios.post(`/search/all-exact-matches`, { ...params }, { signal }),
  docSearch: (
    query: string,
    orgId: string,
    repoIds?: string[],
    signal?: AbortSignal
  ) =>
    axios.post(
      `/search/doc-search`,
      { query: query, org_id: orgId, repo_ids: repoIds },
      { signal }
    ),
  getHistory: () => axios.get(`/v2/search/history`),
  gtd: (
    params: {
      results: ResultType[];
      query: string;
      use_rephrase: boolean;
    },
    signal?: AbortSignal
  ) => axios.post(`/search/gtd`, params, { signal }),
  summarize: (
    params: { results: ResultType[]; query: string },
    signal?: AbortSignal
  ) => axios.post(`/search/summarize`, params, { signal }),
  suggestMetadata: (params: SearchParams, signal?: AbortSignal) =>
    axios.post(`/search/suggest-metadata`, params, { signal }),
  getEarnings: (
    companyId: number,
    orgId: string
  ): Promise<EarningsSearchResponse> =>
    axios
      .get(`/sheets/docs/company-earnings/${companyId}?org_id=${orgId}`)
      .then(({ data }) => data),
  getInvestorPresentations: (
    companyId: number,
    orgId: string
  ): Promise<EarningsSearchResponse> =>
    axios
      .get(
        `/sheets/docs/company-investor-presentations/${companyId}?org_id=${orgId}`
      )
      .then(({ data }) => data),
  searchEarnings: (
    params: PublicsSearchParams
  ): Promise<EarningsSearchResponse> =>
    axios
      .post(`/sheets/docs/company-transcripts`, params)
      .then(({ data }) => data),
  searchFilings: async (
    params: PublicsSearchParams
  ): Promise<SecSearchResponse> => {
    // For insider trading disclosures just get the results
    if (
      params.filing_types?.includes("3") ||
      params.filing_types?.includes("4") ||
      params.filing_types?.includes("5")
    ) {
      return axios
        .post(`/sheets/docs/company-insider-trading`, params)
        .then(({ data }) => data);
    }
    // For 8-K filings, we need to search both the 8-Ks and the exhibits
    if (params.filing_types?.includes("8-K")) {
      const filingsPromise = axios
        .post(`/sheets/docs/company-filings`, params)
        .then(({ data }) => data);
      const exhibitsPromise = axios
        .post(`/sheets/docs/company-filing-exhibits`, {
          ...params,
          filing_types: ["8-K"],
        })
        .then(({ data }) => data);

      const [filingsResult, exhibitsResult] = await Promise.all([
        filingsPromise,
        exhibitsPromise,
      ]);

      return {
        next_page_count: 0,
        results: [
          ...(filingsResult?.results ?? []),
          ...(exhibitsResult?.results ?? []),
        ],
      };
    }

    return axios
      .post(`/sheets/docs/company-filings`, params)
      .then(({ data }) => data);
  },
  searchFilingExhibits: (
    params: PublicsSearchParams
  ): Promise<SecSearchResponse> =>
    axios
      .post(`/sheets/docs/company-filing-exhibits`, params)
      .then(({ data }) => data),

  searchPublicsFullText: (params: PublicsFullTextSearchParams) =>
    axios
      .post(`/sheets/docs/full-text-search-companies`, params)
      .then(({ data }) => data),
};
