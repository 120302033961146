import React from "react";
import { classNames } from "../../index";

export type ListProps<T extends React.ElementType> = {
  as: T;
  variant: "default" | "elevated";
  className?: string;
} & React.ComponentProps<T>;

const List = React.forwardRef(
  <T extends React.ElementType>(
    { as = "ul", className, variant, ...rest }: ListProps<T>,
    ref: React.Ref<React.ElementRef<T>>
  ) => {
    const Component = as;
    return (
      <Component
        {...rest}
        ref={ref}
        className={classNames(
          variant === "elevated" &&
            "rounded-md border border-gray-200 bg-white px-1 py-1.5 shadow-xl focus:outline-none",
          className
        )}
      />
    );
  }
);

export default List;
