import React from "react";

export type BuffetProps = {
  children: React.ReactNode;
};

const Buffet = ({ children }: BuffetProps) => {
  return (
    <div
      aria-live="assertive"
      className="pointer-events-none fixed inset-x-0 bottom-0 z-[1000001] flex items-end px-4 py-6 opacity-100 sm:items-start sm:p-6"
    >
      <div className="flex w-full flex-col-reverse items-center gap-4 sm:items-end">
        {children}
      </div>
    </div>
  );
};

export default Buffet;
