import styled from "styled-components";

export const Card = styled.div`
  // border-radius: 4px;
  font-family: Inter;
  font-weight: 400;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  color: var(--text-secondary);
  margin-right: 22px;
  white-space: nowrap;
`;

export const Button = styled.button`
  padding: 4px 12px;
  font-size: 14px;
  font-family: Inter;
  font-weight: 400;
  // border-radius: 7px;
  color: var(--text-secondary);
  border: none;
  background: transparent;
  border: 1px solid var(--text-light);
  cursor: pointer;
  :disabled {
    opacity: 0.4;
    cursor: default;
  }
`;

export const Input = styled.input`
  font-size: 14px;
  font-family: Inter;
  font-weight: 400;
  // border-radius: 2px;
  color: var(--text-secondary);
  border: 0px solid var(--text-secondary);
  background: rgb(247, 246, 251);
  padding: 5px 10px;
  outline: none;
  margin: 14px 14px 8px 14px;
`;
