import React, { useState } from "react";
import { SidebarToggle } from "../SidebarToggle";
import { useSelector } from "react-redux";
import { ClearTargetButton } from "../ClearTargetButton";
import { useDispatch } from "react-redux";
import { SearchBar } from "../SearchBar";
import { FiltersConfig } from "source/constants/filters";
import {
  clearAllAppliedFiltersByKey,
  clearReportFilterValues,
  getReportFilterKey,
  getTargetAppliedFilters,
  getTargetCompanies,
  getTargetDisabledFilters,
} from "source/redux/advanced";
import { CompanyList } from "./components/CompanyList";

export const COMPANY_SECTION_TITLE = "Companies";

type Props = {
  reposCanOpen?: boolean;
};

export const CompanySection = ({ reposCanOpen }: Props) => (
  <SidebarToggle title={COMPANY_SECTION_TITLE} badge={<CompanySectionBadge />}>
    <SearchableSection reposCanOpen={reposCanOpen} />
  </SidebarToggle>
);

const CompanySectionBadge = () => {
  const dispatch = useDispatch();
  const sectionKey = getReportFilterKey(COMPANY_SECTION_TITLE);
  const companyIds = useSelector(getTargetCompanies);

  const numCompanyTargets = companyIds.length;

  const appliedFilters = useSelector(getTargetAppliedFilters);
  const disabledFilters = useSelector(getTargetDisabledFilters);
  const numAppliedFilters = appliedFilters
    .filter((a) => a.key === FiltersConfig.TICKER_FILTER_KEY)
    .filter(
      (a) =>
        !disabledFilters.some((d) => d.value === a.value && d.key === a.key)
    ).length;

  const handleClear = (e: React.MouseEvent) => {
    e.stopPropagation();
    dispatch(clearReportFilterValues({ sectionKey }));
    dispatch(clearAllAppliedFiltersByKey(FiltersConfig.TICKER_FILTER_KEY));
  };

  return (
    <ClearTargetButton
      numTargets={numCompanyTargets + numAppliedFilters}
      handleClear={handleClear}
    />
  );
};

type SearchableSectionProps = {
  reposCanOpen?: boolean;
};

const SearchableSection = ({ reposCanOpen }: SearchableSectionProps) => {
  const [searchValue, setSearchValue] = useState("");
  return (
    <div className="flex w-full flex-col pb-2">
      <SearchBar searchable="company" setSearchValue={setSearchValue} />
      <CompanyList searchValue={searchValue} reposCanOpen={reposCanOpen} />
    </div>
  );
};
