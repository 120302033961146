import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { DownloadBuildType, RepoType } from "source/Types";
import { ReduxState } from ".";

export type DownloadBuildReduxType = {
  activeDownload?: DownloadBuildType;
  repo?: RepoType;
};

const initialState: DownloadBuildReduxType = {};

const getDownloadBuild = (state: ReduxState) => state.downloadBuild;

export const getDownloadBuildInfo = createSelector(
  getDownloadBuild,
  (downloadBuild) => ({
    activeBuild: downloadBuild.activeDownload,
    activeRepo: downloadBuild.repo,
  })
);

const downloadBuildSlice = createSlice({
  name: "downloadBuild",
  initialState,
  reducers: {
    setBuildDownload: (
      state,
      action: PayloadAction<{ download: DownloadBuildType; repo: RepoType }>
    ) => {
      state.activeDownload = action.payload.download;
      state.repo = action.payload.repo;
      return state;
    },
  },
});

export const { setBuildDownload } = downloadBuildSlice.actions;
export const downloadBuildReducer = downloadBuildSlice.reducer;
