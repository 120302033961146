import { getSessionStorage } from "source/storage/storage";

export const HEBBIA_AUTH_KEY = "hebbia:auth:access_token";

export const getAuthToken = (): string | null => {
  return getSessionStorage().getItem(HEBBIA_AUTH_KEY);
};

export const setAuthToken = (token: string): void => {
  return getSessionStorage().setItem(HEBBIA_AUTH_KEY, token);
};

export const clearAuthToken = (): void => {
  return getSessionStorage().removeItem(HEBBIA_AUTH_KEY);
};
