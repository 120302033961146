import { DocIcon } from "source/components/shared/Icons/DocIcon";
import { documentTypeToIconName } from "source/components/shared/Icons/utils";
import { ToggleStatelessIcon } from "source/components/shared/ToggleIcon";
import { DocContextMenu } from "source/components/sidebar/components/DocContextMenu";
import React, { useState } from "react";
import { useFetchPaginatedChildDocs } from "source/hooks/repos/useFetchPaginatedChildDocs";
import { LoadingRow } from "source/components/sidebar/components/LoadingRow";
import { getFilterSearchKey, getReportFilterKey } from "source/redux/advanced";
import { DocumentType } from "source/Types";
import { GigabarRow } from "./GigabarRow";
import { ALWAYS_SHOW_TOGGLE_MIMES } from "source/constants";
import { ShowMoreButton } from "./FileSection/components/ShowMoreButton";
import { DOCUMENT_SECTION_TITLE } from "./FileSection";
import { Tooltip } from "source/components/shared/Tooltip";
import { DocTooltipBreadCrumb } from "./FileSection/components/DocTooltipBreadCrumb";
import { setDoc } from "source/api/docs/useMutateDocs";
import { useDispatch } from "react-redux";
import { setDocModalVisible } from "source/redux/ui";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";

type Props = {
  doc: DocumentType;
  idx: number;
  depth: number;
  showChildren?: boolean;
  searchValue?: string;
  showPathToolTip?: boolean;
  styleOverride?: string;
};

export const DocRow = ({
  doc,
  idx,
  depth,
  showChildren = true,
  searchValue,
  showPathToolTip = false,
  styleOverride,
}: Props) => {
  const dispatch = useDispatch();
  const [toggle, setToggle] = useState(false);
  const sectionKey = getReportFilterKey(DOCUMENT_SECTION_TITLE);
  const searchKey = getFilterSearchKey("document");

  const canDocToggle =
    (doc.num_children > 0 || ALWAYS_SHOW_TOGGLE_MIMES.includes(doc.mime)) &&
    showChildren;

  return (
    <>
      <Tooltip
        title={
          doc.failure_reason ? (
            <span>{doc.failure_reason}</span>
          ) : showPathToolTip ? (
            <>
              <DocTooltipBreadCrumb doc={doc}></DocTooltipBreadCrumb>
            </>
          ) : undefined
        }
        enterDelay={800}
        enterNextDelay={800}
      >
        <div>
          <DocContextMenu doc={doc} key={`${doc.id}${idx}`}>
            <GigabarRow
              title={doc.title}
              titleSubstringMatch={searchValue}
              id={doc.id}
              overrideCheckbox={
                doc.failure_reason ? (
                  <ErrorOutlineIcon
                    fontSize="small"
                    className="cursor-pointer text-secondary"
                  />
                ) : undefined
              }
              icon={
                <div>
                  <DocIcon
                    iconName={documentTypeToIconName(doc?.mime, doc?.type)}
                    fontSize="small"
                  />
                </div>
              }
              sectionKey={sectionKey}
              searchKey={searchKey}
              showContextMenu={false}
              bookmarked={false}
              toggle={
                canDocToggle && (
                  <ToggleStatelessIcon
                    isOpen={toggle}
                    handleOpenClick={(e) => {
                      e?.stopPropagation();
                      setToggle(!toggle);
                    }}
                  />
                )
              }
              depth={depth}
              styleOverride={styleOverride}
              cacheSetter={() => setDoc(doc)}
              handleClick={
                canDocToggle
                  ? undefined
                  : () => {
                      dispatch(
                        setDocModalVisible({
                          docId: doc.id,
                          repoId: doc.repo_id,
                        })
                      );
                    }
              }
            />
          </DocContextMenu>
        </div>
      </Tooltip>
      {toggle && showChildren && <DocChildren doc={doc} depth={depth + 1} />}
    </>
  );
};

type DocChildrenProps = {
  doc: DocumentType;
  depth: number;
};

const DocChildren = ({ doc, depth }: DocChildrenProps) => {
  const { allChildren, isLoading, fetchNextPage, hasNextPage } =
    useFetchPaginatedChildDocs(doc);
  return (
    <>
      {allChildren.map((doc, idx) => (
        <DocRow doc={doc} key={idx} idx={idx} depth={depth} />
      ))}
      {isLoading && <LoadingRow depth={depth} />}
      {hasNextPage && (
        <ShowMoreButton depth={depth} handleClick={fetchNextPage} />
      )}
    </>
  );
};
