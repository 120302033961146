import React from "react";
import { DocumentType } from "source/Types";
import { useQueryDoc } from "source/api/docs/useQueryDocs";
import ActivityIndicator from "react-loader-spinner";
import FolderOutlinedSharpIcon from "@mui/icons-material/FolderOutlined";
import InsertDriveFileOutlinedSharpIcon from "@mui/icons-material/InsertDriveFileOutlined";
import { ALWAYS_SHOW_TOGGLE_MIMES } from "source/constants";
import { useQueryRepo } from "source/api/repos/useQueryRepos";

type DocToolTipBreadCrumbProps = {
  doc: DocumentType;
};

export const DocTooltipBreadCrumb = ({ doc }: DocToolTipBreadCrumbProps) => {
  const { data, isLoading } = useQueryDoc(doc.id);

  const { data: repoData, isLoading: repoIsLoading } = useQueryRepo(
    doc.repo_id
  );
  if (isLoading || repoIsLoading)
    return (
      <ActivityIndicator type="TailSpin" color="white" height={15} width={15} />
    );

  if (!data) return <div>{doc.title}</div>;

  const ancestors = data.ancestors.slice(-1);
  const truncatedAncestors = ancestors.slice(0, 4);
  // TOOD: implement showing dots
  //   const showDots = ancestors.length !== truncatedAncestors.length;

  return (
    <div className="flex flex-row flex-wrap items-center gap-2 p-1 text-center text-xs font-normal text-gray1">
      {repoData ? (
        <div className="inline-block max-w-[150px] overflow-hidden text-ellipsis whitespace-nowrap">
          <FolderOutlinedSharpIcon
            className="mr-2 text-gray1"
            fontSize={"small"}
          />
          {repoData.name}
        </div>
      ) : null}
      {truncatedAncestors.map((a, idx) => [
        <div key={idx * 2} className="text-base">
          /
        </div>,
        <div
          key={idx * 2 + 1}
          className="inline-block max-w-[150px] overflow-hidden text-ellipsis whitespace-nowrap"
        >
          <FolderOutlinedSharpIcon
            className="mr-2 text-gray1"
            fontSize={"small"}
          />
          {a.title}
        </div>,
      ])}
      <div className="text-base">/</div>
      <div className="inline-block max-w-[300px] overflow-hidden text-ellipsis whitespace-nowrap">
        {doc.num_children > 0 || ALWAYS_SHOW_TOGGLE_MIMES.includes(doc.mime) ? (
          <FolderOutlinedSharpIcon
            className="mr-2 text-gray1"
            fontSize={"small"}
          />
        ) : (
          <InsertDriveFileOutlinedSharpIcon
            className="mr-2 text-gray1"
            fontSize={"small"}
          />
        )}
        {doc.title}
      </div>
    </div>
  );
};
