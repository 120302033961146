import React from "react";
import { styled } from "@mui/material/styles";
import TooltipRoot, {
  TooltipProps,
  tooltipClasses,
} from "@mui/material/Tooltip";
import { keyframes } from "@stitches/react";

const slideUpAndFade = keyframes({
  "0%": { opacity: 0, transform: "translateY(2px)" },
  "100%": { opacity: 1, transform: "translateY(0)" },
});
const slideRightAndFade = keyframes({
  "0%": { opacity: 0, transform: "translateX(-2px)" },
  "100%": { opacity: 1, transform: "translateX(0)" },
});
const slideDownAndFade = keyframes({
  "0%": { opacity: 0, transform: "translateY(-2px)" },
  "100%": { opacity: 1, transform: "translateY(0)" },
});
const slideLeftAndFade = keyframes({
  "0%": { opacity: 0, transform: "translateX(2px)" },
  "100%": { opacity: 1, transform: "translateX(0)" },
});

const StyledTooltip = styled(({ className, ...props }: TooltipProps) => (
  <TooltipRoot {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    padding: "4px 8px",
    textAlign: "center",
    maxWidth: 320,
    backgroundColor: "var(--text-primary)",
    color: "var(--card)",
    fontSize: 12,
    borderRadius: 4,
    boxShadow: "0px 7px 24px -7px rgba(var(--shadow), 0.47)",
    "@media (prefers-reduced-motion: no-preference)": {
      animationDuration: "400ms",
      animationTimingFunction: "cubic-bezier(0.16, 1, 0.3, 1)",
      willChange: "transform, opacity",
      '&[data-state="open"]': {
        '&[data-side="top"]': { animationName: slideDownAndFade },
        '&[data-side="right"]': { animationName: slideLeftAndFade },
        '&[data-side="bottom"]': { animationName: slideUpAndFade },
        '&[data-side="left"]': { animationName: slideRightAndFade },
      },
    },
  },
}));

interface Props {
  children: JSX.Element;
  title?: React.ReactElement | string;
  // delay for tooltip
  enterDelay?: number;
  // delay for tooltip displaying on next tooltip
  enterNextDelay?: number;
  // whether tooltip should display or not
  open?: boolean;
  styleOverride?: React.CSSProperties;
}

/** Renders a tooltip around the children elements */
export const Tooltip = ({
  children,
  title,
  open,
  enterDelay = 100,
  enterNextDelay = 100,
  styleOverride,
}: Props) => (
  <>
    {title ? (
      <StyledTooltip
        enterDelay={enterDelay}
        enterNextDelay={enterNextDelay}
        title={title}
        open={open}
        sx={{ ...styleOverride }}
        disableInteractive
      >
        {children}
      </StyledTooltip>
    ) : (
      <>{children}</>
    )}
  </>
);
