import { useMemo } from "react";

// certain components require id's to be set to work correctly
// if we have a parent component like <Menu>, this hook can be
// used to generate unique <MenuItem> ids such that they contain
// the parent <Menu>'s id within them
export const useChildComponentId = (
  parentId: string,
  prefix: string,
  index?: number
) => {
  return useMemo(
    () => `${parentId}_${prefix}${index === undefined ? "" : `-${index}`}`,
    [parentId, prefix, index]
  );
};
