import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { MIN_SIDEBAR_WIDTH } from "source/constants";
import { ReduxState } from ".";
import { FeedbackPayload } from "source/components/matrix/types/reports.types";
import { SettingsTabType } from "source/components/settings";
import {
  DocModalVisbleType,
  DocumentType,
  RepoType,
  ResultType,
} from "source/Types";
import { ToastType } from "packages/core";

export type AlertType = "websocketDisconnected";

export type ReduxAlert = {
  alert: AlertType | null;
};

export type DuplicateReportModalProps = {
  open: boolean;
  reportName: string;
  reportId: string;
  orgId: string;
};

export type FeedbackModalProps = {
  open: boolean;
  payload: FeedbackPayload | null;
};

export enum BulkReportModalState {
  RunPrompt,
  ConfirmationPrompt,
  Confirmed,
  Error,
}

export type BulkRunModalProps = {
  open: boolean;
  state: BulkReportModalState;
};

export type DeleteColumnModalProps = {
  open: boolean;
  columnId: string | null;
};

export type UIReduxType = {
  reportBarVisible: boolean;
  metadataBarVisible: boolean;
  settingsTab: SettingsTabType;
  editReportModalId: string | null;
  docModalVisible: DocModalVisbleType | null;
  searchNoDocsVisible: boolean;
  synonymsPopover: {
    visible: boolean;
    phrase: string;
    synonyms: string[];
  };
  runModal: {
    visible: boolean;
    reportId: string | null;
    target: {
      docs?: DocumentType[];
      repo?: RepoType;
    } | null;
    orgId: string | null;
  };
  topBarWidth: number;
  searchBarCopy: string | null;
  hebbiaView: boolean;
  searchBarsFocused: {
    searchBar: boolean;
    sourcesBar: boolean;
  };
  pm: boolean;
  showAllActivities: boolean;
  linkedResult: ResultType | null;
  currentSelectionId?: string;
  gridReportCellModalOpen: boolean;
  tableExtractionModeEnabled: boolean;
  overrideEscHotkey: boolean;
  query: string; // Adding this here as *UI* state of search bar that can be manipulated across the dom
  showUnsupportedDocs: boolean;
  showDocFailureReason: boolean;
  sidebarWidth: number;
  hideRepoHighlights: {
    [id: string]: boolean;
  };
  openSidebarSection: string | null;
  reportToggleModal: boolean;
  alert: ReduxAlert;
  duplicateReportModal: DuplicateReportModalProps;
  isDeleteReportModalOpen: boolean;
  feedbackModal: FeedbackModalProps;
  bulkRunModal: BulkRunModalProps;
  templateModalOpen: boolean;
  // Used to enabled / disable hotkeys at the global level. Enabled by default.
  hotkeysEnabled: boolean;
  deleteColumnModal: DeleteColumnModalProps;

  matrixBottomCellsBarVisible: boolean;

  toasts: ToastType[];
  snackbarItems: string[];
};

export const getReportToggleModal = (state: ReduxState) =>
  state.ui.reportToggleModal;

export const getReportBarVisible = (state: ReduxState) =>
  state.ui.reportBarVisible;
export const getSidebarWidth = (state: ReduxState) => state.ui.sidebarWidth;
export const getMetadataBarVisible = (state: ReduxState) =>
  state.ui.metadataBarVisible;
export const getSettingsTab = (state: ReduxState) => state.ui.settingsTab;
export const getTopBarWidth = (state: ReduxState) => state.ui.topBarWidth;
export const getEditReportModalId = (state: ReduxState) =>
  state.ui.editReportModalId;

// Synonyms chips modal
export const getSynonymsPopoverVisible = (state: ReduxState) =>
  state.ui.synonymsPopover.visible;

// Run report modal
export const getRunModalVisible = (state: ReduxState) =>
  state.ui.runModal.visible;
export const getRunModalTarget = (state: ReduxState) =>
  state.ui.runModal.target;
export const getRunModalReportId = (state: ReduxState) =>
  state.ui.runModal.reportId;
export const getRunModalOrgId = (state: ReduxState) => state.ui.runModal.orgId;

export const getDocModalVisible = (state: ReduxState) =>
  state.ui.docModalVisible;

export const getSearchBarCopy = (state: ReduxState) => state.ui.searchBarCopy;

export const getHebbiaView = (state: ReduxState) => state.ui.hebbiaView;

export const getSearchBarsFocused = (state: ReduxState) =>
  state.ui.searchBarsFocused;

export const getShowAllActivities = (state: ReduxState) =>
  state.ui.showAllActivities;

export const getLinkedResult = (state: ReduxState) => state.ui.linkedResult;

export const getCurrentSelectionId = (state: ReduxState) =>
  state.ui.currentSelectionId;

export const getGridReportCellModalOpen = (state: ReduxState) =>
  state.ui.gridReportCellModalOpen;

export const getTableExtractionModeEnabled = (state: ReduxState) =>
  state.ui.tableExtractionModeEnabled;

export const getOverrideEscHotkey = (state: ReduxState) =>
  state.ui.overrideEscHotkey;

export const getSearchQuery = (state: ReduxState) => state.ui.query;

export const getShowUnsupportedDocs = (state: ReduxState) =>
  state.ui.showUnsupportedDocs;

export const getShowDocFailureReason = (state: ReduxState) =>
  state.ui.showDocFailureReason;

export const getHideRepoHighlights = (state: ReduxState) =>
  state.ui.hideRepoHighlights;

export const getOpenSidebarSection = (state: ReduxState) =>
  state.ui.openSidebarSection;

export const getSearchNoDocsVisible = (state: ReduxState) =>
  state.ui.searchNoDocsVisible;

export const getPM = (state: ReduxState) => state.ui.pm;
export const getAlert = (state: ReduxState) => state.ui.alert;
export const getDuplicateReportModal = (state: ReduxState) =>
  state.ui.duplicateReportModal;
export const getIsDeleteReportModalOpen = (state: ReduxState) =>
  state.ui.isDeleteReportModalOpen;
export const getFeedbackModal = (state: ReduxState) => state.ui.feedbackModal;
export const getBulkRunModal = (state: ReduxState) => state.ui.bulkRunModal;
export const getTemplateModalOpen = (state: ReduxState) =>
  state.ui.templateModalOpen;
export const getHotkeysEnabled = (state: ReduxState) => state.ui.hotkeysEnabled;
export const getMatrixBottomCellsBarVisible = (state: ReduxState) =>
  state.ui.matrixBottomCellsBarVisible;
export const getDeleteColumnModal = (state: ReduxState) =>
  state.ui.deleteColumnModal;
export const getToasts = (state: ReduxState) => state.ui.toasts;
export const getSnackbarItems = (state: ReduxState) => state.ui.snackbarItems;

const uiSlice = createSlice({
  name: "ui",
  initialState: {
    reportToggleModal: false,
    reportBarVisible: false,
    metadataBarVisible: false,
    settingsTab: "profile",
    sidebarWidth: MIN_SIDEBAR_WIDTH,
    editReportModalId: null as any,
    docModalVisible: null,
    searchNoDocsVisible: false,
    synonymsPopover: {
      visible: false,
      phrase: "",
      synonyms: [],
    },
    pm: false,
    runModal: {
      visible: false,
      reportId: null as any,
      is_grid: false,
      target: null as any,
      orgId: null as any,
      view: "run",
    },
    searchBarCopy: null as string | null,
    hebbiaView: false,
    topBarWidth: 0,
    searchBarsFocused: {
      searchBar: false,
      sourcesBar: false,
    },
    showAllActivities: false,
    linkedResult: null as ResultType | null,
    // Sidebar selected element (pin, table, more to come...)
    // currentSelectionId: undefined as string | undefined,
    gridReportCellModalOpen: false,
    tableExtractionModeEnabled: false,
    overrideEscHotkey: false,
    query: "",
    showUnsupportedDocs: false,
    showDocFailureReason: false,
    hideRepoHighlights: {},
    openSidebarSection: null,
    alert: { alert: null },
    modal: { modal: null },
    duplicateReportModal: {
      open: false,
      reportName: "",
      reportId: "",
      orgId: "",
    },
    isDeleteReportModalOpen: false,
    feedbackModal: { open: false, payload: null },
    bulkRunModal: { open: false, state: BulkReportModalState.RunPrompt },
    templateModalOpen: false,
    hotkeysEnabled: true,
    matrixBottomCellsBarVisible: false,
    deleteColumnModal: { open: false, columnId: null },
    notifications: [],
    toasts: [],
    snackbarItems: [],
  } as UIReduxType,
  reducers: {
    setReportToggleModalVisible: (
      state: UIReduxType,
      action: PayloadAction<boolean>
    ) => {
      state.reportToggleModal = action.payload;
      return state;
    },
    setPM: (state: UIReduxType, action: PayloadAction<boolean>) => {
      state.pm = action.payload;
      return state;
    },
    toggleReportBar: (state: UIReduxType) => {
      state.reportBarVisible = !state.reportBarVisible;
      return state;
    },
    toggleMetadataBar: (state: UIReduxType) => {
      state.metadataBarVisible = !state.metadataBarVisible;
      return state;
    },
    setReportBarVisible: (
      state: UIReduxType,
      action: PayloadAction<boolean>
    ) => {
      state.reportBarVisible = action.payload;
      return state;
    },
    setSettingsTab: (
      state: UIReduxType,
      action: PayloadAction<SettingsTabType>
    ) => {
      state.settingsTab = action.payload;
      return state;
    },
    setTopBarWidth: (state: UIReduxType, action: PayloadAction<number>) => {
      state.topBarWidth = action.payload;
      return state;
    },
    setEditReportModalId: (
      state: UIReduxType,
      action: PayloadAction<string | null>
    ) => {
      state.editReportModalId = action.payload;
      return state;
    },
    setDocModalVisible: (
      state: UIReduxType,
      action: PayloadAction<DocModalVisbleType | null>
    ) => {
      state.docModalVisible = action.payload;
      return state;
    },
    setSynonymsPopoverVisible: (
      state: UIReduxType,
      action: PayloadAction<boolean>
    ) => {
      state.synonymsPopover.visible = action.payload;
      return state;
    },
    setRunModalVisible: (
      state: UIReduxType,
      action: PayloadAction<boolean>
    ) => {
      state.runModal.visible = action.payload;
      return state;
    },
    setRunModalTarget: (
      state: UIReduxType,
      action: PayloadAction<{
        repo?: RepoType;
        docs?: DocumentType[];
      } | null>
    ) => {
      if (action.payload) state.runModal.visible = true;
      state.runModal.target = action.payload;
      return state;
    },
    setRunModalReportId: (
      state: UIReduxType,
      action: PayloadAction<string | null>
    ) => {
      if (action.payload) state.runModal.visible = true;
      state.runModal.reportId = action.payload;
      return state;
    },
    setRunModalOrgId: (
      state: UIReduxType,
      action: PayloadAction<string | null>
    ) => {
      state.runModal.orgId = action.payload;
      return state;
    },
    setSearchBarCopy: (
      state: UIReduxType,
      action: PayloadAction<string | null>
    ) => {
      state.searchBarCopy = action.payload;
      return state;
    },
    setHebbiaView: (state: UIReduxType, action: PayloadAction<boolean>) => {
      state.hebbiaView = action.payload;
      return state;
    },
    setSearchBarFocused: (
      state: UIReduxType,
      action: PayloadAction<boolean>
    ) => {
      state.searchBarsFocused.searchBar = action.payload;
      return state;
    },
    setSourcesBarFocused: (
      state: UIReduxType,
      action: PayloadAction<boolean>
    ) => {
      state.searchBarsFocused.sourcesBar = action.payload;
      return state;
    },
    setShowAllActivities: (
      state: UIReduxType,
      action: PayloadAction<boolean>
    ) => {
      state.showAllActivities = action.payload;
      return state;
    },
    setLinkedResult: (
      state: UIReduxType,
      action: PayloadAction<ResultType | null>
    ) => {
      state.linkedResult = action.payload;
      return state;
    },
    setCurrentSelectionId: (
      state: UIReduxType,
      action: PayloadAction<string | undefined>
    ) => {
      state.currentSelectionId = action.payload;
      return state;
    },
    setGridReportCellModalOpen: (
      state: UIReduxType,
      action: PayloadAction<boolean>
    ) => {
      state.gridReportCellModalOpen = action.payload;
      return state;
    },
    setTableExtractionModeEnabled: (
      state: UIReduxType,
      action: PayloadAction<boolean>
    ) => {
      state.tableExtractionModeEnabled = action.payload;
      return state;
    },
    setOverrideEscHotkey: (
      state: UIReduxType,
      action: PayloadAction<boolean>
    ) => {
      state.overrideEscHotkey = action.payload;
      return state;
    },
    setSearchQuery: (state: UIReduxType, action: PayloadAction<string>) => {
      state.query = action.payload;
      return state;
    },
    setShowUnsupportedDocs: (
      state: UIReduxType,
      action: PayloadAction<boolean>
    ) => {
      state.showUnsupportedDocs = action.payload;
      return state;
    },
    setShowDocFailureReason: (
      state: UIReduxType,
      action: PayloadAction<boolean>
    ) => {
      state.showDocFailureReason = action.payload;
      return state;
    },
    setOpenSidebarSection: (
      state: UIReduxType,
      action: PayloadAction<string | null>
    ) => {
      state.openSidebarSection = action.payload;
      return state;
    },
    setSearchNoDocsVisible: (
      state: UIReduxType,
      action: PayloadAction<boolean>
    ) => {
      state.searchNoDocsVisible = action.payload;
      return state;
    },
    setAlert: (state: UIReduxType, action: PayloadAction<ReduxAlert>) => {
      state.alert = action.payload;
      return state;
    },
    setDuplicateReportModal: (
      state: UIReduxType,
      action: PayloadAction<DuplicateReportModalProps>
    ) => {
      state.duplicateReportModal = action.payload;
      return state;
    },
    setIsDeleteReportModalOpen: (
      state: UIReduxType,
      action: PayloadAction<boolean>
    ) => {
      state.isDeleteReportModalOpen = action.payload;
      return state;
    },
    setIsFeedbackModalOpen: (
      state: UIReduxType,
      action: PayloadAction<FeedbackModalProps>
    ) => {
      state.feedbackModal = action.payload;
      return state;
    },
    setBulkRunModalOpen: (state, action: PayloadAction<boolean>) => {
      state.bulkRunModal.open = action.payload;
      return state;
    },
    setBulkRunModalState: (
      state,
      action: PayloadAction<BulkReportModalState>
    ) => {
      state.bulkRunModal.state = action.payload;
      return state;
    },
    setTemplateModalOpen: (
      state: UIReduxType,
      action: PayloadAction<boolean>
    ) => {
      state.templateModalOpen = action.payload;
      return state;
    },
    setHotkeysEnabled: (state: UIReduxType, action: PayloadAction<boolean>) => {
      state.hotkeysEnabled = action.payload;
      return state;
    },
    setMatrixBottomCellsBarVisible: (
      state: UIReduxType,
      action: PayloadAction<boolean>
    ) => {
      state.matrixBottomCellsBarVisible = action.payload;
      return state;
    },
    setDeleteColumnModal: (
      state: UIReduxType,
      action: PayloadAction<DeleteColumnModalProps>
    ) => {
      state.deleteColumnModal = action.payload;
      return state;
    },
    setToasts: (state: UIReduxType, action: PayloadAction<ToastType[]>) => {
      state.toasts = action.payload;
      return state;
    },
    upsertToast: (state: UIReduxType, action: PayloadAction<ToastType>) => {
      const newToast = action.payload;
      const toastIndex = state.toasts.findIndex(({ id }) => id === newToast.id);

      if (toastIndex === -1) {
        state.toasts.push(newToast);
      } else {
        state.toasts[toastIndex] = newToast;
      }
      return state;
    },
    removeToast: (state: UIReduxType, action: PayloadAction<string>) => {
      const id = action.payload;
      state.toasts = state.toasts.filter((toast) => toast.id !== id);
      return state;
    },
    clearToasts: (state: UIReduxType) => {
      state.toasts = [];
      return state;
    },
    upsertSnackbar: (state: UIReduxType, action: PayloadAction<string>) => {
      const snackbarId = action.payload;
      const snackbarIndex = state.snackbarItems.findIndex(
        (id) => id === snackbarId
      );

      if (snackbarIndex === -1) {
        state.snackbarItems.push(snackbarId);
      }
      return state;
    },
    removeSnackbar: (state: UIReduxType, action: PayloadAction<string>) => {
      const snackbarId = action.payload;
      state.snackbarItems = state.snackbarItems.filter(
        (id) => snackbarId !== id
      );
      return state;
    },
  },
});

export const {
  setReportToggleModalVisible,
  toggleReportBar,
  toggleMetadataBar,
  setReportBarVisible,
  setSettingsTab,
  setPM,
  setEditReportModalId,
  setSynonymsPopoverVisible,
  setRunModalReportId,
  setRunModalTarget,
  setRunModalVisible,
  setRunModalOrgId,
  setTopBarWidth,
  setSearchBarCopy,
  setHebbiaView,
  setSearchBarFocused,
  setSourcesBarFocused,
  setShowAllActivities,
  setLinkedResult,
  setCurrentSelectionId,
  setGridReportCellModalOpen,
  setTableExtractionModeEnabled,
  setOverrideEscHotkey,
  setSearchQuery,
  setShowUnsupportedDocs,
  setShowDocFailureReason,
  setDocModalVisible,
  setOpenSidebarSection,
  setSearchNoDocsVisible,
  setAlert,
  setDuplicateReportModal,
  setIsDeleteReportModalOpen,
  setIsFeedbackModalOpen,
  setBulkRunModalOpen,
  setBulkRunModalState,
  setTemplateModalOpen,
  setHotkeysEnabled,
  setMatrixBottomCellsBarVisible,
  setDeleteColumnModal,
  setToasts,
  upsertToast,
  clearToasts,
  removeToast,
  upsertSnackbar,
  removeSnackbar,
} = uiSlice.actions;
export const uiReducer = uiSlice.reducer;
