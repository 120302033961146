import { useLDClient, useLDClientError } from "launchdarkly-react-client-sdk";
import { useRouter } from "next/router";
import React, { useEffect } from "react";
import { API_ENV, BETA_ENV, PROD_ENV } from "source/envConstants";
import { useFeatureFlag } from "source/hooks/useFeatureFlag";
import logger from "source/utils/logger";
import {
  hasGreenCookie,
  setGreenCookie,
  clearGreenCookie,
} from "source/utils/blue-green";

type Props = {
  children: React.ReactNode;
};

export const LDPreflightSetup = ({ children }: Props) => {
  const router = useRouter();
  const ldClient = useLDClient();
  const clientError = useLDClientError();

  const greenEnvironment = useFeatureFlag("greenEnvironment");
  const enableBetaEnvironment = useFeatureFlag("enableBetaEnvironment");

  // Re-identify the user in LaunchDarkly when the user or org changes
  useEffect(() => {
    if (!ldClient) return;

    // The astute among you may be asking yourself, "What is bro cooking here?".
    // You may even ask yourself, "Has bro burnt the wellington?". The answer is no.
    // With this line of code, we can access our feature flags outside of the react render cycle.
    // This means we can access the code at function runtime i.e. thunks or arbitrary functions.
    window.ldClient = ldClient;
  }, [ldClient]);

  // Use effect for
  useEffect(() => {
    if (!clientError) return;

    logger.error("LD clientError", {
      error: clientError?.message,
      stack: clientError?.stack,
      cause: clientError?.cause,
      name: clientError?.name,
    });
  }, [clientError]);

  // TODO: Assuming this is cooked, but will let Ammar delete
  // TODO: Add routing the other way if we are on beta and the feature flag is turned off
  useEffect(() => {
    if (typeof window === "undefined") return;

    const hasGreen = hasGreenCookie();
    if (hasGreen && greenEnvironment === false) {
      clearGreenCookie();
      // hard refresh the page
      router.reload();
    } else if (!hasGreen && greenEnvironment) {
      setGreenCookie();
      // hard refresh the page
      router.reload();
    }
  }, [greenEnvironment, router]);

  // TODO: This belongs in cookies/middleware but we are bad at our jobs and SSRM needs to go out
  useEffect(() => {
    if (typeof window === "undefined") return;
    if (process.env.NODE_ENV !== "production") return;

    const ldContext = ldClient?.getContext();
    const isUserIdentified = !!ldContext && ldContext.anonymous !== true;

    // Wait until we have the user's context before redirecting
    if (!isUserIdentified) {
      return;
    }

    // Prod -> Beta
    if (enableBetaEnvironment === true && API_ENV === PROD_ENV) {
      logger.info("Redirecting to beta environment", {
        ldContext: ldContext,
        path: router.asPath,
      });
      router.replace(`https://beta.hebbia.ai${router.asPath}`);
    }

    // Beta -> Prod
    if (enableBetaEnvironment === false && API_ENV === BETA_ENV) {
      logger.info("Redirecting to production environment", {
        ldContext: ldContext,
        path: router.asPath,
      });
      router.replace(`https://search.hebbia.ai${router.asPath}`);
    }
  }, [router, ldClient, enableBetaEnvironment]);

  return <>{children}</>;
};
