import React from "react";
import AddSharpIcon from "@mui/icons-material/AddSharp";
import { useFeatureFlag } from "source/hooks/useFeatureFlag";

type AddFolderButtonProps = {
  handleClick: () => void;
};

export const AddFolderButton = ({ handleClick }: AddFolderButtonProps) => {
  const disablePersonalUploads = useFeatureFlag("disablePersonalUploads");
  if (disablePersonalUploads) return null;

  return (
    <div
      className="flex h-5 w-5 cursor-pointer items-center justify-center border bg-white text-xs text-mediumGray drop-shadow-sm hover:text-lightBlack active:text-black"
      onClick={handleClick}
    >
      <AddSharpIcon style={{ fontSize: "16px" }} />
    </div>
  );
};
