import { WEBSERVER_URL } from "../../envConstants";
import { getAuthToken } from "../../auth/localStorage";
import fetchAccessToken from "../../auth/fetchAccessToken";
import {
  ChatHistoryResponse,
  SingleDocChatSSEMessage,
  SuggestTemplatesResponse,
} from "../../types/agents/agents.types";
import axios from "../axios";

export const AgentsAPI = {
  sendChatMessage: async (
    message: string,
    docId: string,
    orgId: string | undefined,
    onMessage: (data: SingleDocChatSSEMessage) => void,
    controller: AbortController
  ) => {
    const url = `${WEBSERVER_URL}/agents/single-doc-chat/message`;

    let accessToken = getAuthToken();
    if (!accessToken) {
      const response = await fetchAccessToken();
      accessToken = response.accessToken;
    }

    try {
      const response = await fetch(url, {
        method: "POST",
        // @ts-ignore doesn't like the org id header
        headers: {
          Authorization: `Bearer ${accessToken}`,
          "Content-Type": "application/json",
          "Current-Org-Id": orgId,
        },
        body: JSON.stringify({
          message,
          doc_id: docId,
        }),
        signal: controller.signal,
      });

      if (!response.ok) {
        throw new Error(`Network response was not ok: ${response.statusText}`);
      }

      const reader = response.body?.getReader();
      if (!reader) {
        throw new Error("ReadableStream not supported in this environment.");
      }

      const decoder = new TextDecoder("utf-8");
      let buffer = "";

      // eslint-disable-next-line no-constant-condition
      while (true) {
        const { done, value } = await reader.read();
        if (done) break;

        buffer += decoder.decode(value, { stream: true });

        const lines = buffer.split("\n");
        // Keep the last partial line in the buffer
        buffer = lines.pop() || "";

        for (const line of lines) {
          const trimmedLine = line.trim();
          if (trimmedLine.startsWith("data:")) {
            const jsonString = trimmedLine.replace(/^data:\s*/, "");
            try {
              const data = JSON.parse(jsonString);
              onMessage(data);
            } catch (err) {
              console.error("Failed to parse JSON:", err, "Line:", jsonString);
            }
          }
        }
      }
    } catch (error) {
      console.error("Error in SSE subscription:", error);
    }
  },
  getChatHistory: async (docId: string): Promise<ChatHistoryResponse> => {
    return axios
      .get(`/agents/single-doc-chat/history?doc_id=${docId}`)
      .then((res) => res.data);
  },
  deleteChatHistory: async (docId: string) => {
    return axios
      .delete(`/agents/single-doc-chat/history?doc_id=${docId}`)
      .then((res) => res.data);
  },
  suggestTemplates: async (
    documentListId: string
  ): Promise<SuggestTemplatesResponse> => {
    return axios
      .get(
        `/agents/route-agents/template-suggest?document_list_id=${documentListId}`
      )
      .then((res) => res.data);
  },
};
