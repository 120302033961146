import { ReduxState } from ".";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { EvidenceQuoteV2 } from "source/components/matrix/types/cells.types";

export type DocumentViewerViewType = "citations" | "tables" | "chat";

export enum DocumentViewerThumbnailState {
  closed = "closed",
  "pages" = "pages",
  "tables" = "tables",
}

export type DocViewerReduxType = {
  selectedQuoteId: string | null;
  selectedColumnId: string | null;
  activeView: DocumentViewerViewType;
  thumbnailState: DocumentViewerThumbnailState;
  activeHighlight: EvidenceQuoteV2 | null;
  areThumbnailsOpen: boolean;
  isTableFullScreen: boolean;
  clearChat: boolean;
  showCmdFInput: boolean;
  cmdFQuery: string;
  cmdFIndex: number;
  isTableExtractionLoading: boolean;
  numPages: number;
  currPage: number;
};

const initialState: DocViewerReduxType = {
  selectedQuoteId: null,
  selectedColumnId: null,
  activeView: "citations",
  thumbnailState: DocumentViewerThumbnailState.pages,
  activeHighlight: null,
  areThumbnailsOpen: true,
  isTableFullScreen: false,
  clearChat: false,
  showCmdFInput: false,
  cmdFQuery: "",
  cmdFIndex: 0,
  isTableExtractionLoading: false,
  numPages: 1,
  currPage: 1,
};

export const getSelectedQuoteId = (state: ReduxState) =>
  state.docViewer.selectedQuoteId;
export const getSelectedColumnId = (state: ReduxState) =>
  state.docViewer.selectedColumnId;
export const getActiveView = (state: ReduxState) => state.docViewer.activeView;
export const getActiveHighlight = (state: ReduxState) =>
  state.docViewer.activeHighlight;
export const getAreThumbnailsOpen = (state: ReduxState) =>
  state.docViewer.areThumbnailsOpen;
export const getIsTableFullScreen = (state: ReduxState) =>
  state.docViewer.isTableFullScreen;
export const getClearChat = (state: ReduxState) => state.docViewer.clearChat;
export const getShowCmdFInput = (state: ReduxState) =>
  state.docViewer.showCmdFInput;
export const getCmdFQuery = (state: ReduxState) => state.docViewer.cmdFQuery;
export const getCmdFIndex = (state: ReduxState) => state.docViewer.cmdFIndex;
export const getThumbnailState = (state: ReduxState) =>
  state.docViewer.thumbnailState;
export const getIsTableExtractionLoading = (state: ReduxState) =>
  state.docViewer.isTableExtractionLoading;
export const getNumPages = (state: ReduxState) => state.docViewer.numPages;
export const getCurrPage = (state: ReduxState) => state.docViewer.currPage;

const docViewerSlice = createSlice({
  name: "docViewer",
  initialState,
  reducers: {
    setSelectedQuoteId: (state, action: PayloadAction<string | null>) => {
      state.selectedQuoteId = action.payload;
    },
    setSelectedColumnId: (state, action: PayloadAction<string | null>) => {
      state.selectedColumnId = action.payload;
    },
    setActiveView: (state, action: PayloadAction<DocumentViewerViewType>) => {
      state.activeView = action.payload;
      if (action.payload !== "tables") {
        state.isTableFullScreen = false;
      }
    },
    setActiveHighlight: (
      state,
      action: PayloadAction<EvidenceQuoteV2 | null>
    ) => {
      state.activeHighlight = action.payload;
    },
    setAreThumbnailsOpen: (state, action: PayloadAction<boolean>) => {
      state.areThumbnailsOpen = action.payload;
    },
    setIsTableFullScreen: (state, action: PayloadAction<boolean>) => {
      state.isTableFullScreen = action.payload;
    },
    setClearChat: (state, action: PayloadAction<boolean>) => {
      state.clearChat = action.payload;
    },
    setShowCmdFInput: (state, action: PayloadAction<boolean>) => {
      state.showCmdFInput = action.payload;
    },
    setCmdFQuery: (state, action: PayloadAction<string>) => {
      state.cmdFQuery = action.payload;
    },
    setCmdFIndex: (state, action: PayloadAction<number>) => {
      state.cmdFIndex = action.payload;
    },
    setThumbnailState: (
      state,
      action: PayloadAction<DocumentViewerThumbnailState>
    ) => {
      state.thumbnailState = action.payload;
      if (
        action.payload !== DocumentViewerThumbnailState.tables &&
        state.activeHighlight?.kind === "table"
      ) {
        state.activeHighlight = null;
      }
    },
    setIsTableExtractionLoading: (state, action: PayloadAction<boolean>) => {
      state.isTableExtractionLoading = action.payload;
    },
    setCurrPage: (state, action: PayloadAction<number>) => {
      state.currPage = action.payload;
    },
    setNumPages: (state, action: PayloadAction<number>) => {
      state.numPages = action.payload;
    },
    resetViewerStateOnClose: (state) => {
      state.cmdFQuery = initialState.cmdFQuery;
      state.cmdFIndex = initialState.cmdFIndex;
      state.showCmdFInput = initialState.showCmdFInput;
      state.isTableFullScreen = initialState.isTableFullScreen;
      state.isTableExtractionLoading = initialState.isTableExtractionLoading;
      state.numPages = initialState.numPages;
      state.currPage = initialState.currPage;
      state.thumbnailState = initialState.thumbnailState;
    },
  },
});

export const {
  setSelectedQuoteId,
  setSelectedColumnId,
  setActiveView,
  setActiveHighlight,
  setAreThumbnailsOpen,
  setIsTableFullScreen,
  setClearChat,
  setShowCmdFInput,
  setCmdFQuery,
  setCmdFIndex,
  setThumbnailState,
  setIsTableExtractionLoading,
  setCurrPage,
  setNumPages,
  resetViewerStateOnClose,
} = docViewerSlice.actions;
export const docViewerReducer = docViewerSlice.reducer;
