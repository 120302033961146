import React, {
  DetailedHTMLProps,
  forwardRef,
  ReactNode,
  TableHTMLAttributes,
} from "react";
import { classNames } from "../../index";
import { TableContextProvider } from "./TableContext";

export interface TableHeadProps
  extends DetailedHTMLProps<
    TableHTMLAttributes<HTMLTableSectionElement>,
    HTMLTableSectionElement
  > {
  children?: ReactNode;
}

const TableHead = forwardRef<HTMLTableSectionElement, TableHeadProps>(
  (props: TableHeadProps, ref) => {
    const { children, className, ...rest } = props;
    return (
      <TableContextProvider
        value={{
          isHead: true,
        }}
      >
        <thead {...rest} ref={ref} className={classNames(className)}>
          {children}
        </thead>
      </TableContextProvider>
    );
  }
);

export default TableHead;
