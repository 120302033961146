import React from "react";
import KeyboardArrowDownSharpIcon from "@mui/icons-material/KeyboardArrowDownSharp";
import { DOC_TAB_WIDTH } from "source/constants";

type ShowMoreButtonProps = {
  handleClick: () => void;
  depth?: number;
};

export const ShowMoreButton = ({
  handleClick,
  depth = 0,
}: ShowMoreButtonProps) => (
  <div
    className="flex cursor-pointer items-center gap-2 py-2 pr-4 text-xs text-mediumGray hover:bg-gray1"
    onClick={handleClick}
    style={{
      paddingLeft: 16 + DOC_TAB_WIDTH * depth,
    }}
  >
    <span className="pl-0.5">
      <KeyboardArrowDownSharpIcon style={{ fontSize: "16px" }} />
    </span>
    <span>Show more</span>
  </div>
);
