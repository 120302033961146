// Generally useful info for this file can be found at
// https://api.slack.com/reference/surfaces/formatting
import {
  BASE_URL,
  SLACK_WEBHOOK_URL,
  REPORTS_SLACK_WEBHOOK_URL,
  CHATDOCS_SLACK_WEBHOOK_URL,
  REPORT_CELL_FEEDBACK_SLACK_WEBHOOK_URL,
  ALERTS_PUBLICS_SLACK_WEBHOOK_URL,
  DETECT_TABLES_SLACK_WEBHOOK_URL,
} from "source/constants";
import { isProd } from "source/envConstants";

export const GOALIE_USERGROUP = "<!subteam^S05TWGFREUU>";

export type ValidSlackURLType =
  | typeof SLACK_WEBHOOK_URL
  | typeof REPORTS_SLACK_WEBHOOK_URL
  | typeof CHATDOCS_SLACK_WEBHOOK_URL
  | typeof REPORT_CELL_FEEDBACK_SLACK_WEBHOOK_URL
  | typeof ALERTS_PUBLICS_SLACK_WEBHOOK_URL
  | typeof DETECT_TABLES_SLACK_WEBHOOK_URL;
/**
 * Send Slack alert to one of several available channels if in prod in deployed app
 * @param text message to send to slack. Can include specific users and group identifiers
 * @param webhookURL incoming webhook URL for a specific bot and Slack channel
 */
export const sendSlackAlert = (text: string, webhookURL: ValidSlackURLType) => {
  if (isProd && BASE_URL !== "http://localhost:3000") {
    fetch(webhookURL, {
      method: "POST",
      mode: "no-cors",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
      body: JSON.stringify({
        text: text,
      }),
    });
  }
};
