import { useQuery } from "@tanstack/react-query";
import { ALLOWED_MIME_FILTERS, PUBLIC_DOC_CLASS } from "source/constants";
import api from "..";
import { QueryContextFromKeys } from "../utils";
import { BatchKeysReposParams } from "./filters";
import { FiltersConfig } from "source/constants/filters";

// filters query key factory
const filtersKeys = {
  all: [{ scope: "filters" }] as const,
  filteredRepoDocs: (params: BatchKeysReposParams) =>
    [
      {
        ...filtersKeys.all[0],
        feature: "docsInMiddle",
        repo_ids: params.repo_ids,
        doc_ids: params.doc_ids,
        filters: params.filters,
        page: params.page,
        size: params.size,
      },
    ] as const,
  repoFilters: (repoIds: string[]) =>
    [{ ...filtersKeys.all[0], feature: "repoFilters", repoIds }] as const,
  orgFilters: (orgId?: string) => [{ ...filtersKeys.all[0], orgId }] as const,
  searchCompanyRepos: (searchValue?: string) =>
    [{ ...filtersKeys.all[0], feature: "companySearch", searchValue }] as const,
};

// Typed filters key factory context
// QueryFunctionContext is an object that is passed as argument to the queryFn, this is simply a way of typing it
type FiltersQueryContext = QueryContextFromKeys<typeof filtersKeys>;

export const useQueryFilteredRepoDocs = (params: BatchKeysReposParams) =>
  useQuery({
    queryKey: filtersKeys.filteredRepoDocs(params),
    queryFn: filteredRepoDocsFetcher,
    // Only fetch if repo is selected and the repo is not the spoofed public doc repo
    enabled: !!(
      !!params.toggled &&
      params.repo_ids.length &&
      !params.repo_ids.includes(PUBLIC_DOC_CLASS)
    ),
    // keepPreviousData: true,
    retry: 2,
  });

const filteredRepoDocsFetcher = async ({
  queryKey: [{ repo_ids, doc_ids, filters, page, size }],
}: FiltersQueryContext["filteredRepoDocs"]) =>
  api.filters.listDocsByRepoIdsAndKeys({
    repo_ids,
    doc_ids,
    filters,
    page,
    size,
  });

export const useQueryOrgFilters = (orgId?: string) =>
  useQuery({
    queryKey: filtersKeys.orgFilters(orgId),
    queryFn: orgFiltersFetcher,
    enabled: !!orgId,
    select: (data) => {
      const filterObj = data?.filters ?? {};
      filterObj[FiltersConfig.MIME_FILTER_KEY] = ALLOWED_MIME_FILTERS;
      return filterObj;
    },
  });

const orgFiltersFetcher = async ({
  queryKey: [{ orgId }],
}: FiltersQueryContext["orgFilters"]) => api.filters.listFiltersByOrgId(orgId);

export const useQuerySearchCompanyRepos = (searchValue?: string) =>
  useQuery({
    queryKey: filtersKeys.searchCompanyRepos(searchValue),
    queryFn: searchCompanyReposFetcher,
    enabled: !!searchValue?.length,
  });

const searchCompanyReposFetcher = async ({
  queryKey: [{ searchValue }],
}: FiltersQueryContext["searchCompanyRepos"]) =>
  api.filters.filterPublicRepos({ title: searchValue });
