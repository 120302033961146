import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { ReduxState } from ".";
import { AlertType } from "source/Types";

// TODO: rename this type
// Must be null instead of undefined to work with JSON serialization
export type AlertEditingType = {
  currentAlert: AlertType | null;
  query: string | null;
  filterString: string | null;
};

export const getAlertEditingProperties = (
  state: ReduxState
): AlertEditingType => state.alertEditingProps;
export const getUnfinalizedAlertQuery = (state: ReduxState) =>
  state.alertEditingProps.query;
export const getUnfinalizedAlertFilterString = (state: ReduxState) =>
  state.alertEditingProps.filterString;

const initialState: AlertEditingType = {
  currentAlert: null,
  query: null,
  filterString: null,
};

const alertEditingSlice = createSlice({
  name: "alert",
  initialState: initialState,
  reducers: {
    setAlertEditingProps: (
      state: AlertEditingType,
      action: PayloadAction<AlertEditingType>
    ) => {
      state = action.payload;
      return state;
    },
    setCurrentAlert: (
      state: AlertEditingType,
      action: PayloadAction<AlertType>
    ) => {
      state.currentAlert = action.payload;
      return state;
    },
    setAlertQuery: (state: AlertEditingType, action: PayloadAction<string>) => {
      state.query = action.payload;
      return state;
    },
    setAlertFilterString: (
      state: AlertEditingType,
      action: PayloadAction<string>
    ) => {
      state.filterString = action.payload;
      return state;
    },
  },
});

export const {
  setAlertEditingProps,
  setCurrentAlert,
  setAlertQuery,
  setAlertFilterString,
} = alertEditingSlice.actions;
export const alertEditingReducer = alertEditingSlice.reducer;
