import React from "react";
import { Hilby } from "./Hilby";

type Props = {
  background?: string;
};

export const Loading = ({ background }: Props) => (
  <div
    className="z-1000 flex h-[100vh] items-center justify-center gap-3 bg-background"
    style={background ? { background } : {}}
  >
    <Hilby
      size={125}
      initialWidth={12}
      startOrder={2}
      endOrder={5}
      rounded={true}
    />
  </div>
);
