import React from "react";
import { Button, ButtonProps } from "source/components/library/Button";
import { classNames } from "core";

export type ModalFooterButtonProps = ButtonProps & {
  position?: "left" | "right";
};

interface Props {
  actions: ModalFooterButtonProps[];
  overrideStyles?: string;
}

export const ModalFooter = ({ actions, overrideStyles }: Props) => (
  <div
    className={classNames(
      "flex items-center justify-between gap-2 px-6 py-[9px] align-middle",
      overrideStyles
    )}
  >
    <div className="flex gap-2">
      {actions
        .filter(({ position }) => position === "left")
        .map(({ ...rest }, idx) => (
          <Button key={`modal-footer-${idx}`} {...rest} />
        ))}
    </div>
    <div className="flex gap-2">
      {actions
        .filter(({ position }) => position != "left")
        .map(({ ...rest }, idx) => (
          <Button key={`modal-footer-${idx}`} {...rest} />
        ))}
    </div>
  </div>
);
