import {
  addDays,
  addMonths,
  endOfDay,
  endOfMonth,
  endOfQuarter,
  endOfWeek,
  endOfYear,
  startOfDay,
  startOfMonth,
  startOfQuarter,
  startOfWeek,
  startOfYear,
  format,
} from "date-fns";
import moment from "moment";

export type TimeUnit = "milliseconds" | "seconds" | "minutes" | "hours";

const timeMap: Record<TimeUnit, number> = {
  milliseconds: 1,
  seconds: 1_000,
  minutes: 60 * 1_000,
  hours: 60 * 60 * 1_000,
};

export const Millis = {
  of(time: number, unit: TimeUnit): number {
    return time * timeMap[unit];
  },
};

export const definedDates = {
  startOfWeek: startOfWeek(new Date()),
  endOfWeek: endOfWeek(new Date()),
  startOfLastWeek: startOfWeek(addDays(new Date(), -7)),
  endOfLastWeek: endOfWeek(addDays(new Date(), -7)),
  startOfToday: startOfDay(new Date()),
  endOfToday: endOfDay(new Date()),
  startOfYesterday: startOfDay(addDays(new Date(), -1)),
  endOfYesterday: endOfDay(addDays(new Date(), -1)),
  startOfMonth: startOfMonth(new Date()),
  endOfMonth: endOfMonth(new Date()),
  startOfLastMonth: startOfMonth(addMonths(new Date(), -1)),
  endOfLastMonth: endOfMonth(addMonths(new Date(), -1)),
  startOfThisQuarter: startOfQuarter(new Date()),
  endOfThisQuarter: endOfQuarter(new Date()),
  startOfLastQuarter: startOfQuarter(addMonths(new Date(), -3)),
  endOfLastQuarter: endOfQuarter(addMonths(new Date(), -3)),
  startOfThisYear: startOfYear(addMonths(new Date(), -12)),
  endOfThisYear: endOfYear(addMonths(new Date(), -12)),
  startOfLastYear: startOfYear(addMonths(new Date(), -24)),
  endOfLastYear: endOfYear(addMonths(new Date(), -24)),
  oneWeekAgo: startOfDay(addDays(new Date(), -7)),
  oneMonthAgo: startOfDay(addMonths(new Date(), -1)),
  oneYearAgo: startOfDay(addMonths(new Date(), -12)),
};

// Store cached default moments to avoid creating them multiple times
let cachedDefaultMoments: Record<string, moment.Moment> | null = null;

export const getDefaultMoments = () => {
  if (cachedDefaultMoments) return cachedDefaultMoments;

  cachedDefaultMoments = {
    oneMinuteAgo: moment().subtract(1, "minute"),
    fiveMinutesAgo: moment().subtract(5, "minutes"),
    oneHourAgo: moment().subtract(1, "hour"),
    oneDayAgo: moment().subtract(1, "day"),
    oneWeekAgo: moment().subtract(1, "week"),
    oneMonthAgo: moment().subtract(1, "month"),
    oneYearAgo: moment().subtract(1, "year"),
  };
  return cachedDefaultMoments;
};

// eslint-disable-next-line
export enum TimeFormat {
  RFC3339 = "",
  CALENDAR_DATE = "MMM DD, YYYY",
  LT = "LT",
  ll = "ll",
  LONG_CALENDAR_DATE = "LL",
}

export const formatDateString = (
  timestamp: string,
  format: TimeFormat
): string => {
  if (format === TimeFormat.RFC3339) return moment(timestamp).format();

  return moment(timestamp).format(format);
};

export const formatToLongDateWithTime = (date: Date) => {
  const formattedDate = format(date, "MMMM d");
  const formattedTime = format(date, "h:mm a");

  return `${formattedDate} at ${formattedTime}`;
};

export const formatToShortDateWithTime = (date: Date) =>
  date.toLocaleDateString("en-US", {
    month: "short",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
  });

export const convertDateFormat = (inputDate: string): string => {
  const date = new Date(inputDate);

  const year = date.getUTCFullYear();
  const month = (date.getUTCMonth() + 1).toString().padStart(2, "0");
  const day = date.getUTCDate().toString().padStart(2, "0");
  const hours = date.getUTCHours().toString().padStart(2, "0");
  const minutes = date.getUTCMinutes().toString().padStart(2, "0");
  const seconds = date.getUTCSeconds().toString().padStart(2, "0");
  const milliseconds = date.getUTCMilliseconds().toString().padStart(6, "0");

  return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}.${milliseconds}+00:00`;
};

export const mostRecentDate = (
  dateString1: string,
  dateString2: string
): string => {
  const currentDate = new Date();
  const date1 = new Date(dateString1);
  const date2 = new Date(dateString2);

  const diff1 = Math.abs(currentDate.getTime() - date1.getTime());
  const diff2 = Math.abs(currentDate.getTime() - date2.getTime());

  if (diff1 < diff2) {
    return dateString1;
  } else {
    return dateString2;
  }
};

export const mostRecentMoment = (
  momentA: moment.Moment,
  momentB: moment.Moment
): moment.Moment => {
  const currentTime = moment();
  const durationA = momentA.diff(currentTime);
  const durationB = momentB.diff(currentTime);

  return Math.abs(durationA) < Math.abs(durationB) ? momentA : momentB;
};

export const formatRelativeDateString = (timestamp: string): string => {
  const defaultMoments = getDefaultMoments();
  const timestampMoment = moment(timestamp);

  if (timestampMoment.isAfter(defaultMoments.fiveMinutesAgo)) {
    return "Just now";
  } else if (timestampMoment.isAfter(defaultMoments.oneHourAgo)) {
    return `${moment().diff(timestampMoment, "minutes")}m ago`;
  } else if (timestampMoment.isAfter(defaultMoments.oneDayAgo)) {
    return `${moment().diff(timestampMoment, "hours")}h ago`;
  } else if (timestampMoment.isAfter(defaultMoments.oneMonthAgo)) {
    return `${moment().diff(timestampMoment, "days")}d ago`;
  } else if (timestampMoment.isAfter(defaultMoments.oneYearAgo)) {
    const diff = moment().diff(timestampMoment, "months");
    return `${diff} month${diff > 1 ? "s" : ""} ago`;
  }
  const diff = moment().diff(timestampMoment, "years");
  return `${diff} year${diff > 1 ? "s" : ""} ago`;
};
