import React, {
  Dispatch,
  RefObject,
  SetStateAction,
  useContext,
  useRef,
  useState,
} from "react";
import { useComponentId } from "../../hooks/useComponentId";

export type MenuItemRef = RefObject<HTMLElement>;

export const getMenuItemIndex = (ref: MenuItemRef) => {
  return Number(ref?.current?.dataset?.index);
};

interface GetMenuItemProps {
  onClick?: (event: MouseEvent) => void;
  className?: string;
  role?: string;
  tabIndex?: number;
}

interface GetMenuProps {
  ref?: React.Ref<HTMLElement>;
  className?: string;
}

interface MenuContextProps {
  registerMenuItem: (ref: MenuItemRef) => void;
  getMenuItemProps: (ref: MenuItemRef) => GetMenuItemProps;
  getMenuProps: () => GetMenuProps;
  focusedIndex: number;
  setFocusedIndex: Dispatch<SetStateAction<number>>;
  items: MenuItemRef[];
  parentId: string;
}

// @ts-ignore the `registerMenuItem` is sufficient to protect against people using this context incorrectly, the other default fields are not needed.
export const MenuContext = React.createContext<MenuContextProps>({
  registerMenuItem: () => {
    throw new Error(
      "You're rendering a <MenuItem> outside the context of one of its parent components (Dropdown, Autocomplete, etc). If you just want a presentational component, use ListItem instead."
    );
  },
});

export const useMenu = () => {
  return useContext(MenuContext);
};

const isEqual = (arr1: any[], arr2: any[]): boolean => {
  if (arr1.length !== arr2.length) return false;
  for (let i = 0; i < arr1.length; i++) {
    if (arr1[i] !== arr2[i]) return false;
  }
  return true;
};

interface MenuProviderProps {
  id?: string;
  children: React.ReactNode;
}

export const MenuProvider = ({ children }: MenuProviderProps) => {
  const [items, setItems] = useState<MenuItemRef[]>([]);
  const itemsRef = useRef<Set<MenuItemRef>>(new Set());
  const timeoutRef = useRef<number>();
  const [focusedIndex, setFocusedIndex] = React.useState(-1);

  // allows us to know the exact list of <MenuItem> children that exist, and the order they occur in
  const registerMenuItem = (ref: MenuItemRef) => {
    // we can't just do setItems([...items, ref]) since this function will be called
    // multiple times per render cycle (once per each menu item), and items doesn't change until
    // the full render cycle ends, so basically we'd just be calling [...[], ref] and only the
    // last <MenuItem> would end up in the array
    // to fix this we assemble the items array using a ref, which we then copy to state immediately after the first render
    if (itemsRef.current) {
      itemsRef.current.add(ref);
    }
    clearTimeout(timeoutRef.current);
    timeoutRef.current = setTimeout(() => {
      const updatedItems = Array.from(itemsRef.current);
      if (!isEqual(updatedItems, items)) {
        setItems(updatedItems);
        for (let i = 0; i < updatedItems.length; i++) {
          const ref = updatedItems[i];
          if (ref?.current) {
            ref.current.dataset.index = String(i);
          }
        }
      }
      itemsRef.current = new Set();
    }, 0) as unknown as number;
  };

  const getMenuItemProps = () => {
    return {
      role: "option",
      tabIndex: -1,
    };
  };

  const id = useComponentId(undefined, "Menu");

  return (
    <MenuContext.Provider
      value={{
        registerMenuItem,
        getMenuItemProps,
        focusedIndex,
        setFocusedIndex,
        getMenuProps: () => ({}),
        items,
        parentId: id,
      }}
    >
      {children}
    </MenuContext.Provider>
  );
};
