import React, { useContext } from "react";

interface TableContextProps {
  isHead: boolean;
}

const TableContext = React.createContext({
  isHead: false,
});

export const useTableContext = () => {
  return useContext(TableContext);
};

interface TableContextProviderProps {
  children: React.ReactNode;
  value?: Partial<TableContextProps>;
}

export const TableContextProvider = ({
  children,
  value,
}: TableContextProviderProps) => {
  return (
    <TableContext.Provider value={{ isHead: false, ...value }}>
      {children}
    </TableContext.Provider>
  );
};
