import React from "react";
import { useSelector } from "react-redux";
import { DocumentType, RepoType } from "source/Types";
import { useCompanyRepos } from "source/hooks/gigabar/useCompanyRepos";
import { useGlobalNavigator } from "source/hooks/useSetRouter";
import { AllCompanies } from "./AllCompanies";
import { AppliedCompanies } from "./AppliedCompanies";
import { SearchedCompanies } from "./SearchedCompanies";
import {
  getTargetAppliedFilters,
  getTargetSuggestedFilters,
} from "source/redux/advanced";

export type HandleCheckType = {
  newCheckValue: boolean;
  repo?: RepoType;
  doc?: DocumentType;
};

type CompanyListProps = {
  searchValue?: string;
  reposCanOpen?: boolean;
};

export const CompanyList = ({
  searchValue,
  reposCanOpen,
}: CompanyListProps) => {
  const { goToRepo } = useGlobalNavigator();
  const appliedFilters = useSelector(getTargetAppliedFilters);
  const suggestedFilters = useSelector(getTargetSuggestedFilters);

  const {
    recentCompanyRepos,
    bookmarkedCompanyRepos,
    appliedCompanyRepos,
    suggestedCompanyRepos,
    searchedCompanyRepos,
  } = useCompanyRepos();

  const handleRepoClick = (repoId: string) => {
    goToRepo(repoId);
  };

  return (
    <div className="flex flex-col">
      <div className="flex w-full flex-col">
        {searchValue ? (
          <SearchedCompanies
            searchValue={searchValue}
            bookmarkedRepos={bookmarkedCompanyRepos}
            handleRepoClick={reposCanOpen ? handleRepoClick : undefined}
          />
        ) : (
          <>
            <AppliedCompanies
              filters={appliedFilters}
              repos={appliedCompanyRepos}
              bookmarkedRepos={bookmarkedCompanyRepos}
              autoApplied={true}
              tooltipText="Company filter is auto-applied"
            />
            <AppliedCompanies
              filters={suggestedFilters}
              repos={suggestedCompanyRepos}
              bookmarkedRepos={bookmarkedCompanyRepos}
              autoApplied={false}
              tooltipText="Company filter is suggested"
            />
            <AllCompanies
              searchedCompanyRepos={searchedCompanyRepos}
              bookmarkedRepos={bookmarkedCompanyRepos}
              repos={recentCompanyRepos}
              handleRepoClick={reposCanOpen ? handleRepoClick : undefined}
            />
          </>
        )}
      </div>
    </div>
  );
};
