import { AnyAction } from "@reduxjs/toolkit";
import { useInfiniteQuery, useQueries, useQuery } from "@tanstack/react-query";
import { queryClient } from "pages/_app";
import { Dispatch, useEffect, useMemo } from "react";
import { DocumentType, FilterType, ResultType } from "source/Types";
import {
  SEE_MORE_THRESHOLD,
  SIDEBAR_PAGINATION,
  APPROX_NUM_MORGAN_MORGAN_REPO,
  MORGAN_MORGAN_REPO_ID,
  MORGAN_MORGAN_ORG_ID,
} from "source/constants";
import { setSearchGroupBy } from "source/redux/search";
import api from "..";
import { QueryContextFromKeys } from "../utils";
import { docsKeys } from "./docsKeyFactory";
import { getActiveReportId, getReportRetrieveTool } from "source/redux/matrix";
import { useSelector } from "react-redux";
import { delay } from "lodash";
import { useFeatureFlag } from "source/hooks/useFeatureFlag";
import { getCurrentOrg } from "source/redux/organization";
import { DocByIdResponse } from "./types";

// Typed docs key factory context
type DocsQueryContext = QueryContextFromKeys<typeof docsKeys>;

export const useQueryDoc = (docId?: string, retry?: number) => {
  const enableMatrixDocPermissionsChecks = useFeatureFlag(
    "enableMatrixDocPermissionsChecks"
  );
  const orgId = useSelector(getCurrentOrg)?.id;
  const matrixId = useSelector(getActiveReportId);

  return useQuery({
    queryKey: docsKeys.doc(
      docId,
      matrixId,
      orgId,
      enableMatrixDocPermissionsChecks
    ),
    queryFn: docFetcher,
    enabled: !!docId,
    retry: retry ?? 2,
  });
};

export const useUpdateDocQueryCache = () => {
  const enableMatrixDocPermissionsChecks = useFeatureFlag(
    "enableMatrixDocPermissionsChecks"
  );
  const orgId = useSelector(getCurrentOrg)?.id;
  const matrixId = useSelector(getActiveReportId);

  return (doc: DocumentType) => {
    // We need to preserve previous ancestors, repo if it was there
    const previousDoc: DocByIdResponse | undefined = queryClient.getQueryData(
      docsKeys.doc(doc.id, matrixId, orgId, enableMatrixDocPermissionsChecks)
    );

    const newDoc = {
      doc,
      ancestors: previousDoc?.ancestors,
      repo: previousDoc?.repo,
    };
    queryClient.setQueryData(
      docsKeys.doc(doc.id, matrixId, orgId, enableMatrixDocPermissionsChecks),
      newDoc
    );
  };
};

export const usePrefetchDocsByIds = (numDocsToPrefetch) => {
  const enableMatrixDocPermissionsChecks = useFeatureFlag(
    "enableMatrixDocPermissionsChecks"
  );
  const orgId = useSelector(getCurrentOrg)?.id;
  const matrixId = useSelector(getActiveReportId);
  const retrieveTool = useSelector(getReportRetrieveTool);
  const docIds = retrieveTool?.tool_params?.doc_ids;
  const nDocs = docIds?.slice(0, numDocsToPrefetch);

  useEffect(() => {
    if (nDocs) {
      const chunkSize = 10;
      for (let i = 0; i < nDocs.length; i += chunkSize) {
        const chunk = nDocs.slice(i, i + chunkSize);
        delay(
          () => {
            chunk.forEach((docId) => {
              queryClient.prefetchQuery(
                docsKeys.doc(
                  docId,
                  matrixId,
                  orgId,
                  enableMatrixDocPermissionsChecks
                ),
                docFetcher
              );
            });
          },
          (i / chunkSize) * 100
        ); // Wait 100 ms between each chunk
      }
    }
  }, [nDocs]);
};

// Error-catching wrapper function for async doc fetch
const catchDocFetchErrors = async (docPromise: Promise<DocByIdResponse>) => {
  try {
    return await docPromise;
  } catch (e) {
    // Return undefined if an error occurs
    return;
  }
};

// To enable local upload + sharepoint doc sharing, we have a new doc fetch endpoint on the `sheets` service
// this new `sheets` endpoint is local-upload / sharepoint - specific, unlike the old `brain` endpoint
// so here, we run both requests in parallel - if the enableMatrixDocPermissionsChecks sharing flag is enabled
export const docFetcher = async ({
  queryKey: [{ docId, matrixId, orgId, sharingDocPermissionChecksEnabled }],
}: DocsQueryContext["doc"]) => {
  // check doc id at runtime because it can be `undefined`
  if (typeof docId === "undefined") return;
  // Default to brain endpoint if feature flag is disabled (or matrix / org ID is undefined)
  if (!matrixId || !orgId || !sharingDocPermissionChecksEnabled)
    return await api.docs.getDocById(docId); // No error-catching here, single query
  // Query brain and sheets endpoints in parallel
  const docPromise = catchDocFetchErrors(api.docs.getDocById(docId));
  const sharedDocPromise = catchDocFetchErrors(
    api.docs.getSharedDoc(docId, matrixId, orgId)
  );
  // Await both queries
  const [docResult, sharedDocResult] = await Promise.all([
    docPromise,
    sharedDocPromise,
  ]);
  // Return docResult or fall back to localDocResult if docResult fails
  if (docResult) return docResult;
  if (sharedDocResult) return sharedDocResult;
  // If both endpoints have failed, throw an error
  throw new Error("Both doc queries failed in docFetcher");
};

/** Used to grab a bunch of docs individually from the cache,
 * AND will fetch the docs if they aren't in the cache -- neat!!!
 */
export const useQueryDocsByIds = (docIds: string[], enabled = true) => {
  const enableMatrixDocPermissionsChecks = useFeatureFlag(
    "enableMatrixDocPermissionsChecks"
  );
  const orgId = useSelector(getCurrentOrg)?.id;
  const matrixId = useSelector(getActiveReportId);
  const docsQueries = useQueries({
    queries: docIds.map((docId) => ({
      queryKey: docsKeys.doc(
        docId,
        matrixId,
        orgId,
        enableMatrixDocPermissionsChecks
      ),
      queryFn: docFetcher,
      enabled: enabled,
    })),
  });
  return docsQueries;
};

/**Used to fetch many docs in a single batch request */
export const useQueryBatchDocsByIds = ({
  docIds,
  matrixId,
  enabled,
}: {
  docIds: string[];
  matrixId?: string;
  enabled: boolean;
}) =>
  useQuery({
    queryKey: docsKeys.batchDocs(docIds, matrixId),
    queryFn: batchDocsFetcher,
    enabled: enabled && matrixId !== undefined,
  });

export const batchDocsFetcher = ({
  queryKey: [{ docIds, matrixId }],
}: DocsQueryContext["batchDocs"]) =>
  docIds.length && matrixId !== undefined
    ? api.docs.getSimpleDocsBatch(docIds, matrixId)
    : Promise.resolve({ docs: [] });

export const useQuerySearchDocs = (repoIds: string[], searchValue?: string) =>
  useQuery({
    queryKey: docsKeys.searchDocs(repoIds, searchValue),
    queryFn: searchDocsFetcher,
    enabled: !!searchValue?.length,
  });

const searchDocsFetcher = async ({
  queryKey: [{ searchValue, repoIds }],
}: DocsQueryContext["searchDocs"]) =>
  api.filters.filterSubstringDocs({ title: searchValue, repo_ids: repoIds });

/** Hook that returns all docs in the react query cache for a given repo */
export const useCachedRepoDocs = (repoId?: string) => {
  const parentDocQueries = queryClient.getQueriesData(
    docsKeys.parentDocs(repoId)
  );
  const childrenDocQueries = queryClient.getQueriesData([
    { scope: "docs", repoId, level: "children" },
  ]);
  const parentDocs = useMemo(() => {
    const queries = parentDocQueries
      .map((query) => (query.length > 1 ? query[1] : undefined))
      .filter((_) => _);
    const docs: any[] = [];
    queries.forEach((query: any) => {
      if (query.pages) {
        query.pages.forEach((page) => {
          if (page.docs?.length) docs.push(...page.docs);
          if (page.failed_docs?.length) docs.push(...page.failed_docs);
        });
      }
    });
    return docs;
  }, [parentDocQueries]);

  const childrenDocs = useMemo(() => {
    const queries = childrenDocQueries
      .map((query) => (query.length > 1 ? query[1] : undefined))
      .filter((_) => _);
    const docs: any[] = [];
    queries.forEach((query: any) => {
      if (query.pages) {
        query.pages.forEach((page) => {
          if (page.docs?.length) docs.push(...page.docs);
        });
      }
    });
    return docs;
  }, [childrenDocQueries]);

  if (!repoId) return [];

  return [...parentDocs, ...childrenDocs];
};

/** An infinite query that sequentially fetches a repo's parent docs */
export const useQueryParentDocs = (repoId: string | undefined) => {
  const parentQuery = useInfiniteQuery({
    queryKey: docsKeys.parentDocs(repoId),
    queryFn: parentDocsFetcher,
    // This function determines if we need to fetch more data.
    // By default, value returned from getNextPageParam will be passed to fetchNextPage()
    getNextPageParam: (lastPage, pages) =>
      (lastPage?.docs?.length ?? 0) + (lastPage?.failed_docs?.length ?? 0) >
      SIDEBAR_PAGINATION
        ? pages?.length
        : undefined,
  });
  const { data, fetchNextPage, hasNextPage, isFetchingNextPage, isLoading } =
    parentQuery;
  return { data, fetchNextPage, hasNextPage, isFetchingNextPage, isLoading };
};

const parentDocsFetcher = async ({
  queryKey: [{ repoId }],
  pageParam = 0,
}: DocsQueryContext["parentDocs"]) =>
  // check id at runtime because it can be `undefined`
  typeof repoId === "undefined"
    ? Promise.resolve(undefined)
    : api.repos.getParentDocs(repoId, {
        page: pageParam,
        size: SIDEBAR_PAGINATION + 1,
      });

/** An infinite query that sequentially fetches a documents' children docs */
export const useQueryChildrenDocs = (
  docId: string | undefined,
  repoId: string | undefined
) => {
  const childrenQuery = useInfiniteQuery({
    queryKey: docsKeys.childrenDocs(docId, repoId),
    queryFn: childrenDocsFetcher,
    getNextPageParam: (lastPage, pages) =>
      (lastPage?.docs?.length ?? 0) > SEE_MORE_THRESHOLD
        ? pages?.length
        : undefined,
  });
  const { data, fetchNextPage, hasNextPage, isFetchingNextPage, isLoading } =
    childrenQuery;
  return { data, fetchNextPage, hasNextPage, isFetchingNextPage, isLoading };
};

const childrenDocsFetcher = async ({
  queryKey: [{ docId }],
  pageParam = 0,
}: DocsQueryContext["childrenDocs"]) =>
  // check id at runtime because it can be `undefined`
  typeof docId === "undefined"
    ? Promise.resolve(undefined)
    : api.docs.getDocChildren(docId, {
        page: pageParam,
        size: SEE_MORE_THRESHOLD + 1,
      });

export const useQueryDocCounts = (
  docIds: string[],
  filters: FilterType[],
  repoId?: string,
  orgId?: string
) => {
  const query = useQuery({
    queryKey: docsKeys.docCounts(docIds, filters, repoId, orgId),
    queryFn: docCountFetcher,
    enabled: !!repoId,
  });
  return query.data;
};

const docCountFetcher = async ({
  queryKey: [{ repoId, docIds, filters, orgId }],
}: DocsQueryContext["docCounts"]) => {
  if (orgId === MORGAN_MORGAN_ORG_ID)
    return Promise.resolve({
      success:
        repoId === MORGAN_MORGAN_REPO_ID ? APPROX_NUM_MORGAN_MORGAN_REPO : 0,
      building: 0,
      failed: 0,
    });
  if (repoId)
    return api.docs.getDocCount(repoId, {
      doc_ids: docIds,
      filters: filters,
    });
};

/** Given search results, return the docs */
export const useQuerySearchSuggestedDocs = (
  results: ResultType[],
  dispatch: Dispatch<AnyAction>
) => {
  const searchResultDocIds = Array.from(
    new Set<string>(results.map((result: ResultType) => result.doc_id))
  );
  const query = useQuery({
    queryKey: docsKeys.searchSuggested(searchResultDocIds),
    queryFn: searchSuggestedFetcher,
    enabled: !!searchResultDocIds.length,
  });
  const docs = useMemo(() => {
    if (query.isError || query.isLoading) return [];
    const searchResultsDocs = query.data.docs ?? [];
    const searchResultDocsAncestors = query.data.all_ancestors ?? [];
    const groupByKey = searchResultDocIds.length > 1 ? "doc" : null;
    dispatch(setSearchGroupBy(groupByKey));
    return [...searchResultsDocs, ...searchResultDocsAncestors];
  }, [query.data]);

  return docs;
};

const searchSuggestedFetcher = async ({
  queryKey: [{ docIds }],
}: DocsQueryContext["searchSuggested"]) => api.docs.getDocsBatch(docIds);

/** Fetches preview docs for report in template mode */
export const useQueryPreviewDocs = (
  repoId: string | undefined,
  reportId: string | undefined,
  docTargets: string[],
  templateMode: boolean | undefined
) =>
  useQuery({
    queryKey: docsKeys.previewDocs(repoId, repoId, docTargets),
    queryFn: previewDocsFetcher,
    enabled: !!(repoId && reportId && templateMode),
    retry: 2,
  });

export const previewDocsFetcher = async ({
  queryKey: [{ repoId, reportId, docTargets }],
}: DocsQueryContext["previewDocs"]) =>
  // check id at runtime because it can be `undefined`
  typeof repoId === "undefined" || typeof reportId === "undefined"
    ? Promise.resolve(undefined)
    : api.repos.getTargetPreviewDocs(repoId, reportId, docTargets);

export const useQueryUnsupportedDocs = (repoId?: string) => {
  const docQuery = useQuery({
    queryKey: docsKeys.unsupportedDocs(repoId),
    queryFn: unsupportedDocsFetcher,
    enabled: !!repoId,
    retry: 2,
  });

  return docQuery;
};

export const unsupportedDocsFetcher = async ({
  queryKey: [{ repoId }],
}: DocsQueryContext["unsupportedDocs"]) =>
  // check id at runtime because it can be `undefined`
  typeof repoId === "undefined"
    ? Promise.resolve(undefined)
    : api.docs.getUnsupportedDocs(repoId);

export const useQueryRepoChildrenCount = (repoId: string, enabled?: boolean) =>
  useQuery({
    queryKey: docsKeys.repoChildrenCount(repoId),
    queryFn: repoChildrenCount,
    enabled: enabled,
  });

export const repoChildrenCount = async ({
  queryKey: [{ repoId }],
}: DocsQueryContext["repoChildrenCount"]) => api.repos.getChildrenCount(repoId);

export const useQueryDocChildrenCount = (docId: string, enabled?: boolean) =>
  useQuery({
    queryKey: docsKeys.docChildrenCount(docId),
    queryFn: docChildrenCount,
    enabled: enabled,
  });

export const docChildrenCount = async ({
  queryKey: [{ docId }],
}: DocsQueryContext["docChildrenCount"]) => api.docs.getChildrenCount(docId);

export const useQueryDocViewerAsPDF = (docId: string) =>
  useQuery({
    queryKey: docsKeys.docViewerAsPDF(docId),
    queryFn: docViewerAsPDFFetcher,
  });

export const docViewerAsPDFFetcher = async ({
  queryKey: [{ docId }],
}: DocsQueryContext["docViewerAsPDF"]) =>
  // check id at runtime because it can be `undefined`
  typeof docId === "undefined"
    ? Promise.resolve(undefined)
    : api.docs.getDocViewerAsPDF(docId);
