import {
  AzureDocumentDataType,
  DocumentFailureReasonType,
  DocumentType,
  ReportFilterOperation,
  EarningsTranscript,
} from "source/Types";

export enum FastBuildSource {
  SHAREPOINT = "sharepoint",
  LOCAL = "local",
  AZURE_FILE_SHARE = "azure_file_share",
  SPLIT_DOC = "split_doc",
  SNP_EARNINGS = "snp_earnings",
  SNP_FILINGS = "snp_filings",
  SNP_PRESENTATIONS = "snp_presentations",
  S3 = "s3",
  BOX = "box",
  URL = "url",
  THIRD_BRIDGE = "third_bridge",
}

export const PUBLIC_DOC_SOURCES = [
  FastBuildSource.SNP_EARNINGS,
  FastBuildSource.SNP_PRESENTATIONS,
  FastBuildSource.SNP_FILINGS,
];

export enum FastBuildStatus {
  PENDING = "PENDING",
  BUILDING = "BUILDING",
  RUNNING_OCR = "RUNNING_OCR",
  SUCCESS = "SUCCESS",
  FAILED = "FAILED",
}
export enum DetectTablesAndChartsStatus {
  NOT_STARTED = "not_started",
  PENDING = "pending",
  RUNNING = "running",
  DONE = "done",
  FAILED = "failed",
}

export type FastBuildDocData = {
  doc_id: string;
  doc_name: string;
  doc_source: FastBuildSource;
  status: FastBuildStatus;
  failure_reason?: DocumentFailureReasonType;

  detect_tables_and_charts_status?: DetectTablesAndChartsStatus;
};

//#region INTEGRATION SPECIFIC TYPES

export type SharepointNodeIdentifier = {
  site_id?: string | null;
  remote_id?: string | null;
  drive_id?: string | null;
};

export type S3ParentIdentifier = {
  id: string;
};

export type BoxParentIdentifier = {
  id: string;
};

export type AzureParentIdentifier = {
  id: string;
};

export type ThirdBridgeParentIdentifier = {
  id: string;
};

export type SharepointDisplayData =
  | {
      // For Sites
      web_url: string;
    }
  | {
      // For Drives
      web_url: string;
      drive_type: DriveType;
    }
  | {
      // For Docs/Folders (site_id does not uniquely identify them, but is needed to render)
      web_url: string;
      site_id: string;
    };

export type S3DisplayData = {
  key: string;
};

// TODO: should the display data be typed
export type BoxDisplayData = {
  permissions: { [key: string]: boolean };
  file_path: { id: string; name: string }[];
};

export type ThirdBridgeDisplayData = Record<string, never>;

export type PublicsSnpEarningsIdentifier = {
  company_id: number;
  earnings_query_params?: EarningsTranscript;
};

export type PublicsSnpFilingsQueryParams = {
  accession_number: string;
  company_name: string;
  exhibit_type?: string;
  company_id?: number;
};

export type PublicsSnpFilingsIdentifier = {
  company_id: number;
  filings_query_params?: PublicsSnpFilingsQueryParams;
};

//#endregion

//#region GENERIC TYPES - EXTEND FOR NEW INTEGRATIONS

// Generic integration type to use in menu
export type IntegrationContext = {
  id: string;
  integrationType: FastBuildSource;
  connected: boolean;
  bucketName?: string; // s3 only
  roleARN?: string; // s3 only
  reconfigureEnabled?: boolean;
};

export type FastBuildNode<Identifier, DisplayData> = {
  node_identifier: Identifier;
  hash: string;
  title: string;
  display_path?: string;
  size?: number;
  has_content: boolean;
  has_children: boolean;
  last_modified: string;
  mime?: string;
  display_data: DisplayData;
};

export type SelectedBoxResource = SelectedResource<BoxParentIdentifier>;
export type SelectedSharepointResource =
  SelectedResource<SharepointNodeIdentifier>;

export type SelectedResource<Identifier> = {
  node_identifier: Identifier;
  title?: string;
  file_path?: { [key: string]: string }[];
};

export type SelectedThirdBridgeResource = {
  node_identifier: ThirdBridgeParentIdentifier;
  title: string;
  third_bridge_company_key: string;
};

//#endregion

export type FastBuildChildrenParams<Identifier, AuthContext> = {
  integration: FastBuildSource;
  org_id: string;
  auth_context_info: AuthContext;
  parent_identifier?: Identifier;
};

export type FastBuildIngestDocRequest<Identifier> = {
  node_identifier: Identifier;
  hash?: string;
  mime?: string;
  title: string;
  display_path?: string;
  last_modified?: string;
};

export type FastBuildIngestParams<Identifier, AuthContext> = {
  integration: FastBuildSource;
  org_id: string;
  auth_context_info: AuthContext;
  requests: Record<string, FastBuildIngestDocRequest<Identifier>>;
  sheet_id?: string; // This is legacy and no longer required
};

export type FastBuildIngestResponse = Record<string, DocumentType>;

/* SharePoint Types */
export type DriveType = "personal" | "business" | "documentLibrary";

export type RemoteDrive = {
  name: string;
  drive_id: string;
  drive_type: DriveType;
  web_url: string;
  site_id?: string;
  last_modified?: string;
};
export type SharepointNodeIdentifierType = "doc" | "folder" | "drive" | "site";
export type SharePointMetadataType =
  | "amendment"
  | "annual_financials"
  | "budget_statement"
  | "capstack_at_close"
  | "commitment_letter"
  | "compliance_certificate"
  | "credit_agreement"
  | "credit_agreement_summary"
  | "credit_monitoring_report"
  | "deal"
  | "esg"
  | "facility_agreement"
  | "fee_letter"
  | "financial_statement"
  | "forbearance_and_waivers"
  | "ic_memo"
  | "intercreditor_agreement"
  | "miscellaneous_closing_documentation"
  | "monitoring"
  | "private_rating_letter"
  | "quarterly_financials"
  | "security_agreement"
  | "term_sheet"
  | "two_pager";

type BaseExtension = "docx" | "pptx" | "pdf" | "folder";

// can union with integration specific extensions (e.g. boxnote)
// TODO: These are extensions not mimes, and references to them should be named appropriately
export type SharePointMimeType = BaseExtension;
export type BoxMimeType = BaseExtension;
export const BoxMimeTypes: BoxMimeType[] = ["docx", "pptx", "pdf", "folder"];

export type SharePointSearchParams = {
  org_id: string;
  query: string;
  folder_path?: string;
  mime_type?: SharePointMimeType;
};

export type SharePointChildrenMetadataFilterParams = {
  org_id: string;
  metadata: SharePointMetadataType;
  curr_page?: string;
};

export type SharePointAccessByIdsParams = {
  org_id: string;
  drive_id: string;
  remote_id: string;
};

export type SharePointAccessByIdsResponse = {
  access: boolean;
};

export type SharePointSearchResponse = {
  docs: SharepointFastBuildNode[];
};

export type CrawlSharePointParentResource = {
  root_id: string;
  drive_id?: string;
  site_id?: string;
};

export type CrawlSharePointFolderParams = {
  org_id: string;
  sheet_id: string;
  parent_resources: CrawlSharePointParentResource[];
};

/* Global Types */

export type IngestFilesResponseV2 = Record<string, DocumentType>;

export type BoxSearchParams = {
  org_id: string;
  query: string;
  extension?: BoxMimeType;
  parentId?: string;
};

export type BoxSearchResponse = {
  docs: BoxFastBuildNode[];
};

export type SharepointFastBuildNode = FastBuildNode<
  SharepointNodeIdentifier,
  SharepointDisplayData
>;

export type AzureFastBuildNode = FastBuildNode<
  AzureParentIdentifier,
  AzureDocumentDataType
>;

export type BoxFastBuildNode = FastBuildNode<
  BoxParentIdentifier,
  BoxDisplayData
>;

export type S3FastBuildNode = FastBuildNode<S3ParentIdentifier, S3DisplayData>;

export type SharepointAncestorsParams = SharepointNodeIdentifier;

// export type FilePathObject<Identifier> = {
//   node_identifier: Identifier;
//   name: string;
// };

export type SharepointAncestorsResponse = {
  ancestors: SharepointFastBuildNode[];
};

export enum FastBuildSearchFilterKey {
  TIMESTAMP = "timestamp",
  COMPANY_NAME = "company",
}

export type FastBuildSearchFilter = {
  key: FastBuildSearchFilterKey;
  operation: ReportFilterOperation;
  value: string;
};
