import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { TableType } from "source/Types";
import { ReduxState } from ".";

export type DocTablesReduxType = {
  tables: { [id: string]: TableType[] };
  newTables: { [id: string]: TableType[] };
};

export const getNewTables = (state: ReduxState) => state.docTables.newTables;

const initialState: DocTablesReduxType = { tables: {}, newTables: {} };

export const docTablesSlice = createSlice({
  name: "docTables",
  initialState,
  reducers: {
    setTables: (
      state: DocTablesReduxType,
      action: PayloadAction<Record<string, TableType[]>>
    ) => {
      Object.entries(action.payload).forEach(([key, val]) => {
        state.tables[key] = val;
      });
      return state;
    },
    setNewTables: (
      state: DocTablesReduxType,
      action: PayloadAction<Record<string, TableType[]>>
    ) => {
      Object.entries(action.payload).forEach(([key, val]) => {
        state.newTables[key] = val;
      });
      return state;
    },
    upsertNewTables: (
      state: DocTablesReduxType,
      action: PayloadAction<Record<string, TableType[]>>
    ) => {
      Object.entries(action.payload).forEach(([key, val]) => {
        const prevTables = state.newTables[key] ?? [];
        const tablesToUpsert = val.filter(
          (table) => !prevTables.find((tab) => tab.id === table.id)
        );
        state.newTables[key] = [...prevTables, ...tablesToUpsert];
      });

      return state;
    },
  },
});

export const { setTables, setNewTables, upsertNewTables } =
  docTablesSlice.actions;

export const docTablesReducer = docTablesSlice.reducer;
