import React from "react";
import { classNames } from "core";

export interface SpinnerProps extends React.SVGProps<SVGSVGElement> {
  className?: string;
  size?: number | string;
  color?: "inherit" | "primary" | string;
}

const Spinner = ({
  size = 18,
  className,
  color = "primary",
  style,
  ...rest
}: SpinnerProps) => {
  const hasNamedColor = ["inherit", "primary"].includes(color);

  return (
    <svg
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      className={classNames(
        "fill-current text-inherit",
        {
          "text-brand-500": color === "primary",
          "text-inherit": color === "inherit",
        },
        className
      )}
      style={{
        color: hasNamedColor ? undefined : color,
        ...style,
      }}
      {...rest}
    >
      <path
        d="M12,1A11,11,0,1,0,23,12,11,11,0,0,0,12,1Zm0,19a8,8,0,1,1,8-8A8,8,0,0,1,12,20Z"
        opacity=".25"
      />
      <path d="M10.72,19.9a8,8,0,0,1-6.5-9.79A7.77,7.77,0,0,1,10.4,4.16a8,8,0,0,1,9.49,6.52A1.54,1.54,0,0,0,21.38,12h.13a1.37,1.37,0,0,0,1.38-1.54,11,11,0,1,0-12.7,12.39A1.54,1.54,0,0,0,12,21.34h0A1.47,1.47,0,0,0,10.72,19.9Z">
        <animateTransform
          attributeName="transform"
          type="rotate"
          dur="0.75s"
          values="0 12 12;360 12 12"
          repeatCount="indefinite"
        />
      </path>
    </svg>
  );
};

export default Spinner;
