import React from "react";
import { FilterIcon } from "source/components/shared/Icons/FilterIcon";
import { Tooltip } from "source/components/shared/Tooltip";
import { IDocumentMime, KeyValuePair } from "source/Types";
import { FiltersConfig } from "source/constants/filters";
import { CompanyFilterChip } from "./CompanyFilterChip";
import { RepoFilterChip } from "./RepoFilterChip";
import { DateFilterChip } from "./DateFilterChip";
import { DocFilterChip } from "./DocFilterChip";
import { FilterCheckbox } from "./FilterCheckbox";
import { getHumanMime } from "source/utils/documents";
import { transformFilter } from "source/components/gigabar/components/FilterSection/utils";
import { wordToTitleCase, cleanText } from "source/utils/common/strings";
import dayjs from "dayjs";

type TargetFilterChipProps = {
  keyValuePair: KeyValuePair;
  disableCheckbox: boolean;
  isChecked: boolean;
  handleCheck: () => void;
};

/* Base filter chip component for target filters*/
export const TargetFilterChip = ({
  keyValuePair,
  disableCheckbox = false,
  isChecked,
  handleCheck,
}: TargetFilterChipProps) => {
  if (keyValuePair.key === FiltersConfig.TICKER_FILTER_KEY)
    return (
      <div
        onClick={handleCheck}
        className="mx-1.5 my-1.5 flex h-10 items-center justify-center border border-border3 hover:cursor-pointer hover:border-mediumGray"
      >
        <CompanyFilterChip
          checkbox={
            <FilterCheckbox
              shouldShow={!disableCheckbox}
              isChecked={isChecked}
            />
          }
          keyValuePair={keyValuePair}
        />
      </div>
    );
  if (
    keyValuePair.key === FiltersConfig.REPO_ID_FILTER_KEY ||
    keyValuePair.key === FiltersConfig.DOC_ID_FILTER_KEY
  )
    return (
      <div
        onClick={handleCheck}
        className="mx-1.5 my-1.5 flex h-10 items-center justify-center border border-border3 hover:cursor-pointer hover:border-mediumGray"
      >
        {keyValuePair.key === FiltersConfig.REPO_ID_FILTER_KEY ? (
          <RepoFilterChip
            checkbox={
              <FilterCheckbox shouldShow={true} isChecked={isChecked} />
            }
            keyValuePair={keyValuePair}
          />
        ) : (
          <DocFilterChip
            checkbox={
              <FilterCheckbox shouldShow={true} isChecked={isChecked} />
            }
            keyValuePair={keyValuePair}
          />
        )}
      </div>
    );
  if (keyValuePair.key === FiltersConfig.FILTER_KEY_DATE) {
    const readableValue = dayjs(keyValuePair.value as string).format(
      "MM/DD/YYYY"
    );
    const readablePair = { ...keyValuePair, value: readableValue };
    return (
      <div
        onClick={handleCheck}
        className="mx-1.5 my-1.5 flex h-10 items-center justify-center border border-border3 hover:cursor-pointer hover:border-mediumGray"
      >
        <DateFilterChip
          checkbox={
            <FilterCheckbox
              shouldShow={!disableCheckbox}
              isChecked={isChecked}
            />
          }
          keyValuePair={readablePair}
        />
      </div>
    );
  }

  const friendlyFilter = getFriendlyFilter(keyValuePair);

  return (
    <div
      onClick={handleCheck}
      className="mx-1.5 my-1.5 flex h-10 items-center justify-center border border-border3 hover:cursor-pointer hover:border-mediumGray"
    >
      <Tooltip title={friendlyFilter.key} enterDelay={500}>
        <div className="flex flex-1 flex-nowrap items-center gap-2 overflow-hidden whitespace-nowrap bg-card px-2 py-1 text-center text-sm font-medium">
          <FilterCheckbox shouldShow={!disableCheckbox} isChecked={isChecked} />
          <span className="flex items-center">
            <FilterIcon
              filter={keyValuePair}
              classOverride={"text-secondary"}
            />
          </span>
          <span>{friendlyFilter.value}</span>
        </div>
      </Tooltip>
    </div>
  );
};

export const getFriendlyFilter = (keyValuePair: KeyValuePair): KeyValuePair => {
  if (keyValuePair.key === FiltersConfig.MIME_FILTER_KEY)
    return {
      key: "Type",
      value: getHumanMime(keyValuePair.value as IDocumentMime) ?? "",
    };
  if (keyValuePair.key === FiltersConfig.INTEGRATION_FILTER_KEY) {
    return {
      key: FiltersConfig.INTEGRATION_FILTER_NAME,
      value: transformFilter(keyValuePair.key, keyValuePair.value) ?? "",
    };
  }

  return {
    key: wordToTitleCase(cleanText(keyValuePair.key)),
    value: keyValuePair.value,
  };
};
