import { useRouter } from "next/router";
import { useEffect, useRef } from "react";
import posthog from "posthog-js";
import type { ParsedUrlQuery } from "querystring";

interface RouterState {
  pathname: string;
  query: ParsedUrlQuery;
}

// query params that, if changed, we want to consider to be a new pageview
const WHITELISTED_QUERY_PARAMS = ["id", "matrix_id"];

// Posthog doesn't track route changes in SPAs, so we need to manually
// trigger a page view event each time the URL changes
export const usePosthogPageView = () => {
  const router = useRouter();
  const last = useRef<RouterState>({
    pathname: router.pathname,
    query: router.query,
  });

  useEffect(() => {
    const handleRouteChange = () => {
      const didPathnameChange = router.pathname !== last.current.pathname;
      let didQueryChange = false;
      for (const param of WHITELISTED_QUERY_PARAMS) {
        if (router.query[param] !== last.current.query[param]) {
          didQueryChange = true;
        }
      }

      if (didQueryChange || didPathnameChange) {
        posthog?.capture("$pageview");
      }

      last.current = {
        pathname: router.pathname,
        query: router.query,
      };
    };

    router.events.on("routeChangeComplete", handleRouteChange);

    // on unmount, remove the event listener
    return () => {
      router.events.off("routeChangeComplete", handleRouteChange);
    };
  }, [router]);
};
