import { useDispatch } from "react-redux";
import { setActiveReportName } from "source/redux/matrix";
import { queryClient } from "pages/_app";
import {
  reportsKeys,
  useGenerateTitleMutation,
  useRenameReportMutation,
} from "source/api/matrix/useQueryReports";
import logger from "source/utils/logger";

// This is what the LLM is asked to return when it doesn't have enough context to generate a name
const LLM_FALLBACK_REPORT_NAME = "Untitled Matrix";

export const MIN_AUTOTITLE_DOCUMENTS = 3;
export const MIN_AUTOTITLE_COLUMNS = 1;

export const DEFAULT_REPORT_NAME_REGEX =
  /^Matrix on (January|February|March|April|May|June|July|August|September|October|November|December) \d{1,2}(st|nd|rd|th)$/;

export type GenerateReportTitleCallback = (
  reportId?: string | undefined,
  oldReportName?: string | undefined,
  documentNames?: string[] | undefined,
  columnNames?: string[] | undefined
) => void;

export const useRenameReport = () => {
  const dispatch = useDispatch();

  // NOTE: this works without a success handler because it assumes a rename does not change
  // report version, in which case we would normally need to update the entire report
  // object.
  const { isLoading: isTitleRenaming, mutate: renameTitle } =
    useRenameReportMutation({
      onError: (err: any, { reportId, oldReportName }) => {
        logger.error(`Error renaming report with id=${reportId}: `, err);
        dispatch(setActiveReportName(oldReportName));
      },
      onSettled: () => {
        queryClient.invalidateQueries({ queryKey: reportsKeys.all });
      },
    });

  const { isLoading: isTitleGenerating, mutate: generateTitle } =
    useGenerateTitleMutation({
      onSuccess: (res) => {
        // Sanity check, if the LLM fails or doesn't have enough context it will return Untitled Matrix
        if (
          !res?.name ||
          res.name
            .toLowerCase()
            .endsWith(LLM_FALLBACK_REPORT_NAME.toLowerCase())
        ) {
          return;
        }

        dispatch(setActiveReportName(res.name));
      },
      onError: (err: any, { reportId, oldReportName }) => {
        logger.error(
          `Error generating name for report with id=${reportId}: `,
          err
        );
        dispatch(setActiveReportName(oldReportName));
      },
      onSettled: () => {
        queryClient.invalidateQueries({ queryKey: reportsKeys.all });
      },
    });

  const renameReport = (
    reportId?: string,
    oldReportName?: string,
    newReportName?: string
  ) => {
    // Sanity check
    if (!reportId || !oldReportName || !newReportName) {
      logger.error("Cannot rename a null report");
      return;
    }

    // Flag the report as renaming and optimistically update the report name
    dispatch(setActiveReportName(newReportName));

    renameTitle({ reportId, oldReportName, newReportName });
  };

  const generateReportTitle: GenerateReportTitleCallback = (
    reportId?: string,
    oldReportName?: string,
    documentNames?: string[],
    columnNames?: string[]
  ) => {
    if (!reportId || !oldReportName || !documentNames || !columnNames) {
      logger.error("Cannot rename a null report");
      return;
    }

    // Don't overwrite a request to change the report name
    if (isTitleGenerating || isTitleRenaming) {
      return;
    }

    // Don't rename a matrix that doesn't have at least 3 documents and 1 user added column
    if (
      documentNames.length < MIN_AUTOTITLE_DOCUMENTS ||
      columnNames.length < MIN_AUTOTITLE_COLUMNS
    ) {
      logger.error(
        "Cannot rename a matrix with less than 3 documents and 1 column"
      );
      return;
    }

    // Generate a new title for the matrix using up to 10 document and column names
    generateTitle({
      reportId,
      oldReportName,
      params: {
        doc_names: documentNames.slice(0, 10),
        col_names: columnNames.slice(0, 10),
      },
    });
  };

  return { renameReport, generateReportTitle };
};
