import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { DocumentType } from "source/Types";
import { ReduxState } from ".";

export type BuildReduxType = {
  buildingDocs: DocumentType[];
  recentDocs: DocumentType[];
  finishedDocs: number;
  numDocsFailed: number;
  numDocsRemaining: number;
  numDocsCrawling: number;
  numDocsTotal: number;
  etaInMinutes: number;
  lastBuiltAt: Date | null;
  buildFinished: boolean;
};

const initialState: BuildReduxType = {
  buildingDocs: [],
  recentDocs: [],
  finishedDocs: 0,
  numDocsFailed: 0,
  numDocsRemaining: 0,
  numDocsCrawling: 0,
  numDocsTotal: 0,
  etaInMinutes: 0,
  lastBuiltAt: null,
  buildFinished: false,
};

export const getBuildingDocs = (state: ReduxState) => state.build.buildingDocs;
export const getFinishedDocs = (state: ReduxState) => state.build.finishedDocs;
export const getNumDocsFailed = (state: ReduxState) =>
  state.build.numDocsFailed;
export const getNumDocsRemaining = (state: ReduxState) =>
  state.build.numDocsRemaining;
export const getNumDocsCrawling = (state: ReduxState) =>
  state.build.numDocsCrawling;
export const getNumDocsTotal = (state: ReduxState) => state.build.numDocsTotal;
export const getEtaInMinutes = (state: ReduxState) => state.build.etaInMinutes;
export const getLastBuiltAt = (state: ReduxState) => state.build.lastBuiltAt;
export const getBuildFinished = (state: ReduxState) =>
  state.build.buildFinished;

const buildSlice = createSlice({
  name: "build",
  initialState,
  reducers: {
    setBuildingDocs: (state, action: PayloadAction<DocumentType[]>) => {
      state.buildingDocs = action.payload;
      return state;
    },
    setFinishedDocs: (state, action: PayloadAction<number>) => {
      state.finishedDocs = action.payload;
      return state;
    },
    setNumDocsFailed: (state, action: PayloadAction<number>) => {
      state.numDocsFailed = action.payload;
      return state;
    },
    setNumDocsRemaining: (state, action: PayloadAction<number>) => {
      state.numDocsRemaining = action.payload;
      return state;
    },
    setNumDocsCrawling: (state, action: PayloadAction<number>) => {
      state.numDocsCrawling = action.payload;
      return state;
    },
    setNumDocsTotal: (state, action: PayloadAction<number>) => {
      state.numDocsTotal = action.payload;
      return state;
    },
    setEtaInMinutes: (state, action: PayloadAction<number>) => {
      state.etaInMinutes = action.payload;
      return state;
    },
    setLastBuiltAt: (state, action: PayloadAction<Date | null>) => {
      state.lastBuiltAt = action.payload;
      return state;
    },
    setBuildFinished: (state, action: PayloadAction<boolean>) => {
      state.buildFinished = action.payload;
      return state;
    },
  },
});

export const {
  setBuildingDocs,
  setFinishedDocs,
  setNumDocsFailed,
  setNumDocsRemaining,
  setNumDocsCrawling,
  setNumDocsTotal,
  setEtaInMinutes,
  setLastBuiltAt,
  setBuildFinished,
} = buildSlice.actions;

export const buildReducer = buildSlice.reducer;
