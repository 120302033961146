import { classNames } from "core";
import React from "react";
import { MIN_SIDEBAR_WIDTH } from "source/constants";

type Props = {
  depth: number;
  noMaxWidth?: boolean;
  noShine?: boolean;
  height?: number;
  multiple?: boolean;
  styleOverrides?: string;
};

export const LoadingRow = ({
  depth,
  noMaxWidth,
  noShine,
  height,
  multiple,
  styleOverrides,
}: Props) => (
  <div
    className={classNames(
      multiple ? "h-6" : "h-8",
      multiple ? "items-start" : "items-center",
      multiple ? "" : "py-0.5 pr-3",
      `my-0 flex w-full flex-row text-secondary`,
      styleOverrides
    )}
    style={{ paddingLeft: multiple ? 0 : 14 + depth * 8 }}
  >
    <div
      style={{
        height: height ? height : 14,
        width: "100%",
        maxWidth: noMaxWidth ? undefined : MIN_SIDEBAR_WIDTH,
      }}
      className={noShine ? "loading-no-shine" : "loading-shine"}
    />
  </div>
);
