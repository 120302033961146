import axios from "../axios";

export const Dates = {
  getDocDateCounts: (
    repoId: string | undefined,
    datetime: Record<string, string>[]
  ): Promise<number[]> =>
    axios
      .post(`v2/docs/${repoId}/counts`, { datetime })
      .then(({ data }) => data.counts),
};
