import React, {
  ButtonHTMLAttributes,
  DetailedHTMLProps,
  forwardRef,
  ReactNode,
} from "react";
import { classNames } from "core";

export interface IconButtonProps
  extends DetailedHTMLProps<
    ButtonHTMLAttributes<HTMLButtonElement>,
    HTMLButtonElement
  > {
  children: ReactNode;
  size?: "small" | "medium";
  variant?: "primary" | "outlined" | "text";
}

const IconButton = forwardRef<HTMLButtonElement, IconButtonProps>(
  (props, ref) => {
    const {
      children,
      className,
      size = "medium",
      variant = "primary",
      ...rest
    } = props;

    return (
      <button
        {...rest}
        ref={ref}
        className={classNames(
          "flex cursor-pointer items-center justify-center rounded border border-transparent font-medium leading-4 disabled:cursor-default [&:disabled>svg]:opacity-60 [&>svg]:fill-current [&>svg]:text-inherit",
          size === "small" && "h-[24px] w-[24px] [&>svg]:h-4 [&>svg]:w-4",
          size === "medium" && "h-[30px] w-[30px] [&>svg]:h-5 [&>svg]:w-5",
          variant === "primary" &&
            "shadow-xs border-brand-500 bg-brand-500 text-white hover:border-brand-600 hover:bg-brand-600 focus-visible:border-brand-600 active:border-brand-500 active:bg-brand-500 disabled:border-gray-100 disabled:bg-gray-100 disabled:text-gray-400",
          variant === "outlined" &&
            "border-gray-200 bg-white text-gray-600 shadow-sm outline-none hover:bg-gray-100 focus-visible:bg-gray-100 active:bg-opacity-60 disabled:border-opacity-60 disabled:bg-white disabled:text-opacity-60",
          variant === "text" &&
            "text-gray-500 outline-none hover:bg-gray-100 focus-visible:bg-gray-100 active:bg-opacity-60 disabled:bg-transparent disabled:text-opacity-60",
          className
        )}
      >
        {children}
      </button>
    );
  }
);

export default IconButton;
