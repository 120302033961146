import { currentModal } from "./currentModal";
import { ModalTypes } from "./modals.types";

export const useModal = (type: ModalTypes) => ({
  open: (props: any) => {
    currentModal.set({ type, props: props });
  },
  close: () => {
    currentModal.set(null);
  },
  isOpen: currentModal.isOpen(type),
});
