import React, { forwardRef } from "react";
import { classNames } from "core";

export type MaterialSymbolsIconSize =
  | "xs"
  | "sm"
  | "base"
  | "lg"
  | "xl"
  | "2xl";
export type MaterialSymbolsIconVariant = "sharp" | "rounded" | "outlined";

export interface MaterialSymbolsIconProps
  extends React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLSpanElement>,
    HTMLSpanElement
  > {
  icon: string;
  size?: MaterialSymbolsIconSize;
  variant?: MaterialSymbolsIconVariant;
  filled?: boolean;
}

const MaterialSymbolsIcon = forwardRef<
  HTMLButtonElement,
  MaterialSymbolsIconProps
>(
  (
    {
      icon,
      size = "base",
      variant = "sharp",
      className,
      filled,
      color,
      style,
      ...rest
    }: MaterialSymbolsIconProps,
    ref
  ) => {
    return (
      <span
        {...rest}
        ref={ref}
        className={classNames(
          "text-center text-inherit",
          variant === "sharp" && "material-symbols-sharp",
          variant === "rounded" && "material-symbols-rounded",
          variant === "outlined" && "material-symbols-outlined",
          size === "xs" && "max-w-[12px] text-xs",
          size === "sm" && "max-w-[14px] text-sm",
          size === "base" && "max-w-[16px] text-base",
          size === "lg" && "max-w-[18px] text-lg",
          size === "xl" && "max-w-[20px] text-xl",
          size === "2xl" && "max-w-[24px] text-2xl",
          "leading-none", // Override the line height from text-size
          className
        )}
        style={{
          fontVariationSettings: filled
            ? `'FILL' 1, 'wght' 400, 'GRAD' 0, 'opsz' 24`
            : undefined,
          color,
          ...style,
        }}
      >
        {icon}
      </span>
    );
  }
);

export default MaterialSymbolsIcon;
