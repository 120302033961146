export const BACKGROUND_COLORS = [
  "rgba(223, 217, 247, 0.85)",
  "rgba(214, 229, 255, 0.85)",
  "rgba(226, 232, 240, 0.85)",
  "rgba(251, 217, 178, 0.85)",
  "rgba(252, 233, 166, 0.85)",
  "rgba(255, 207, 202, 0.85)",
  "rgba(211, 237, 197, 0.85)",
  "rgba(205, 227, 229, 0.85)",
];

export const TEXT_COLORS = [
  "#51298E",
  "#00479E",
  "#334155",
  "#904304",
  "#6D4B03",
  "#B00707",
  "#256402",
  "#0D4F54",
];

export const COLOR_MAP = {
  color1: {
    background: "rgba(223, 217, 247, 0.85)",
    text: "#51298E",
    name: "Purple",
  },
  color2: {
    background: "rgba(214, 229, 255, 0.85)",
    text: "#00479E",
    name: "Blue",
  },
  color3: {
    background: "rgba(226, 232, 240, 0.85)",
    text: "#334155",
    name: "Grey",
  },
  color4: {
    background: "rgba(251, 217, 178, 0.85)",
    text: "#904304",
    name: "Orange",
  },
  color5: {
    background: "rgba(252, 233, 166, 0.85)",
    text: "#6D4B03",
    name: "Yellow",
  },
  color6: {
    background: "rgba(255, 207, 202, 0.85)",
    text: "#B00707",
    name: "Red",
  },
  color7: {
    background: "rgba(211, 237, 197, 0.85)",
    text: "#256402",
    name: "Green",
  },
  color8: {
    background: "rgba(205, 227, 229, 0.85)",
    text: "#0D4F54",
    name: "Teal",
  },
};

export const CURRENCY_TO_SYMBOL = {
  usd: "$",
  eur: "€",
  gbp: "£",
  cny: "¥",
  inr: "₹",
  try: "₺",
  btc: "₿",
};

export const SCALE_TO_NUMBER = {
  one: 1,
  thousand: 1e3,
  million: 1e6,
  billion: 1e9,
  trillion: 1e12,
};

export const SCALE_TO_SYMBOL = {
  one: "",
  thousand: "K",
  million: "M",
  billion: "B",
  trillion: "T",
};

export const DEFAULT_NUM_DECIMALS = 0;

export const CURRENCY_DROPDOWN_OPTIONS = [
  { id: "usd", name: "USD", symbol: "$", long_name: "United States Dollar" },
  { id: "eur", name: "EUR", symbol: "€", long_name: "Euro" },
  { id: "gbp", name: "GBP", symbol: "£", long_name: "British Pound Sterling" },
  { id: "cad", name: "CAD", symbol: "$", long_name: "Canadian Dollar" },
  { id: "cny", name: "CNY", symbol: "¥", long_name: "Chinese Yuan" },
  { id: "inr", name: "INR", symbol: "₹", long_name: "Indian Rupee" },
  { id: "hkd", name: "HKD", symbol: "$", long_name: "Hong Kong Dollar" },
  { id: "chf", name: "CHF", symbol: "CHF", long_name: "Swiss Franc" },
  { id: "nzd", name: "NZD", symbol: "$", long_name: "New Zealand Dollar" },
  { id: "brl", name: "BRL", symbol: "R$", long_name: "Brazilian Real" },
  { id: "mxn", name: "MXN", symbol: "$", long_name: "Mexican Peso" },
  { id: "jpy", name: "JPY", symbol: "¥", long_name: "Japanese Yen" },
  { id: "krw", name: "KRW", symbol: "₩", long_name: "South Korean Won" },
  { id: "aud", name: "AUD", symbol: "$", long_name: "Australian Dollar" },
  { id: "sar", name: "SAR", symbol: "﷼", long_name: "Saudi Riyal" },
  {
    id: "aed",
    name: "AED",
    symbol: "د.إ",
    long_name: "United Arab Emirates Dirham",
  },
  { id: "try", name: "TRY", symbol: "₺", long_name: "Turkish Lira" },
  { id: "btc", name: "BTC", symbol: "₿", long_name: "Bitcoin" },
];

export const CURRENCY_FORMAT_DROPDOWN_OPTIONS = [
  { id: "number", name: "Currency (default)", example: "$1,000.12" },
  { id: "thousands", name: "Currency (thousands)", example: "$100,000 K" },
  { id: "millions", name: "Currency (millions)", example: "$1,000 M" },
  { id: "billions", name: "Currency (billions)", example: "$1 B" },
];

export const NUMBER_FORMAT_DROPDOWN_OPTIONS = [
  { id: "number", name: "Number (default)", example: "1,000.12" },
  { id: "thousands", name: "Number (thousands)", example: "100,000 K" },
  { id: "millions", name: "Number (millions)", example: "1,000 M" },
  { id: "billions", name: "Number (billions)", example: "1 B" },
  { id: "multiple", name: "Multiple", example: "1.2×" },
];

export const DATE_FORMAT_DROPDOWN_OPTIONS = [
  { id: "MM/DD/YYYY", name: "MM/DD/YYYY", example: "01/30/2024" },
  { id: "DD/MM/YYYY", name: "DD/MM/YYYY", example: "30/01/2024" },
  { id: "YYYY/MM/DD", name: "YYYY/MM/DD", example: "2024/01/30" },
  { id: "Month DD, YYYY", name: "Month DD, YYYY", example: "January 30, 2024" },
  { id: "YYYY", name: "YYYY", example: "2024" },
];

export const NEW_CURRENCY_FORMAT_DROPDOWN_OPTIONS = [
  { id: "number", name: "Currency (default)", example: 1000000.12 },
  { id: "thousands", name: "Currency (thousands)", example: 1005000 },
  { id: "millions", name: "Currency (millions)", example: 10230000 },
  { id: "billions", name: "Currency (billions)", example: 1000000000 },
];

export const NEW_NUMBER_FORMAT_DROPDOWN_OPTIONS = [
  { id: "number", name: "Number (default)", example: 1000000.12 },
  { id: "thousands", name: "Number (thousands)", example: 1005000 },
  { id: "millions", name: "Number (millions)", example: 10230000 },
  { id: "billions", name: "Number (billions)", example: 1000000000 },
  { id: "multiple", name: "Multiple", example: 1.123456 },
];

export const EMOJI_UNIFIED_CODES = [
  "1f60a",
  "1f60e",
  "1f60f",
  "1f601",
  "1f602",
  "1f609",
  "1f618",
  "1f44d",
  "1f44e",
  "1f44c",
  "1f60c",
  "1f642",
  "1f64f",
];

export const PERSONAL_PERMISSION_CHECK_INTEGRATIONS = ["sharepoint", "box"];
