import { OrgType, RepoType } from "source/Types";

export const getTargetReposMap = (repoIds: string[]) => new Set(repoIds);

/** Gets orgs repos */
export const getOrgRepos = (repos: RepoType[], currentOrgId?: string) =>
  repos.filter(
    (repo) =>
      repo.organization_id === currentOrgId && !repo.tags?.includes("hidden")
  );

/** Gets personal repos */
export const getPersonalRepos = (repos: RepoType[]) =>
  repos.filter(
    (repo) =>
      !repo.organization_id &&
      repo.private &&
      repo.role === "owner" &&
      !repo.tags?.includes("hidden")
  );

/** Gets shared repos */
export const getSharedRepos = (repos: RepoType[], orgs: OrgType[]) =>
  repos.filter(
    (repo) =>
      repo.private &&
      repo.role !== "owner" &&
      !repo.tags?.includes("hidden") &&
      // user isn't an owner and this repo isn't in another org that this user is in
      (repo?.organization_id
        ? !orgs.map((o) => o.id).includes(repo.organization_id)
        : true)
  );
