import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";
import _ from "lodash";
import { ReduxState } from ".";
import {
  ReduxTab,
  Report,
  ReportTab,
  ReportStatus,
  ViewConfig,
  SheetColumnMetadata,
  CellOverflowMethod,
} from "source/components/matrix/types/reports.types";
import { DEFAULT_TOOL_PARAM_OUTPUT_TYPE } from "source/constants";
import logger from "source/utils/logger";
import {
  CellContent,
  ReportCellType,
  HydratedReportCellType,
  ReportTableRow,
} from "source/components/matrix/types/cells.types";
import {
  getHydratedCellArrayForTab,
  isColumnLoading,
  splitCellWithResult,
  transformHydratedCellToReportCell,
} from "source/utils/matrix/cells";
import { MatrixHomePanelType } from "source/components/matrix/home/types";
import { DEFAULT_REPORT_NAME_REGEX } from "source/hooks/matrix/useRenameReport";
import { DEFAULT_MARKDOWN_RENDERED } from "source/components/matrix/tables/config";
import { MatrixGridDataType } from "source/components/matrix/types/grid.types";
import {
  generateStaticColumnIdDataMapping,
  getDocIdsFromTools,
  getRetrieveColumnFromToolMap,
  getRetrieveColumnIdFromToolMap,
  isAnswerToolType,
  transformReportToolDependencyToReduxTool,
} from "source/utils/matrix/tools";
import { Placement } from "@floating-ui/react";
import {
  ColumnConfig,
  ReduxTool,
  ReportPrompt,
  ReportToolDependency,
  ReportToolParamType,
  ReportToolType,
} from "source/components/matrix/types/tools.types";
import { updateCellsAfterDelete } from "source/utils/matrix/deleting";
import { v4 as uuidv4 } from "uuid";
import { getIsMatrixChatLoading } from "./matrixChat";
import { ModelType } from "source/constants/llms";
import { getDefaultModel } from "source/utils/matrix/models";

export const INITIAL_NUM_COLS = 2;

export type CellType = { static_column_id: string; y: number };

export type CellIdToResultMapType = {
  [id: string]: CellContent;
};

export type ColumnIdYToCellMapType = {
  [tabId: string]: { [columnId: string]: { [y: string]: ReportCellType } };
};

export type ColumnIdToToolMapType = { [columnId: string]: ReduxTool };
export type ColumnIdToPromptMapType = { [columnId: string]: string };
export type ColumnIdToColumnHeaderMapType = { [columnId: string]: string };
export type ColumnIdToToolParamMapType = {
  [columnId: string]: ReportToolParamType;
};

export type ColumnEditorState = {
  columnId: string;
  referenceElement: string | null;
  isExistingColumn: boolean;
  isAISuggestion?: boolean;
  columnOverrides?: ColumnConfig;
  placement?: Placement;
  animate?: boolean;
  outputTypeIsHighlighted?: boolean; // highlight output type for CTA
};

export type PromptGeneratorState = {
  referenceElement: string | null;
};

export type SelectOptionPayload = {
  promptKey: string;
  value: string[] | null;
};

export type AddMatrixUserFailuresPayload = {
  doc_id: string;
  title: string;
  integration?: string;
  failure_reason?: string;
};

export type AddMatrixUserPayload = {
  success: boolean;
  global_failure_reason: string | null;
  failures?: AddMatrixUserFailuresPayload[];
};

export type AddDocsFailedPermissionsUserMap = {
  [userId: string]: AddMatrixUserFailuresPayload[];
};

export type ReportReduxType = {
  activeReport: Report | null;

  tabs: { byId: { [tabId: string]: ReduxTab }; allIds: string[] }; // allIDs stores tab order

  tools: { [tabId: string]: ColumnIdToToolMapType };

  cells: ColumnIdYToCellMapType;

  cellResults: CellIdToResultMapType;

  // used for report version navigation
  previewReport: Report | null;

  bulkRunCompletedVersion: string;
  isBulkRunning: boolean;

  // Represents how much the header should be set to if it should be overriden for a given row group
  rowGroupHeaderHeights: {
    [groupKey: string]: number | undefined;
  };
  // This is used to tell viewers to refresh their page
  // if the report has been updated
  isReportOutOfDateForViewer: boolean;
  // This is a UX hack to display an updated timestamp when the user clicks 'save matrix' from the file menu.
  // TODO: Delete this when FileMenu save operation is updated
  reportUpdatedAtOverride: string;

  // Used for controlling loading progress of Matrix operation
  reportLoadingProgress: {
    numTotalChunks: number;
    numCompletedChunks: number;
    queueLength: number;
  };
  formattedCellContent: ReportTableRow[];

  matrixHomePanel: MatrixHomePanelType;

  // A status to indicate if the report has been fetched, is in error, etc
  reportStatus: ReportStatus;
  initialRowMount: boolean;

  // Tracks view config state for viewers
  localViewConfig: ViewConfig | null;
  isMarkdownRendered: boolean;

  leaseId: string | null;

  nextRowsToRun: number[];

  columnEditor: ColumnEditorState & {
    open: boolean;
  };

  promptGenerator: PromptGeneratorState & {
    open: boolean;
  };

  selectOptions: {
    [promptKey: string]: string[] | null;
  };

  numSelectedRows: number | null;

  // This is pretty silly but it's used to determine if
  // the cell overflow method is set to "truncate" or "wrap" on load
  // but only if the cell overflow method is not set in the view config
  defaultCellOverflowMethod: CellOverflowMethod | null;

  // Used to track the status doc access when sharing a matrix
  addMatrixUserStatus: AddMatrixUserPayload | null;

  // Used to track the status doc access when sharing a matrix
  addDocsFailedPermissionsUserMap: AddDocsFailedPermissionsUserMap | null;
};

export const DEFAULT_COLUMN_EDITOR_STATE = {
  open: false,
  isReadOnly: false,
  columnId: uuidv4(),
  referenceElement: null,
  isExistingColumn: false,
};

const initialState: ReportReduxType = {
  activeReport: null,
  tabs: { byId: {}, allIds: [] },
  tools: {},
  cells: {},
  cellResults: {},
  columnEditor: DEFAULT_COLUMN_EDITOR_STATE,
  promptGenerator: {
    open: false,
    referenceElement: null,
  },
  selectOptions: {},
  previewReport: null,
  bulkRunCompletedVersion: "",
  isBulkRunning: false,
  rowGroupHeaderHeights: {},
  isReportOutOfDateForViewer: false,
  reportUpdatedAtOverride: new Date().toISOString(),
  reportLoadingProgress: {
    numTotalChunks: 0,
    numCompletedChunks: 0,
    queueLength: 0,
  },
  formattedCellContent: [],
  matrixHomePanel: MatrixHomePanelType.HOME,
  reportStatus: "fetching",
  initialRowMount: false,
  localViewConfig: null,
  leaseId: null,
  isMarkdownRendered: DEFAULT_MARKDOWN_RENDERED,
  nextRowsToRun: [],
  numSelectedRows: null,
  defaultCellOverflowMethod: null,
  addMatrixUserStatus: null,
  addDocsFailedPermissionsUserMap: null,
};

export const getReportStatus = (state: ReduxState) =>
  state.reports.reportStatus;
export const getReportTabs = (state: ReduxState) => state.reports.tabs;
export const getReportTabsById = (state: ReduxState) => state.reports.tabs.byId;
export const getReportTabsAllIds = (state: ReduxState) =>
  state.reports.tabs.allIds;

export const getReportCells = (state: ReduxState) => state.reports.cells;
export const getReportCellResults = (state: ReduxState) =>
  state.reports.cellResults;
export const getBulkRunCompletedVersion = (state: ReduxState) =>
  state.reports.bulkRunCompletedVersion;
export const getIsBulkRunning = (state: ReduxState) =>
  state.reports.isBulkRunning;
export const getRowGroupHeaderHeights = (state: ReduxState) =>
  state.reports.rowGroupHeaderHeights;
export const getMatrixHomePanel = (state: ReduxState) =>
  state.reports.matrixHomePanel;
export const getReportLoadingProgress = (state: ReduxState) =>
  state.reports.reportLoadingProgress;
// Report object and cell properties
export const getActiveReport = (state: ReduxState) =>
  state.reports.activeReport;
export const getActiveReportId = (state: ReduxState) =>
  state.reports.activeReport?.id;
export const getActiveReportVersionId = (state: ReduxState) =>
  state.reports.activeReport?.version_id;
export const getActiveReportName = (state: ReduxState) =>
  state.reports.activeReport?.name;
export const getDisplayReport = (state: ReduxState) =>
  state.reports.previewReport ?? state.reports.activeReport;
export const getDisplayReportName = createSelector(
  getDisplayReport,
  (report) => report?.name
);
export const getIsReportNameDefault = createSelector(
  getDisplayReport,
  (report) => DEFAULT_REPORT_NAME_REGEX.test(report?.name || "")
);
export const getDisplayReportIsGiga = createSelector(
  getDisplayReport,
  (report) => report?.is_giga
);
export const getDisplayReportUpdatedAt = createSelector(
  getDisplayReport,
  (report) => report?.updated_at
);
export const getSocketLeaseId = (state: ReduxState) => state.reports.leaseId;
export const getActiveTabId = (state: ReduxState) =>
  state.reports.activeReport?.active_tab_id;
export const getReportTools = (state: ReduxState) => state.reports.tools;
export const getisReportOutOfDateForViewer = (state: ReduxState) =>
  state.reports.isReportOutOfDateForViewer;
export const getReportUpdatedAtOverride = (state: ReduxState) =>
  state.reports.reportUpdatedAtOverride;
export const getFormattedCellContent = (state: ReduxState) =>
  state.reports.formattedCellContent;
export const getInitialRowMount = (state: ReduxState) =>
  state.reports.initialRowMount;
export const getLocalViewConfig = (state: ReduxState) =>
  state.reports.localViewConfig;
export const getIsMarkdownRendered = (state: ReduxState) =>
  state.reports.isMarkdownRendered;
export const getNextRowsToRun = (state: ReduxState) =>
  state.reports.nextRowsToRun;
export const getColumnEditorState = (state: ReduxState) =>
  state.reports.columnEditor;
export const getPromptGeneratorState = (state: ReduxState) =>
  state.reports.promptGenerator;
export const getSelectOptions = (state: ReduxState) =>
  state.reports.selectOptions;
export const getNumSelectedRows = (state: ReduxState) =>
  state.reports.numSelectedRows;
export const getDefaultCellOverflowMethod = (state: ReduxState) =>
  state.reports.defaultCellOverflowMethod;
export const getAddMatrixUserStatus = (state: ReduxState) =>
  state.reports.addMatrixUserStatus;
export const getAddDocsFailedPermissionsUserMap = (state: ReduxState) =>
  state.reports.addDocsFailedPermissionsUserMap;

export const getFlattenedAddDocsFailures = createSelector(
  getAddDocsFailedPermissionsUserMap,
  (userMap): AddMatrixUserFailuresPayload[] => {
    return userMap && !_.isEmpty(userMap)
      ? _.flatten(Object.values(userMap))
      : [];
  }
);

/**
 * Gets the active tab object
 * @returns {Object} - ReduxTab
 */
export const getDisplayReportTab = createSelector(
  [getReportTabs, getActiveTabId],
  (tabs, tabId): ReduxTab | undefined => {
    // If no active tab ID try returning first tab
    if (!tabId) return Object.values(tabs.byId)[0];
    return tabs.byId[tabId];
  }
);

export const getDisplayReportTabById = (tabId?: string) =>
  createSelector(getReportTabs, (tabs) =>
    tabId ? tabs.byId[tabId] : undefined
  );

/**
 * Gets the cells for the active tab
 * @returns {Object} - { [x: number]: { [y: number]: ReportCellType } }
 */
export const getDisplayReportCells = createSelector(
  [getReportCells, getActiveTabId],
  (cellMap, tabId) => {
    if (!tabId || !(tabId in cellMap)) return {};
    return cellMap[tabId];
  }
);

/**
 * Gets the cells for the active tab in a flat array
 * @returns {Array} - ReportCellType[]
 */
export const getDisplayReportCellsFlatArray = createSelector(
  [getReportCells, getActiveTabId],
  (cellMap, tabId) => {
    if (!tabId || !(tabId in cellMap)) return;
    const colCells = Object.values(cellMap?.[tabId] ?? {});
    return colCells.reduce((acc: ReportCellType[], colMap) => {
      acc.push(...Object.values(colMap));
      return acc;
    }, []);
  }
);

/**
 * Returns a bool if the active tab has cells
 * @returns {boolean}
 */
export const getDisplayReportHasCells = createSelector(
  getDisplayReportCells,
  (cells) => Object.keys(cells ?? []).length > 0
);

/**
 * Gets the filters object for the active tab
 * @returns {Object} - BackendReportFilter
 */
export const getDisplayReportFilters = createSelector(
  getDisplayReportTab,
  (tab) => tab?.filters
);

export const getReportHydratedCellArrayForActiveTab = createSelector(
  [
    getActiveTabId,
    getReportCells,
    getReportCellResults,
    getFormattedCellContent,
  ],
  (tabId, cells, cellResults, formattedCellContent) =>
    tabId
      ? getHydratedCellArrayForTab(
          tabId,
          cells,
          cellResults,
          formattedCellContent
        )
      : []
);

/** Returns true if any report operation is currently being executed */
export const getReportIsLoading = createSelector(
  [getReportHydratedCellArrayForActiveTab],
  (hydratedCellArray) => hydratedCellArray.some((cell) => cell?.loading)
);

// The following mappings are helpful to get a mapping of the specific
// piece of data we want to access, but all of them could be accessed directly
// from the report tools:

/** Get the tools for the current tab
 * @returns {Object} - { [x: number]: ReduxTool }: XToToolMapType
 */
export const getDisplayReportToolMap = createSelector(
  [getReportTools, getActiveTabId],
  (toolMap, tabId) => {
    if (!tabId) return;
    return toolMap[tabId] ?? {};
  }
);

export const getToolOutputTypeForColumn = (columnId: string) =>
  createSelector(
    [getDisplayReportToolMap],
    (toolMap) => toolMap?.[columnId]?.tool_params?.output_type
  );

/** Get the list find columns in the active report tab
 * @returns {number}
 */
export const getDisplayReportFindColumns = createSelector(
  [getDisplayReportToolMap],
  (toolMap): ReduxTool[] =>
    Object.values(toolMap ?? {}).filter(({ tool }) => isAnswerToolType(tool))
);

/** Get the names of the find columns in the active report tabs
 * @returns {string[]}
 */
export const getFindToolColumnNames = createSelector(
  [getDisplayReportToolMap],
  (toolMap): string[] =>
    Object.values(toolMap ?? {})
      .filter(({ tool, name }) => isAnswerToolType(tool, false) && !!name)
      .map(({ name }) => name) as string[]
);

/**
 * Get a mapping of column number to prompt
 * @returns {Object} - { [x: number]: string }: XToPromptMapType
 */
export const getDisplayReportPromptMap = createSelector(
  getDisplayReportToolMap,
  (toolMap): ColumnIdToPromptMapType => {
    if (!toolMap) return {} as ColumnIdToPromptMapType;
    return Object.entries(toolMap).reduce((acc, [x, tool]) => {
      if (tool.prompt) acc[x] = tool.prompt;
      return acc;
    }, {} as ColumnIdToPromptMapType);
  }
);

/**
 * Get a mapping of column number to column header
 * @returns {Object} - { [x: number]: string }: XToColumnHeaderMapType
 */
export const getDisplayReportColumnHeaders = createSelector(
  getDisplayReportToolMap,
  (toolMap) => {
    if (!toolMap) return {};
    return Object.entries(toolMap).reduce((acc, [x, tool]) => {
      if (tool.name) acc[x] = tool.name;
      return acc;
    }, {} as ColumnIdToColumnHeaderMapType);
  }
);

/**
 * Get a mapping of column number to column tool params
 * @returns {Object} - { [x: number]: ReportToolParamType }: XToToolParamMapType
 */
export const getDisplayReportColumnToolParams = createSelector(
  getDisplayReportToolMap,
  (toolMap) => {
    const defaultModel = getDefaultModel();
    if (!toolMap) return {};
    const defaultParams: ReportToolParamType = {
      output_type: DEFAULT_TOOL_PARAM_OUTPUT_TYPE,
      model: defaultModel,
    };
    return Object.entries(toolMap).reduce((acc, [columnId, tool]) => {
      if (tool.name) acc[columnId] = tool.tool_params ?? defaultParams;
      return acc;
    }, {} as ColumnIdToToolParamMapType);
  }
);

/**
 * Get matching report tool by column name. Case-insensitive.
 */
export const getReportToolByColumnName = (columnName: string) =>
  createSelector(getDisplayReportToolMap, (toolMap) => {
    if (toolMap) {
      return Object.values(toolMap).find(
        (tool) => tool.name?.toLowerCase() == columnName.toLowerCase()
      );
    }
  });

export const getSavedPromptForTabAndColumn = (columnId: string) =>
  createSelector(getDisplayReportPromptMap, (reportPromptMap) => {
    if (reportPromptMap) {
      return {
        prompt: reportPromptMap[columnId] ?? "",
        isExistingPrompt: columnId in reportPromptMap,
      };
    }
    return { prompt: "", isExistingPrompt: false };
  });

export const getDisplayReportCellMap = createSelector(
  [getReportCells, getActiveTabId],
  (cells, tabId) => (tabId && tabId in cells ? cells[tabId] : {})
);

export const getReportHydratedCellArrayForTab = (tabId: string) =>
  createSelector(
    [getReportCells, getReportCellResults, getFormattedCellContent],
    (cells, cellResults, formattedCellContent) =>
      getHydratedCellArrayForTab(
        tabId,
        cells,
        cellResults,
        formattedCellContent
      )
  );

export const getReportColumnToolParamsForColSelector = (
  columnId: string,
  defaultModel: ModelType
) =>
  createSelector([getReportTools, getActiveTabId], (tools, tabId) => {
    const defaultParams: ReportToolParamType = {
      output_type: DEFAULT_TOOL_PARAM_OUTPUT_TYPE,
      model: defaultModel,
    };
    if (!tabId || !(tabId in tools)) return defaultParams;

    if (tools?.[tabId]?.[columnId]?.tool_params) {
      return tools?.[tabId]?.[columnId]?.tool_params;
    }
    return defaultParams;
  });

export const getAreDocCellsLoading = (tabId?: string) =>
  createSelector(
    [getReportCells, getReportCellResults, getRetrieveColumnId],
    (cells, cellResults, retrieveColumnId) => {
      if (!tabId) return false;
      return isColumnLoading(tabId, cells, cellResults, retrieveColumnId);
    }
  );

export const getCellOverflowMethod = createSelector(
  [getLocalViewConfig, getDisplayReportTab],
  (localViewConfig, tab) => {
    return (
      localViewConfig?.cell_overflow_method ??
      tab?.cell_overflow_method ??
      undefined
    );
  }
);

export const getIsGroupByEnabled = createSelector(
  [getLocalViewConfig],
  (localViewConfig) => {
    return (
      localViewConfig?.grid_configuration?.some((column) => column.rowGroup) ??
      false
    );
  }
);

export const getGridConfiguration = createSelector(
  [getLocalViewConfig, getDisplayReportTab],
  (localViewConfig, tab) => {
    return localViewConfig?.grid_configuration ?? tab?.grid_configuration;
  }
);

export const getColumnViewConfiguration = (columnId: string) =>
  createSelector(getLocalViewConfig, (localViewConfig) => {
    return localViewConfig?.column_view_configuration?.[columnId];
  });

/**
 * Takes in ReportTab.
 * Generates map from columns to ReduxTool types
 */
export const generateTabToolMap = (tab: ReportTab): ColumnIdToToolMapType => {
  const colHeaderMap = generateStaticColumnIdDataMapping<
    SheetColumnMetadata,
    string
  >(tab.column_metadata ?? [], "name");

  const colPromptMap = generateStaticColumnIdDataMapping<ReportPrompt, string>(
    tab.prompts ?? [],
    "prompt"
  );

  const colToolDepsMap = generateStaticColumnIdDataMapping<
    ReportToolDependency,
    ReportToolDependency
  >(tab.tools ?? []);

  const columnIds = _.uniq(
    Object.keys(colHeaderMap)
      .concat(Object.keys(colPromptMap))
      .concat(Object.keys(colToolDepsMap))
  );

  const toolMap = columnIds.reduce(
    (toolMap: ColumnIdToToolMapType, columnId) => {
      const toolDep = colToolDepsMap[columnId];

      if (toolDep)
        toolMap[columnId] = transformReportToolDependencyToReduxTool(toolDep, {
          name: colHeaderMap[columnId],
          prompt: colPromptMap[columnId],
        });
      return toolMap;
    },
    {}
  );

  return toolMap;
};

export const getReportRetrieveTool = createSelector(
  [getReportTools, getActiveTabId],
  (tools, tabId) => {
    if (!tabId || !(tabId in tools)) return;
    // find tool where tool.tool === "retrieve"
    return getRetrieveColumnFromToolMap(tools[tabId] ?? {});
  }
);

const addCells = (
  state: ReportReduxType,
  tabId: string,
  newCellContents: CellContent[],
  newCells?: ReportCellType[]
) => {
  // Generate the new cell mappings by id and append them to the current
  // cells in redux. If two cells somehow have the same ID, the new one
  // will overwrite the existing one in the redux map
  // Only update matrix cells if group synthesis isn't running
  const newCellIdToResultMap = newCellContents.reduce((map, cell) => {
    map[cell.id] = cell;
    return map;
  }, {} as CellIdToResultMapType);

  state.cellResults = {
    ...state.cellResults,
    ...newCellIdToResultMap,
  };
  // Generate the new cell XY to ID mappings. Overwrite the existing mapping
  // in redux.
  // TODO: if we want to manage redux state a bit more, we can optimize by
  // going thru all the cellIds in redux and deleting those that don't appear
  // in the list of report cells.
  if (!(tabId in state.cells)) state.cells[tabId] = {};
  const tabCells = state.cells?.[tabId];

  if (tabCells) {
    newCells?.forEach((cell) => {
      const cellContent = newCellIdToResultMap[cell.id];
      const columnId = cellContent?.static_column_id;
      if (columnId) {
        if (!(columnId in tabCells)) tabCells[columnId] = {};
        const tabColumn = tabCells[columnId];
        if (tabColumn)
          tabColumn[cell.y] = { ...cell, static_column_id: columnId };
      }
    });
  }
};

/**
 * Generate placeholder loading cells when we do the following:
 * - Add documents
 * - Run more rows
 * - Re-run a row
 */
export const setTemporaryLoadingState = ({
  state,
  requestId,
  tabId,
  cells,
  dropCells,
  tool,
}: {
  state: ReportReduxType;
  requestId: string;
  tabId: string;
  cells: CellType[];
  dropCells?: boolean;
  tool: ReportToolType;
}) => {
  // Different cellId for retrieve vs find. Initially adding documents we get same request id but different tools.
  // Always either retrieve/find, never both
  const tempCellId = `${requestId}-${tool}-loading`;

  const columnIds = new Set(
    cells.map(({ static_column_id }) => static_column_id)
  );

  Array.from(columnIds).forEach((static_column_id) => {
    state.cellResults[tempCellId] = {
      id: tempCellId,
      loading: true,
      tool,
      static_column_id,
    };
  });

  // If dropping cells, clear out the existing cells for the given columns, and
  // drop any ongoing operations.
  if (dropCells) {
    Object.entries(state.cells[tabId] ?? {}).forEach(([columnId, colMap]) => {
      // If col is being dropped
      if (columnIds.has(columnId)) {
        // Delete cells from result map
        Object.values(colMap).forEach((cell) => {
          if (cell.id in state.cellResults) delete state.cellResults[cell.id];
        });
        // Delete cells from cel lmap
        delete state.cells?.[tabId]?.[columnId];
      }
    });
  }

  cells.forEach(({ static_column_id, y }) => {
    if (!(tabId in state.cells)) state.cells[tabId] = {};
    const tabCells = state.cells[tabId];
    if (tabCells && !(static_column_id in tabCells))
      tabCells[static_column_id] = {};
    const tabColumn = tabCells?.[static_column_id];
    if (tabColumn) {
      tabColumn[y] = {
        id: tempCellId,
        tool,
        y,
        disabled: false,
        static_column_id,
      };
    }
  });
};

export const getIsCellLoading = (cellId: string) => (state: ReduxState) => {
  return state.reports.cellResults[cellId]?.loading ?? false;
};

export const getNumberOfLoadingCells = (state: ReduxState) =>
  Object.keys(state.reports.cells).reduce((outerSum, tabId) => {
    const numTabLoadingCells = getReportHydratedCellArrayForTab(tabId)(
      state
    ).filter((cell) => isAnswerToolType(cell.tool) && cell.loading).length;

    return outerSum + numTabLoadingCells;
  }, 0);

export const getNumberOfLoadingCellsForTab =
  (tabId?: string) => (state: ReduxState) => {
    if (!tabId) return 0;

    return getReportHydratedCellArrayForTab(tabId)(state).filter(
      (cell) => isAnswerToolType(cell.tool) && cell.loading
    ).length;
  };

export const getDisplayReportDocIds = createSelector(
  [getDisplayReportToolMap],
  (tools) => getDocIdsFromTools(Object.values(tools ?? {}))
);

export const getRetrieveColumnId = createSelector(
  [getDisplayReportToolMap],
  (tools) => getRetrieveColumnIdFromToolMap(tools)
);

export const disableReportButtons = createSelector(
  [getIsBulkRunning, getIsMatrixChatLoading],
  (isBulkRunning, isMatrixChatLoading) => isBulkRunning || isMatrixChatLoading
);

const reportSlice = createSlice({
  name: "report",
  initialState,
  reducers: {
    resetGlobalReportState: (state: ReportReduxType) => {
      state = initialState;
      return state;
    },
    setReportStatus: (
      state: ReportReduxType,
      action: PayloadAction<ReportStatus>
    ) => {
      state.reportStatus = action.payload;
      return state;
    },
    shiftCells: (
      state: ReportReduxType,
      action: PayloadAction<{
        tabId: string;
        columnsToDelete: string[];
      }>
    ) => {
      const { tabId, columnsToDelete } = action.payload;
      if (!(tabId in state.cells)) return state;
      const tabCells = _.cloneDeep(state.cells[tabId]) ?? {};

      // Set new mapping as new cells for tab
      state.cells[tabId] = updateCellsAfterDelete(tabCells, columnsToDelete);

      return state;
    },
    updateReportVersion: (
      state: ReportReduxType,
      action: PayloadAction<{ createdAt: string; versionId: string }>
    ) => {
      if (state.activeReport) {
        state.activeReport.created_at = action.payload.createdAt;
        state.activeReport.version_id = action.payload.versionId;
      }
      return state;
    },
    setActiveReportName: (
      state: ReportReduxType,
      action: PayloadAction<string>
    ) => {
      if (!state.activeReport) return;
      state.activeReport.name = action.payload;
      return state;
    },
    setActiveReport: (
      state: ReportReduxType,
      action: PayloadAction<Report>
    ) => {
      state.activeReport = action.payload;
      const toolMap = {} as {
        [tabId: string]: ColumnIdToToolMapType;
      };
      action.payload.tabs.forEach((tab) => {
        toolMap[tab.tab_id] = generateTabToolMap(tab);
      });
      state.tools = toolMap;
      return state;
    },
    setPreviewReport: (
      state: ReportReduxType,
      action: PayloadAction<{ report: Report; cells: CellContent[] } | null>
    ) => {
      state.previewReport = action.payload?.report ?? null;
      return state;
    },
    setReportTools: (
      state: ReportReduxType,
      action: PayloadAction<{ tabId: string; tools: ColumnIdToToolMapType }>
    ) => {
      const { tabId, tools } = action.payload;
      if (!(tabId in state.tools)) state.tools[tabId] = {};
      state.tools[action.payload.tabId] = tools;
      return state;
    },
    addReportCellsResponse: (
      state: ReportReduxType,
      action: PayloadAction<{
        requestId?: string;
        tabId: string;
        cells: HydratedReportCellType[];
      }>
    ) => {
      const { requestId, tabId, cells } = action.payload;

      const cellContentsToAdd: CellContent[] = [];
      const cellsToAdd: ReportCellType[] = [];

      cells.forEach((cell) => {
        // Don't need to do requestId validation. We handled that in loading_cells and cached_cells
        const { cellContent, reportCell } = splitCellWithResult(cell);

        // Add cell result if there is one
        cellContentsToAdd.push(cellContent);

        // Add cell to id to cell map
        cellsToAdd.push(reportCell);
      });
      addCells(state, tabId, cellContentsToAdd, cellsToAdd);
    },
    addReportTab: (state: ReportReduxType, action: PayloadAction<string>) => {
      if (state.tabs.allIds.some((tabId) => tabId === action.payload)) {
        logger.error(
          "Attempted to add a tabId that already exists in the report",
          { tabId: action.payload }
        );
        console.error(
          "Attempted to add a tabId that already exists in the report",
          { tabId: action.payload }
        );
        return;
      }
      state.tabs.allIds.push(action.payload);
      state.tabs.byId[action.payload] = {
        tab_id: action.payload,
      };
      return state;
    },
    duplicateReportTab: (
      state: ReportReduxType,
      action: PayloadAction<{
        oldTabId: string;
        newTab: ReduxTab;
      }>
    ) => {
      const { oldTabId, newTab } = action.payload;
      const tools = state.tools;
      const tool = state.tools[oldTabId];
      if (tool) {
        // Copy over tool state
        tools[newTab.tab_id] = tool;
        // Add new tab
        state.tabs.allIds.push(newTab.tab_id);
        state.tabs.byId[newTab.tab_id] = newTab;
      }

      return state;
    },
    /** Overwrites the existing report, but updates cells (adding the new ones, only
     * overwriting the ones that conflict with the old). */
    addReportResponse: (
      state: ReportReduxType,
      action: PayloadAction<{
        tabId: string;
        report: Report;
        cellContents: CellContent[];
      }>
    ) => {
      const { tabId, report, cellContents } = action.payload;

      // Happens if initial report load
      state.activeReport = report;
      const tab = report.tabs.find((tab) => tab.tab_id === tabId);
      if (tab) {
        state.tools[tab.tab_id] = generateTabToolMap(tab);
        // Add tab
        state.tabs.byId[tabId] = { ...tab };
        if (!state.tabs.allIds.includes(tabId)) state.tabs.allIds.push(tabId);
      }
      addCells(state, tabId, cellContents, tab?.cells);

      return state;
    },
    /** Updates cells (adding the new ones, only overwriting the ones that conflict with the old). */
    updateReportCells: (
      state: ReportReduxType,
      action: PayloadAction<{
        tabId: string;
        cells: CellContent[];
      }>
    ) => {
      const { tabId, cells } = action.payload;
      addCells(state, tabId, cells);
      return state;
    },
    setActiveTableRequest: (
      state: ReportReduxType,
      action: PayloadAction<{
        tabId: string;
        requestId: string;
        columns: string[];
        rows?: number[];
        tool: ReportToolType;
      }>
    ) => {
      const { tabId, requestId, columns, rows, tool } = action.payload;

      setTemporaryLoadingState({
        state,
        tabId,
        requestId,
        cells: columns
          .map((col) =>
            (rows ?? []).map((row) => ({
              y: row,
              static_column_id: col,
            }))
          )
          .flat(),
        tool,
      });

      return state;
    },
    setActiveColCellRequest: (
      state: ReportReduxType,
      action: PayloadAction<{
        requestId: string;
        tabId: string;
        cells: CellType[];
        dropCells?: boolean;
        colsToDelete?: number[];
        tool: ReportToolType;
      }>
    ) => {
      const { requestId, tabId, cells, dropCells, colsToDelete, tool } =
        action.payload;

      setTemporaryLoadingState({
        state,
        requestId,
        tabId,
        cells,
        dropCells,
        tool,
      });

      return state;
    },
    setBulkRunCompletedVersion: (
      state: ReportReduxType,
      action: PayloadAction<string>
    ) => {
      state.bulkRunCompletedVersion = action.payload;
      return state;
    },
    replaceTemporaryLoadingCells: (
      state: ReportReduxType,
      action: PayloadAction<{
        tabId: string;
        requestId: string;
        cells: HydratedReportCellType[];
      }>
    ) => {
      // Used to remove optimistic loading cells and refresh with accurate ones from WS message
      const { tabId, requestId, cells } = action.payload;
      if (!cells.length) return;

      const cellContentsToAdd: CellContent[] = [];
      const cellsToAdd: ReportCellType[] = [];

      cells.forEach((cell) => {
        const { cellContent, reportCell } = splitCellWithResult(cell);

        // Add cell result if there is one
        cellContentsToAdd.push(cellContent);

        // Add cell to id to cell map
        cellsToAdd.push(reportCell);
      });

      addCells(state, tabId, cellContentsToAdd, cellsToAdd);

      return state;
    },
    setIsBulkRunning: (
      state: ReportReduxType,
      action: PayloadAction<boolean>
    ) => {
      state.isBulkRunning = action.payload;
      return state;
    },
    setActiveTabId: (
      state: ReportReduxType,
      action: PayloadAction<string | undefined>
    ) => {
      if (state.activeReport) state.activeReport.active_tab_id = action.payload;
      return state;
    },
    setIsReportOutOfDateForViewer: (
      state: ReportReduxType,
      action: PayloadAction<boolean>
    ) => {
      state.isReportOutOfDateForViewer = action.payload;
      return state;
    },
    setReportUpdatedAtOverride: (state: ReportReduxType) => {
      state.reportUpdatedAtOverride = new Date().toISOString();
      return state;
    },
    moveTabLeft: (state: ReportReduxType, action: PayloadAction<string>) => {
      const tabIdx = state.tabs.allIds.findIndex(
        (tabId) => tabId === action.payload
      );
      if (tabIdx === -1) {
        console.error("Tab not found when moving tab right");
        return state;
      }
      if (tabIdx > 0) {
        const neighbor = state.tabs.allIds[tabIdx - 1];
        if (neighbor) {
          const nextTab = state.tabs.allIds[tabIdx];
          if (nextTab) state.tabs.allIds[tabIdx - 1] = nextTab;
        }
        if (neighbor) state.tabs.allIds[tabIdx] = neighbor;
      }
      return state;
    },
    moveTabRight: (state: ReportReduxType, action: PayloadAction<string>) => {
      const tabIdx = state.tabs.allIds.findIndex(
        (tabId) => tabId === action.payload
      );
      if (tabIdx === -1) {
        console.error("Tab not found when moving tab right");
        return state;
      }
      if (tabIdx < state.tabs.allIds.length - 1) {
        const neighbor = state.tabs.allIds[tabIdx + 1];
        const prevTab = state.tabs.allIds[tabIdx];
        if (prevTab) state.tabs.allIds[tabIdx + 1] = prevTab;
        if (neighbor) state.tabs.allIds[tabIdx] = neighbor;
      }
      return state;
    },
    setTabName: (
      state: ReportReduxType,
      action: PayloadAction<{ tabId: string; name: string }>
    ) => {
      if (!(action.payload.tabId in state.tabs.byId)) {
        console.error("Tab not found when setting tab name");
        return state;
      }
      const tab = state.tabs.byId[action.payload.tabId];
      if (tab) {
        tab.name = action.payload.name;
      }
      return state;
    },
    deleteReportTab: (
      state: ReportReduxType,
      action: PayloadAction<{ tabId: string }>
    ) => {
      Object.keys(state.tabs.byId).forEach((tabId) => {
        if (tabId === action.payload.tabId) delete state.tabs.byId[tabId];
      });
      return state;
    },
    setReportProgressUpdate: (
      state: ReportReduxType,
      action: PayloadAction<{
        report_id: string;
        num_total_chunks?: number;
        num_completed_chunks?: number;
        queue_length?: number;
      }>
    ) => {
      const {
        report_id,
        num_total_chunks,
        num_completed_chunks,
        queue_length,
      } = action.payload;
      const { numTotalChunks, numCompletedChunks, queueLength } =
        state.reportLoadingProgress;

      if (state.activeReport?.id !== report_id) return;
      else if (
        num_total_chunks === undefined &&
        num_completed_chunks === undefined &&
        queue_length === undefined
      )
        return;

      state.reportLoadingProgress = {
        numTotalChunks: num_total_chunks ?? numTotalChunks,
        numCompletedChunks: num_completed_chunks ?? numCompletedChunks,
        queueLength: queue_length ?? queueLength,
      };

      return state;
    },
    setReportColumnHeader: (
      state: ReportReduxType,
      action: PayloadAction<{ name: string; tabId: string; columnId: string }>
    ) => {
      const { name, tabId, columnId } = action.payload;
      const tabTool = state.tools[tabId]?.[columnId];
      if (!tabTool) {
        console.error("Tab not found when setting column header");
        return state;
      }
      tabTool.name = name;
      return state;
    },
    setFormattedCellContent: (
      state: ReportReduxType,
      action: PayloadAction<MatrixGridDataType[]>
    ) => {
      state.formattedCellContent = action.payload;
      return state;
    },
    upsertReportTool: (
      state: ReportReduxType,
      action: PayloadAction<{ tool: ReduxTool; tabId: string }>
    ) => {
      const { tool, tabId } = action.payload;
      if (!(tabId in state.tools)) state.tools[tabId] = {};
      const tabTools = state.tools[tabId];
      if (tabTools) {
        tabTools[tool.static_column_id] = {
          ...(tabTools[tool.static_column_id] ?? {}),
          ...tool,
        };
      }
      return state;
    },
    replaceReportToolVersionedColumnId: (
      state: ReportReduxType,
      action: PayloadAction<{
        staticColumnId?: ReduxTool["static_column_id"];
        versionedColumnId?: ReduxTool["versioned_column_id"];
        tabId: string;
      }>
    ) => {
      const { staticColumnId, versionedColumnId, tabId } = action.payload;
      if (!(tabId in state.tools)) state.tools[tabId] = {};
      const tabTools = state.tools[tabId];
      if (tabTools && staticColumnId) {
        tabTools[staticColumnId] = {
          ...(tabTools[staticColumnId] ?? {}),
          versioned_column_id: versionedColumnId,
        } as ReduxTool;
      }
      return state;
    },
    clearAllCells: (state: ReportReduxType) => {
      state.cells = {};
      state.cellResults = {};
      return state;
    },
    setMatrixHomePanel: (
      state: ReportReduxType,
      action: PayloadAction<MatrixHomePanelType>
    ) => {
      state.matrixHomePanel = action.payload;
      return state;
    },
    addDisplayReportDocIds: (
      state: ReportReduxType,
      action: PayloadAction<{ tabId: string; docIds: string[] }>
    ) => {
      const { tabId, docIds } = action.payload;
      const toolMapCopy = _.cloneDeep(state.tools[tabId]);
      if (!toolMapCopy) return state;
      Object.values(toolMapCopy).forEach((tool) => {
        if (tool.tool === "retrieve" && tool.tool_params?.doc_ids)
          tool.tool_params.doc_ids.push(...docIds);
      });
      state.tools[tabId] = toolMapCopy;
      return state;
    },
    setInitialRowMount: (
      state: ReportReduxType,
      action: PayloadAction<boolean>
    ) => {
      state.initialRowMount = action.payload;
      return state;
    },
    setLocalViewConfig: (
      state: ReportReduxType,
      action: PayloadAction<ViewConfig | null>
    ) => {
      state.localViewConfig = action.payload;
    },
    upsertCells: (
      state: ReportReduxType,
      action: PayloadAction<{ cells: HydratedReportCellType[]; tabId: string }>
    ) => {
      const { cells, tabId } = action.payload;
      const newCells = {};

      cells.forEach((cell) => {
        const { y, static_column_id } = cell;
        if (!newCells[static_column_id]) newCells[static_column_id] = {};
        if (!newCells[static_column_id][y]) newCells[static_column_id][y] = {};
        newCells[static_column_id][y] = transformHydratedCellToReportCell(cell);
      });

      state.cells[tabId] = newCells;
      return state;
    },
    setLeaseId: (
      state: ReportReduxType,
      action: PayloadAction<string | null>
    ) => {
      state.leaseId = action.payload;
      return state;
    },
    setIsMarkdownRendered: (
      state: ReportReduxType,
      action: PayloadAction<boolean>
    ) => {
      state.isMarkdownRendered = action.payload;
      return state;
    },
    setNextRowsToRun: (
      state: ReportReduxType,
      action: PayloadAction<number[]>
    ) => {
      state.nextRowsToRun = action.payload;
      return state;
    },
    openColumnEditor: (
      state: ReportReduxType,
      action: PayloadAction<ColumnEditorState>
    ) => {
      const editorState = action.payload;
      state.columnEditor = {
        open: true,
        ...editorState,
      };
      return state;
    },
    closeColumnEditor: (state: ReportReduxType) => {
      state.columnEditor = {
        ...DEFAULT_COLUMN_EDITOR_STATE,
        animate: state.columnEditor.animate,
      };
      return state;
    },
    openPromptGenerator: (
      state: ReportReduxType,
      action: PayloadAction<PromptGeneratorState>
    ) => {
      const promptGenratorState = action.payload;
      state.promptGenerator = {
        open: true,
        ...promptGenratorState,
      };
      return state;
    },
    closePromptGenerator: (state: ReportReduxType) => {
      state.promptGenerator = {
        open: false,
        referenceElement: null,
      };
      return state;
    },
    setSelectOptions: (
      state: ReportReduxType,
      action: PayloadAction<SelectOptionPayload>
    ) => {
      state.selectOptions[action.payload.promptKey ?? ""] =
        action.payload.value;
      return state;
    },
    setEntireSelectOptions: (
      state: ReportReduxType,
      action: PayloadAction<Record<string, string[] | null>>
    ) => {
      state.selectOptions = action.payload;
      return state;
    },
    setNumSelectedRows: (
      state: ReportReduxType,
      action: PayloadAction<number | null>
    ) => {
      state.numSelectedRows = action.payload;
      return state;
    },
    setDefaultCellOverflowMethod: (
      state: ReportReduxType,
      action: PayloadAction<CellOverflowMethod>
    ) => {
      state.defaultCellOverflowMethod = action.payload;
      return state;
    },
    setAddMatrixUserStatus: (
      state: ReportReduxType,
      action: PayloadAction<AddMatrixUserPayload>
    ) => {
      state.addMatrixUserStatus = action.payload;
      return state;
    },
    clearAddMatrixUserStatus: (state: ReportReduxType) => {
      state.addMatrixUserStatus = null;
      return state;
    },
    setAddDocsFailedPermissionsUserMap: (
      state: ReportReduxType,
      action: PayloadAction<AddDocsFailedPermissionsUserMap>
    ) => {
      state.addDocsFailedPermissionsUserMap = action.payload;
    },
  },
});

export const {
  resetGlobalReportState,
  setReportStatus,
  updateReportVersion,
  setActiveReport,
  setPreviewReport,
  setActiveReportName,
  addReportResponse,
  updateReportCells,
  setActiveTableRequest,
  setActiveColCellRequest,
  addReportCellsResponse,
  setBulkRunCompletedVersion,
  setIsBulkRunning,
  setActiveTabId,
  setIsReportOutOfDateForViewer,
  setReportUpdatedAtOverride,
  upsertReportTool,
  addReportTab,
  duplicateReportTab,
  moveTabLeft,
  moveTabRight,
  shiftCells,
  setTabName,
  deleteReportTab,
  setReportProgressUpdate,
  setReportColumnHeader,
  setFormattedCellContent,
  setReportTools,
  replaceTemporaryLoadingCells,
  clearAllCells,
  setMatrixHomePanel,
  addDisplayReportDocIds,
  setInitialRowMount,
  setLocalViewConfig,
  upsertCells,
  setLeaseId,
  setIsMarkdownRendered,
  setNextRowsToRun,
  openColumnEditor,
  closeColumnEditor,
  openPromptGenerator,
  closePromptGenerator,
  replaceReportToolVersionedColumnId,
  setSelectOptions,
  setEntireSelectOptions,
  setNumSelectedRows,
  setDefaultCellOverflowMethod,
  setAddMatrixUserStatus,
  clearAddMatrixUserStatus,
  setAddDocsFailedPermissionsUserMap,
} = reportSlice.actions;
export const reportReducer = reportSlice.reducer;
