import React from "react";
import CheckBoxOutlineBlankSharpIcon from "@mui/icons-material/CheckBoxOutlineBlankSharp";
import CheckBoxSharpIcon from "@mui/icons-material/CheckBoxSharp";
import { Tooltip } from "source/components/shared/Tooltip";
import { MenuIcon } from "source/components/shared/Icons/MenuIcon";
import BookmarkSharpIcon from "@mui/icons-material/BookmarkSharp";
import { SidebarRowInput } from "./SidebarRowInput";
import { useSelector } from "react-redux";
import {
  addReportFilter,
  getReportFilterKey,
  getTargetFilterExists,
  removeReportFilter,
} from "source/redux/advanced";
import { useDispatch } from "react-redux";
import { DOC_TAB_WIDTH } from "source/constants";
import { ReportFilter, ReportFilterOperation } from "source/Types";

type Props = {
  icon: React.ReactNode;
  id: string;
  // We have value as value if passed in otherwise we use id
  value?: string;
  title: string;
  titleSubstringMatch?: string;
  sectionKey: string;
  searchKey: string;
  showContextMenu?: boolean;
  bookmarked?: boolean;
  toggle?: React.ReactNode;
  depth?: number;
  styleOverride?: string;
  overrideCheckbox?: React.ReactNode;
  handleClick?: (id: string) => void;
  handleRename?: (newTitle: string, id: string) => void;
  cacheSetter?: () => void;
};

const boldSubstringMatchInText = (
  text: string,
  match: string
): React.ReactNode => {
  // Thank you gpt
  const regex = new RegExp(match.replace(/[.*+?^${}()|[\]\\]/g, "\\$&"), "gi");
  const parts = text.split(regex);

  if (parts.length <= 1) {
    return text; // No matches found
  }

  const matches = text.match(regex);

  return parts.map((part, index) => (
    <span key={index}>
      {part}
      {matches && index < parts.length - 1 && (
        <span className="font-bold">{matches[index]}</span>
      )}
    </span>
  ));
};

export const GigabarRow = React.memo(
  ({
    icon,
    id,
    value,
    title,
    titleSubstringMatch,
    sectionKey,
    searchKey,
    showContextMenu,
    bookmarked,
    toggle,
    depth = 0,
    styleOverride,
    overrideCheckbox,
    handleClick,
    handleRename,
    cacheSetter,
  }: Props) => {
    const dispatch = useDispatch();
    const rowKey = getReportFilterKey(sectionKey, value || id);
    const checked = useSelector(getTargetFilterExists(sectionKey, rowKey));

    const handleSelect = () => {
      if (cacheSetter) cacheSetter();
      if (checked) dispatch(removeReportFilter({ sectionKey, rowKey }));
      else {
        // Create new report filter
        const reportFilter: ReportFilter = {
          // This key is used to identify the filter at search time
          key: searchKey,
          values: value || id,
          operation: ReportFilterOperation.IS,
        };
        dispatch(addReportFilter({ sectionKey, rowKey, reportFilter }));
      }
    };

    return (
      <div
        className={`group/sidebarRow flex cursor-pointer flex-nowrap items-center justify-between gap-2 py-1 pr-2.5 ${
          checked ? "bg-blue2 hover:bg-blue2" : "hover:bg-gray1"
        } ${styleOverride}`}
        style={{
          paddingLeft: 16 + depth * DOC_TAB_WIDTH,
        }}
        onClick={(e: React.MouseEvent) => {
          e.stopPropagation();
          if (!handleClick) {
            handleSelect();
          } else {
            handleClick(id);
          }
        }}
      >
        <div className="flex flex-1 flex-nowrap items-center gap-2 overflow-hidden text-ellipsis whitespace-nowrap">
          {overrideCheckbox ? (
            <>{overrideCheckbox}</>
          ) : (
            <div
              onClick={(e: React.MouseEvent) => {
                e.stopPropagation();
                handleSelect();
              }}
            >
              <Checkbox checked={checked} />
            </div>
          )}
          <span className="flex items-center">{icon}</span>
          {titleSubstringMatch ? (
            <div className="mr-2 w-full select-none overflow-hidden text-ellipsis whitespace-nowrap bg-transparent text-start text-xs">
              {boldSubstringMatchInText(title, titleSubstringMatch)}
            </div>
          ) : (
            <SidebarRowInput
              title={title}
              id={id}
              handleRename={handleRename}
            />
          )}
        </div>
        <div className="invisible flex pr-1 text-checkIcon group-hover/sidebarRow:visible">
          {showContextMenu && !titleSubstringMatch && (
            <Tooltip
              enterDelay={400}
              enterNextDelay={400}
              title="Delete, rename, and more..."
            >
              <span className="mr-0.5">
                <MenuIcon isActive={checked} />
              </span>
            </Tooltip>
          )}
        </div>
        {bookmarked && (
          <div className="flex pr-1 text-darkGray">
            <span className="mr-0.5">
              <BookmarkSharpIcon style={{ fontSize: "16px" }} />
            </span>
          </div>
        )}
        {toggle}
      </div>
    );
  }
);

export const Checkbox = ({ checked }: { checked: boolean }) => (
  <>
    {checked ? (
      <span className="text-hebbiaBlue">
        <CheckBoxSharpIcon fontSize="small" />
      </span>
    ) : (
      <span className="text-lightGray hover:text-hebbiaBlue">
        <CheckBoxOutlineBlankSharpIcon fontSize="small" />
      </span>
    )}
  </>
);
