import {
  createListenerMiddleware,
  ListenerMiddlewareInstance,
} from "@reduxjs/toolkit";

const initListenerMiddleware = (): ListenerMiddlewareInstance | null => {
  return typeof window !== "undefined" ? createListenerMiddleware() : null;
};

export const listenerMiddleware = initListenerMiddleware();
