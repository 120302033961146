import * as ContextMenuPrimitive from "@radix-ui/react-context-menu";
import React from "react";
import styled from "styled-components";

export const StyledContent = styled(ContextMenuPrimitive.Content)`
  min-width: 220px;
  background-color: var(--card);
  border: 1px solid var(--text-light);
  border-radius: 2px;
  overflow: hidden;
  box-shadow: 0px 7px 24px -7px rgba(var(--shadow), 0.47);
`;
export const RightSlot = styled("div")`
  margin-left: auto;
  padding-left: 20px;
`;

export const StyledItem = styled(ContextMenuPrimitive.Item)`
  font-family: Inter;
  font-size: 13px;
  all: unset;
  line-height: 1px;
  // border-radius: 3px;
  display: flex;
  align-items: center;
  height: 32px;
  padding: 2px 7px;
  margin: 2px;
  position: relative;
  user-select: none;
  cursor: pointer;
  color: var(--text-secondary);
  :hover {
    background: var(--background-hover);
  }
`;

const StyledLabel = styled(ContextMenuPrimitive.Label)`
  padding: 5px 5px;
  font-size: 14px;
  line-height: 14px;
  color: var(--text-light);
  cursor: default;
  margin: 4px;
`;

type Props = {
  title: string;
  icon: JSX.Element;
  onSelect?: (e) => void;
};

export const ContextMenuItem = ({ title, icon, onSelect }: Props) => {
  const _handleClick = (e) => {
    e.stopPropagation();
    if (onSelect) onSelect(e);
  };
  return (
    <StyledItem onClick={_handleClick}>
      <span className="mr-1">{icon}</span>
      <span>{title}</span>
    </StyledItem>
  );
};

export const ContextMenu = ContextMenuPrimitive.Root;
export const ContextMenuTrigger = ContextMenuPrimitive.Trigger;
export const ContextMenuContent = StyledContent;
export const ContextMenuLabel = StyledLabel;
