import React from "react";
import { classNames, TailwindColor } from "core";

type PingSize = "xs" | "sm" | "md";

export type PingProps = {
  pulse?: boolean;
  className?: string;
  color?: TailwindColor;
  size?: PingSize;
};

const getSizeClass = (size: PingSize) => {
  switch (size) {
    case "xs":
      return 8;
    case "sm":
      return 10;
    case "md":
      return 14;
  }
};

export const Ping = ({
  color = "brand",
  size = "xs",
  pulse = true,
  className,
}: PingProps) => {
  const s = getSizeClass(size);
  return (
    <span
      className={classNames("relative inline-block h-fit w-fit", className)}
    >
      <span
        className={classNames(
          `block rounded-full bg-${color}-300`,
          pulse && "animate-ping"
        )}
        style={{
          width: s,
          height: s,
          maxWidth: s,
          maxHeight: s,
          animationDuration: "1.5s",
        }}
      />
      <span
        className={classNames(
          `absolute left-1/2 top-1/2 block -translate-x-1/2 -translate-y-1/2 rounded-full`,
          `bg-${color}-500`
        )}
        style={{
          width: s * 0.8,
          height: s * 0.8,
        }}
      />
    </span>
  );
};
