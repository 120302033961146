import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ReduxState } from ".";

export type slackAlertReduxType = {
  sentAlert: boolean;
};

export const getSlackAlertSent = (state: ReduxState) =>
  state.slackAlert.sentAlert;

const initialState: slackAlertReduxType = {
  sentAlert: false,
};

const slackAlertSlice = createSlice({
  name: "slackAlert",
  initialState,
  reducers: {
    setSlackAlertSent: (
      state: slackAlertReduxType,
      action: PayloadAction<boolean>
    ) => {
      state.sentAlert = action.payload;
    },
  },
});

export const { setSlackAlertSent } = slackAlertSlice.actions;
export const slackAlertReducer = slackAlertSlice.reducer;
