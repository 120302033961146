import {
  PinType,
  QueryResultType,
  HighlightContextType,
  GPTAnswerPinType,
} from "source/Types";
import axios from "./axios";

export const Pins = {
  get: (pinId: string) => axios.get(`/v2/repos/repo/pins/${pinId}`),
  getDocPins: (docId: string) => axios.get(`/v2/docs/${docId}/pins`),
  getRepoPins: (repoId: string) => axios.get(`/v2/repos/${repoId}/pins`),
  getAllPins: (params: { show_all: boolean }) =>
    axios.get(`/v2/repos/repo/pins/all-pins`, { params }),
  create: (payload: {
    repo_id: string;
    doc_id?: string;
    build_id?: string;
    query?: string;
    result?: QueryResultType;
    org_id?: string;
    highlight_context?: HighlightContextType;
    is_link?: boolean;
  }) => axios.post(`/v2/repos/${payload.repo_id}/pins`, payload),
  delete: (pin: PinType) =>
    axios.delete(`/v2/repos/${pin.repo_id}/pins/${pin.id}`),
  getGPTAnswerPin: (pinId: string) =>
    axios.get(`/v2/repos/repo/generated_answer_pins/${pinId}`),
  getRepoGPTAnswerPins: (repoId: string) =>
    axios.get(`/v2/repos/${repoId}/generated_answer_pins`),
  getAllGPTAnswerPins: (params: { show_all: boolean }) =>
    axios.get(`/v2/repos/repo/generated_answer_pins/all-pins`, { params }),
  createGPTAnswerPin: (payload: {
    repo_id: string;
    doc_id?: string;
    query: string;
    answer: string;
    result_ids?: string[];
  }) =>
    axios.post(`/v2/repos/${payload.repo_id}/generated_answer_pins`, payload),
  deleteGPTAnswerPin: (generatedAnswer: GPTAnswerPinType) =>
    axios.delete(
      `/v2/repos/${generatedAnswer.repo_id}/generated_answer_pins/${generatedAnswer.id}`
    ),
};
