import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ReduxState } from ".";

export type BannersReduxType = {
  statusBannerCollapsed: boolean;
  showStatusBanner: boolean;
};

const initialState: BannersReduxType = {
  statusBannerCollapsed: false,
  showStatusBanner: false,
};

export const getStatusBannerCollapsed = (state: ReduxState) =>
  state.banners.statusBannerCollapsed;

const bannersSlice = createSlice({
  name: "banners",
  initialState,
  reducers: {
    setStatusBannerCollapsed: (
      state: BannersReduxType,
      action: PayloadAction<boolean>
    ) => {
      state.statusBannerCollapsed = action.payload;
      return state;
    },
    setShowStatusBanner: (
      state: BannersReduxType,
      action: PayloadAction<boolean>
    ) => {
      state.showStatusBanner = action.payload;
      return state;
    },
  },
});

export const { setStatusBannerCollapsed, setShowStatusBanner } =
  bannersSlice.actions;
export const bannersReducer = bannersSlice.reducer;
