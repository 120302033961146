import React, { useEffect, useState } from "react";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowRightSharpIcon from "@mui/icons-material/ArrowRightSharp";
import { useSelector } from "react-redux";
import { getOpenSidebarSection, setOpenSidebarSection } from "source/redux/ui";
import { useDispatch } from "react-redux";

type Props = {
  title: string;
  // Badge is small item to the right of the title
  badge?: React.ReactNode;
  defaultOpen?: boolean;
  children: React.ReactNode;
  last?: boolean;
};

/** Renders a collapsible sidebar section */
export const SidebarToggle = ({ title, badge, children, last }: Props) => {
  const dispatch = useDispatch();
  const openSidebarSection = useSelector(getOpenSidebarSection);
  const [isOpen, setIsOpen] = useState(true);

  useEffect(() => {
    if (openSidebarSection === title) {
      setIsOpen(true);
      dispatch(setOpenSidebarSection(null));
    }
  }, [openSidebarSection]);

  return (
    <div className={`${last ? "border-y" : "border-t"}`}>
      <ToggleHeader
        title={title}
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        badge={badge}
      />
      {isOpen && children}
    </div>
  );
};

type ToggleHeaderProps = {
  title: string;
  badge?: React.ReactNode;
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

const ToggleHeader = ({
  title,
  isOpen,
  setIsOpen,
  badge,
}: ToggleHeaderProps) => (
  <div
    className="flex w-full cursor-pointer select-none justify-between p-2 px-2.5"
    onClick={() => setIsOpen(!isOpen)}
  >
    <div className="flex items-center gap-3">
      <span className="text-[13px] font-medium text-black">{title}</span>
      {badge && <span>{badge}</span>}
    </div>

    <span className="cursor-pointer text-black">
      {isOpen ? (
        <ArrowDropDownIcon fontSize="medium" />
      ) : (
        <ArrowRightSharpIcon fontSize="medium" />
      )}
    </span>
  </div>
);
