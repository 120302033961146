import React from "react";
import { FontSizeMui, RepoType } from "source/Types";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import FolderIcon from "@mui/icons-material/Folder";
import CorporateFareIcon from "@mui/icons-material/CorporateFare";

import { FEDERAL_RESERVE_MINUTES_REPO_ID } from "source/constants";
import { Emoji } from "emoji-mart";
import { classNames } from "core";

type RepoIconProps = {
  repo?: Partial<RepoType>;
  fontSize?: FontSizeMui;
  className?: string;
  fontOverride?: string;
  emojiSizeOverride?: number;
  repoEmojiContainerOverride?: string;
};

export const RepoIcon = ({
  repo,
  className,
  fontSize = "inherit",
  fontOverride,
  emojiSizeOverride,
  repoEmojiContainerOverride,
}: RepoIconProps) => {
  if (!repo) {
    return (
      <FolderIcon
        fontSize={fontSize}
        className={classNames("text-secondary", className)}
        style={fontOverride ? { fontSize: fontOverride } : {}}
      />
    );
  }

  // Hard code for fed minutes
  if (repo.id === FEDERAL_RESERVE_MINUTES_REPO_ID) {
    return (
      <AccountBalanceIcon
        fontSize={fontSize}
        className={className}
        style={fontOverride ? { fontSize: fontOverride } : {}}
      />
    );
  }

  // Custom emojis for public repos
  if (repo.emoji && repo.emoji.id !== "folder") {
    return (
      <div
        className={classNames(
          "ml-0.5 pr-0.5 pt-0",
          repoEmojiContainerOverride && "h-5",
          className
        )}
      >
        <Emoji
          emoji={{ ...repo.emoji }}
          size={emojiSizeOverride ?? 20}
          native
        />
      </div>
    );
  }

  // Hard code for sec repos w/ no custom emojis
  if (repo.ticker) {
    return (
      <CorporateFareIcon
        fontSize={fontSize}
        className={classNames("text-secondary", className)}
        style={fontOverride ? { fontSize: fontOverride } : {}}
      />
    );
  }

  // Default folder Icon
  return (
    <FolderIcon
      fontSize={fontSize}
      className={classNames("text-secondary", className)}
      style={fontOverride ? { fontSize: fontOverride } : {}}
    />
  );
};
