import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { GTDAnswer } from "source/Types";
import { ReduxState } from ".";

export type GtdReduxAnswerType = {
  gtdAnswer: GTDAnswer;
  keywordGTDAnswer: GTDAnswer;
  gtdAnswerLoading: boolean;
  keywordGTDAnswerLoading: boolean;
  gtdAnswerFinished: boolean;
  keywordGTDAnswerFinished: boolean;
  gtdSummariesLoading: boolean;
};

export const getGTDAnswer = (state: ReduxState) => state.gtd.gtdAnswer;
export const getKeywordGTDAnswer = (state: ReduxState) =>
  state.gtd.keywordGTDAnswer;
export const getGTDAnswerLoading = (state: ReduxState) =>
  state.gtd.gtdAnswerLoading;
export const getKeywordGTDAnswerLoading = (state: ReduxState) =>
  state.gtd.keywordGTDAnswerLoading;
export const getGTDAnswerFinished = (state: ReduxState) =>
  state.gtd.gtdAnswerFinished;
export const getKeywordGTDAnswerFinished = (state: ReduxState) =>
  state.gtd.keywordGTDAnswerFinished;
export const getGTDSummariesLoading = (state: ReduxState) =>
  state.gtd.gtdSummariesLoading;

const initialState: GtdReduxAnswerType = {
  gtdAnswer: {},
  keywordGTDAnswer: {},
  gtdAnswerLoading: true,
  keywordGTDAnswerLoading: true,
  gtdAnswerFinished: false,
  keywordGTDAnswerFinished: false,
  gtdSummariesLoading: true,
};

const gtdAnswerSlice = createSlice({
  name: "gtd",
  initialState,
  reducers: {
    setGTDAnswer: (
      state: GtdReduxAnswerType,
      action: PayloadAction<GTDAnswer>
    ) => {
      state.gtdAnswer = action.payload;
    },
    setKeywordGTDAnswer: (
      state: GtdReduxAnswerType,
      action: PayloadAction<GTDAnswer>
    ) => {
      state.keywordGTDAnswer = action.payload;
    },
    upsertGTDAnswerAnswer: (
      state: GtdReduxAnswerType,
      action: PayloadAction<string>
    ) => {
      if (!state.gtdAnswer.answer) state.gtdAnswer.answer = "";
      state.gtdAnswer.answer = state.gtdAnswer.answer + action.payload;
    },
    upsertGTDKeywordAnswer: (
      state: GtdReduxAnswerType,
      action: PayloadAction<string>
    ) => {
      if (!state.keywordGTDAnswer.answer) state.keywordGTDAnswer.answer = "";
      state.keywordGTDAnswer.answer =
        state.keywordGTDAnswer.answer + action.payload;
    },
    setGTDKeywordAnswerDegraded: (
      state: GtdReduxAnswerType,
      action: PayloadAction<boolean>
    ) => {
      state.keywordGTDAnswer.degraded = action.payload;
    },
    setGTDAnswerDegraded: (
      state: GtdReduxAnswerType,
      action: PayloadAction<boolean>
    ) => {
      state.gtdAnswer.degraded = action.payload;
    },
    setGTDAnswerAnswer: (
      state: GtdReduxAnswerType,
      action: PayloadAction<string>
    ) => {
      state.gtdAnswer.answer = action.payload;
    },
    setGTDPlainAnswer: (
      state: GtdReduxAnswerType,
      action: PayloadAction<string>
    ) => {
      state.gtdAnswer.plainAnswer = action.payload;
    },
    setGTDAnswerLoading: (
      state: GtdReduxAnswerType,
      action: PayloadAction<boolean>
    ) => {
      state.gtdAnswerLoading = action.payload;
    },
    setKeywordGTDAnswerLoading: (
      state: GtdReduxAnswerType,
      action: PayloadAction<boolean>
    ) => {
      state.keywordGTDAnswerLoading = action.payload;
    },
    setGTDAnswerFinished: (
      state: GtdReduxAnswerType,
      action: PayloadAction<boolean>
    ) => {
      state.gtdAnswerFinished = action.payload;
    },
    setKeywordGTDAnswerFinished: (
      state: GtdReduxAnswerType,
      action: PayloadAction<boolean>
    ) => {
      state.keywordGTDAnswerFinished = action.payload;
    },
    setGTDSummariesLoading: (
      state: GtdReduxAnswerType,
      action: PayloadAction<boolean>
    ) => {
      state.gtdSummariesLoading = action.payload;
    },
    setGTDSummaries: (
      state: GtdReduxAnswerType,
      action: PayloadAction<Record<string, string>>
    ) => {
      state.gtdAnswer.summaries = action.payload;
    },
    upsertGTDSummaries: (
      state: GtdReduxAnswerType,
      action: PayloadAction<Record<string, string>>
    ) => {
      if (!state.gtdAnswer.summaries) state.gtdAnswer.summaries = {};
      Object.entries(action.payload).forEach(([id, summary]) => {
        if (!state.gtdAnswer.summaries) state.gtdAnswer.summaries = {};
        state.gtdAnswer.summaries[id] = summary;
      });
    },
    setGTDCheckAnswer: (
      state: GtdReduxAnswerType,
      action: PayloadAction<boolean>
    ) => {
      state.gtdAnswer.checkAnswer = action.payload;
    },
    setGTDSuggestedQuestion: (
      state: GtdReduxAnswerType,
      action: PayloadAction<string>
    ) => {
      state.gtdAnswer.suggestedQuestion = action.payload;
    },
    setGTDResultIds: (
      state: GtdReduxAnswerType,
      action: PayloadAction<string[]>
    ) => {
      state.gtdAnswer.resultIds = action.payload;
    },
    setKeywordGTDResultIds: (
      state: GtdReduxAnswerType,
      action: PayloadAction<string[]>
    ) => {
      state.keywordGTDAnswer.resultIds = action.payload;
    },
  },
});

export const {
  setGTDAnswer,
  setKeywordGTDAnswer,
  upsertGTDAnswerAnswer,
  upsertGTDKeywordAnswer,
  setGTDAnswerAnswer,
  setGTDPlainAnswer,
  setGTDSummaries,
  setGTDAnswerLoading,
  setKeywordGTDAnswerLoading,
  setGTDAnswerFinished,
  setKeywordGTDAnswerFinished,
  setGTDSummariesLoading,
  upsertGTDSummaries,
  setGTDCheckAnswer,
  setGTDSuggestedQuestion,
  setGTDResultIds,
  setKeywordGTDResultIds,
  setGTDAnswerDegraded,
  setGTDKeywordAnswerDegraded,
} = gtdAnswerSlice.actions;
export const gtdAnswerReducer = gtdAnswerSlice.reducer;
