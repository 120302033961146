import axios from "../axios";
import { PermissionGroup, UserType } from "source/Types";
import { CreatePermissionGroupPayload } from "./useQueryPermissionGroups";

export const PermissionGroups = {
  // Crud
  getPermissionGroups: (
    orgId: string,
    repoId: string | undefined = undefined
  ): Promise<PermissionGroup[]> =>
    axios
      .get(`/v2/permission-groups`, {
        params: { org_id: orgId, repo_id: repoId },
      })
      .then(({ data: { permission_groups } }) => permission_groups),
  getPermissionGroup: (id: string): Promise<PermissionGroup> =>
    axios
      .get(`/v2/permission-groups/${id}`)
      .then(({ data: { permission_group } }) => permission_group),
  createPermissionGroup: (
    permissionGroup: CreatePermissionGroupPayload
  ): Promise<PermissionGroup> =>
    axios
      .post(`/v2/permission-groups`, permissionGroup)
      .then(({ data: { permission_group } }) => permission_group),
  updatePermissionGroup: (
    id: string,
    permissionGroup: Partial<PermissionGroup>
  ): Promise<PermissionGroup> =>
    axios
      .put(`/v2/permission-groups/${id}`, permissionGroup)
      .then(({ data: { permission_group } }) => permission_group),
  deletePermissionGroup: (id: string) =>
    axios.delete(`/v2/permission-groups/${id}`),
  // Access Control

  addRepoToPermissionGroup: (id: string, repoId: string) =>
    axios.post(`/v2/permission-groups/${id}/${repoId}`),
  updateRepoPermissionGroup: (id: string, repoId: string, role: string) =>
    axios.put(`/v2/permission-groups/${id}/${repoId}`, { role }),
  removeRepoFromPermissionGroup: (id: string, repoId: string) =>
    axios.delete(`/v2/permission-groups/${id}/${repoId}`),
  getPermissionGroupMembers: (id: string): Promise<UserType[]> =>
    axios
      .get(`/v2/permission-groups/${id}/members`)
      .then(({ data: { members } }) => members),
  addMemberToPermissionGroup: (id: string, email: string) =>
    axios.post(`/v2/permission-groups/${id}/add-member`, { email }),
  removeMemberFromPermissionGroup: (id: string, userId: string) =>
    axios.post(`/v2/permission-groups/${id}/remove-member`, {
      user_id: userId,
    }),
};
