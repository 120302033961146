import React from "react";
import { Tooltip } from "source/components/shared/Tooltip";
import { KeyValuePair } from "source/Types";
import { wordToTitleCase, cleanText } from "source/utils/common/strings";
import CalendarMonthSharpIcon from "@mui/icons-material/CalendarMonthSharp";

type FilterChipProps = {
  keyValuePair: KeyValuePair;
  checkbox?: React.ReactNode;
  isLastMessage?: boolean;
};

export const DateFilterChip = ({ keyValuePair, checkbox }: FilterChipProps) => {
  if (isNaN(Date.parse(keyValuePair.value))) return null;
  const date = new Date(keyValuePair.value);
  const prefix = keyValuePair.key === "start_date" ? ">" : "<";
  // TODO: IP-based geolocation to determine date formatting
  const dateString = `${prefix} ${
    date.getMonth() + 1
  }/${date.getDate()}/${date.getFullYear()}`;
  return (
    <Tooltip
      title={wordToTitleCase(cleanText(keyValuePair.key))}
      enterDelay={500}
    >
      <div className="flex flex-row items-center gap-2 overflow-hidden bg-card px-2.5 py-1.5 text-center text-sm font-medium">
        {checkbox}
        <CalendarMonthSharpIcon fontSize="small" className="text-secondary" />
        <span>{dateString}</span>
      </div>
    </Tooltip>
  );
};
