import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { GPTAnswerPinType, GroupedGPTAnswerPins } from "source/Types";
import { ReduxState } from ".";

export type GPTAnswerPinReduxType = {
  [id: string]: GPTAnswerPinType;
};

export const getGeneratedAnswerPinsDict = (state: ReduxState) =>
  state.gptAnswerPins;
export const getGeneratedAnswerPins = createSelector(
  [getGeneratedAnswerPinsDict],
  (pins) => Object.values(pins)
);

export const getGroupedGenAnswerPins = createSelector(
  [getGeneratedAnswerPinsDict],
  (pins) => {
    const groupedPins = {} as GroupedGPTAnswerPins;
    Object.entries(pins).forEach(([key, pin]) => {
      if (!pin.query) return;
      if (!groupedPins[pin.query]) groupedPins[pin.query] = [];
      groupedPins[pin.query]?.push(pin);
    });

    return groupedPins;
  }
);

const pinsSlice = createSlice({
  name: "generated_answer_pins",
  initialState: {},
  reducers: {
    setGPTAnswerPins: (
      state: GPTAnswerPinReduxType,
      action: PayloadAction<GPTAnswerPinType[]>
    ) => {
      state = {};

      action.payload.forEach((pin) => {
        state[pin.id] = pin;
      });

      return state;
    },
    upsertGPTAnswerPins: (
      state: GPTAnswerPinReduxType,
      action: PayloadAction<GPTAnswerPinType[]>
    ) => {
      action.payload.forEach((pin) => {
        state[pin.id] = {
          ...state[pin.id],
          ...pin,
        };
      });
      return state;
    },
    deleteGPTAnswerPins: (
      state: GPTAnswerPinReduxType,
      action: PayloadAction<GPTAnswerPinType[]>
    ) => {
      action.payload.forEach((pin) => {
        delete state[pin.id];
      });
      return state;
    },
  },
});

export const { upsertGPTAnswerPins, deleteGPTAnswerPins, setGPTAnswerPins } =
  pinsSlice.actions;
export const gptAnswerPinsReducer = pinsSlice.reducer;
