import React, { ElementRef, useEffect } from "react";
import { classNames, MaterialSymbolsIcon } from "core";
import { getMenuItemIndex, useMenu } from "./MenuContext";
import ListItem, { ListItemProps } from "./ListItem";
import { useChildComponentId } from "../../hooks/useChildComponentId";
import Dropdown from "./Dropdown"; // Import Dropdown to use for submenus
import { flip } from "@floating-ui/core";

export type MenuItemProps<T extends React.ElementType> = {
  onClick: (event: React.MouseEvent<T, MouseEvent>) => void;
  closeOnClick?: boolean;
  renderSubMenu?: () => React.ReactElement;
} & ListItemProps<T>;

const MenuItem = React.forwardRef(
  <T extends React.ElementType>(
    {
      className,
      onClick,
      closeOnClick = true,
      renderSubMenu,
      right,
      ...rest
    }: MenuItemProps<T>,
    parentRef: React.Ref<React.ElementRef<T>>
  ) => {
    const internalRef = React.useRef(null);
    const [isSubMenuOpen, setSubMenuOpen] = React.useState(false);

    React.useImperativeHandle(
      parentRef,
      () => internalRef.current as ElementRef<T>
    );

    const {
      registerMenuItem,
      getMenuItemProps,
      focusedIndex,
      setFocusedIndex,
      parentId,
    } = useMenu();

    useEffect(() => {
      registerMenuItem(internalRef);
    }, [registerMenuItem]);

    const {
      onClick: menuOnClick,
      className: classNameFromMenu,
      ...propsFromMenu
    } = getMenuItemProps(internalRef);

    const index = getMenuItemIndex(internalRef);
    const isFocused = focusedIndex === index;
    const id = useChildComponentId(parentId, "MenuItem", index);

    return (
      <ListItem
        {...propsFromMenu}
        {...rest}
        className={classNames(
          isFocused && "bg-gray-100",
          classNameFromMenu,
          className
        )}
        aria-selected={isFocused}
        id={id}
        onClick={(e: MouseEvent) => {
          e.preventDefault();
          e.stopPropagation();
          if (closeOnClick) {
            menuOnClick?.(e);
          }
          onClick?.(e);
        }}
        onMouseEnter={() => {
          setFocusedIndex(index);
          if (renderSubMenu) setSubMenuOpen(true);
        }}
        onMouseLeave={() => {
          setFocusedIndex(-1);
          if (renderSubMenu) setSubMenuOpen(false);
        }}
        ref={internalRef}
        right={
          renderSubMenu ? (
            <Dropdown
              isOpen={isSubMenuOpen}
              setIsOpen={setSubMenuOpen}
              renderMenu={renderSubMenu}
              floatingOptions={{
                placement: "right-start",
                middleware: [
                  flip({
                    mainAxis: true,
                    crossAxis: false,
                    padding: 0,
                    fallbackAxisSideDirection: "start",
                  }),
                ],
              }}
              as="div"
              className="flex justify-center"
            >
              <MaterialSymbolsIcon
                icon="keyboard_arrow_right"
                className="text-gray-500"
              />
            </Dropdown>
          ) : (
            right
          )
        }
      >
        {rest.children}
      </ListItem>
    );
  }
);

export default MenuItem;
