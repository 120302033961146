import { RepoIcon } from "source/components/shared/Icons/RepoIcon";
import { RepoContextMenu } from "source/components/sidebar/components/RepoContextMenu";
import React, { useState } from "react";
import { useFetchPaginatedParentDocs } from "source/hooks/repos/useFetchPaginatedParentDocs";
import { RepoType } from "source/Types";
import { GigabarRow } from "./GigabarRow";
import { DocRow } from "./DocRow";
import { ToggleStatelessIcon } from "source/components/shared/ToggleIcon";
import { ShowMoreButton } from "./FileSection/components/ShowMoreButton";
import { LoadingRow } from "source/components/sidebar/components/LoadingRow";
import { Tooltip } from "source/components/shared/Tooltip";
import { RepoTooltipBreadCrumb } from "./FileSection/components/RepoTooltipBreadCrumb";
import { getFilterSearchKey, getReportFilterKey } from "source/redux/advanced";
import { DOCUMENT_SECTION_TITLE } from "./FileSection";
/**
 * THIS IS TEMPORARY UNTIL WE REMOVE REPOS AND MOVE TO FOLDERS
 *
 */
type Props = {
  repo: RepoType;
  key: number;
  handleRepoClick?: (repoId: string) => void;
  handleRename?: (newTitle: string, id: string) => void;
  bookmarked?: boolean;
  showContextMenu?: boolean;
  showChildren?: boolean;
  searchValue?: string;
  styleOverride?: string;
  showPathToolTip?: boolean;
};
export const RepoRow = ({
  repo,
  key,
  handleRepoClick,
  handleRename,
  bookmarked,
  showContextMenu,
  showChildren = true,
  searchValue,
  styleOverride,
  showPathToolTip = false,
}: Props) => {
  const [toggle, setToggle] = useState(false);
  const sectionKey = getReportFilterKey(DOCUMENT_SECTION_TITLE);
  const searchKey = getFilterSearchKey("repo");

  return (
    <>
      <Tooltip
        title={
          showPathToolTip ? (
            <>
              <RepoTooltipBreadCrumb repo={repo}></RepoTooltipBreadCrumb>
            </>
          ) : undefined
        }
        enterDelay={800}
        enterNextDelay={800}
        // open={true}
      >
        <div>
          <RepoContextMenu repo={repo} key={`${repo.id}${key}`}>
            <GigabarRow
              title={repo.name}
              titleSubstringMatch={searchValue}
              id={repo.id}
              icon={<RepoIcon repo={repo} fontSize="small" />}
              sectionKey={sectionKey}
              searchKey={searchKey}
              handleClick={handleRepoClick}
              showContextMenu={showContextMenu}
              handleRename={handleRename}
              bookmarked={bookmarked}
              toggle={
                showChildren && (
                  <ToggleStatelessIcon
                    isOpen={toggle}
                    handleOpenClick={(e) => {
                      e?.stopPropagation();
                      setToggle(!toggle);
                    }}
                  />
                )
              }
              styleOverride={styleOverride}
            />
          </RepoContextMenu>
        </div>
      </Tooltip>

      {toggle && showChildren && <RepoChildren repo={repo} />}
    </>
  );
};

type RepoChildrenProps = {
  repo: RepoType;
};

const RepoChildren = ({ repo }: RepoChildrenProps) => {
  const { parentDocs, hasMore, isLoading, fetchNextPage } =
    useFetchPaginatedParentDocs({
      repo,
    });
  return (
    <>
      {parentDocs.map((doc, idx) => (
        <DocRow doc={doc} key={idx} idx={idx} depth={1} />
      ))}
      {isLoading && <LoadingRow depth={1} />}
      {hasMore && <ShowMoreButton depth={1} handleClick={fetchNextPage} />}
    </>
  );
};
