import React from "react";
import CheckBoxOutlineBlankSharpIcon from "@mui/icons-material/CheckBoxOutlineBlankSharp";
import CheckBoxSharpIcon from "@mui/icons-material/CheckBoxSharp";
import { Tooltip } from "source/components/shared/Tooltip";
import { MenuIcon } from "source/components/shared/Icons/MenuIcon";
import BookmarkSharpIcon from "@mui/icons-material/BookmarkSharp";
import { SidebarRowInput } from "./SidebarRowInput";
import { useDispatch } from "react-redux";
import { FilterType, ReportFilter, ReportFilterOperation } from "source/Types";
import AutoAwesomeIcon from "@mui/icons-material/AutoAwesome";
import { useSelector } from "react-redux";
import {
  addReportFilter,
  getReportFilterKey,
  getTargetDisabledFilters,
  getTargetFilterExists,
  removeReportFilter,
  removeTargetDisabledFilter,
  upsertTargetDisabledFilters,
} from "source/redux/advanced";

type Props = {
  icon: React.ReactNode;
  filter?: FilterType;
  id: string;
  value?: string;
  title: string;
  autoApplied: boolean;
  tooltipText: string;
  showContextMenu?: boolean;
  bookmarked?: boolean;
  handleClick?: (id: string) => void;
  handleRename?: (newTitle: string, id: string) => void;
  sectionKey: string;
  searchKey: string;
};

export const AppliedRow = React.memo(
  ({
    icon,
    filter,
    id,
    value,
    title,
    autoApplied,
    tooltipText,
    showContextMenu,
    bookmarked,
    handleClick,
    handleRename,
    sectionKey,
    searchKey,
  }: Props) => {
    const dispatch = useDispatch();
    const disabledFilters = useSelector(getTargetDisabledFilters);
    const rowKey = getReportFilterKey(sectionKey, value ?? id);
    const isTarget = useSelector(getTargetFilterExists(sectionKey, rowKey));
    const isDisabled = disabledFilters.find((f) => f.value === filter?.value);
    const checked = (autoApplied && !isDisabled) || isTarget;

    const handleCheck = () => {
      if (!filter) return;
      if (checked) {
        if (autoApplied) dispatch(upsertTargetDisabledFilters([filter]));
        else dispatch(removeReportFilter({ sectionKey, rowKey }));
      } else {
        if (autoApplied) dispatch(removeTargetDisabledFilter(filter));
        else {
          // Create new report filter
          const reportFilter: ReportFilter = {
            // This key is used to identify the filter at search time
            key: searchKey,
            values: value || id,
            operation: ReportFilterOperation.IS,
          };
          dispatch(addReportFilter({ sectionKey, rowKey, reportFilter }));
        }
      }
    };

    return (
      <div
        className={`group/sidebarRow flex cursor-pointer flex-nowrap items-center justify-between gap-2 px-4 py-1 ${
          checked ? "bg-blue2 hover:bg-blue2" : "hover:bg-gray1"
        }`}
        onClick={(e: React.MouseEvent) => {
          e.stopPropagation();
          if (!handleClick) {
            handleCheck();
          } else {
            handleClick(id);
          }
        }}
      >
        <div className="flex flex-1 flex-nowrap items-center gap-2 overflow-hidden text-ellipsis whitespace-nowrap">
          <div
            onClick={(e: React.MouseEvent) => {
              e.stopPropagation();
              handleCheck();
            }}
          >
            <Checkbox checked={checked ?? false} />
          </div>
          <span className="flex items-center">{icon}</span>
          <SidebarRowInput title={title} id={id} handleRename={handleRename} />
        </div>
        <div className="invisible flex pr-1 text-checkIcon group-hover/sidebarRow:visible">
          {showContextMenu && (
            <Tooltip
              enterDelay={400}
              enterNextDelay={400}
              title="Delete, rename, and more..."
            >
              <span className="mr-0.5">
                <MenuIcon isActive={checked} />
              </span>
            </Tooltip>
          )}
        </div>
        {autoApplied && (
          <Tooltip title={tooltipText}>
            <div className="flex pr-1 text-hebbiaBlue">
              <span className="mr-0.5">
                <AutoAwesomeIcon style={{ fontSize: "16px" }} />
              </span>
            </div>
          </Tooltip>
        )}
        {bookmarked && (
          <div className="flex pr-1 text-darkGray">
            <span className="mr-0.5">
              <BookmarkSharpIcon style={{ fontSize: "16px" }} />
            </span>
          </div>
        )}
      </div>
    );
  }
);

const Checkbox = ({ checked }: { checked: boolean }) => (
  <>
    {checked ? (
      <span className="text-hebbiaBlue">
        <CheckBoxSharpIcon fontSize="small" />
      </span>
    ) : (
      <span className="text-lightGray hover:text-hebbiaBlue">
        <CheckBoxOutlineBlankSharpIcon fontSize="small" />
      </span>
    )}
  </>
);
