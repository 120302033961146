import { interpolatePath } from "d3-interpolate-path";
import React, { useCallback } from "react";
import { useSpring, animated, to } from "react-spring";

interface Props {
  startOrder?: number;
  endOrder?: number;
  initialWidth?: number;
  widthDecay?: number;
  size?: number;
  innerPadding?: number;
  rounded?: boolean;
  pause?: number;
}

export const Hilby = React.memo(
  ({
    startOrder = 2,
    endOrder = 6,
    initialWidth = 40,
    widthDecay = 0.5,
    size = 400,
    innerPadding = 3,
    rounded = true,
    pause = 350,
  }: Props) => {
    // Animate with pause at each iteration
    const toFunc = useCallback(async (next) => {
      for (let i = startOrder + 1; i <= endOrder; i++) {
        await new Promise((resolve) => setTimeout(resolve, pause));
        await next({
          x: i,
          strokeWidth: getStrokeWidth(i - startOrder),
        });
      }
      for (let i = endOrder - 1; i >= startOrder; i--) {
        await new Promise((resolve) => setTimeout(resolve, pause));
        await next({
          x: i,
          strokeWidth: getStrokeWidth(i - startOrder),
        });
      }
    }, []);

    const { x, strokeWidth } = useSpring({
      reset: false,
      from: {
        x: startOrder,
        strokeWidth: initialWidth,
      },
      to: toFunc,
      flip: false,
      loop: true,
    });

    const getStrokeWidth = (iter) => initialWidth * Math.pow(widthDecay, iter);

    const next = (s) =>
      s
        .replace(/([LR])/g, (x) => {
          switch (x) {
            case "L":
              return "+RF-LFL-FR+";
            case "R":
              return "-LF+RFR+FL-";
            default:
              return "+RF-LFL-FR+";
          }
        })
        .replace(/-\+|\+-|\+\+\+\+|----/g, "");

    const fake = (s) =>
      s
        .replace(/([LR])/gi, (x) => {
          switch (x.toUpperCase()) {
            case "L":
              return "+RF-LFL-FR+".toLowerCase();
            case "R":
              return "-LF+RFR+FL-".toLowerCase();
            default:
              return "+RF-LFL-FR+".toLowerCase();
          }
        })
        .replace(/-\+|\+-|\+\+\+\+|----/g, "");

    // Get hilbert curve rules for order
    const getIterationString = (iter) => {
      let s = "L";
      let i = 0;
      for (i = 0; i < iter; i++) s = next(s);
      for (; i < endOrder; i++) s = fake(s);
      return s;
    };

    // Get path string from hilbert curve rule
    const getPathString = (iter) => {
      const s = getIterationString(iter);
      const currentStrokeWidth = getStrokeWidth(iter);

      // Calculate padding from width of svg
      const padding = currentStrokeWidth * 2 * Math.SQRT2 + innerPadding;
      let r = "M " + padding + " " + padding;
      const segmentLength = (size - 2 * padding) / (Math.pow(2, iter) - 1);
      let b: [number, number] = [segmentLength, 0];
      let prev: [number, number] = [padding, padding];
      for (let j = 0; j < s.length; j++) {
        switch (s.charAt(j)) {
          case "+":
            b = [-b[1], b[0]];
            break;
          case "-":
            b = [b[1], -b[0]];
            break;
          case "F":
            r += " L " + (prev[0] + b[0]) + " " + (prev[1] + b[1]);
            prev = [prev[0] + b[0], prev[1] + b[1]];
            break;
          default:
            break;
        }
      }
      return r;
    };

    // Generate interpolators for all path transitions
    const interpolators = Array.from(Array(endOrder - startOrder).keys()).map(
      (i) =>
        interpolatePath(
          getPathString(startOrder + i),
          getPathString(startOrder + i + 1)
        )
    );

    // Create a main interpolator function that maps order to interpolator
    const interpolator = (input: number) => {
      // Transform order to [0, 1] value for interpolator, handle end index
      const transformedX = input !== endOrder ? input - Math.floor(input) : 1.0;
      const interpolatorIdx = Math.min(
        Math.max(Math.floor(input - startOrder), 0),
        interpolators.length - 1
      );
      return interpolators[interpolatorIdx](transformedX);
    };

    return (
      <svg id="hilbertMorph" width={size} height={size} transform="rotate(180)">
        <defs>
          <linearGradient id="0" x1="0" y1="0.5" x2="1" y2="0.5">
            <stop offset="0%" stopColor="#1f44ff" />
            <stop offset="42.86%" stopColor="#0089f3" />
            <stop offset="100%" stopColor="#297bff" />
          </linearGradient>
          <radialGradient
            id="1"
            gradientTransform="translate(-1.0 -1.0) scale(2, 2)"
          >
            <stop offset="0%" stopColor="#802bff" />
            <stop offset="100%" stopColor="rgba(190, 227, 248, 0)" />
          </radialGradient>
        </defs>
        <mask id="mask">
          <animated.path
            d={to(x, interpolator)}
            fill="none"
            stroke="white"
            strokeLinejoin={rounded ? "round" : undefined}
            strokeLinecap={rounded ? "round" : "square"}
            strokeWidth={strokeWidth}
          />
        </mask>
        <g mask="url(#mask)">
          <rect fill="url(#0)" height="100%" width="100%" />
          <rect fill="url(#1)" height="100%" width="100%" />
        </g>
      </svg>
    );
  }
);
