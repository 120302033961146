import { useState } from "react";
import { useDispatch } from "react-redux";
import { openPrettyAlert } from "source/utils/helpers";
import { removeReposById } from "source/redux/activityFeed";
import { removeActiveRepoBuild } from "source/redux/activeBuilds";
import { RepoType } from "source/Types";
import { useGlobalNavigator } from "../useSetRouter";
import { useDeleteRepoMutation } from "source/api/repos/useQueryRepos";
import { DOCUMENT_SECTION_TITLE } from "source/components/gigabar/components/FileSection";
import { getReportFilterKey, removeReportFilter } from "source/redux/advanced";

type Props = {
  repo: RepoType;
};

export const useDeleteRepo = ({ repo }: Props) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const dispatch = useDispatch();
  const { goToPage } = useGlobalNavigator();
  const { mutate: deleteRepo } = useDeleteRepoMutation();

  const handleConfirm = async () => {
    if (!repo) return;
    try {
      setIsLoading(true);
      goToPage("/chat-docs");
      const sectionKey = getReportFilterKey(DOCUMENT_SECTION_TITLE);
      const rowKey = getReportFilterKey(sectionKey, repo.id);
      dispatch(removeReportFilter({ sectionKey, rowKey }));
      dispatch(removeReposById(repo.id));
      deleteRepo(repo.id);
      dispatch(removeActiveRepoBuild(repo.id));
    } catch (e: any) {
      console.error(e);
      openPrettyAlert({
        title: "Error deleting folder",
        text: "Please contact support@hebbia.ai if the problem persists.",
        icon: "error",
        error: e,
        url: window.location.href,
      });
    } finally {
      setIsLoading(false);
    }
  };

  const handleShowConfirmAlert = () => {
    if (repo?.has_docs) {
      openPrettyAlert({
        title: "Please Confirm",
        text: `Are you sure you want to delete ${repo?.name}? This cannot be undone.`,
        icon: "question",
        showCancelButton: true,
        showDenyButton: true,
        showConfirmButton: false,
        denyButtonText: "Delete",
        cancelButtonText: "Cancel",
      }).then((res) => {
        if (res.isDenied) {
          handleConfirm();
        }
      });
    } else {
      handleConfirm();
    }
  };

  return { handleShowConfirmAlert, isLoading };
};
