import React from "react";
import { openContextMenu } from "source/utils/helpers";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";

type Props = {
  isActive?: boolean;
  resetSelectedReports?: () => void;
};

export const MenuIcon = ({ isActive, resetSelectedReports }: Props) => (
  <div
    className="cursor-pointer select-none text-center text-darkGray hover:text-bluePurple"
    onClick={(e) => {
      resetSelectedReports && resetSelectedReports();
      openContextMenu(e);
    }}
  >
    <MoreHorizIcon />
  </div>
);
