import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  ChatPerformanceType,
  CitationType,
  Message,
  TaskType,
} from "source/Types";
import { ReduxState } from ".";
import { v4 as uuidv4 } from "uuid";

export const EMPTY_ID = "00000000-0000-0000-0000-000000000000";
export type ChatReduxType = {
  messages: Message[];
  // Task objects which are references by id inside messages and citations
  tasks: TaskType[];
  // Citations stores the citations for all messages where uniqueness is (taskId, resultId)
  citations: CitationType[];
  // History stores the chat history without extra non-sense
  // only stores the required data to re-initialize the ws
  history: Message[];
  loading: boolean;
  // Handles user clicking information messsage to search it in chat
  preloadedMessage: string | null;

  // We use this to determine which result is currently selected and in which message
  selectedResultId: string | null;
  selectedMessageId: string | null;

  // These items are bundled so that they change in step together, since in the
  // useChat hook there is a useEffect that depends on either of these props changing
  sessionProps: {
    // Session id used to keep track of the current session in case of ws disconnect
    sessionId: string;
    chatPerformance: ChatPerformanceType;
  };
};

const findLastIndex = <Type>(
  arr: Type[],
  fn: (elem: Type) => boolean
): number =>
  arr
    .map((elem, idx) => ({
      idx,
      elem,
    }))
    .filter(({ elem }) => fn(elem))
    .map(({ idx }) => idx)
    .pop() ?? -1;

const initialState: ChatReduxType = {
  messages: [],
  tasks: [],
  citations: [],
  history: [],
  loading: false,
  preloadedMessage: null,
  selectedResultId: null,
  selectedMessageId: null,
  sessionProps: {
    sessionId: uuidv4(),
    chatPerformance: "accurate",
  },
};

export const getChatMessages = (state: ReduxState) => state.chat.messages;
export const getChatTasks = (state: ReduxState) => state.chat.tasks;
export const getChatCitations = (state: ReduxState) => state.chat.citations;

export const getChatHistory = (state: ReduxState) => state.chat.history;
export const getChatLoading = (state: ReduxState) => state.chat.loading;
export const getChatPreloadedMessage = (state: ReduxState) =>
  state.chat.preloadedMessage;

export const getChatSelectedResultId = (state: ReduxState) =>
  state.chat.selectedResultId;
export const getChatSelectedMessageId = (state: ReduxState) =>
  state.chat.selectedMessageId;

export const getChatPerformance = (state: ReduxState) =>
  state.chat.sessionProps.chatPerformance;

export const getSessionProps = (state: ReduxState) => state.chat.sessionProps;

const chatSlice = createSlice({
  name: "chat",
  initialState,
  reducers: {
    resetChatSession: (
      state: ChatReduxType,
      action: PayloadAction<{
        prompt?: Message;
        chatPerformanceType?: ChatPerformanceType;
      }>
    ) => {
      const oldProps = state.sessionProps;
      const { prompt, chatPerformanceType } = action.payload;
      state.messages = prompt ? [prompt] : [];
      state.tasks = [];
      state.citations = [];

      // Provide a new session id
      state.sessionProps = {
        sessionId: uuidv4(),
        chatPerformance: chatPerformanceType
          ? chatPerformanceType
          : oldProps.chatPerformance,
      };

      return state;
    },
    setAllMessages: (
      state: ChatReduxType,
      action: PayloadAction<Message[]>
    ) => {
      state.messages = action.payload;
      return state;
    },
    addMessages: (state: ChatReduxType, action: PayloadAction<Message[]>) => {
      state.messages.push(...action.payload);
      return state;
    },
    upsertMessage: (state: ChatReduxType, action: PayloadAction<Message>) => {
      const messageIdx = findLastIndex(
        state.messages,
        (message) => message.id === action.payload.id
      );
      if (messageIdx !== -1) {
        // Message already exists, so upsert it
        state.messages[messageIdx] = {
          ...state.messages[messageIdx],
          ...action.payload,
        };
      }
      // New message, so add it
      else {
        state.messages.push(action.payload);
      }
      return state;
    },
    upsertOrHijackLast: (
      state: ChatReduxType,
      action: PayloadAction<Message>
    ) => {
      const messageIdx = findLastIndex(
        state.messages,
        (message) => message.id === action.payload.id
      );

      if (messageIdx !== -1) {
        // Message already exists, so upsert it
        state.messages[messageIdx] = {
          ...state.messages[messageIdx],
          ...action.payload,
        };
      }
      // get last assistant message
      else {
        if (action.payload.role === "assistant") {
          const lastMessage = state.messages[state.messages.length - 1];
          if (lastMessage?.id === EMPTY_ID) {
            state.messages[state.messages.length - 1] = {
              ...state.messages[state.messages.length - 1],
              ...action.payload,
            };
          } else {
            state.messages.push(action.payload);
          }
        }
      }
      return state;
    },
    addTaskIdToMessage: (
      state: ChatReduxType,
      action: PayloadAction<AddTaskIdToMessageType>
    ) => {
      const messageIdx = state.messages.findIndex(
        (message) => message.id === action.payload.messageId
      );
      if (messageIdx !== -1) {
        const taskIds = state.messages[messageIdx]?.taskIds ?? [];
        const message = state.messages[messageIdx];
        if (message) message.taskIds = [...taskIds, action.payload.taskId];
      }
      return state;
    },
    setAllTasks: (state: ChatReduxType, action: PayloadAction<TaskType[]>) => {
      state.tasks = action.payload;
      return state;
    },
    addTasks: (state: ChatReduxType, action: PayloadAction<TaskType[]>) => {
      state.tasks.push(...action.payload);
      return state;
    },
    upsertTask: (state: ChatReduxType, action: PayloadAction<TaskType>) => {
      const taskIdx = state.tasks.findIndex(
        (task) => task.id === action.payload.id
      );

      if (
        taskIdx !== -1 &&
        action.payload.tool_type === state.tasks[taskIdx]?.tool_type
      ) {
        // task already exists, so upsert it
        state.tasks[taskIdx] = action.payload;
      }
      // New task, so add it
      else {
        state.tasks.push(action.payload);
      }
      return state;
    },
    setAllCitations: (
      state: ChatReduxType,
      action: PayloadAction<CitationType[]>
    ) => {
      state.citations = action.payload;
      return state;
    },
    addCitations: (
      state: ChatReduxType,
      action: PayloadAction<CitationType[]>
    ) => {
      state.citations.push(...action.payload);
      return state;
    },
    addDocCountToMessage: (
      state: ChatReduxType,
      action: PayloadAction<AddDocCountToMessageType>
    ) => {
      const messageIdx = state.messages.findIndex(
        (message) => message.id === action.payload.messageId
      );
      if (messageIdx !== -1) {
        const docCount = state.messages[messageIdx]?.docCount ?? 0;
        const message = state.messages[messageIdx];
        if (message) message.docCount = docCount + action.payload.docCount;
      }
      return state;
    },
    setChatHistory: (
      state: ChatReduxType,
      action: PayloadAction<Message[]>
    ) => {
      state.history = action.payload;
      return state;
    },
    setChatLoading: (state: ChatReduxType, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
      return state;
    },
    setChatPreloadedMessage: (
      state: ChatReduxType,
      action: PayloadAction<string | null>
    ) => {
      state.preloadedMessage = action.payload;
      return state;
    },
    setMessageOpen: (
      state: ChatReduxType,
      action: PayloadAction<MessageOpenState>
    ) => {
      const messageIdx = state.messages.findIndex(
        (message) => message.id === action.payload.messageId
      );
      if (messageIdx !== -1) {
        const message = state.messages[messageIdx];
        if (message) message.open = action.payload.open;
      }
      return state;
    },
    setChatSelectedIds: (
      state: ChatReduxType,
      action: PayloadAction<MessageResultId>
    ) => {
      state.selectedResultId = action.payload.selectedResultId;
      state.selectedMessageId = action.payload.messageId;
      return state;
    },
    setChatSessionId: (state: ChatReduxType, action: PayloadAction<string>) => {
      state.sessionProps.sessionId = action.payload;
      return state;
    },
    // Sets all states to loading false -- useful for when something goes wrong
    // and we need to relinquish control back to the uesr
    setAllChatLoadingFalse: (state: ChatReduxType) => {
      state.loading = false;
      return state;
    },
  },
});

export const {
  resetChatSession,
  setAllMessages,
  addMessages,
  upsertMessage,
  setAllTasks,
  addTasks,
  upsertTask,
  setAllCitations,
  addCitations,
  addDocCountToMessage,
  setChatHistory,
  setChatLoading,
  setChatPreloadedMessage,
  setMessageOpen,
  setChatSelectedIds,
  setChatSessionId,
  setAllChatLoadingFalse,
  addTaskIdToMessage,
  upsertOrHijackLast,
} = chatSlice.actions;
export const chatReducer = chatSlice.reducer;

type AddTaskIdToMessageType = {
  messageId: string;
  taskId: string;
};

type AddDocCountToMessageType = {
  messageId: string;
  docCount: number;
};

type MessageOpenState = {
  messageId: string;
  open: boolean;
};

type MessageResultId = {
  messageId: string;
  selectedResultId: string;
};
