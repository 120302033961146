import React, {
  ButtonHTMLAttributes,
  DetailedHTMLProps,
  forwardRef,
  ReactNode,
} from "react";
import { classNames } from "core";

export interface ToggleButtonProps
  extends DetailedHTMLProps<
    ButtonHTMLAttributes<HTMLButtonElement>,
    HTMLButtonElement
  > {
  selected?: boolean;
  children: ReactNode;
}

export interface ToggleButtonGroupProps
  extends DetailedHTMLProps<
    React.HTMLAttributes<HTMLDivElement>,
    HTMLDivElement
  > {
  children: ReactNode;
}

export const ToggleButton = forwardRef<HTMLButtonElement, ToggleButtonProps>(
  (props, ref) => {
    const { selected, children, className, ...rest } = props;

    return (
      <button
        {...rest}
        ref={ref}
        className={classNames(
          "box-content flex items-center justify-center rounded border p-1.5 hover:bg-gray-300 active:bg-opacity-60",
          selected && "border-gray-300 bg-white shadow-sm hover:bg-gray-50",
          className
        )}
      >
        {children}
      </button>
    );
  }
);

export const ToggleButtonGroup = forwardRef<
  HTMLDivElement,
  ToggleButtonGroupProps
>((props, ref) => {
  const { children, className, ...rest } = props;

  return (
    <div
      {...rest}
      ref={ref}
      role="group"
      className={classNames("flex rounded bg-gray-200", className)}
    >
      {children}
    </div>
  );
});
