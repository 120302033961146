import Image, { ImageProps } from "next/image";
import React from "react";
import DefaultIcon from "@mui/icons-material/Person";
import { classNames } from "../../index";

interface AvatarUserProps {
  name?: string;
  picture?: string;
}

export interface AvatarProps
  extends Omit<ImageProps, "alt" | "src" | "component"> {
  size?: number;
  user: AvatarUserProps;
  src?: string;
  alt?: string;
}

export const Avatar = (props: AvatarProps) => {
  const { user, size = 24, className, ...rest } = props;

  const src = user.picture || rest.src;

  if (!src) {
    return <DefaultIcon width={size} height={size} />;
  }

  return (
    <Image
      width={size}
      height={size}
      {...rest}
      alt={user.name || "Profile Picture"}
      src={src}
      className={classNames("h-6 w-6 rounded-full shadow-sm", className)}
    />
  );
};
