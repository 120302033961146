import { RepoType } from "source/Types";
import axios from "./axios";
import { FilterCompaniesResponse } from "./filter/filters";

export const Companies = {
  getMatchingNames: (searchString: string) =>
    axios.get(`/v2/companies/list/${searchString}`),
  getRecommendedRepos: (): Promise<RepoType[]> =>
    axios.get(`/v2/companies/repos/recommended`).then(({ data }) => data.repos),
  getRecommendedPitchbookRepos: (): Promise<RepoType[]> =>
    axios
      .get(`/v2/pitchbook_docs/repos/recommended`)
      .then(({ data }) => data.repos),
  batchLookup: (repoIds: string[]) =>
    axios.post(`/v2/pitchbook_docs/batch-lookup/`, { repo_ids: repoIds }),
  getCompanyById: (companyId?: number): Promise<FilterCompaniesResponse> =>
    axios.post(`/v2/filter/single-company/`, { company_id: companyId }),
};
