import { Loading } from "source/components/shared/Loading";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import posthog from "posthog-js";
import { useGetRouter } from "source/hooks/useGetRouter";
import { getUser, setUser } from "source/redux/user";
import {
  getCurrentOrg,
  getDefaultOrg,
  setCurrentOrg,
  upsertOrgs,
} from "source/redux/organization";
import { useAdminMode } from "source/hooks/useAdminMode";
import { POSTHOG_KEY, SLACK_WEBHOOK_URL } from "source/constants";
import { OrgType } from "source/Types";
import api from "source/api";
import { useQueryCurrentUser } from "source/api/users/useQueryUsers";
import { useQueryOrgs } from "source/api/orgs/useQueryOrgs";
import { useUser } from "@auth0/nextjs-auth0/client";
import { sendSlackAlert } from "source/utils/slack";
import fetchAccessToken from "./fetchAccessToken";
import { clearAuthToken, setAuthToken } from "./localStorage";
import { getLDContext } from "source/utils/ldClient";
import { LDProvider } from "launchdarkly-react-client-sdk";
import logger from "source/utils/logger";

type Props = {
  children: React.ReactNode;
};

export const ClientAuthBarrier = ({ children }: Props) => {
  const { router } = useGetRouter();

  const dispatch = useDispatch();

  const user = useSelector(getUser);
  const reduxOrg = useSelector(getCurrentOrg);

  const { prefetchHebbiaAdmin } = useAdminMode();
  const { user: auth0User, isLoading: auth0UserLoading } = useUser();

  const { data: userData, isInitialLoading: isUserInitialLoading } =
    useQueryCurrentUser();
  const { data: orgsData, isInitialLoading: isOrgsInitialLoading } =
    useQueryOrgs();

  const showLoadingShield =
    !auth0UserLoading && (isUserInitialLoading || isOrgsInitialLoading);

  // Hydrate the orgs data to Redux
  useEffect(() => {
    if (orgsData?.orgs) {
      dispatch(upsertOrgs(orgsData.orgs));
    }
  }, [orgsData, dispatch]);

  // Hydrate the user data to Redux/Posthog
  useEffect(() => {
    const currentUser = userData?.user;

    if (currentUser) {
      dispatch(setUser(currentUser));

      if (POSTHOG_KEY && process.env.NEXT_PUBLIC_VERCEL_ENV === "production") {
        posthog.identify(currentUser.id, {
          name: currentUser.name,
          email: currentUser.email,
          created_at: currentUser.created_at,
          platform_role: currentUser.platform_role,
          is_locked: currentUser.is_locked,
        });
      }
    }
  }, [userData, dispatch]);

  useEffect(() => {
    // Lets do one more call to access token to rehydrate cached token.
    fetchAccessToken().then(({ accessToken }) => {
      if (accessToken) setAuthToken(accessToken);
      else clearAuthToken();
    });
  }, [dispatch]);

  // If at any point auth0 user doesn't match hebbia user, immediately log out and send and alert
  useEffect(() => {
    if (
      user &&
      auth0User &&
      auth0User.email?.trim().toLowerCase() !== user.email.trim().toLowerCase()
    ) {
      sendSlackAlert(
        `Authentication Error. Auth0 user ${auth0User.email} with id ${auth0User.sub} does not match Hebbia User ${user.email} with id ${user.id} credentials.`,
        SLACK_WEBHOOK_URL
      );

      window.location.href = "/api/auth/logout";
    }
  }, [user, auth0User, router]);

  useEffect(() => {
    if (showLoadingShield) return;

    if (!user) {
      return;
    } else if (user.is_locked) {
      router.push("/locked", undefined, {
        shallow: true,
      });
    } else if (!(user.is_email_verified || auth0User?.email_verified)) {
      router.push("/verify", undefined, {
        shallow: true,
      });
    } else if (!isOrgsInitialLoading && orgsData && !orgsData?.orgs.length) {
      router.push("/setup", undefined, {
        shallow: true,
      });
    }
  }, [
    router,
    user,
    orgsData,
    showLoadingShield,
    auth0User?.email_verified,
    isOrgsInitialLoading,
  ]);

  // Pre-fetch admin data once we have the auth0 user
  useEffect(() => {
    if (!auth0UserLoading && auth0User) {
      prefetchHebbiaAdmin();
    }
  }, [auth0User, auth0UserLoading, prefetchHebbiaAdmin]);

  // Fetch members early
  // TODO: Move org members out of current org redux and into react-query
  useEffect(() => {
    if (reduxOrg) {
      api.orgs.getOrgMembers(reduxOrg.id).then((data) => {
        const newOrg: OrgType = {
          ...reduxOrg,
          members: data.members,
          pending_members: data.pending,
        };
        dispatch(setCurrentOrg(newOrg));
      });
      return;
    }
  }, [reduxOrg?.id, dispatch]);

  const passContext =
    (userData?.user && reduxOrg) ||
    (!isUserInitialLoading && !isOrgsInitialLoading);

  const ldContext = passContext
    ? getLDContext(
        userData?.user,
        getDefaultOrg(orgsData?.orgs ?? []) ?? reduxOrg
      )
    : undefined;

  return (
    <LDProvider
      clientSideID={process.env.NEXT_PUBLIC_LAUNCHDARKLY_SDK_CLIENT_SIDE_ID!}
      options={{ bootstrap: "localStorage", logger: logger }}
      deferInitialization={true}
      reactOptions={{ useCamelCaseFlagKeys: false }}
      context={ldContext}
      timeout={10000}
    >
      {showLoadingShield ? <Loading /> : children}
    </LDProvider>
  );
};
