import React, { forwardRef, ReactNode } from "react";
import { classNames } from "core";

export interface ModalBodyProps extends React.HTMLProps<HTMLDivElement> {
  children: ReactNode;
  className?: string;
}

const ModalBody = forwardRef<HTMLDivElement, ModalBodyProps>(
  (props: ModalBodyProps, ref) => {
    const { children, className, ...rest } = props;
    return (
      <div
        {...rest}
        className={classNames(
          "h-full w-full overflow-visible px-6 py-4",
          className
        )}
        ref={ref}
      >
        {children}
      </div>
    );
  }
);

export default ModalBody;
