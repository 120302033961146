import { LoadingRow } from "source/components/sidebar/components/LoadingRow";
import React, { useEffect, useState } from "react";
import { useQueryBookmarks } from "source/api/bookmarks/useQueryBookmarks";
import { useQuerySearchDocs } from "source/api/docs/useQueryDocs";
import { useSidebarRepos } from "source/hooks/useSidebarRepos";
import { RepoType } from "source/Types";
import { DocRow } from "../../DocRow";
import { RepoRow } from "../../RepoRow";
import { ShowMoreButton } from "./ShowMoreButton";
import { SubSectionHeader } from "./SubSections";
import { useGlobalNavigator } from "source/hooks/useSetRouter";

type Props = {
  searchValue: string;
  reposCanOpen?: boolean;
};
export const SearchedFiles = ({ searchValue, reposCanOpen }: Props) => {
  const [numDocsToShow, setNumDocsToShow] = useState(5);
  const [numFoldersToShow, setNumFoldersToShow] = useState(5);

  const bookmarksRepoIds = useQueryBookmarks().data?.repos.map(
    (repo) => repo.id
  );

  const sidebarRepos = useSidebarRepos();
  const { goToRepo } = useGlobalNavigator();

  // Perform searches
  const matchingRepos = substringMatchRepos(searchValue, sidebarRepos);

  const {
    data: searchedDocs,
    isLoading: docsAreLoading,
    isError: searchDocsError,
  } = useQuerySearchDocs(
    sidebarRepos.map((repo) => repo.id),
    searchValue
  );

  // Objects to show in the folder section
  const bookmarkRepos = matchingRepos.filter((repo) =>
    bookmarksRepoIds?.includes(repo.id)
  );
  const allOtherRepos = matchingRepos.filter(
    (repo) => !bookmarksRepoIds?.includes(repo.id)
  );
  const folders =
    searchedDocs?.docs.filter(
      (doc) => !doc.npassages || doc.npassages === null
    ) ?? [];

  const paginatedBookmarkRepos = bookmarkRepos.slice(0, numFoldersToShow);
  const paginatedAllOtherRepos = allOtherRepos.slice(
    0,
    Math.max(0, numFoldersToShow - paginatedBookmarkRepos.length)
  );
  const paginatedFolders = folders?.slice(
    0,
    numFoldersToShow -
      paginatedBookmarkRepos.length -
      paginatedAllOtherRepos.length
  );

  const noFoldersFound = !(
    bookmarkRepos.length +
    allOtherRepos.length +
    folders.length
  );

  const moreFoldersToShow =
    numFoldersToShow <
    bookmarkRepos.length + allOtherRepos.length + folders.length;

  useEffect(() => {
    setNumFoldersToShow(5);
    setNumDocsToShow(5);
  }, [searchValue]);

  // Objects to show in the docs section
  const documents = searchedDocs?.docs.filter((doc) => doc.npassages) ?? [];
  const paginatedDocuments = documents.slice(0, numDocsToShow);
  const moreDocsToShow = numDocsToShow < documents.length;
  const noDocumentsFound = !documents.length;

  const handleRepoClick = (repoId: string) => {
    goToRepo(repoId);
  };

  return (
    <div
      style={{
        minHeight: 745,
      }}
    >
      <SubSectionHeader title="Folders" />
      {paginatedBookmarkRepos.map((repo, idx) => (
        <RepoRow
          repo={repo}
          key={idx}
          bookmarked={true}
          showContextMenu={false}
          showChildren={true}
          searchValue={searchValue}
          showPathToolTip={true}
          handleRepoClick={reposCanOpen ? handleRepoClick : undefined}
        />
      ))}
      {paginatedAllOtherRepos.map((repo, idx) => (
        <RepoRow
          repo={repo}
          key={idx + bookmarkRepos.length}
          bookmarked={false}
          showContextMenu={false}
          showChildren={true}
          searchValue={searchValue}
          showPathToolTip={true}
          handleRepoClick={reposCanOpen ? handleRepoClick : undefined}
        />
      ))}
      {paginatedFolders.map((doc, idx) => (
        <DocRow
          doc={doc}
          key={sidebarRepos.length + idx}
          idx={sidebarRepos.length + idx}
          depth={0}
          showChildren={true}
          showPathToolTip={true}
          searchValue={searchValue}
        />
      ))}
      {docsAreLoading && <LoadingRow depth={0} />}
      {!docsAreLoading && noFoldersFound && (
        <div className="px-4 text-xs">No matching folders</div>
      )}
      {moreFoldersToShow && (
        <ShowMoreButton
          handleClick={() => setNumFoldersToShow(numFoldersToShow + 10)}
        />
      )}
      <SubSectionHeader title="Documents" />
      {paginatedDocuments?.map((doc, idx) => (
        <DocRow
          doc={doc}
          key={idx}
          idx={idx}
          depth={0}
          showChildren={true}
          showPathToolTip={true}
          searchValue={searchValue}
        />
      ))}
      {docsAreLoading && <LoadingRow depth={0} />}
      {!docsAreLoading && noDocumentsFound && (
        <div className="px-4 text-xs">No matching documents</div>
      )}
      {moreDocsToShow && (
        <ShowMoreButton
          handleClick={() => setNumDocsToShow(numDocsToShow + 10)}
        />
      )}
    </div>
  );
};

const substringMatchRepos = (query: string, repos: RepoType[]): RepoType[] => {
  const regex = new RegExp(query.replace(/[.*+?^${}()|[\]\\]/g, "\\$&"), "i");
  return repos.filter((repo) => regex.test(repo.name));
};
