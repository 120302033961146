import axios from "./axios";
import {
  SharepointAncestorsParams,
  SharepointAncestorsResponse,
  SharePointChildrenMetadataFilterParams,
  SharePointSearchResponse,
  SharePointAccessByIdsParams,
  SharePointAccessByIdsResponse,
  SharePointSearchParams,
  CrawlSharePointFolderParams,
  FastBuildChildrenParams,
  FastBuildIngestParams,
  FastBuildIngestResponse,
} from "source/types/matrix/fastBuild.types";
import { IngestFilesResponse } from "source/components/matrix/types/reports.types";
import {
  FastBuildSearchFilterMap,
  SearchType,
  SourceType,
} from "source/components/matrix/menu/AddDocumentsModal/shared/types";

export const FastBuild = {
  getChildren: <Identifier, AuthContext>(
    params: FastBuildChildrenParams<Identifier, AuthContext>
  ) =>
    axios
      .post(`/sheets/fastbuild/children`, { ...params })
      .then(({ data }) => data),
  ingestDocs: <Identifier, AuthContext>(
    params: FastBuildIngestParams<Identifier, AuthContext>
  ): Promise<FastBuildIngestResponse> =>
    axios
      .post(`/sheets/fastbuild/ingest-docs`, { ...params })
      .then(({ data }) => data),
  searchSharePoint: (
    params: SharePointSearchParams
  ): Promise<SharePointSearchResponse> =>
    axios
      .post(
        `/sheets/build/search-sharepoint-fastbuild-v2`,
        { folder_path: params.folder_path, mime_type: params.mime_type },
        { params: { org_id: params.org_id, query: params.query } }
      )
      .then(({ data }) => data),
  getSharePointMetadataFilter: (
    params: SharePointChildrenMetadataFilterParams
  ): Promise<SharePointSearchResponse> =>
    axios
      .get(`/sheets/build/metadata-filter-fastbuild-v2`, { params })
      .then(({ data }) => data),
  // Sharepoint Access
  fetchSharePointAccess: (
    params: SharePointAccessByIdsParams
  ): Promise<SharePointAccessByIdsResponse> =>
    axios
      .get(`/sheets/build/fetch-sharepoint-access-by-ids`, { params })
      .then(({ data }) => data),

  // Sharepoint Crawl
  crawlSharePointFolder: async (
    params: CrawlSharePointFolderParams
  ): Promise<IngestFilesResponse> =>
    axios
      .post(`/sheets/build/crawl-sharepoint-folder`, params)
      .then(({ data }) => data),

  getAncestors: (
    params?: SharepointAncestorsParams,
    orgId?: string
  ): Promise<SharepointAncestorsResponse> =>
    axios
      .post(`/sheets/build/ancestors`, params, { params: { org_id: orgId } })
      .then(({ data }) => data),

  search: async (
    query: string,
    searchType: SearchType,
    integration: SourceType,
    orgId: string,
    filters?: FastBuildSearchFilterMap
  ) => {
    const { data } = await axios.post("/sheets/fastbuild/search", {
      query: query,
      search_type: searchType,
      filters: filters,
      integration: integration,
      org_id: orgId,
    });

    return data;
  },

  getThirdBridgePrefixSearch: async (prefix: string) => {
    const { data } = await axios.get(
      `/sheets/fastbuild/third-bridge-prefix-search/${prefix}`
    );
    return data;
  },
};
