import LogRocket from "logrocket";
import { datadogLogs, StatusType } from "@datadog/browser-logs";

const _log = (
  message: string,
  context?: Record<string, unknown>,
  status?: StatusType
) => {
  datadogLogs.logger.log(
    message,
    { ...context, lr_session: LogRocket.sessionURL },
    status
  );

  if (status === "debug") LogRocket.debug(message, context ?? {});
  else if (status === "info") LogRocket.info(message, context ?? {});
  else if (status === "warn") LogRocket.warn(message, context ?? {});
  else if (status === "error") LogRocket.error(message, context ?? {});
};

export default {
  log: (
    message: string,
    context?: Record<string, unknown>,
    status?: StatusType
  ) => _log(message, context, status),
  debug: (message: string, context?: Record<string, unknown>) =>
    _log(message, context, "debug"),
  info: (message: string, context?: Record<string, unknown>) =>
    _log(message, context, "info"),
  warn: (message: string, context?: Record<string, unknown>) =>
    _log(message, context, "warn"),
  error: (message: string, context?: Record<string, unknown>) => {
    _log(message, context, "error");
    console.error(message, context);
  },
};
