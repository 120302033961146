import axios from "../axios";
import { SynonymCreateParams, SynonymType } from "source/Types";
import { CreateSynonymPayload } from "./useQuerySynonyms";

export const Synonyms = {
  getSynonyms: (orgId: string, repoId?: string): Promise<SynonymType[]> =>
    axios
      .get(`/v2/synonyms`, {
        params: { org_id: orgId, repo_id: repoId },
      })
      .then(({ data: { synonyms } }) => synonyms),
  createSynonym: (
    synonym: Partial<CreateSynonymPayload>
  ): Promise<SynonymType> =>
    axios
      .post(`/v2/synonyms`, synonym)
      .then(({ data: { synonyms } }) => synonyms),
  updateSynonym: (
    id: string,
    synonym: Partial<SynonymCreateParams>
  ): Promise<SynonymType> =>
    axios
      .put(`/v2/synonyms/${id}`, synonym)
      .then(({ data: { synonym } }) => synonym),
  deleteSynonym: (id: string) => axios.delete(`/v2/synonyms/${id}`),
};
