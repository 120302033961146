import { IDocumentMime, IDocumentType } from "source/Types";

// Maps document type/mime to google Icon names
export const documentTypeToIconName = (
  docMime?: IDocumentMime,
  docType?: IDocumentType
) => {
  switch (docType) {
    case "earnings":
      return "call";
    case "web":
      return "language";
  }
  switch (docMime) {
    case "application/vnd.google-apps.folder":
    case "hebbia/local.folder":
    case "hebbia/onedrive.folder":
    case "hebbia/folder":
    case "hebbia/dropbox.folder":
    case "hebbia/findox.folder":
    case "hebbia/findox.holding":
    case "hebbia/box.folder":
    case "hebbia/tegus.network":
    case "hebbia/sharepoint.site":
    case "hebbia/s3.folder":
    case "hebbia/finnhub_filing":
      return "folder";
    case "application/vnd.ms-excel": // XLS
    case "application/vnd.ms-excel.sheet.binary.macroEnabled.12": // XLSB
    case "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": // XLSX
      return "backup_table";
    case "hebbia/onedrive":
    case "hebbia/onedrive.shared":
      return "description";
    case "application/rss+xml":
    case "application/xml":
    case "text/xml":
      return "rss_feed";
    case "text/html":
      return "public";
    case "image/jpeg":
    case "image/png":
      return "image";
  }
  return "description";
};
