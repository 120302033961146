import React from "react";
import { FilterSection } from "./FilterSection";
import { buildFilterMap } from "source/components/gigabar/components/FilterSection/utils";
import { useSelector } from "react-redux";
import { OrgType } from "source/Types";
import { useQueryOrgFilters } from "source/api/filter/useQueryFilters";
import { getCurrentOrg } from "source/redux/organization";

/** Renders a list of filter sections */
export const FiltersSections = () => (
  <div className="flex flex-col">
    <MimeFilters />
  </div>
);

/** Mime filters is separate one off component for hard-coded mime-filters and earnings/sec.
 * Separate component so repos changing doesn't necessarily re-render all of the other filter sections
 */
const MimeFilters = () => {
  const currentOrg: OrgType | null = useSelector(getCurrentOrg);

  const orgFilters = useQueryOrgFilters(currentOrg?.id);
  const filterMap = buildFilterMap(orgFilters.data);

  return (
    <FilterSection filterMap={filterMap} title={MIME_TYPE_SECTION_TITLE} />
  );
};

export const MIME_TYPE_SECTION_TITLE = "Type";
