import { Message } from "source/Types";
import axios from "../axios";

export const Chat = {
  chat: (messages: Record<string, string>[]): Promise<Message[]> =>
    axios
      .post(`/v2/chat`, {
        messages: messages,
      })
      .then(({ data }) => data.messages),
};
