import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { SectionSearchState } from "source/components/sidebar/multitoggle/SidebarToggleUtils";
import { isMobile } from "react-device-detect";
import { ReduxState } from ".";

export type SidebarReduxType = {
  visible: boolean;
  userToggled: boolean;
  visibleConfig: boolean;
  rowsOpen: { [id: string]: boolean };
  toggleSectionStates: Record<string, boolean>;
  /**
   * mapping from section titles to their internal search state.
   * useful for keeping LHS UI state constant across /docs and /repos.
   */
  sectionSearchStates: Record<string, SectionSearchState>;
  dateSelected: number | null;
};

const initialState: SidebarReduxType = {
  visible: !isMobile,
  userToggled: false,
  visibleConfig: !isMobile,
  dateSelected: null,
  rowsOpen: {},
  toggleSectionStates: {},
  sectionSearchStates: {},
};

type DateFilterArgs = {
  sectionTitle: string;
  isExpanded: boolean;
  index: number;
};

export const getSidebarVisible = (state: ReduxState) => state.sidebar.visible;
export const getUserToggledSidebar = (state: ReduxState) =>
  state.sidebar.userToggled;
export const getSidebarRowsOpen = (state: ReduxState) => state.sidebar.rowsOpen;
export const getSectionSearchStates = (state: ReduxState) =>
  state.sidebar.sectionSearchStates;
export const getToggleSectionStates = (state: ReduxState) =>
  state.sidebar.toggleSectionStates;

const sidebarSlice = createSlice({
  name: "sidebar",
  initialState,
  reducers: {
    toggleSidebar: (state) => {
      state.visibleConfig = !state.visibleConfig;
      state.visible = state.visibleConfig;
    },
    setSidebarVisible: (state, action: PayloadAction<boolean>) => {
      state.visible = action.payload;
      return state;
    },
    setUserToggledSidebar: (state, action: PayloadAction<boolean>) => {
      state.userToggled = action.payload;
      return state;
    },
    resetSidebar: (state) => {
      state.visible = state.visibleConfig;
      return state;
    },
    upsertSidebarRowsOpen: (
      state,
      action: PayloadAction<{ [id: string]: boolean }>
    ) => {
      state.rowsOpen = { ...state.rowsOpen, ...action.payload };
      return state;
    },
    setSidebarRowsClosed: (state, action: PayloadAction<string[]>) => {
      action.payload.forEach((id) => {
        state.rowsOpen[id] = false;
      });
    },
    setToggleSectionState: (
      state,
      action: PayloadAction<{ sectionTitle: string; isExpanded: boolean }>
    ) => {
      state.toggleSectionStates[action.payload.sectionTitle] =
        action.payload.isExpanded;
    },
    setDateFilter: (state, action: PayloadAction<DateFilterArgs>) => {
      state.toggleSectionStates[action.payload.sectionTitle] =
        action.payload.isExpanded;
      state.dateSelected = action.payload.index;
    },
    clearAllSearchStates: (state) => {
      state.sectionSearchStates = {};
    },
    setSectionSearchValue: (
      state,
      action: PayloadAction<{ sectionTitle: string; searchValue: string }>
    ) => {
      if (
        state.sectionSearchStates[action.payload.sectionTitle] === undefined
      ) {
        state.sectionSearchStates[action.payload.sectionTitle] = {
          result: {},
          value: "",
          isLoading: false,
        };
      }
      const search = state.sectionSearchStates[action.payload.sectionTitle];
      if (search) search.value = action.payload.searchValue;
    },
    setSectionSearchResult: (
      state,
      action: PayloadAction<{
        sectionTitle: string;
        resultUpdate: Record<string, any>;
      }>
    ) => {
      if (
        state.sectionSearchStates[action.payload.sectionTitle] === undefined
      ) {
        state.sectionSearchStates[action.payload.sectionTitle] = {
          result: {},
          value: "",
          isLoading: false,
        };
      }

      Object.keys(action.payload.resultUpdate).map((resultKey: string) => {
        const search = state.sectionSearchStates[action.payload.sectionTitle];
        if (search)
          search.result[resultKey] = action.payload.resultUpdate[resultKey];
      });
    },
    setDateSelected: (state, action: PayloadAction<number | null>) => {
      state.dateSelected = action.payload;
    },
  },
});

export const {
  toggleSidebar,
  setSidebarVisible,
  setUserToggledSidebar,
  resetSidebar,
  upsertSidebarRowsOpen,
  setSidebarRowsClosed,
  setToggleSectionState,
  clearAllSearchStates,
  setSectionSearchValue,
  setSectionSearchResult,
  setDateSelected,
  setDateFilter,
} = sidebarSlice.actions;
export const sidebarReducer = sidebarSlice.reducer;
