import axios from "../axios";
import { ViewConfig } from "source/components/matrix/types/reports.types";

// Upserts happen via /upsert-view-config websocket event
export type UpsertViewConfigPayload = {
  matrixId: string;
  viewConfig: ViewConfig;
};

export const ViewConfigs = {
  getViewConfig: (
    matrixId: string,
    viewConfigId: string
  ): Promise<ViewConfig> =>
    axios
      .get(
        `/sheets/view-config?sheet_id=${matrixId}&view_config_id=${viewConfigId}`
      )
      .then(({ data }) => data),
};
