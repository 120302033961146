import { useMemo } from "react";
import { v4 as UUID } from "uuid";

// certain components require id's to be set to work correctly
// this hook allows us to either use an id (if one is passed in),
// or otherwise generate a random new one. It uses useMemo so the id
// doesn't change on rerender
export const useComponentId = (
  id?: string | undefined | null,
  prefix?: string
) => {
  return useMemo(
    () => id || `${prefix ? `${prefix}_` : ""}${UUID().split("-")[0]}`,
    [id, prefix]
  );
};
