import _ from "lodash";
import { DocumentType, ResultType, ReportType } from "source/Types";
import { marked } from "marked";
import { isMobile } from "react-device-detect";
import Swal, { SweetAlertIcon } from "sweetalert2";
import tinycolor from "tinycolor2";
import {
  MATCH_THRESHOLD,
  STRONG_MATCH_THRESHOLD,
  SLACK_WEBHOOK_URL,
  MAX_LINES,
} from "../constants";
import { isProd } from "../envConstants";
import { TabType } from "source/components/tab-bar/tabs/Tabs";
import { STOPWORDS } from "source/constants/strings";

marked.setOptions({
  renderer: new marked.Renderer(),
  pedantic: false,
  gfm: true,
  breaks: false,
  sanitize: false,
  smartypants: false,
  xhtml: false,
  langPrefix: "code-highlight language-", // This is really stupid but I don't care enought to make it not stupid for now
});

export const openPrettyAlert = ({
  title,
  text,
  icon,
  showConfirmButton = true,
  showCancelButton,
  showDenyButton,
  denyButtonText,
  confirmButtonText = icon === "error" ? "Notify Hebbia Support" : "Ok",
  cancelButtonText,
  allowOutsideClick = true,
  width,
  error,
  url,
  imageUrl,
  imageWidth,
  imageHeight,
  imageAlt,
  html,
}: {
  title?: string;
  text?: string;
  icon?: SweetAlertIcon;
  showConfirmButton?: boolean;
  showCancelButton?: boolean;
  showDenyButton?: boolean;
  confirmButtonText?: string;
  cancelButtonText?: string;
  denyButtonText?: string;
  allowOutsideClick?: boolean;
  width?: number;
  error?: any;
  url?: string;
  imageUrl?: string;
  imageWidth?: number | string;
  imageHeight?: number | string;
  imageAlt?: string;
  html?: string;
}) => {
  // Send error notification if prod (will cause CORS issues on localhost)
  if (icon === "error" && error && isProd) {
    const today = new Date();
    let message = `Date: ${
      today.getFullYear() + "-" + (today.getMonth() + 1) + "-" + today.getDate()
    }  `;
    message =
      message +
      `Time: ${
        today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds()
      }\n\n`;
    if (typeof error === "string") message = message + error;
    else {
      const split_stack: string[] = error.stack.split("\n");
      const num_lines = Math.min(split_stack.length, MAX_LINES);
      for (let step = 0; step < num_lines; step++) {
        message = message + split_stack[step];
      }
    }
    if (url) {
      message = message + "\n\n-----------------------\n\nAt URL: " + url;
    }
    fetch(SLACK_WEBHOOK_URL, {
      method: "POST",
      mode: "no-cors",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
      body: JSON.stringify({
        text: message,
      }),
    });
  }
  return Swal.fire({
    title,
    text,
    html,
    icon: icon === "error" ? undefined : icon,
    showConfirmButton,
    confirmButtonColor: "#0576FF",
    showCancelButton,
    confirmButtonText,
    cancelButtonText,
    showDenyButton,
    denyButtonText,
    allowOutsideClick,
    imageUrl,
    imageWidth,
    imageHeight,
    imageAlt,
    customClass: {
      popup: "swal",
    },
    heightAuto: true,
    backdrop: "rgba(0 0 0 / 40%)",
    ...(!isMobile && { width: width || 600 }),
  });
};

export const isSECRoot = (doc: DocumentType) =>
  doc.mime === "hebbia/sec" && doc.num_children === 0;

export const removeStopwords = (text: string): string =>
  text
    .toLowerCase()
    .split(" ")
    .map((word) =>
      word.replaceAll(".", "").replaceAll("?", "").replaceAll(",", "")
    )
    .filter((word_clean) => !STOPWORDS.includes(word_clean))
    .join(" ");

export const areEqual = <T>(obj1: Record<string, T>, obj2: Record<string, T>) =>
  _.isEqual(obj1, obj2);

export const openContextMenu = (
  e: React.MouseEvent<HTMLSpanElement, MouseEvent>,
  yOffset = 0
) => {
  e.stopPropagation();
  // Convert to "right click to prompt menu"
  const ev3 = new MouseEvent("contextmenu", {
    bubbles: true,
    cancelable: false,
    view: window,
    button: 2,
    buttons: 0,
    clientX: (e.target as any).getBoundingClientRect().x,
    clientY: (e.target as any).getBoundingClientRect().y + yOffset,
  });
  e.target.dispatchEvent(ev3);
};

// https://github.com/microsoft/monaco-editor/issues/2427
export const getComputedColor = (v: string) =>
  tinycolor(
    window.getComputedStyle(document.documentElement).getPropertyValue(v).trim()
  ).toHexString();

export const sortReports = (a: ReportType, b: ReportType) => {
  if (a.created_at && b.created_at)
    return b.created_at.localeCompare(a.created_at);
  if (a.created_at) return -1;
  else return 1;
};

// TODO: Hacky will update after poc
// Returns the base query of a question formatted as `query Ex. example`
export const getBaseQuestion = (question: string) =>
  question.split("Ex.")[0]?.trim();

export const scrollTo = (
  selector,
  yOffset = 0,
  behavior: ScrollBehavior = "smooth"
) => {
  const el = document.getElementById(selector);
  if (el) {
    const y = el.getBoundingClientRect().top + window.pageYOffset + yOffset;
    window.scrollTo({ top: y, behavior });
  }
};

export const isMacintosh = () => navigator.platform.indexOf("Mac") > -1;

export const getRelevance = (relevance: number) => {
  if (relevance >= STRONG_MATCH_THRESHOLD) return "Very Similar";
  if (relevance >= MATCH_THRESHOLD) return "Similar";
  return "Potentially Similar";
};

// TODO: make this less recursive
const deIndentListGroup = (bodyListMdLines: string[]) => {
  // Recursively de-indent the bulleted list while there are still indented lines
  // prior to the minimally indented line in the list.
  // Example:
  //     * bullet 1
  //   * bullet 2
  // * bullet 3
  //
  // will change to:
  //   * bullet 1
  // * bullet 2
  // * bullet 3
  //
  // and then:
  // * bullet 1
  // * bullet 2
  // * bullet 3
  //
  // This is done because the marked library cannot accurately parse a list if it
  // begins with a nested bullet item.
  let minimallyIndentedLineIndex = bodyListMdLines.length;

  // Equivalent to while true, but the linter likes this better.
  for (;;) {
    // If the first line has no indentation, then we can deduce all following lines
    // have already been maximally decremented, so break.
    const firstElemLeadingSpaces = bodyListMdLines[0]?.search(/\S/);
    if (firstElemLeadingSpaces === 0) {
      break;
    }

    // Finds the index and number of leading whitespaces of the element in the bodyListMd
    // in range [0, minimallyIndentedLineIndex) that is the least indented
    const bodyListMdLinesSlice = bodyListMdLines.slice(
      0,
      minimallyIndentedLineIndex
    );
    const { currMinLeadingSpaces, currMinIndentedIndex } =
      bodyListMdLinesSlice.reduce(
        (pair, mdLine, idx) => {
          // Finds the index of first non-whitespace char (== num leading whitespaces)
          const numLeadingSpaces = mdLine.search(/\S/);

          // Update if it's the new min
          if (numLeadingSpaces < pair.currMinLeadingSpaces) {
            pair.currMinLeadingSpaces = numLeadingSpaces;
            pair.currMinIndentedIndex = idx;
          }
          return pair;
        },
        {
          currMinLeadingSpaces: bodyListMdLines[0]?.search(/\S/) ?? 0,
          currMinIndentedIndex: 0,
        }
      );

    // Decrement all lines in range [0, minimallyIndentedLineIndex) by the
    // currMinLeadingSpaces.
    const whitespace = " ".repeat(currMinLeadingSpaces ?? 0);
    bodyListMdLines = bodyListMdLines.map((mdLinesElem, idx) => {
      if (idx < minimallyIndentedLineIndex)
        return mdLinesElem.replaceAll(`${whitespace}*`, "*");

      return mdLinesElem;
    });

    // Update the minimally indented line number
    minimallyIndentedLineIndex = currMinIndentedIndex;
  }

  return bodyListMdLines;
};

// Returns a list of lists
export const splitPassageIntoListGroups = (bodyMd: string) => {
  const bodyMdLines = bodyMd.split("\n");
  const listLineRegex = /^[ ]*\*/g;

  const passageGroups: string[][] = [];
  const listGroupIdxSet = new Set<number>();

  let currPassageGroup: string[] = [];
  let currentlyInList = false;
  for (let i = 0; i < bodyMdLines.length; i++) {
    // Check if the current line is a list item by checking if the first non-whitespace
    // char is an asterisk
    const found = !!bodyMdLines[i]?.match(listLineRegex);
    if (found !== currentlyInList) {
      if (currPassageGroup.length > 0) {
        passageGroups.push(currPassageGroup);
        // Add the idx of the newly added group if it was a list group
        if (currentlyInList) {
          listGroupIdxSet.add(passageGroups.length - 1);
        }
      }

      currentlyInList = !currentlyInList;
      currPassageGroup = [bodyMdLines[i]!];
    } else {
      currPassageGroup.push(bodyMdLines[i]!);
    }
  }

  // Catch the last passage at the end
  if (currPassageGroup.length > 0) {
    passageGroups.push(currPassageGroup);
    if (currentlyInList) {
      listGroupIdxSet.add(passageGroups.length - 1);
    }
  }

  return { passageGroups, listGroupIdxSet };
};

export const rejoinListGroupsIntoPassage = (listGroups: string[][]) => {
  const passageGroups = listGroups.map((group: string[]) => group.join("\n"));
  return passageGroups.join("\n");
};

// markedWithPreprocessing does some preprocessing on the given bodyMd
// markdown string before trying to convert the markdown into HTML for rendering
// todo - replace marked with MarkdownIt to ensure our md processing is consistent across the app
export const markedWithPreprocessing = (bodyMd: string) => {
  // replace weird  characters that are most likely bullet points
  bodyMd = bodyMd.replaceAll("\uf06e", "•");
  bodyMd = bodyMd.replaceAll("\uf06f", "•");

  const { passageGroups, listGroupIdxSet } = splitPassageIntoListGroups(bodyMd);
  if (listGroupIdxSet.size === 0) {
    return marked(bodyMd);
  }

  // If there are some lists in the passage, de-indent them so marked library
  // can properly handle them.
  const deIndentedPassageGroups = passageGroups.map((passageGroup, idx) => {
    if (listGroupIdxSet.has(idx)) return deIndentListGroup(passageGroup);

    return passageGroup;
  });

  const modifiedBodyMd = rejoinListGroupsIntoPassage(deIndentedPassageGroups);
  return marked(modifiedBodyMd);
};

// get count of very similar and similar results
export const getNumSimilarResults = (results: ResultType[]) => {
  const numVerySimilar = results.filter(
    (r: ResultType) => r.smoothed_absolute_relevance >= STRONG_MATCH_THRESHOLD
  ).length;
  const numSimilar = results.filter(
    (r: ResultType) => r.smoothed_absolute_relevance >= MATCH_THRESHOLD
  ).length;

  return { numVerySimilar, numSimilar };
};

/** Gets the current page for tabs */
export const getCurrentPage = (pathname: string): TabType => {
  if (pathname.includes("/chat-docs")) return "ChatDocs";
  else if (pathname.includes("/chat")) return "Chat";
  else if (pathname.includes("/matrix")) return "Matrix";
  else if (pathname.includes("/browse")) return "Browse";
  return "Search";
};
