import React from "react";
import { RepoIcon } from "source/components/shared/Icons/RepoIcon";
import { Tooltip } from "source/components/shared/Tooltip";
import ActivityIndicator from "react-loader-spinner";
import { useQueryFilterSpecificRepos } from "source/api/repos/useQueryRepos";
import { KeyValuePair } from "source/Types";

type FilterChipProps = {
  keyValuePair: KeyValuePair;
  checkbox?: React.ReactNode;
  isLastMessage?: boolean;
};

export const CompanyFilterChip = ({
  keyValuePair,
  checkbox,
}: FilterChipProps) => {
  const { data: companyFilters, isLoading } = useQueryFilterSpecificRepos([
    keyValuePair.value,
  ]);

  if (isLoading)
    return (
      <ActivityIndicator
        type="TailSpin"
        color="var(--text-light)"
        height={18}
        width={18}
      />
    );

  if (!companyFilters || companyFilters.length !== 1) return <></>;
  return (
    <Tooltip title="Company" enterDelay={500}>
      <div className="flex flex-1 flex-nowrap items-center gap-2 bg-card px-2.5 py-2 text-center text-sm font-medium">
        {checkbox}
        <RepoIcon repo={companyFilters[0]} fontSize="small" />
        <span>{companyFilters[0]?.name}</span>
      </div>
    </Tooltip>
  );
};
