import { COMPANY_SECTION_TITLE } from "source/components/gigabar/components/CompanySection";
import { DOCUMENT_SECTION_TITLE } from "source/components/gigabar/components/FileSection";
import { MIME_TYPE_SECTION_TITLE } from "source/components/gigabar/components/FiltersSections";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  KeyValuePair,
  ReportFilter,
  ReportFilterOperation,
} from "source/Types";
import { FiltersConfig } from "source/constants/filters";
import {
  addReportFilter,
  getReportFilter,
  removeReportFilter,
} from "source/redux/advanced";
import { TargetFilterChip } from "./TargetFilterChip";

export const FILTER_KEY_SECTION_TITLE_MAP = {
  [FiltersConfig.TICKER_FILTER_KEY]: COMPANY_SECTION_TITLE,
  [FiltersConfig.REPO_ID_FILTER_KEY]: DOCUMENT_SECTION_TITLE,
  [FiltersConfig.DOC_ID_FILTER_KEY]: DOCUMENT_SECTION_TITLE,
  [FiltersConfig.MIME_FILTER_KEY]: MIME_TYPE_SECTION_TITLE,
  [FiltersConfig.INTEGRATION_FILTER_KEY]: MIME_TYPE_SECTION_TITLE,
};

type SelectedTargetFilterChipProps = {
  keyValuePair: KeyValuePair;
  disableCheckbox: boolean;
};

/* Filter chip component for user-selected targets filters */
export const SelectedTargetFilterChip = ({
  keyValuePair,
  disableCheckbox = false,
}: SelectedTargetFilterChipProps) => {
  const dispatch = useDispatch();
  const reportFilter = useSelector(getReportFilter);

  const reportFilterKeys: Record<string, string>[] = [];

  // assuming 2 levels, extract the values for each key
  const checkedDict: { [key: string]: Set<string | number | boolean> } = {};
  const keyedGroups = reportFilter.values as Record<string, ReportFilter>;
  Object.entries(keyedGroups).forEach(([sectionKey, group]) => {
    const keyedRules = group.values as Record<string, ReportFilter>;
    Object.entries(keyedRules).forEach(([rowKey, rule]) => {
      if (rule.key) {
        if (
          rule.key === keyValuePair.key &&
          rule.values === keyValuePair.value
        ) {
          reportFilterKeys.push({ sectionKey, rowKey });
        }
        if (rule.key in checkedDict) {
          checkedDict[rule.key]?.add(rule.values as string | number | boolean);
        } else {
          checkedDict[rule.key] = new Set([
            rule.values as string | number | boolean,
          ]);
        }
      }
    });
  });

  let isChecked = false;
  if (keyValuePair.key in checkedDict) {
    isChecked =
      checkedDict?.[keyValuePair.key]?.has(keyValuePair.value) ?? false;
  }

  // we remove from entire report structure whereever there is key
  // we add to section filters if checked
  const handleCheck = () => {
    if (disableCheckbox) return;
    if (isChecked) {
      reportFilterKeys.forEach(({ sectionKey, rowKey }) => {
        if (sectionKey && rowKey)
          dispatch(removeReportFilter({ sectionKey, rowKey }));
      });
    } else {
      const sectionKey = FILTER_KEY_SECTION_TITLE_MAP[keyValuePair.key];
      if (sectionKey)
        dispatch(
          addReportFilter({
            sectionKey,
            reportFilter: {
              key: keyValuePair.key,
              values: keyValuePair.value,
              operation: ReportFilterOperation.IS,
            },
          })
        );
    }
  };
  return (
    <TargetFilterChip
      keyValuePair={keyValuePair}
      disableCheckbox={disableCheckbox}
      isChecked={isChecked}
      handleCheck={handleCheck}
    />
  );
};
