import { classNames } from "core";
import React from "react";

type Props = {
  value?: string;
  searchable: string;
  classOverride?: string;
  setSearchValue: (value: string) => void;
};

/** A dumb component for the sidebar search bars */
export const SearchBar = ({
  value,
  searchable,
  classOverride,
  setSearchValue,
}: Props) => (
  <input
    type="text"
    value={value}
    placeholder={`Search for ${putAn(searchable)}`}
    className={classNames(
      "mx-2.5 mb-2 mt-1 h-7 rounded-none border bg-white/80 p-1 px-2 text-xs shadow-sm focus:border-blue-500 focus:outline-none focus:ring-1 focus:ring-blue-500",
      classOverride
    )}
    onChange={(e) => setSearchValue(e.target.value)}
  />
);

// FML lmao
// helper function that given a string puts a/an before it if it starts with a vowel
export const putAn = (str: string) => {
  if (!str[0]) return "";
  const vowels = ["a", "e", "i", "o", "u"];
  return vowels.includes(str[0].toLowerCase()) ? `an ${str}` : `a ${str}`;
};
