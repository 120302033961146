import { useDispatch } from "react-redux";
import { openPrettyAlert } from "source/utils/helpers";
import { removeReposById } from "source/redux/activityFeed";
import { removeActiveRepoBuild } from "source/redux/activeBuilds";
import { RepoType, UserType } from "source/Types";
import { useGlobalNavigator } from "../useSetRouter";
import { useGetRouter } from "source/hooks/useGetRouter";
import { useRemoveRepoMemberMutation } from "source/api/repos/useQueryRepos";
import { DOCUMENT_SECTION_TITLE } from "source/components/gigabar/components/FileSection";
import { getReportFilterKey, removeReportFilter } from "source/redux/advanced";

type Props = {
  repo: RepoType;
  user: UserType | null;
};

export const useRemoveRepoMember = ({ repo, user }: Props) => {
  const dispatch = useDispatch();
  const { goToPage } = useGlobalNavigator();
  const { router } = useGetRouter();
  const { mutate: removeRepoMember } = useRemoveRepoMemberMutation();

  const handleConfirm = async () => {
    if (!repo || !user) return;
    try {
      if (!router.pathname.includes("/search")) {
        goToPage("/search");
      }
      const sectionKey = getReportFilterKey(DOCUMENT_SECTION_TITLE);
      const rowKey = getReportFilterKey(sectionKey, repo.id);
      dispatch(removeReportFilter({ sectionKey, rowKey }));
      dispatch(removeReposById(repo.id));
      removeRepoMember({ repoId: repo.id, userId: user.id });
      dispatch(removeActiveRepoBuild(repo.id));
    } catch (e) {
      console.error(e);
      openPrettyAlert({
        title: "Error removing folder",
        text: "Please contact support@hebbia.ai if the problem persists.",
        icon: "error",
        error: e,
        url: window.location.href,
      });
    }
  };

  const handleShowConfirmAlert = () => {
    if (repo?.has_docs) {
      openPrettyAlert({
        title: "Please Confirm",
        text: `Are you sure you want to remove ${repo?.name} from your shared list? Collaborators will still be able to view.`,
        icon: "question",
        showCancelButton: true,
        showDenyButton: true,
        showConfirmButton: false,
        denyButtonText: "Remove",
        cancelButtonText: "Cancel",
      }).then((res) => {
        if (res.isDenied) {
          handleConfirm();
        }
      });
    } else {
      handleConfirm();
    }
  };

  return { handleShowConfirmAlert };
};
