import React from "react";

type SubSectionHeaderProps = {
  title: string;
  badge?: React.ReactNode;
};

export const SubSectionHeader = ({ title, badge }: SubSectionHeaderProps) => (
  <div className="m-2 w-fit cursor-default gap-2 px-2">
    <span className="text-[11px] font-medium uppercase text-darkGray">
      {title}
    </span>
    {badge && <span>{badge}</span>}
  </div>
);
