import React, { forwardRef } from "react";
import { ReactNode } from "react";
import { classNames } from "core";

export interface ModalFooterProps extends React.HTMLProps<HTMLDivElement> {
  children: ReactNode;
  className?: string;
}

const ModalFooter = forwardRef<HTMLDivElement, ModalFooterProps>(
  ({ children, className, ...rest }: ModalFooterProps, ref) => {
    return (
      <div
        {...rest}
        className={classNames("flex justify-end gap-2 px-6 pb-6", className)}
        ref={ref}
      >
        {children}
      </div>
    );
  }
);

export default ModalFooter;
