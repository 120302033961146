import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export type RepoStatusType = {
  id: string;
  num_docs_crawling: number;
  num_docs_remaining: number;
  num_docs_total: number;
  num_docs_failed: number;
  eta_in_minutes: number;
  status_percentage: number;
};

export type RepoStatusReduxType = {
  [id: string]: RepoStatusType;
};

const initialState: RepoStatusReduxType = {};

const repoStatusSlice = createSlice({
  name: "repoStatus",
  initialState,
  reducers: {
    upsertRepoStatus: (
      state: RepoStatusReduxType,
      action: PayloadAction<RepoStatusType[]>
    ) => {
      action.payload.forEach((status) => {
        state[status.id] = { ...state[status.id], ...status };
      });
      return state;
    },
  },
});

export const { upsertRepoStatus } = repoStatusSlice.actions;
export const repoStatusReducer = repoStatusSlice.reducer;
