import React from "react";
import { RepoType } from "source/Types";
import { useQueryBookmarks } from "source/api/bookmarks/useQueryBookmarks";
import {
  useCreateRepoMutation,
  useUpdateRepoMutation,
} from "source/api/repos/useQueryRepos";
import { DEFAULT_REPO_NAME } from "source/constants";
import { useSidebarRepos } from "source/hooks/useSidebarRepos";
import { SubSectionHeader } from "./SubSections";
import { AllRepos } from "./AllRepos";
import { useGlobalNavigator } from "source/hooks/useSetRouter";
import { getPersonalRepos } from "../utils";

/** Gets personal repos and filters out bookmarked repos */
const filterPersonalRepos = (
  repos: RepoType[],
  bookmarkedRepos: RepoType[]
) => {
  const personalRepos = getPersonalRepos(repos);
  return personalRepos.filter(
    (repo) => !bookmarkedRepos.find((r) => r.id === repo.id)
  );
};

type PersonalSectionProps = {
  reposCanOpen?: boolean;
};

export const PersonalSection = ({ reposCanOpen }: PersonalSectionProps) => {
  const sidebarRepos = useSidebarRepos();
  const bookmarkedRepos = useQueryBookmarks().data?.repos ?? [];
  const bookmarkedPersonalRepos = getPersonalRepos(bookmarkedRepos);
  const personalRepos = filterPersonalRepos(
    sidebarRepos,
    bookmarkedPersonalRepos
  );
  const createRepoMutation = useCreateRepoMutation();
  const updateRepoMutation = useUpdateRepoMutation();
  const { goToRepo } = useGlobalNavigator();

  const handleRepoClick = (repoId: string) => {
    goToRepo(repoId);
  };

  const handleRename = (newTitle: string, id: string) => {
    const payload = {
      repoId: id,
      body: {
        name: newTitle,
      },
    };
    updateRepoMutation.mutate(payload);
  };

  return (
    <div className="flex flex-col">
      <AllRepos
        bookmarkedRepos={bookmarkedPersonalRepos}
        repos={personalRepos}
        handleRepoClick={reposCanOpen ? handleRepoClick : undefined}
        handleRename={handleRename}
        header={<SubSectionHeader title="personal" />}
        onAddFolder={(name?: string) => {
          const payload = {
            name: name ?? DEFAULT_REPO_NAME,
            is_private: true,
          };
          createRepoMutation.mutate(payload);
        }}
      />
    </div>
  );
};
