import { queryClient } from "pages/_app";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { docsKeys } from "source/api/docs/docsKeyFactory";
import { setRepo } from "source/api/repos/useQueryRepos";
import { openPrettyAlert } from "source/utils/helpers";
import { insertActiveBuild } from "source/redux/activeBuilds";
import { upsertSidebarRowsOpen } from "source/redux/sidebar";
import { DocumentType } from "source/Types";
import { useCurrentRepo } from "./repos/useCurrentRepo";
import { useGlobalNavigator } from "./useSetRouter";
import { useUpdateActivityFeed } from "./useUpdateActivityFeed";
import { isFolder } from "source/utils/documents";

export const useUploadSourceSuccess = () => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const repo = useCurrentRepo(true);
  const { handleDocUpdate } = useUpdateActivityFeed();
  const { goToRepo } = useGlobalNavigator();

  const handleSuccess = async (
    docs: DocumentType[],
    parentDoc?: DocumentType
  ) => {
    setIsLoading(false);
    handleDocUpdate(docs);

    // so we start pinging for status
    if (repo) setRepo({ ...repo, status_percentage: 0 });

    // if the new docs are attached to a parent, open that parent in the sidebar
    docs.forEach((doc) => {
      if (!doc.parent_id) return;
      const payload = {};
      payload[`d${doc.parent_id}`] = true;
      dispatch(upsertSidebarRowsOpen(payload));
    });

    // Basically an in-line mutation for handling new docs uploaded
    // if parent doc refetch its children
    if (parentDoc) {
      queryClient.invalidateQueries(
        docsKeys.childrenDocs(parentDoc.id, parentDoc.repo_id)
      );
    }
    // If repoId refetch its parent docs
    if (repo?.id) {
      queryClient.invalidateQueries(docsKeys.parentDocs(repo.id));
    }

    if (!(docs.length === 1 && isFolder(docs[0]?.mime))) {
      dispatch(
        insertActiveBuild({
          repoId: repo ? repo.id : parentDoc?.repo_id,
          docId: docs.length == 1 ? docs[0]?.id : parentDoc?.id,
          action: "add",
          status: "processing",
        })
      );
    }

    if (!repo?.id) return;
    const payload = {};
    payload[`r${repo.id}`] = true;
    dispatch(upsertSidebarRowsOpen(payload));
    // Manually prime repo data
    setRepo(repo);
    goToRepo(repo.id);
  };

  const handleError = async (errorMsg?: string) => {
    const text = errorMsg
      ? "Please try again later. Contact support@hebbia.ai if the problem persists."
      : "Please verify your credentials and try again.";
    await openPrettyAlert({
      title: errorMsg || "Could not add document",
      text: text,
      icon: errorMsg ? "error" : "warning",
      error: errorMsg,
      url: window.location.href,
    });
    setIsLoading(false);
  };

  return { handleSuccess, isLoading, setIsLoading, handleError };
};
