import { RepoIcon } from "source/components/shared/Icons/RepoIcon";
import { RepoContextMenu } from "source/components/sidebar/components/RepoContextMenu";
import React, { useState } from "react";
import { RepoType } from "source/Types";
import { GigabarRow } from "../../GigabarRow";
import { ShowMoreButton } from "../../FileSection/components/ShowMoreButton";
import { getFilterSearchKey, getReportFilterKey } from "source/redux/advanced";
import { COMPANY_SECTION_TITLE } from "..";

type AllCompaniesProps = {
  searchedCompanyRepos: RepoType[]; // repos that we selected while searching
  bookmarkedRepos: RepoType[];
  repos: RepoType[];
  handleRepoClick?: (repoId: string) => void;
};

export const AllCompanies = ({
  searchedCompanyRepos,
  bookmarkedRepos,
  repos,
  handleRepoClick,
}: AllCompaniesProps) => {
  const sectionKey = getReportFilterKey(COMPANY_SECTION_TITLE);
  const searchKey = getFilterSearchKey("company");

  const [numToShow, setNumToShow] = useState(3);
  const moreToShow = repos.length - numToShow > 0;

  const handleShowMore = () => {
    setNumToShow(numToShow + 3);
  };

  return (
    <>
      {searchedCompanyRepos.map((repo, idx) => (
        <RepoContextMenu repo={repo} key={`${repo.id}${idx}`}>
          <GigabarRow
            title={repo.name}
            id={repo.id}
            value={repo.ticker}
            icon={<RepoIcon repo={repo} fontSize="small" />}
            sectionKey={sectionKey}
            searchKey={searchKey}
            handleClick={handleRepoClick}
            showContextMenu={true}
            bookmarked={false}
          />
        </RepoContextMenu>
      ))}
      {bookmarkedRepos.map((repo, idx) => (
        <RepoContextMenu repo={repo} key={`${repo.id}${idx}`}>
          <GigabarRow
            title={repo.name}
            id={repo.id}
            value={repo.ticker}
            icon={<RepoIcon repo={repo} fontSize="small" />}
            sectionKey={sectionKey}
            searchKey={searchKey}
            handleClick={handleRepoClick}
            showContextMenu={true}
            bookmarked={true}
          />
        </RepoContextMenu>
      ))}
      {repos.slice(0, numToShow).map((repo, idx) => (
        <RepoContextMenu repo={repo} key={`${repo.id}${idx}`}>
          <GigabarRow
            title={repo.name}
            id={repo.id}
            value={repo.ticker}
            icon={<RepoIcon repo={repo} fontSize="small" />}
            sectionKey={sectionKey}
            searchKey={searchKey}
            handleClick={handleRepoClick}
            showContextMenu={true}
            bookmarked={false}
          />
        </RepoContextMenu>
      ))}
      {moreToShow && <ShowMoreButton handleClick={handleShowMore} />}
    </>
  );
};
