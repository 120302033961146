import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ReduxState } from ".";

export type DebugFlagReduxType = {
  shouldRerank?: boolean;
  hitStagingReranker?: boolean;
  rawColbertResults?: boolean;
  useRephrase?: boolean;
  useHyde?: boolean;
  useTitles?: boolean;
  hydePrefix?: string | null;
};

export type DebugFlagType =
  | "shouldRerank"
  | "hitStagingReranker"
  | "rawColbertResults"
  | "useRephrase"
  | "useHyde"
  | "useTitles";

export const getSearchDebugFlags = (state: ReduxState) => state.debugFlag;

const initialState: DebugFlagReduxType = {};

const debugFlagSlice = createSlice({
  name: "upload",
  initialState,
  reducers: {
    setDebugFlagTrue: (
      state: DebugFlagReduxType,
      action: PayloadAction<DebugFlagType>
    ) => {
      state[action.payload] = true;
    },
    setDebugFlagFalse: (
      state: DebugFlagReduxType,
      action: PayloadAction<DebugFlagType>
    ) => {
      state[action.payload] = false;
    },
    setSearchHydePrefix: (
      state,
      action: PayloadAction<string | null | undefined>
    ) => {
      state.hydePrefix = action.payload;
    },
  },
});

export const { setDebugFlagTrue, setDebugFlagFalse, setSearchHydePrefix } =
  debugFlagSlice.actions;
export const debugFlagReducer = debugFlagSlice.reducer;
