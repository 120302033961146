import { useMutation, useQuery } from "@tanstack/react-query";
import { queryClient } from "pages/_app";
import { useCallback } from "react";
import { useSelector } from "react-redux";
import api from "source/api";
import { getUser } from "source/redux/user";

export const useAdminMode = () => {
  const user = useSelector(getUser);
  const { data: admin } = useQuery({
    queryKey: ["hebbiaAdmin"],
    queryFn: api.users.admin,
  });

  const { mutateAsync: toggleAdmin } = useMutation({
    mutationFn: api.users.toggleAdmin,
    onSuccess: () => queryClient.invalidateQueries(["hebbiaAdmin"]),
  });

  const prefetchHebbiaAdmin = useCallback(async () => {
    await queryClient.prefetchQuery({
      queryKey: ["hebbiaAdmin"],
      queryFn: api.users.admin,
    });
  }, []);

  const isAdmin = user?.platform_role === "admin";
  return { hebbiaAdmin: admin, toggleAdmin, prefetchHebbiaAdmin, isAdmin };
};
