import $ from "jquery";
import { useDispatch } from "react-redux";
import { upsertSidebarRowsOpen } from "source/redux/sidebar";

export const useSidebar = () => {
  const dispatch = useDispatch();

  const focus = (id: string) => {
    // enable textarea and move cursor to the end
    setTimeout(() => {
      const input = $(`#sidebar-${id}`);
      if (!input.length) return;
      input.attr("disabled", false as any);
      const tmp = input.val() as string;
      input.trigger("focus");
      (input[0] as HTMLInputElement).setSelectionRange(0, tmp.length);
    }, 200);
  };

  const open = (ids: string[]) => {
    if (!ids.length) return;
    const payload = {};
    ids.forEach((id) => {
      payload[id] = true;
    });
    dispatch(upsertSidebarRowsOpen(payload));
  };

  return { focus, open };
};
