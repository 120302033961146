import React from "react";
import { useSelector } from "react-redux";
import { OrgType, RepoType } from "source/Types";
import { useQueryBookmarks } from "source/api/bookmarks/useQueryBookmarks";
import { useSidebarRepos } from "source/hooks/useSidebarRepos";
import { AllRepos } from "./AllRepos";
import { SubSectionHeader } from "./SubSections";
import { useGlobalNavigator } from "source/hooks/useSetRouter";
import { getOrgs } from "source/redux/organization";
import { getSharedRepos } from "../utils";

/** Gets shared repos and filters out bookmarked repos */
const getSharedReposWithNoBookmarks = (
  repos: RepoType[],
  bookmarkedRepos: RepoType[],
  orgs: OrgType[]
) => {
  const sharedRepos = getSharedRepos(repos, orgs);
  return sharedRepos.filter(
    (repo) => !bookmarkedRepos.find((r) => r.id === repo.id)
  );
};

type SharedSectionProps = {
  reposCanOpen?: boolean;
};

export const SharedSection = ({ reposCanOpen }: SharedSectionProps) => {
  const { goToRepo } = useGlobalNavigator();
  const orgs = useSelector(getOrgs);
  const sidebarRepos = useSidebarRepos();
  const bookmarkedRepos = useQueryBookmarks().data?.repos ?? [];
  const bookmarkedSharedRepos = getSharedRepos(bookmarkedRepos, orgs);
  const sharedRepos = getSharedReposWithNoBookmarks(
    sidebarRepos,
    bookmarkedSharedRepos,
    orgs
  );

  const handleRepoClick = (repoId: string) => {
    goToRepo(repoId);
  };

  if (!bookmarkedSharedRepos.length && !sharedRepos.length) return null;

  return (
    <div className="flex flex-col">
      <AllRepos
        bookmarkedRepos={bookmarkedSharedRepos}
        repos={sharedRepos}
        header={<SubSectionHeader title="shared with me" />}
        handleRepoClick={reposCanOpen ? handleRepoClick : undefined}
      />
    </div>
  );
};
