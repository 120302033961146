export const BLUEGREEN_COOKIE_NAME = "green";
export const BLUEGREEN_COOKIE_VALUE = "true";
const DEFUALT_COOKIE_DAYS = 30;

const setCookie = (name: string, value: string, days: number): void => {
  if (typeof document === "undefined") return; // Ensure we are in a browser environment
  const date = new Date();
  date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
  const expires = "expires=" + date.toUTCString();
  document.cookie = `${name}=${encodeURIComponent(value)};${expires};path=/`;
};

const getCookie = (name: string): string | null => {
  if (typeof document === "undefined") return null; // Ensure we are in a browser environment
  const cookieArray = document.cookie.split(";");
  for (const cookie of cookieArray) {
    const [key, value] = cookie.trim().split("=");
    if (key === name && value) {
      return decodeURIComponent(value);
    }
  }
  return null;
};

export const hasGreenCookie = (): boolean => {
  return getCookie(BLUEGREEN_COOKIE_NAME) === BLUEGREEN_COOKIE_VALUE;
};

export const setGreenCookie = (days: number = DEFUALT_COOKIE_DAYS): void => {
  setCookie(BLUEGREEN_COOKIE_NAME, BLUEGREEN_COOKIE_VALUE, days);
};

export const clearGreenCookie = (): void => {
  setCookie(BLUEGREEN_COOKIE_NAME, "", -1);
};
