import React, { memo } from "react";
import CloseIcon from "@mui/icons-material/Close";
import MaterialSymbolsIcon, {
  MaterialSymbolsIconProps,
} from "../Icon/MaterialSymbolsIcon";
import Notification from "./Notification";

export type ToastType = {
  id: string;
  primaryText: string;
  secondaryText?: string;
  autoClose?: boolean;
  autoCloseTimeout?: number;
  icon?: "success" | "info" | "warning" | "error";
  iconProps?: Pick<
    MaterialSymbolsIconProps,
    "icon" | "size" | "variant" | "filled" | "className" | "style"
  >;
};

export type ToastProps = ToastType & {
  onClose: (id: string) => void;
};

const ToastIcon = ({
  icon,
  iconProps,
}: Pick<ToastType, "icon" | "iconProps">) => {
  if (icon) {
    switch (icon) {
      case "success":
        return (
          <MaterialSymbolsIcon
            size="2xl"
            icon="check_circle"
            color="green"
            {...iconProps}
          />
        );
      case "info":
        return (
          <MaterialSymbolsIcon
            size="2xl"
            icon="info"
            color="blue"
            {...iconProps}
          />
        );
      case "warning":
        return (
          <MaterialSymbolsIcon
            size="2xl"
            icon="warning"
            color="amber"
            {...iconProps}
          />
        );
      case "error":
        return (
          <MaterialSymbolsIcon
            size="2xl"
            icon="error"
            color="red"
            {...iconProps}
          />
        );
      default:
        return null;
    }
  }

  if (!iconProps?.icon) {
    return null;
  }

  return <MaterialSymbolsIcon size="2xl" {...iconProps} />;
};

const Toast = memo(
  ({
    id,
    primaryText,
    secondaryText,
    autoClose = true,
    autoCloseTimeout = 5000,
    icon,
    iconProps,
    onClose,
  }: ToastProps) => {
    return (
      <Notification
        autoClose={autoClose}
        autoCloseTimeout={autoCloseTimeout}
        closeNotification={() => onClose(id)}
      >
        <div className="flex items-center gap-3">
          <ToastIcon icon={icon} iconProps={iconProps} />
          <div className="flex w-0 flex-1 justify-between">
            <div className="flex flex-col">
              <p className="text-sm font-normal text-gray-900">{primaryText}</p>
              {secondaryText && (
                <p className="mt-1 text-xs font-normal text-darkGray">
                  {secondaryText}
                </p>
              )}
            </div>
          </div>
          <div className="ml-4 flex flex-shrink-0">
            <button
              type="button"
              className="focus:ring-purple/75 inline-flex rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2"
              onClick={() => {
                onClose(id);
              }}
            >
              <span className="sr-only">Close</span>
              <CloseIcon fontSize="small" aria-hidden="true" />
            </button>
          </div>
        </div>
      </Notification>
    );
  }
);

export default Toast;
