import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ResultType, DocModalResults } from "source/Types";
import { ReduxState } from ".";

// Eventually remove currentResultIdx.
export type SingleDocSearchReduxType = {
  // Used in the new doc modal. Caches the first query set of results for a given
  // doc modal doc ID.
  resultsByDocId: { [docId: string]: ResultType[] };
  // Used in the new doc modal. Caches all of the queries + results for a given
  // doc modal doc ID.
  queryResultsByDocId: {
    [docId: string]: { results: DocModalResults; ranInitialQuery: boolean };
  };
  // Used in old doc viewer
  currentResultIdx: number;
};

const initialState: SingleDocSearchReduxType = {
  resultsByDocId: {},
  queryResultsByDocId: {},
  currentResultIdx: 0,
};

export const getCurrentResultIdx = (state: ReduxState) =>
  state.singleDocSearch.currentResultIdx;

export const getSingleDocSearchResultsByDocId = (state: ReduxState) =>
  state.singleDocSearch.resultsByDocId;

export const getSingleDocQueryResultsByDocId = (state: ReduxState) =>
  state.singleDocSearch.queryResultsByDocId;

const singleDocSearchSlice = createSlice({
  name: "singleDocSearch",
  initialState,
  reducers: {
    setCurrentResultIdx: (
      state: SingleDocSearchReduxType,
      action: PayloadAction<number>
    ) => {
      state.currentResultIdx = action.payload;
      return state;
    },
    resetSingleDocSearchResults: (state: SingleDocSearchReduxType) => {
      state.resultsByDocId = {};
      state.queryResultsByDocId = {};
      return state;
    },
    insertSearchResultsByDocId: (
      state: SingleDocSearchReduxType,
      action: PayloadAction<{ docId: string; results: ResultType[] }>
    ) => {
      state.resultsByDocId[action.payload.docId] = action.payload.results;
      return state;
    },
    insertQueryResultsByDocId: (
      state: SingleDocSearchReduxType,
      action: PayloadAction<{
        docId: string;
        ranInitialSearch: boolean;
        queryResults: DocModalResults;
      }>
    ) => {
      state.queryResultsByDocId[action.payload.docId] = {
        results: action.payload.queryResults,
        ranInitialQuery: action.payload.ranInitialSearch,
      };
      return state;
    },
  },
});

export const {
  setCurrentResultIdx,
  resetSingleDocSearchResults,
  insertSearchResultsByDocId,
  insertQueryResultsByDocId,
} = singleDocSearchSlice.actions;
export const singleDocSearchReducer = singleDocSearchSlice.reducer;
