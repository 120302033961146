import { KeyboardEvent } from "react";
import { useMenu } from "../components/Menu/MenuContext";

type Props = {
  enabled?: boolean;
  handleEscapeKeyDown?: (e: KeyboardEvent) => void;
  handleEnterKeyDown?: (e: KeyboardEvent) => void;
};

export const useMenuKeyboardEvents = ({
  enabled,
  handleEscapeKeyDown,
  handleEnterKeyDown,
}: Props) => {
  const { focusedIndex, setFocusedIndex, items } = useMenu();

  return (event: KeyboardEvent) => {
    if (!enabled) return;
    switch (event.key) {
      case "ArrowDown":
        setFocusedIndex((prevIndex: number) =>
          prevIndex < items.length - 1 ? prevIndex + 1 : 0
        );
        break;
      case "ArrowUp":
        setFocusedIndex((prevIndex: number) =>
          prevIndex > 0 ? prevIndex - 1 : items.length - 1
        );
        break;
      case "Enter":
        items[focusedIndex]?.current?.focus(); // need to focus to prevent click from propagating to the previously focused el (open dropdown button or whatever)
        items[focusedIndex]?.current?.click();
        handleEnterKeyDown?.(event);
        break;
      case "Escape":
        handleEscapeKeyDown?.(event);
        break;
      case "Tab":
        event.preventDefault(); // Prevent default tabbing behavior
        setFocusedIndex((prevIndex: number) =>
          prevIndex < items.length - 1 ? prevIndex + 1 : 0
        );
        break;
      default:
        break;
    }
  };
};
