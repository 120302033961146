import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export type UploadStatusType = {
  id: string;
  repoId: string;
  numDocs: number;
  numSucceeded: number;
  numFailed: number;
};

export type UploadReduxType = {
  [id: string]: UploadStatusType;
};

const initialState: UploadReduxType = {};

const uploadSlice = createSlice({
  name: "upload",
  initialState,
  reducers: {
    upsertUploads: (
      state: UploadReduxType,
      action: PayloadAction<UploadStatusType[]>
    ) => {
      action.payload.forEach((upload) => {
        state[upload.id] = { ...state[upload.id], ...upload };
      });
      return state;
    },
  },
});

export const { upsertUploads } = uploadSlice.actions;
export const uploadReducer = uploadSlice.reducer;
