import { useMemo } from "react";
import { useSelector } from "react-redux";
import { useQueryChildrenDocs } from "source/api/docs/useQueryDocs";
import { BatchKeysReposParams } from "source/api/filter/filters";
import { useQueryFilteredRepoDocs } from "source/api/filter/useQueryFilters";
import { DocumentType, FilterType, RepoType } from "source/Types";
import { useRepoDocsDict } from "./useRepoDocsDict";
import { getTargetDocs } from "source/redux/advanced";

/**
 * Manages the functionality around paginating a parent document `doc`'s children.
 * This involves:
 * 1. Maintaining state around how many children to show for this parent doc
 * 2. Functionality to fetch another page of children when asked
 * 3. Logic so that we always visualize target docs
 */
export const useFetchPaginatedChildDocs = (
  doc?: DocumentType,
  repo?: RepoType
) => {
  const { data, fetchNextPage, hasNextPage, isFetchingNextPage, isLoading } =
    useQueryChildrenDocs(doc?.id, doc?.repo_id);

  const targetDocIds = useSelector(getTargetDocs);
  const repoDocsDict = useRepoDocsDict(repo?.id);
  const targetChildren = useMemo(
    () =>
      targetDocIds
        .map((id) => repoDocsDict[id])
        .filter((targetDoc) => targetDoc?.parent_id === doc?.id),
    [targetDocIds]
  );

  const childrenDocs = useMemo(() => {
    const allChildrenDocs =
      data?.pages?.map((doc) => doc?.docs ?? []).flat() ?? [];
    return allChildrenDocs;
  }, [data?.pages]);

  const visibleChildrenNoTargets = childrenDocs;
  const visibleChildrenWithUniqueTargets = visibleChildrenNoTargets.concat(
    targetChildren?.filter(
      (doc) =>
        !visibleChildrenNoTargets.find((childDoc) => childDoc.id === doc?.id) &&
        !!doc
    ) as DocumentType[]
  );

  return {
    allChildren: childrenDocs,
    visibleChildren: visibleChildrenWithUniqueTargets,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    isLoading,
  };
};

export const useFetchFilteredPaginatedChildDocs = (
  doc?: DocumentType,
  seeMore?: number,
  filterTargets?: FilterType[],
  toggled?: boolean
) => {
  const params: BatchKeysReposParams = useMemo(() => {
    const keyValueFilters = filterTargets?.map((f) => ({
      key: f.key,
      value: f.value,
    }));
    const repo_ids = doc?.repo_id ? [doc.repo_id] : [];
    const doc_ids = doc?.id ? [doc.id] : [];
    return {
      filters: keyValueFilters ?? [],
      repo_ids,
      doc_ids,
      page: 0,
      size: 10,
      toggled,
    };
  }, [filterTargets, toggled]);

  const filteredReposDocsQuery = useQueryFilteredRepoDocs(params);
  const hasMore = (filteredReposDocsQuery.data?.length ?? 0) > 5;

  return { filteredReposDocsQuery, hasMore };
};
