import React from "react";
import { FilterIcon } from "source/components/shared/Icons/FilterIcon";
import { GigabarRow } from "../../GigabarRow";
import { useSelector } from "react-redux";
import { getTargetAppliedFilters } from "source/redux/advanced";
import { AppliedRow } from "../../AppliedRow";
import { transformFilter } from "../utils";
import { getCurrentOrg } from "source/redux/organization";

type FilterListProps = {
  filterMap: Record<string, string[]>;
  sectionKey: string;
};

export const FilterList = ({ filterMap, sectionKey }: FilterListProps) => {
  const filterKeys = Object.keys(filterMap);

  return (
    <div className="flex flex-col pb-2">
      <div className="flex w-full flex-col">
        {filterKeys.map((filterKey) => {
          const filterValues = filterMap[filterKey];
          return (
            <>
              {filterValues?.map((value, idx) => (
                <FilterRow
                  key={idx}
                  filterKey={filterKey}
                  value={value}
                  sectionKey={sectionKey}
                />
              ))}
            </>
          );
        })}
      </div>
    </div>
  );
};

type FilterRowProps = {
  filterKey: string;
  value: string;
  sectionKey: string;
};

const FilterRow = ({ filterKey, value, sectionKey }: FilterRowProps) => {
  const appliedFilters = useSelector(getTargetAppliedFilters);
  const isAppliedFilter = appliedFilters.some(
    (f) => f.key === filterKey && f.value === value
  );

  const currentOrg = useSelector(getCurrentOrg);
  const enablePublicSources = currentOrg?.config?.enable_public_sources ?? true;
  const isPublicFilter =
    filterKey === "integration" && ["sec", "earnings"].includes(value);

  if (isPublicFilter && !enablePublicSources) return null;

  if (isAppliedFilter)
    return (
      <AppliedRow
        icon={
          <FilterIcon
            classOverride="flex items-center text-darkGray"
            filter={{ key: filterKey, value }}
          />
        }
        filter={{ key: filterKey, value }}
        id={value}
        title={transformFilter(filterKey, value) ?? ""}
        autoApplied={true}
        tooltipText={"Type filter is auto-applied"}
        sectionKey={sectionKey}
        searchKey={filterKey}
      />
    );

  return (
    <GigabarRow
      title={transformFilter(filterKey, value) ?? ""}
      id={value}
      icon={
        <FilterIcon
          classOverride="flex items-center text-darkGray"
          filter={{ key: filterKey, value }}
        />
      }
      sectionKey={sectionKey}
      // TODO: this filter key actually needs to be mapped to the search key which will be returned by the in addy new route
      searchKey={filterKey}
    />
  );
};
