import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ReduxState } from ".";
import {
  DocumentType,
  FilterGroupType,
  FilterType,
  NumericFilterType,
  RepoType,
} from "source/Types";

export type FilterTypeReduxType = {
  num_filters: NumericFilterType[];
  str_filters: FilterType[];
  repoKeyToIdMap: Record<string, string[]>;
  /**
   * Map of NAICS 3-digit code descriptions to SIC 2-digit sector codes (used for icons).
   */
  sicCodeMap: Record<string, number>;
  /**
   * Map of filter key to inner map of filter value to list of repos
   * For performance reasons, this only holds the first page of repo results.
   * and we allow pagination via the "load more" button.
   *
   * UNUSED RIGHT NOW: TODO: pre-fetch to optimize performance
   */
  repoFilterContentsMap: Record<string, Record<string, RepoType[]>>;
  /**
   * Map of filter key to inner map of filter value to list of docs
   * For performance reasons, this only holds the first page of doc results
   * and we allow pagination via a "load more" button.
   *
   * UNUSED RIGHT NOW: TODO: pre-fetch to optimize performance
   */
  docFilterContentsMap: Record<string, Record<string, DocumentType[]>>;
  /**
   * List of filters that are currently being fetched from the server
   */
  fetchingFilterContents: FilterType[];
  /** map key to array of values for available filters */
  keyValueMap: Record<string, string[]>;
  filterGroups: FilterGroupType[];
};

type RepoKeyMapUpsertType = {
  repoId: string;
  keys: string[];
};

const initialState: FilterTypeReduxType = {
  num_filters: [] as NumericFilterType[],
  str_filters: [] as FilterType[],
  sicCodeMap: {} as Record<string, number>,
  repoKeyToIdMap: {} as Record<string, string[]>,
  repoFilterContentsMap: {} as Record<string, Record<string, RepoType[]>>,
  docFilterContentsMap: {} as Record<string, Record<string, DocumentType[]>>,
  fetchingFilterContents: [] as FilterType[],
  keyValueMap: {} as Record<string, string[]>,
  filterGroups: [] as FilterGroupType[],
};

type RepoFilterContentsUpsertPayload = {
  filter: FilterType;
  repos: RepoType[];
};

type DocFilterContentsUpsertPayload = {
  filter: FilterType;
  docs: DocumentType[];
};

/**
 * Retrieves the list of applied/selected filters.
 */
export const getRepoStrFilters = (state: ReduxState) =>
  state.filters.str_filters;
export const getRepoNumFilters = (state: ReduxState) =>
  state.filters.num_filters;
export const getRepoFilterContentsMap = (state: ReduxState) =>
  state.filters.repoFilterContentsMap;
export const getDocFilterContentsMap = (state: ReduxState) =>
  state.filters.docFilterContentsMap;
export const getFetchingFilterContents = (state: ReduxState) =>
  state.filters.fetchingFilterContents;
export const getFilterGroups = (state: ReduxState) =>
  state.filters.filterGroups;
export const getSicCodeMap = (state: ReduxState) => state.filters.sicCodeMap;
export const getRepoKeyToIdMap = (state: ReduxState) =>
  state.filters.repoKeyToIdMap;
export const getFiltersKeyValueMap = (state: ReduxState) =>
  state.filters.keyValueMap;

const filtersSlice = createSlice({
  name: "filters",
  initialState,
  reducers: {
    setRepoStrFilters: (
      state: FilterTypeReduxType,
      action: PayloadAction<FilterType[]>
    ) => {
      state.str_filters = action.payload;
      return state;
    },
    deleteRepoStrFilter: (
      state: FilterTypeReduxType,
      action: PayloadAction<FilterType>
    ) => {
      state.str_filters = state.str_filters.filter(
        (f) => !(f.key == action.payload.key && f.value == action.payload.value)
      );
      return state;
    },
    upsertRepoStrFilter: (
      state: FilterTypeReduxType,
      action: PayloadAction<FilterType>
    ) => {
      if (
        !state.str_filters.some(
          (tf) =>
            tf.key === action.payload.key && tf.value === action.payload.value
        )
      )
        state.str_filters.push(action.payload);
      return state;
    },
    upsertSicCodeMap: (
      state: FilterTypeReduxType,
      action: PayloadAction<Record<string, number>>
    ) => {
      Object.keys(action.payload).forEach((sicIndustry) => {
        const sicCode = action.payload[sicIndustry];
        if (sicCode) state.sicCodeMap[sicIndustry] = sicCode;
      });
      return state;
    },
    upsertRepoFilterContentsMap: (
      state: FilterTypeReduxType,
      action: PayloadAction<RepoFilterContentsUpsertPayload>
    ) => {
      if (!state.repoFilterContentsMap[action.payload.filter.key])
        state.repoFilterContentsMap[action.payload.filter?.key] = {};

      state.repoFilterContentsMap[action.payload.filter?.key]![
        action.payload.filter.value
      ] = action.payload.repos;
      return state;
    },
    upsertDocFilterContentsMap: (
      state: FilterTypeReduxType,
      action: PayloadAction<DocFilterContentsUpsertPayload>
    ) => {
      if (!state.docFilterContentsMap[action.payload.filter.key])
        state.docFilterContentsMap[action.payload.filter?.key] = {};

      state.docFilterContentsMap[action.payload.filter?.key]![
        action.payload.filter.value
      ] = action.payload.docs;
      return state;
    },
    upsertFetchingFilterContents: (
      state: FilterTypeReduxType,
      action: PayloadAction<FilterType[]>
    ) => {
      action.payload.forEach((filter) => {
        if (
          !state.fetchingFilterContents.some(
            (f) => f.key === filter.key && f.value === filter.value
          )
        )
          state.fetchingFilterContents.push(filter);
      });
      return state;
    },
    removeFetchingFilterContents: (
      state: FilterTypeReduxType,
      action: PayloadAction<FilterType>
    ) => {
      state.fetchingFilterContents = state.fetchingFilterContents.filter(
        (f) => f.key !== action.payload.key || f.value !== action.payload.value
      );
      return state;
    },
    upsertRepoStrFilters: (
      state: FilterTypeReduxType,
      action: PayloadAction<FilterType[]>
    ) => {
      action.payload.forEach((filter) => {
        if (
          !state.str_filters.some(
            (tf) => tf.key === filter.key && tf.value === filter.value
          )
        )
          state.str_filters.push(filter);
      });
      return state;
    },
    setRepoNumericFilters: (
      state: FilterTypeReduxType,
      action: PayloadAction<NumericFilterType[]>
    ) => {
      state.num_filters = action.payload;
      return state;
    },
    upsertRepoNumericFilter: (
      state: FilterTypeReduxType,
      action: PayloadAction<NumericFilterType>
    ) => {
      if (!state.num_filters.some((tf) => tf.key === action.payload.key))
        state.num_filters.push(action.payload);
      return state;
    },
    upsertRepoNumericFilters: (
      state: FilterTypeReduxType,
      action: PayloadAction<NumericFilterType[]>
    ) => {
      action.payload.forEach((filter) => {
        if (!state.num_filters.some((tf) => tf.key === filter.key))
          state.num_filters.push(filter);
      });
      return state;
    },
    // Given an object mapping repoId to array of keys, upsert
    upsertRepoKeysToIdMap: (
      state: FilterTypeReduxType,
      action: PayloadAction<RepoKeyMapUpsertType>
    ) => {
      const { repoId, keys } = action.payload;

      keys.forEach((key) => {
        if (!(key in state.repoKeyToIdMap)) state.repoKeyToIdMap[key] = [];
        state.repoKeyToIdMap[key]?.push(repoId);
      });

      return state;
    },
    setRepoKeysToIdMap: (
      state: FilterTypeReduxType,
      action: PayloadAction<Record<string, string[]>>
    ) => {
      state.repoKeyToIdMap = action.payload;
      return state;
    },
    setFiltersKeyValueMap: (
      state: FilterTypeReduxType,
      action: PayloadAction<Record<string, string[]>>
    ) => {
      state.keyValueMap = action.payload;
      return state;
    },
    upsertFilterGroup: (
      state: FilterTypeReduxType,
      action: PayloadAction<FilterGroupType>
    ) => {
      if (!state.filterGroups.some((g) => g.id === action.payload.id))
        state.filterGroups.push(action.payload);
      else
        state.filterGroups = state.filterGroups.map((fg) => {
          if (fg.id === action.payload.id) return action.payload;
          return fg;
        });
      return state;
    },
    removeFilterGroup: (
      state: FilterTypeReduxType,
      action: PayloadAction<FilterGroupType>
    ) => {
      state.filterGroups = state.filterGroups.filter(
        (group) => group.id !== action.payload.id
      );
      return state;
    },
  },
});

export const {
  setRepoStrFilters,
  upsertRepoStrFilter,
  upsertRepoStrFilters,
  setRepoNumericFilters,
  upsertRepoNumericFilter,
  upsertRepoNumericFilters,
  upsertSicCodeMap,
  upsertRepoFilterContentsMap,
  upsertDocFilterContentsMap,
  upsertFetchingFilterContents,
  removeFetchingFilterContents,
  upsertRepoKeysToIdMap,
  setRepoKeysToIdMap,
  setFiltersKeyValueMap,
  upsertFilterGroup,
  removeFilterGroup,
  deleteRepoStrFilter,
} = filtersSlice.actions;
export const filtersReducer = filtersSlice.reducer;
