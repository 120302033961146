import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Dispatch } from "redux";
import api from "source/api";
import { OrgType, RepoType } from "source/Types";
import { ReduxState } from ".";

export type FindoxHoldingsReduxType = {
  holdings: FindoxHolding[];
  isLoaded: boolean;
  requestedEmails: string; // for alerts
};

export type FindoxHolding = {
  id: string;
  deal_name: string;
  issuer_name?: string;
};

const initialState: FindoxHoldingsReduxType = {
  holdings: [],
  isLoaded: false,
  requestedEmails: "",
};

export const fetchFindoxHoldings = async (
  dispatch: Dispatch,
  org: OrgType | null,
  existingRepos: RepoType[]
) => {
  if (org?.integrations?.findox) {
    try {
      const { holdings } = await api.repos.getFindoxHoldings(org.id);
      holdings.sort((a, b) => a["deal_name"].localeCompare(b["deal_name"]));
      const existingHoldings = new Set(
        existingRepos.map((repo) => repo.integrations?.findox?.holding_id)
      );
      const filteredHoldings = holdings.filter(
        (holding) => !existingHoldings.has(holding.id)
      );
      dispatch(setFindoxHoldings(filteredHoldings));
    } catch (e) {
      console.error(e);
      dispatch(setFindoxHoldings([]));
    }
  } else {
    dispatch(setFindoxHoldings([]));
  }
};

export const getFindoxHoldings = (state: ReduxState) =>
  state.findoxHoldings.holdings;

export const getFindoxHoldingsLoaded = (state: ReduxState) =>
  state.findoxHoldings.isLoaded;

export const getFindoxRequestedEmails = (state: ReduxState) =>
  state.findoxHoldings.requestedEmails;

export const searchFindoxHoldings = (
  query: string,
  holdings: FindoxHolding[],
  existingRepos: RepoType[],
  limit: number
) => {
  const existingHoldings = new Set(
    existingRepos.map((repo) => repo.integrations?.findox?.holding_id)
  );
  return holdings
    .filter(
      (holding) =>
        (holding.deal_name.toLowerCase().indexOf(query.toLowerCase()) >= 0 ||
          (holding.issuer_name &&
            holding.issuer_name?.toLowerCase().indexOf(query.toLowerCase()) >=
              0)) &&
        !existingHoldings.has(holding.id)
    )
    .slice(0, limit);
};

const findoxHoldingsSlice = createSlice({
  name: "findoxHoldings",
  initialState,
  reducers: {
    setFindoxHoldings: (
      state: FindoxHoldingsReduxType,
      action: PayloadAction<FindoxHolding[]>
    ) => {
      state.holdings = action.payload;
      state.isLoaded = true;
      return state;
    },
    setFindoxRequestedEmails: (
      state: FindoxHoldingsReduxType,
      action: PayloadAction<string>
    ) => {
      state.requestedEmails = action.payload;
      return state;
    },
  },
});

export const { setFindoxHoldings, setFindoxRequestedEmails } =
  findoxHoldingsSlice.actions;

export const findoxHoldingsReducer = findoxHoldingsSlice.reducer;
