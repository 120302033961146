import { ReduxState } from ".";
import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { IServerSideGroupSelectionState } from "ag-grid-community";

export type DocumentListsReduxType = {
  documentListBatchCounts: Record<string, { resolved: number; total: number }>; // Batch upload counts for each document list
  documentListSelectionStates: Record<string, IServerSideGroupSelectionState>;
};

const initialState: DocumentListsReduxType = {
  documentListBatchCounts: {},
  documentListSelectionStates: {},
};

export const getDocListsBatchCounts = (state: ReduxState) =>
  state.documentLists.documentListBatchCounts;

export const getDocumentListSelectionStates = (state: ReduxState) =>
  state.documentLists.documentListSelectionStates;

const documentListsSlice = createSlice({
  name: "documentLists",
  initialState,
  reducers: {
    resetAllDocumentLists: (state: DocumentListsReduxType) => {
      state = {
        ...initialState,
        documentListBatchCounts: state.documentListBatchCounts, // Persist upload state across resets
      };
      return state;
    },
    setDocumentListSelectionState: (
      state: DocumentListsReduxType,
      action: PayloadAction<{
        documentListId: string;
        selectionState: IServerSideGroupSelectionState;
      }>
    ) => {
      state.documentListSelectionStates[action.payload.documentListId] =
        action.payload.selectionState;
      return state;
    },
    addDocumentListRequests: (
      state: DocumentListsReduxType,
      action: PayloadAction<{ id: string; requestCount: number }>
    ) => {
      const counts = state.documentListBatchCounts[action.payload.id];
      if (counts) {
        counts.total = counts.total + action.payload.requestCount;
        state.documentListBatchCounts[action.payload.id] = counts;
      } else {
        state.documentListBatchCounts[action.payload.id] = {
          resolved: 0,
          total: action.payload.requestCount,
        };
      }
      return state;
    },
    resolveDocumentListRequests: (
      state: DocumentListsReduxType,
      action: PayloadAction<{ id: string; resolvedCount: number }>
    ) => {
      const counts = state.documentListBatchCounts[action.payload.id];
      if (!counts) {
        return; // This should never happen
      }

      const totalResolved = counts.resolved + action.payload.resolvedCount;

      // Wipe if we finish
      if (totalResolved >= counts.total) {
        delete state.documentListBatchCounts[action.payload.id];
        return state;
      }

      counts.resolved = totalResolved;
      state.documentListBatchCounts[action.payload.id] = counts;
      return state;
    },
    clearDocumentListRequests: (
      state: DocumentListsReduxType,
      action: PayloadAction<string>
    ) => {
      delete state.documentListBatchCounts[action.payload];
      return state;
    },
  },
});

export const {
  resetAllDocumentLists,
  setDocumentListSelectionState,
  addDocumentListRequests,
  resolveDocumentListRequests,
  clearDocumentListRequests,
} = documentListsSlice.actions;
export const documentListsReducer = documentListsSlice.reducer;
