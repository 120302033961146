import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { GroupedPins, PinType } from "source/Types";
import { ReduxState } from ".";

export type PinReduxType = {
  pinsById: { [id: string]: PinType };
  pinIdsByDocId: { [docId: string]: string[] };
};

export const getPinsDict = (state: ReduxState) => state.pins.pinsById;
export const getDocPinsDict = (state: ReduxState) => state.pins.pinIdsByDocId;
export const getPins = createSelector([getPinsDict], (pins) =>
  Object.values(pins)
);

export const getGroupedPins = createSelector([getPinsDict], (pins) => {
  const groupedPins = {} as GroupedPins;
  Object.entries(pins).forEach(([key, pin]) => {
    if (!groupedPins[pin.query]) groupedPins[pin.query] = [];
    groupedPins[pin.query]?.push(pin);
  });

  return groupedPins;
});

const pinsSlice = createSlice({
  name: "pins",
  initialState: {
    pinsById: {},
    pinIdsByDocId: {},
  },
  reducers: {
    setPins: (state: PinReduxType, action: PayloadAction<PinType[]>) => {
      state.pinsById = {};
      state.pinIdsByDocId = {};

      action.payload.forEach((pin) => {
        state.pinsById[pin.id] = pin;
        if (pin.doc_id) {
          // If we already have pins for this doc
          if (state.pinIdsByDocId[pin.doc_id]) {
            if (!state.pinIdsByDocId[pin.doc_id]?.includes(pin.id))
              state.pinIdsByDocId[pin.doc_id]?.push(pin.id);
          } else state.pinIdsByDocId[pin.doc_id] = [pin.id];
        }
      });

      return state;
    },
    upsertPins: (state: PinReduxType, action: PayloadAction<PinType[]>) => {
      action.payload.forEach((pin) => {
        state.pinsById[pin.id] = {
          ...state.pinsById[pin.id],
          ...pin,
        };
        if (pin.doc_id) {
          // If we already have pins for this doc
          if (state.pinIdsByDocId[pin.doc_id]) {
            if (!state.pinIdsByDocId[pin.doc_id]?.includes(pin.id))
              state.pinIdsByDocId[pin.doc_id]?.push(pin.id);
          } else state.pinIdsByDocId[pin.doc_id] = [pin.id];
        }
      });
      return state;
    },
    deletePins: (state: PinReduxType, action: PayloadAction<PinType[]>) => {
      action.payload.forEach((pin) => {
        if (pin.doc_id && state.pinIdsByDocId[pin.doc_id]) {
          const pinIdx = state.pinIdsByDocId[pin.doc_id]?.indexOf(pin.id);
          if (pinIdx && pinIdx > -1)
            state.pinIdsByDocId[pin.doc_id]?.splice(pinIdx, 1);
        }
        delete state.pinsById[pin.id];
      });
      return state;
    },
  },
});

export const { upsertPins, deletePins, setPins } = pinsSlice.actions;
export const pinsReducer = pinsSlice.reducer;
