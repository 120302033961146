import axios from "axios";
import LogRocket from "logrocket";
import {
  clearAuthToken,
  getAuthToken,
  setAuthToken,
} from "source/auth/localStorage";
import fetchAccessToken from "source/auth/fetchAccessToken";
import { WEBSERVER_URL } from "source/envConstants";
import { getLocalStorage } from "../storage/storage";

let logRocketURL = "";
// URL to view logrocket session
LogRocket.getSessionURL((sessionURL) => {
  logRocketURL = sessionURL;
});

export const axiosFactory = (token?: string, timeout?: number) => {
  const instance = axios.create({
    baseURL: WEBSERVER_URL,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "Access-Control-Max-Age": 86400,
    },
    timeout,
  });
  if (token) {
    instance.defaults.headers.common.Authorization = `Bearer ${token}`;
  } else {
    // Only add interceptors if we're in the browser
    instance.interceptors.request.use(
      async (config) => {
        let accessToken = getAuthToken();
        if (!accessToken) {
          const response = await fetchAccessToken();
          accessToken = response.accessToken;
          if (accessToken && typeof window !== "undefined")
            setAuthToken(accessToken);
        }
        config.headers = config.headers || {};
        if (accessToken) {
          config.headers.Authorization = `Bearer ${accessToken}`;
        }

        // read the current organization from local storage and pass it as a header
        const currentOrgId = getLocalStorage().getItem("currentOrgId");
        if (currentOrgId) {
          config.headers["Current-Org-Id"] = currentOrgId;
        }

        return config;
      },
      async (error) => Promise.reject(error)
    );

    instance.interceptors.response.use(
      (response) => response,
      async (error) => {
        // If the error response is a 401/403, our auth token is invalid
        // For now we just clear, but if we have a refresh token, we could try to refresh it.
        const status = error?.response?.status;
        if (status === 401) {
          clearAuthToken();
        }
        return Promise.reject(error);
      }
    );
  }
  return instance;
};

export default axiosFactory();
