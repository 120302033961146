import { AlertType, FilterType } from "source/Types";
import axios from "../axios";

export type GetAlertsType = {
  alerts: AlertType[];
};

export type AlertUpdateType = {
  query?: string;
  name?: string;
  targeted_user_ids?: string[];
  repo_ids?: string[];
  send_immediately?: boolean;
  utc_hour?: number;
  doc_ids?: string[];
  filters?: FilterType[];
};

// TODO (ryan): potentially want to add doc_ids to this type
export type AddAlertType = {
  name: string;
  query: string;
  creator_id: string;
  frequency: string;
  repo_ids?: string[];
  filters?: FilterType[];
};

export const Alerts = {
  getAlerts: (): Promise<GetAlertsType> =>
    axios.get(`/v2/alerts`).then(({ data }) => data),
  addAlert: (payload: AddAlertType): Promise<AlertType> =>
    axios.post(`/v2/alerts/add`, payload).then(({ data }) => data),
  updateAlert: (
    alert_id: string,
    payload: AlertUpdateType
  ): Promise<AlertType> =>
    axios
      .put(`/v2/alerts/${alert_id}/update`, payload)
      .then(({ data }) => data),
  deleteAlert: (alert_id: string) =>
    axios.delete(`/v2/alerts/${alert_id}/delete`),
};
