import { FilterType, RepoType } from "source/Types";
import { FiltersConfig } from "source/constants/filters";

/** Filters filters to company tickers and only returns the tickers*/
export const filterToTickers = (
  targetFilters: FilterType[] | null
): string[] => {
  const targetTickerFilters =
    targetFilters?.filter(
      (filter) => filter.key === FiltersConfig.TICKER_FILTER_KEY
    ) ?? [];

  const tickerValues = targetTickerFilters.map((filter) => filter.value) ?? [];

  return tickerValues;
};

/** Gets company repos*/
export const getCompanyRepos = (repos: RepoType[]) =>
  repos.filter((repo) => repo.ticker && !repo.private);

/** Filters out bookmarked repos and applied repos */
export const filterOutBookmarkedReposAndAppliedRepos = (
  repos: RepoType[],
  bookmarkedRepos: RepoType[],
  appliedRepos: RepoType[],
  suggestedRepos: RepoType[]
) =>
  repos.filter(
    (repo) =>
      !bookmarkedRepos.find((r) => r.id === repo.id) &&
      !appliedRepos.find((r) => r.id === repo.id) &&
      !suggestedRepos.find((r) => r.id === repo.id)
  );

/** Filter out repos in the second from the first */
export const filterOutRepos = (
  objectsToKeep: RepoType[],
  objectsToFilterOut: RepoType[][]
) => {
  const allRepos = objectsToFilterOut.flat();
  return objectsToKeep.filter(
    (object) => !allRepos.find((o) => o.id === object.id)
  );
};
