import logger from "source/utils/logger";

// use base 64 encoding to get file ID from path
export const getS3FileIDFromPath = (bucketName: string, path: string) => {
  try {
    return Buffer.from(`${bucketName}/${path}`).toString("base64");
  } catch (e) {
    logger.error("Failed to base-64 encode S3 path", {
      bucket_name: bucketName,
      path: path,
      err: e,
    });
    return undefined;
  }
};
