// pretty formats numbers
// 1000 -> 1,000
// 1.000001 -> 1
// 10000000.01 -> 10,000,000.01
export const formatNumber = (value: number | null | undefined) => {
  if (value === null || value === undefined) return "0";

  const formatter = new Intl.NumberFormat("en-US", {
    maximumFractionDigits: 2,
  });
  return formatter.format(value);
};

export const formatPercent = (numerator: number, denominator: number) => {
  let fraction;
  if (isNaN(numerator) || isNaN(denominator)) fraction = 0;
  else if (denominator === 0) fraction = 0;
  else fraction = numerator / denominator;

  return fraction.toLocaleString("en", {
    style: "percent",
  });
};
