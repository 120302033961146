import { useSelector } from "react-redux";
import { getCurrentOrg } from "source/redux/organization";
import { getUser } from "source/redux/user";

export const useCanEditOrg = () => {
  const user = useSelector(getUser);
  const currentOrg = useSelector(getCurrentOrg);

  // admins or org admins
  return (
    user?.platform_role === "admin" ||
    !!currentOrg?.members?.some(
      (member) =>
        member.auth0_id === user?.auth0_id && member.org_role === "admin"
    )
  );
};
