import React from "react";
import { classNames } from "core";

type BadgeVariant = "primary" | "neutral" | "scary" | "outlined";

export type BadgeProps = {
  variant?: BadgeVariant;
  size?: "sm" | "md" | "lg";
  className?: string;
  children: React.ReactNode;
};

const variantStyles: Record<BadgeVariant, string> = {
  primary: "bg-brand-100 text-hebbiaBlue",
  neutral: "bg-gray-100 text-gray500",
  scary: "bg-red-500 text-white",
  outlined: "bg-white border border-neutral-300",
};

const sizeStyles = {
  sm: "px-1 py-0.5 text-[8px]",
  md: "px-1.5 py-0.5 text-[10px]",
  lg: "px-2 py-1 text-sm",
};

const Badge: React.FC<BadgeProps> = ({
  variant = "primary",
  size = "md",
  className,
  children,
}) => {
  return (
    <span
      className={classNames(
        "rounded-md font-medium",
        variantStyles[variant],
        sizeStyles[size],
        className
      )}
    >
      {children}
    </span>
  );
};

export default Badge;
