import { useMutation, useQuery } from "@tanstack/react-query";
import { queryClient } from "pages/_app";
import { ViewResponse } from "source/Types";
import api from "..";
import { QueryContextFromKeys } from "../utils";

// views query key factory
export const viewsKeys = {
  all: [{ scope: "views" }] as const,
  docs: (orgId?: string) =>
    [
      {
        ...viewsKeys.all[0],
        level: "global",
        feature: "recent",
        orgId,
      },
    ] as const,
  repos: () => [{ ...viewsKeys.all[0], level: "global" }] as const,
  companyRepos: () => [{ ...viewsKeys.all[0], level: "company" }] as const,
  pitchbookRepos: () => [{ ...viewsKeys.all[0], level: "pitchbook" }] as const,
};

// Typed views key factory context
// QueryFunctionContext is an object that is passed as argument to the queryFn, this is simply a way of typing it
type ViewsQueryContext = QueryContextFromKeys<typeof viewsKeys>;

export const useQueryViewedDocs = (orgId?: string) =>
  useQuery({
    queryKey: viewsKeys.docs(orgId),
    queryFn: () => api.views.getViewedDocs({ org_id: orgId }),
  });

export const useQueryViewedRepos = () => {
  const repos = useQuery({
    queryKey: viewsKeys.repos(),
    queryFn: () => api.views.getViewedRepos(),
  });
  return repos.data ?? [];
};

export const useQueryViewedCompanyRepos = () => {
  const companies = useQuery({
    queryKey: viewsKeys.companyRepos(),
    queryFn: () => api.views.getViewedCompanyRepos(),
  });
  return companies;
};

export const useQueryViewedPitchbookRepos = () => {
  const pitchbook = useQuery({
    queryKey: viewsKeys.pitchbookRepos(),
    queryFn: () => api.views.getViewedPitchbookRepos(),
  });
  return pitchbook.data ?? [];
};

export type ViewRepoPayload = {
  repoId?: string;
  orgId?: string;
  docId?: string;
};

/** Mutates and posts a view for repo, org, doc (whatever is passed in) */
export const useViewMutation = () =>
  useMutation({
    mutationFn: (payload: ViewRepoPayload) =>
      api.views.view(payload.repoId, payload.orgId, payload.docId),
    onSuccess: (data: ViewResponse) => {
      queryClient.invalidateQueries({ queryKey: viewsKeys.all });
    },
  });
