import { useDispatch } from "react-redux";
import api from "source/api";
import { setAllActivities, upsertActivities } from "source/redux/activityFeed";
import { RepoType } from "source/Types";

export const useActivityFeed = () => {
  const dispatch = useDispatch();

  const fetchRepoActivities = async (repo: RepoType, showAll: boolean) => {
    api.activityFeed
      .repoActivities(repo.id, {
        show_all: showAll,
      })
      .then(({ data }) => {
        dispatch(upsertActivities(data.activities));
      })
      .catch((err) => {
        console.error("Error getting repo activities", err);
      });
  };

  const fetchAndUpsertActivities = async (showAll: boolean) => {
    api.activityFeed
      .allActivities({ show_all: showAll })
      .then(({ data }) => {
        dispatch(upsertActivities(data.activities));
      })
      .catch((err) => {
        console.error("Error getting all activities:", err);
      });
  };

  const fetchAndReplaceActivities = (showAll: boolean) => {
    api.activityFeed
      .allActivities({ show_all: showAll })
      .then(({ data }) => {
        dispatch(setAllActivities(data.activities));
      })
      .catch((err) => {
        console.error("Error getting all activities:", err);
      });
  };

  return {
    fetchRepoActivities,
    fetchAndUpsertActivities,
    fetchAndReplaceActivities,
  };
};
