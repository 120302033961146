import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Dispatch } from "redux";
import api from "source/api";
import { ActivityType } from "source/Types";
import { ReduxState } from ".";

type ActivitiesType = {
  [id: string]: ActivityType;
};

export type ActivityFeedReduxType = {
  activities: ActivitiesType;
  active: string | null;
};

const initialState: ActivityFeedReduxType = {
  activities: {} as ActivitiesType,
  active: null,
};

export const getActivityActive = (state: ReduxState) =>
  state.activityFeed.active;

export const getActivitiesDict = (state: ReduxState) =>
  state.activityFeed.activities;
export const getAllActivities = createSelector(
  getActivitiesDict,
  (activities) => Object.values(activities)
);

export const updateActivitiesByRepoId = async (
  dispatch: Dispatch,
  repoId: string,
  repoName: string
) => {
  await api.activityFeed.updateRepo(repoId, repoName);
  dispatch(updateActivityNamesByRepo({ repoId, repoName }));
};

export const updateActivitiesByDocId = async (
  dispatch: Dispatch,
  repoId: string,
  docId: string,
  docName: string
) => {
  await api.activityFeed.updateDoc(repoId, docId, docName);
  dispatch(updateActivityNamesByDoc({ docId, docName }));
};

const activityFeedSlice = createSlice({
  name: "activityFeed",
  initialState,
  reducers: {
    setAllActivities: (
      state: ActivityFeedReduxType,
      action: PayloadAction<ActivityType[]>
    ) => {
      state.activities = {};
      action.payload.forEach((activity: ActivityType) => {
        state.activities[activity.id] = {
          ...state.activities[activity.id],
          ...activity,
        };
      });
      return state;
    },
    upsertActivities: (
      state: ActivityFeedReduxType,
      action: PayloadAction<ActivityType[]>
    ) => {
      action.payload.forEach((activity) => {
        state.activities[activity.id] = {
          ...state.activities[activity.id],
          ...activity,
        };
      });
      return state;
    },
    updateActivityNamesByRepo: (
      state: ActivityFeedReduxType,
      action: PayloadAction<{ repoId: string; repoName: string }>
    ) => {
      Object.entries(state.activities).forEach(([key, val]) => {
        // Update repo_name of activities
        if (val.repo_id === action.payload.repoId) {
          const activity = state.activities[key];
          if (activity) activity.repo_name = action.payload.repoName;
        }
      });
      return state;
    },
    updateActivityNamesByDoc: (
      state: ActivityFeedReduxType,
      action: PayloadAction<{ docId: string; docName: string }>
    ) => {
      Object.entries(state.activities).forEach(([key, val]) => {
        // Update doc_name of activities
        if (val.meta.doc_id === action.payload.docId) {
          const activity = state.activities[key]?.meta;
          if (activity) activity.title = action.payload.docName;
        }
      });
      return state;
    },
    removeActivitiesById: (
      state: ActivityFeedReduxType,
      action: PayloadAction<string[]>
    ) => {
      action.payload.forEach((activityId) => {
        if (state.activities[activityId]) delete state.activities[activityId];
      });
      return state;
    },
    removeDocsById: (
      state: ActivityFeedReduxType,
      action: PayloadAction<string[]>
    ) => {
      action.payload.forEach((docId) => {
        Object.entries(state.activities).forEach(([key, val]) => {
          if (val.meta.doc_id === docId) delete state.activities[key];
        });
      });
      return state;
    },
    removeReposById: (
      state: ActivityFeedReduxType,
      action: PayloadAction<string>
    ) => {
      Object.entries(state.activities).forEach(([key, val]) => {
        if (val.repo_id === action.payload) delete state.activities[key];
      });
      return state;
    },
    setActivityActive: (
      state: ActivityFeedReduxType,
      action: PayloadAction<string | null>
    ) => {
      state.active = action.payload;
      return state;
    },
  },
});

export const {
  setAllActivities,
  upsertActivities,
  removeActivitiesById,
  removeDocsById,
  removeReposById,
  updateActivityNamesByRepo,
  updateActivityNamesByDoc,
  setActivityActive,
} = activityFeedSlice.actions;
export const activityFeedReducer = activityFeedSlice.reducer;
