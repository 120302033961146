import React from "react";
import { RepoType } from "source/Types";
import FolderOutlinedSharpIcon from "@mui/icons-material/FolderOutlined";

type RepoToolTipBreadCrumbProps = {
  repo: RepoType;
};

export const RepoTooltipBreadCrumb = ({ repo }: RepoToolTipBreadCrumbProps) => (
  <div className="flex flex-row flex-wrap items-center gap-2 p-1 text-center text-xs font-normal text-gray1">
    <div className="inline-block max-w-[150px] overflow-hidden text-ellipsis whitespace-nowrap">
      <FolderOutlinedSharpIcon className="mr-2 text-gray1" fontSize={"small"} />
      {repo.name}
    </div>
  </div>
);
