import React, { forwardRef } from "react";
import { ButtonProps, default as Button } from "./Button";
import { Spinner } from "../../index";

export interface LoadingButtonProps extends ButtonProps {
  onClick?: (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => void | Promise<void>;
  isLoading?: boolean;
}

const LoadingButton = forwardRef<HTMLButtonElement, LoadingButtonProps>(
  (props: LoadingButtonProps, ref) => {
    const {
      children,
      onClick,
      isLoading: isLoadingProps,
      iconLeft,
      disabled,
      ...rest
    } = props;
    const [isLoading, setIsLoading] = React.useState(false);

    return (
      <Button
        {...rest}
        ref={ref}
        disabled={isLoadingProps || isLoading || disabled}
        iconLeft={
          isLoadingProps || isLoading ? (
            <Spinner color="inherit" size={"1rem"} />
          ) : (
            iconLeft
          )
        }
        onClick={async (e) => {
          if (onClick) {
            setIsLoading(true);
            await onClick(e);
            setIsLoading(false);
          }
        }}
      >
        {children}
      </Button>
    );
  }
);

export default LoadingButton;
