import * as PopoverPrimitive from "@radix-ui/react-popover";
import { keyframes, styled } from "@stitches/react";

const slideUpAndFade = keyframes({
  "0%": { opacity: 0, transform: "translateY(2px)" },
  "100%": { opacity: 1, transform: "translateY(0)" },
});

const slideRightAndFade = keyframes({
  "0%": { opacity: 0, transform: "translateX(-2px)" },
  "100%": { opacity: 1, transform: "translateX(0)" },
});

const slideDownAndFade = keyframes({
  "0%": { opacity: 0, transform: "translateY(-2px)" },
  "100%": { opacity: 1, transform: "translateY(0)" },
});

const slideLeftAndFade = keyframes({
  "0%": { opacity: 0, transform: "translateX(2px)" },
  "100%": { opacity: 1, transform: "translateX(0)" },
});

const StyledContent = styled(PopoverPrimitive.Content, {
  marginTop: 10,
  minWidth: 180,
  maxWidth: 450,
  backgroundColor: "var(--card)",
  boxShadow: "0px 7px 24px -7px rgba(var(--shadow), 0.47)",
  "@media (prefers-reduced-motion: no-preference)": {
    animationDuration: "400ms",
    animationTimingFunction: "cubic-bezier(0.16, 1, 0.3, 1)",
    willChange: "transform, opacity",
    '&[data-state="open"]': {
      '&[data-side="top"]': { animationName: slideDownAndFade },
      '&[data-side="right"]': { animationName: slideLeftAndFade },
      '&[data-side="bottom"]': { animationName: slideUpAndFade },
      '&[data-side="left"]': { animationName: slideRightAndFade },
    },
  },
});

const itemStyles = {
  all: "unset",
  fontSize: 14,
  fontFamily: "Inter",
  lineHeight: 1,
  // color: "var(--text-primary)",
  borderRadius: 3,
  display: "flex",
  alignItems: "center",
  // padding: "5px 7px",
  margin: 0,
  userSelect: "none",
  cursor: "pointer",

  // "&[data-disabled]": {
  //   color: "var(--text-light)",
  //   pointerEvents: "none",
  // },

  // "&:focus": {
  //   backgroundColor: "var(--background-hover)",
  // },
};

const StyledTrigger = styled(PopoverPrimitive.Trigger, {
  // '&[data-state="open"]': {
  //   backgroundColor: "var(--background-hover)",
  // },
  ...itemStyles,
  border: "none",
  background: "transparent",
});

const StyledArrow = styled(PopoverPrimitive.Arrow, {
  fill: "white",
});

// Exports
export const Popover = PopoverPrimitive.Root;
export const PopoverContent = StyledContent;
export const PopoverTrigger = StyledTrigger;
export const PopoverArrow = StyledArrow;
export const PopoverClose = PopoverPrimitive.Close;
