import React from "react";
import { Call } from "@mui/icons-material";
import FilterListSharpIcon from "@mui/icons-material/FilterListSharp";
import { CalendarMonth } from "@mui/icons-material";
import AttachMoneySharpIcon from "@mui/icons-material/AttachMoneySharp";
import { FiltersConfig } from "source/constants/filters";
import { FilterType } from "source/Types";
import ListAltSharpIcon from "@mui/icons-material/ListAltSharp";
import SourceSharpIcon from "@mui/icons-material/SourceSharp";
import TextSnippetSharpIcon from "@mui/icons-material/TextSnippetSharp";
import LanguageSharpIcon from "@mui/icons-material/LanguageSharp";
import CloudQueueSharpIcon from "@mui/icons-material/CloudQueueSharp";
import LockOpenSharpIcon from "@mui/icons-material/LockOpenSharp";
import ArticleSharpIcon from "@mui/icons-material/ArticleSharp";
import WebSharpIcon from "@mui/icons-material/WebSharp";
import ImageSharpIcon from "@mui/icons-material/ImageSharp";
import AnalyticsSharpOutlinedIcon from "@mui/icons-material/AnalyticsOutlined";
import Image from "next/image";
import InsertDriveFileSharpIcon from "@mui/icons-material/InsertDriveFileSharp";
import DescriptionSharpIcon from "@mui/icons-material/DescriptionSharp";
import CallIcon from "@mui/icons-material/Call";
import CoPresentSharpIcon from "@mui/icons-material/CoPresentSharp";

export const getTypeIcon = (value: string) => {
  // TODO: customize these later (like for Findox)
  switch (value) {
    case "findox":
      return (
        <span className="pl-0.5">
          <Image
            alt="Findox"
            src="https://hebbia-emojis.s3.us-west-2.amazonaws.com/findox.png"
            width="18"
            height="18"
          />
        </span>
      );
    case "sec":
    case "10-K":
    case "10-K/A":
    case "10-Q":
    case "8-K":
    case "S-1":
    case "S-4":
    case "DEF 14A":
    case "DEFM14A":
      return <ListAltSharpIcon fontSize="inherit" />;
    case "earnings":
      return <Call fontSize="inherit" />;
    case "earnings_transcript":
      return <CallIcon fontSize="inherit" />;
    case "Investor Presentations":
      return <CoPresentSharpIcon fontSize="inherit" />;
    case "yahoo_finance_description":
      return <DescriptionSharpIcon fontSize="inherit" />;
    case "plaintext":
      return <TextSnippetSharpIcon fontSize="inherit" />;
    case "web":
    case "websearch":
      return <LanguageSharpIcon fontSize="inherit" />;
    case "box":
    case "tegus":
    case "gdrive":
    case "onedrive":
    case "postgres":
    case "sharepoint":
    case "bipsync":
      return <CloudQueueSharpIcon fontSize="inherit" />;
    case "hebbia_folder":
    case "local":
      return <SourceSharpIcon fontSize="inherit" />;
  }
  return <SourceSharpIcon fontSize="inherit" />;
};

const getMimeIcon = (value: string) => {
  switch (value) {
    case "application/msword":
      return <ArticleSharpIcon fontSize="inherit" />;
    case "text/html":
      return <WebSharpIcon fontSize="inherit" />;
    case "application/pdf":
      return <InsertDriveFileSharpIcon fontSize="inherit" />;
    // The Icon we want will not import in vercel TODO: Ammar
    // case "application/vnd.ms-powerpoint":
    //   return <SlideShowSharpIcon fontSize="inherit" />;
    case "text/plain":
      return <TextSnippetSharpIcon fontSize="inherit" />;
    // case "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
    //   return <DescriptionSharpIcon fontSize="inherit" />;
    case "application/vnd.ms-excel":
      return <AnalyticsSharpOutlinedIcon fontSize="inherit" />;
    case "image/jpeg":
    case "image/png":
      return <ImageSharpIcon fontSize="inherit" />;
  }
  return <FilterListSharpIcon fontSize="inherit" />;
};

export const getFilterIcon = (filter: FilterType) => {
  if (filter.value.toLowerCase() === "calendar")
    return <CalendarMonth fontSize="inherit" />;
  if (filter.key === FiltersConfig.FILTER_KEY_MARKET_CAP)
    return <AttachMoneySharpIcon />;
  if (filter.key === FiltersConfig.CUSTOM_FILTER_KEY_VDR)
    return <LockOpenSharpIcon fontSize="inherit" />;
  if (filter.key === FiltersConfig.INTEGRATION_FILTER_KEY)
    return getTypeIcon(filter.value.toLowerCase());
  if (filter.key === FiltersConfig.MIME_FILTER_KEY)
    return getMimeIcon(filter.value);
  return <FilterListSharpIcon fontSize="inherit" />;
};

type Props = {
  filter: FilterType;
  classOverride?: string;
};

export const FilterIcon = ({ filter, classOverride }: Props) => (
  <span className={`${classOverride} text-[20px] leading-3`}>
    {getFilterIcon(filter)}
  </span>
);
