import {
  CellIdToResultMapType,
  ColumnIdToToolMapType,
  ColumnIdYToCellMapType,
} from "source/redux/matrix";
import {
  CellContent,
  ReportCellType,
  HydratedReportCellType,
  ReportTableRow,
  HydratedDocumentCellType,
  DocumentResultType,
  HydratedAnswerCellType,
} from "../../components/matrix/types/cells.types";
import { RowCellState } from "source/api/matrix/types";
import { DEFAULT_EMPTY_ROWS } from "../../components/matrix/tables/config";
import { v4 as uuidv4 } from "uuid";
import logger from "source/utils/logger";
import { UserType } from "source/Types";
import { ViewConfig } from "../../components/matrix/types/reports.types";
import { difference } from "lodash";
import { isAnswerToolType } from "./tools";
import { FastBuildStatus } from "../../types/matrix/fastBuild.types";

export const getHydratedCellArrayForTab = (
  tabId: string,
  cells: ColumnIdYToCellMapType,
  cellResultMap: CellIdToResultMapType,
  formattedCellContent: ReportTableRow[]
): HydratedReportCellType[] => {
  if (formattedCellContent.length > 0) {
    return Object.entries(formattedCellContent)
      .map(([col, row]) =>
        Object.values(row).reduce((acc: HydratedReportCellType[], column) => {
          if (column.tool !== "padding") acc.push(column);
          return acc;
        }, [])
      )
      .flat();
  }
  const v = Object.keys(cells[tabId] ?? {})
    .map((col) =>
      Object.values(cells?.[tabId]?.[col] ?? {}).map((cell) => ({
        ...cell,
        ...cellResultMap[cell.id],
      }))
    )
    .flat();
  // TODO: Unfuck the function above to not type cast
  return v as HydratedReportCellType[];
};

export const isColumnLoading = (
  tabId: string,
  cells: ColumnIdYToCellMapType,
  cellResultMap: CellIdToResultMapType,
  columnId?: string
): boolean => {
  if (columnId === undefined) return false;
  return Object.values(cells?.[tabId]?.[columnId] ?? {}).some(
    (cell) => cellResultMap[cell.id]?.loading
  );
};

export const getLoadingCellsForColumn = (
  tabId: string,
  cells: ColumnIdYToCellMapType,
  cellResultMap: CellIdToResultMapType,
  x?: number
): ReportCellType[] => {
  if (x === undefined) return [];
  return Object.values(cells?.[tabId]?.[x] ?? {}).filter(
    (cell) => cellResultMap[cell.id]?.loading
  );
};

export const splitCellWithResult = (cell: HydratedReportCellType) => {
  const cellContent: CellContent = {
    id: cell.id,
    loading: cell.loading,
    error: cell.error,
    tool: cell.tool,
    result: cell.result,
    static_column_id: cell.static_column_id,
  };
  const reportCell: ReportCellType = {
    id: cell.id,
    x: cell.x,
    y: cell.y,
    tool: cell.tool,
    disabled: cell.disabled,
    static_column_id: cell.static_column_id,
  };

  return { reportCell, cellContent };
};

export const generateRowStateMap = (
  cells: HydratedReportCellType[],
  excludedCoordinateSet: Set<number>
) => {
  const rowStateMap: { [key: string]: RowCellState } = {};
  const rows = new Set<string>();
  // if a cell has answers, set that row to the answered state
  cells
    .filter((cell) => !excludedCoordinateSet.has(cell.y))
    .forEach((cell) => {
      const row = cell.y?.toString();
      // todo: maybe think of a better way to count rows here?
      rows.add(row);
      // Don't override existing rowStateMap
      if (
        cell.tool === "retrieve" &&
        (cell?.loading || cell?.error || cell?.result) &&
        rowStateMap[row] !== RowCellState.ANSWERED
      ) {
        rowStateMap[row] = RowCellState.DOCS;
      } else if (
        isAnswerToolType(cell.tool) &&
        (cell?.loading || cell?.error || cell?.result)
      ) {
        rowStateMap[row] = RowCellState.ANSWERED;
      }
    });

  // if there's a row in the table and we haven't assigned it a state yet
  // default to docs state here
  Array.from(rows).forEach((row) => {
    if (!(row in rowStateMap)) {
      rowStateMap[row] = RowCellState.DOCS;
    }
  });

  return rowStateMap;
};

type GetDocCellFromReportTabelRowParams = {
  row?: ReportTableRow;
  retrieveColumnId?: string;
  force?: boolean;
};

export const getDocCellFromReportTableRow = ({
  row,
  retrieveColumnId,
  force,
}: GetDocCellFromReportTabelRowParams):
  | HydratedDocumentCellType
  | undefined => {
  if (!row) return;

  if (!retrieveColumnId && force) {
    return Object.values(row).find(({ tool }) => tool === "retrieve") as
      | HydratedDocumentCellType
      | undefined;
  }

  return retrieveColumnId && row?.[retrieveColumnId]
    ? (row?.[retrieveColumnId] as HydratedDocumentCellType | undefined)
    : undefined;
};

export const getYFromReportTableRow = (
  params: GetDocCellFromReportTabelRowParams
) => getDocCellFromReportTableRow(params)?.y;

export const getDocIdFromReportTableRow = (
  params: GetDocCellFromReportTabelRowParams
) => getDocCellFromReportTableRow(params)?.result?.id;

export const updateRowCoordinate = (row: ReportTableRow, newY: number) => {
  Object.keys(row).forEach((column) => {
    const cell = row[column];
    if (cell)
      row[column] = {
        ...cell,
        y: newY,
      };
  });
  return row;
};

export const transformHydratedCellToReportCell = (
  cell: HydratedReportCellType
): ReportCellType => {
  const { id, tool, x, y, disabled, static_column_id } = cell;
  return {
    id,
    tool,
    x,
    y,
    disabled,
    static_column_id,
  };
};

type GeneratePaddingRowsProps = {
  numRows: number;
  readOnly?: boolean;
  docLimit?: number;
};

export const generatePaddingRows = ({
  numRows,
  readOnly,
  docLimit,
}: GeneratePaddingRowsProps): ReportTableRow[] => {
  if (readOnly) return [];
  const numPaddingRows =
    (numRows ? 0 : DEFAULT_EMPTY_ROWS) + (numRows === docLimit ? 0 : 1);
  return Array(numPaddingRows)
    .fill(0)
    .map((_, idx) => {
      return {
        id: {
          tool: "padding",
          y: numRows + idx,
          id: generateDefaultY(),
          static_column_id: "padding-column-id",
        },
      };
    });
};

export const generateDefaultY = () => `fish-man-${uuidv4()}`;

export const isPaddingRow = (data?: ReportTableRow) =>
  Object.values(data ?? {}).some((cell) => cell.tool === "padding");

export const checkAndAlertForErrorCells = (
  reportId: string,
  tabId: string,
  cells: HydratedReportCellType[],
  user?: UserType
) => {
  const errorCells = cells.filter((cell) => !!cell.error);
  if (errorCells.length > 0) {
    errorCells.forEach((c) =>
      logger.error(
        `Error with cell: ${c.error}. X: ${c.static_column_id}, Y: ${c.y}. Report: ${reportId}`
      )
    );

    logger.error("Report generated with error cells", {
      user: user,
      tabId: tabId,
      reportId: reportId,
    });
  }
};

type SortRowsProps = {
  rows: ReportTableRow[];
  viewConfig?: ViewConfig;
};

/** Sorts rows based on the view config. If the view config is not present, it will return the rows as is
 * @param rows - The server data for rows
 * @param viewConfig - The view configuration
 * @returns The rows sorted by the order in the view config
 */
export const sortRowsByViewConfig = ({ rows, viewConfig }: SortRowsProps) => {
  if (!viewConfig?.row_configuration) return [...rows];

  const staticColumnId = getDocCellFromReportTableRow({
    row: rows[0],
    force: true,
  })?.static_column_id;

  if (!staticColumnId) {
    logger.error(
      "Unable to sort rows based on view config. Can't find static column id"
    );
    return [...rows];
  }

  const orderMap = viewConfig.row_configuration.reduce<
    Record<number, { order: number }>
  >((acc, rowConfig) => {
    acc[rowConfig.y] = { order: rowConfig.order ?? 0 };
    return acc;
  }, {});

  // Note that rows without a y value will be sorted to the bottom
  return [...rows].sort((a, b) => {
    const aY = a[staticColumnId]?.y ?? 0;
    const bY = b[staticColumnId]?.y ?? 0;
    const aOrder = orderMap[aY]?.order ?? Infinity;
    const bOrder = orderMap[bY]?.order ?? Infinity;
    return aOrder - bOrder;
  });
};

/**
 * Gets the list of y-coordinates in the matrix
 * Calculates the missing cells in cellMap based on total y-coordinates - coordinates populated (loading, error, result)
 */
export const getNumUnrunCells = (
  rows: string[],
  cellMap?: {
    [x: string]: {
      [y: string]: ReportCellType;
    };
  },
  formattedCellContent?: ReportTableRow[],
  toolMap?: ColumnIdToToolMapType
) => {
  if (Object.values(cellMap ?? {}).length) {
    return Object.values(cellMap ?? {}).reduce((acc, column) => {
      const missingRows = difference(rows, Object.keys(column));
      return acc + missingRows.length;
    }, 0);
  } else if (formattedCellContent && toolMap) {
    const allColumns = Object.keys(toolMap);

    return formattedCellContent.reduce((acc, row) => {
      const missingColumns = difference(allColumns, Object.keys(row));
      return acc + missingColumns.length;
    }, 0);
  }
  return 0;
};

export const getExcludedCoordinates = (
  docStatusMap: { [docId: string]: FastBuildStatus },
  hydratedCells: HydratedReportCellType[]
) => {
  return new Set(
    hydratedCells
      .filter(
        (cell) =>
          cell.tool === "retrieve" &&
          docStatusMap[(cell.result as DocumentResultType)?.id] === "FAILED"
      )
      .map(({ y }) => y)
  );
};

// Type guard function for all answer tools
export const isAnswerCell = (
  cell: HydratedReportCellType,
  includeSummary = true
): cell is HydratedAnswerCellType => {
  return isAnswerToolType(cell.tool, includeSummary);
};
