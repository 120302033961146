import React from "react";
import { useQuery } from "@tanstack/react-query";
import { AppliedDate, FilterType } from "source/Types";
import { QueryContextFromKeys } from "../utils";
import { Dates } from "./dates";
import { definedDates } from "source/utils/common/time";
import CalendarMonthSharpIcon from "@mui/icons-material/CalendarMonthSharp";
import CalendarTodaySharpIcon from "@mui/icons-material/CalendarTodaySharp";
import DateRangeSharpIcon from "@mui/icons-material/DateRangeSharp";
import TodaySharpIcon from "@mui/icons-material/TodaySharp";
import { DATE_KEY_NAME, DateRange } from "source/redux/search";

type DatesType = {
  start_date: string;
  end_date: string;
};

// dates query key factory
const datesKeys = {
  all: [{ scope: "dates" }] as const,
  dateCounts: (repoId: string | undefined, dateFilter: DatesType[]) =>
    [{ ...datesKeys.all[0], repo: repoId, dates: dateFilter }] as const,
};

// Typed dates key factory context
type DatesQueryContext = QueryContextFromKeys<typeof datesKeys>;

const formatDates = (appliedDateFilter: AppliedDate, repoId?: string) => {
  if (!repoId) return [];
  const dates = Object.values(defaultDateRanges).map((date) => {
    let start_string = "";
    let end_string = "";
    if (date.startDate && date.endDate) {
      start_string = Math.floor(
        new Date(date.startDate).getTime() / 1000
      ).toString();
      end_string = Math.floor(
        new Date(date.endDate).getTime() / 1000
      ).toString();
    }
    return { start_date: start_string, end_date: end_string };
  });

  // If auto-applied filter on search, toggle it as displayed locally
  if (
    appliedDateFilter?.startDate &&
    appliedDateFilter?.endDate &&
    dateIsDifferent(appliedDateFilter)
  ) {
    const start_string = Math.floor(
      new Date(appliedDateFilter.startDate).getTime() / 1000
    ).toString();
    const end_string = Math.floor(
      new Date(appliedDateFilter.endDate).getTime() / 1000
    ).toString();

    dates.push({ start_date: start_string, end_date: end_string });
  }

  return dates;
};

export const useQueryDocDateCounts = (
  dateFilter: AppliedDate,
  repoId?: string
) => {
  const dates = formatDates(dateFilter, repoId);
  return useQuery({
    queryKey: datesKeys.dateCounts(repoId, dates),
    queryFn: docDateCountsFetcher,
    enabled: !!repoId,
  });
};

// fetcher function --> fetcher only accept keys from the queryKey factory
const docDateCountsFetcher = async (
  // extract named properties from the queryKey
  { queryKey: [{ repo, dates }] }: DatesQueryContext["dateCounts"]
) => Dates.getDocDateCounts(repo, dates);

type DateRangeProps = {
  label: string;
  icon: React.ReactNode;
  startDate: Date | undefined;
  endDate: Date | undefined;
};

export const defaultDateRanges: { [k in DateRange]: DateRangeProps } = {
  week: {
    label: "Past week",
    icon: <DateRangeSharpIcon fontSize="small" />,
    startDate: definedDates.oneWeekAgo,
    endDate: definedDates.endOfToday,
  },
  month: {
    label: "Past month",
    icon: <CalendarMonthSharpIcon fontSize="small" />,
    startDate: definedDates.oneMonthAgo,
    endDate: definedDates.endOfToday,
  },
  quarter: {
    label: "This quarter",
    icon: <TodaySharpIcon fontSize="small" />,
    startDate: definedDates.startOfThisQuarter,
    endDate: definedDates.endOfThisQuarter,
  },
  year: {
    label: "Past year",
    icon: <CalendarTodaySharpIcon fontSize="small" />,
    startDate: definedDates.oneYearAgo,
    endDate: definedDates.endOfToday,
  },
};

export const dateIsDifferent = (appliedDateFilter: AppliedDate) => {
  if (!appliedDateFilter?.startDate) return false;

  const startTime = Math.floor(
    new Date(appliedDateFilter.startDate).getTime() / 1000
  ).toString();

  const endTime = Math.floor(
    new Date(
      appliedDateFilter.endDate ?? new Date().toLocaleTimeString("en-US")
    ).getTime() / 1000
  ).toString();
  // If applied date isn't a default date append it to dates
  if (
    startTime &&
    endTime &&
    !Object.values(defaultDateRanges).find((d) => {
      if (
        d.startDate &&
        Math.floor(d.startDate.getTime() / 1000).toString() === startTime &&
        d.endDate &&
        Math.floor(d.endDate.getTime() / 1000).toString() === endTime
      )
        return true;
    })
  )
    return true;

  return false;
};

export const buildFilterTypeFromDateRange = (
  dateRange: AppliedDate
): FilterType | undefined => {
  // If applied date isn't a default date append it to dates
  if (dateIsDifferent(dateRange)) {
    const startStr = dateRange.startDate?.toLocaleDateString("en-us", {
      month: "2-digit",
      day: "2-digit",
      year: "2-digit",
    });
    const endDate = dateRange.endDate ?? new Date();
    const endStr = endDate.toLocaleDateString("en-us", {
      month: "2-digit",
      day: "2-digit",
      year: "2-digit",
    });

    return { key: DATE_KEY_NAME, value: `${startStr} - ${endStr}` };
  } else if (!!dateRange?.startDate && !!dateRange?.endDate) {
    const startTime = Math.floor(
      new Date(dateRange.startDate).getTime() / 1000
    ).toString();

    const endTime = Math.floor(
      new Date(dateRange.endDate).getTime() / 1000
    ).toString();
    const defaultMatch = Object.values(defaultDateRanges).find((d) => {
      if (
        d.startDate &&
        Math.floor(d.startDate.getTime() / 1000).toString() === startTime &&
        d.endDate &&
        Math.floor(d.endDate.getTime() / 1000).toString() === endTime
      )
        return true;
    });
    return defaultMatch
      ? { key: DATE_KEY_NAME, value: defaultMatch.label }
      : undefined;
  }
};
