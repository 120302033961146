import React, { useEffect, useState } from "react";
import { DocumentType } from "source/Types";
import api from "source/api";
import { ModalBody } from "source/components/library/Modal/ModalBody";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined";
import { setHotkeysEnabled } from "source/redux/ui";
import { useDispatch } from "react-redux";
import { ModalHeader } from "source/components/library/Modal/ModalHeader";
import { ModalFooter } from "source/components/library/Modal/ModalFooter";
import { Modal } from "source/components/library/Modal";

type Props = {
  doc: DocumentType;
  open: boolean;
  onClose: () => void;
  canEdit: boolean;
};

export const DocPasswordEntryModal = ({
  doc,
  open,
  onClose,
  canEdit,
}: Props) => {
  const dispatch = useDispatch();
  const [value, setValue] = useState(doc.data?.password || "");
  const [showPassword, setShowPassword] = useState(false);

  // Disable hotkeys across app when this modal is opened
  useEffect(() => {
    dispatch(setHotkeysEnabled(!open));
  }, [open]);

  const handleSave = async () => {
    if (!value.length || value === doc.data?.password) return;
    const { data } = await api.docs.setPassword(doc.id, value);
    onClose();
  };

  if (doc.failure_reason !== "Document is password protected" || !canEdit)
    return null;

  return (
    <Modal
      isOpen={open}
      onClose={onClose}
      containerStyleOverrides="bg-white overflow-auto max-h-[800px] max-w-[600px]"
      overlayStyleOverrides="bg-opacity-40"
    >
      <ModalHeader
        title="Please enter password"
        onClose={onClose}
        titleStyleOverride="text-base font-semibold leading-7"
        overrideStyles="border-b-0"
      />
      <ModalBody overrideStyles="px-6 flex flex-col gap-3.5">
        <div className="text-sm text-darkGray">
          It may take time for document to be available.
        </div>
        <div className="flex flex-row justify-between gap-2 border border-border2 p-3">
          <input
            type={showPassword ? "text" : "password"}
            placeholder="Password"
            className="w-full border-none outline-none"
            value={value}
            onChange={(e) => setValue(e.target.value)}
          />
          <span
            className="text-darkGray"
            onClick={() => setShowPassword((val) => !val)}
          >
            {showPassword ? (
              <VisibilityOffOutlinedIcon fontSize="small" />
            ) : (
              <VisibilityOutlinedIcon fontSize="small" />
            )}
          </span>
        </div>
      </ModalBody>
      <ModalFooter
        actions={[
          {
            label: "Cancel",
            variant: "secondary",
            onClick: onClose,
          },
          {
            label: "Enter",
            onClick: handleSave,
            isDisabled: !value,
            variant: "primary",
          },
        ]}
      />
    </Modal>
  );
};
