import React from "react";
import CallIcon from "@mui/icons-material/Call";
import LanguageIcon from "@mui/icons-material/Language";
import FolderIcon from "@mui/icons-material/FolderSharp";
import BackupTableIcon from "@mui/icons-material/BackupTable";
import DescriptionIcon from "@mui/icons-material/Description";
import RssFeedIcon from "@mui/icons-material/RssFeed";
import ImageIcon from "@mui/icons-material/Image";
import LockIcon from "@mui/icons-material/Lock";
import SdCardAlertIcon from "@mui/icons-material/SdCardAlert";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import { FontSizeMui } from "source/Types";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";

export type DocIconType =
  | "call"
  | "language"
  | "folder"
  | "image"
  | "backup_table"
  | "description"
  | "rss_feed"
  | "public"
  | "locked"
  | "OCR"
  | "error";

interface Props {
  iconName: DocIconType;
  fontSize: FontSizeMui;
  styleOverride?: any;
  onClick?: () => void;
  fontOverride?: string;
  display?: "block" | "flex";
}

const getIcon = (
  iconName: DocIconType,
  fontSize: FontSizeMui,
  fontOverride?: string
) => {
  switch (iconName) {
    case "call":
      return (
        <CallIcon
          fontSize={fontSize}
          style={fontOverride ? { fontSize: fontOverride } : {}}
        />
      );
    case "language":
      return (
        <LanguageIcon
          fontSize={fontSize}
          style={fontOverride ? { fontSize: fontOverride } : {}}
        />
      );
    case "folder":
      return (
        <FolderIcon
          fontSize={fontSize}
          style={fontOverride ? { fontSize: fontOverride } : {}}
        />
      );
    case "backup_table":
      return (
        <BackupTableIcon
          fontSize={fontSize}
          style={fontOverride ? { fontSize: fontOverride } : {}}
        />
      );
    case "description":
      return (
        <DescriptionIcon
          fontSize={fontSize}
          style={fontOverride ? { fontSize: fontOverride } : {}}
        />
      );
    case "rss_feed":
      return (
        <RssFeedIcon
          fontSize={fontSize}
          style={fontOverride ? { fontSize: fontOverride } : {}}
        />
      );
    case "public":
      return (
        <AccountBalanceIcon
          fontSize={fontSize}
          style={fontOverride ? { fontSize: fontOverride } : {}}
        />
      );
    case "image":
      return (
        <ImageIcon
          fontSize={fontSize}
          style={fontOverride ? { fontSize: fontOverride } : {}}
        />
      );
    case "locked":
      return (
        <LockIcon
          fontSize={fontSize}
          style={fontOverride ? { fontSize: fontOverride } : {}}
        />
      );
    case "OCR":
      return (
        <SdCardAlertIcon
          fontSize={fontSize}
          style={fontOverride ? { fontSize: fontOverride } : {}}
        />
      );
    case "error":
      return (
        <ErrorOutlineIcon
          fontSize={fontSize}
          style={fontOverride ? { fontSize: fontOverride } : {}}
        />
      );
  }
};

export const DocIcon = ({
  iconName,
  fontSize,
  styleOverride,
  onClick,
  fontOverride,
  display = "flex",
}: Props) => (
  <span
    className={`${display} h-4 w-4 items-center object-contain text-center align-middle text-secondary`}
    style={{ ...styleOverride }}
    onClick={onClick}
  >
    {getIcon(iconName, fontSize, fontOverride)}
  </span>
);
