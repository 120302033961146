import axios from "./axios";

export const Selections = {
  create: (docId: string, buildId: string, context: any, link: boolean) =>
    axios.post(`/v2/selections`, {
      doc_id: docId,
      build_id: buildId,
      context: context,
      is_link: link,
    }),
  get: (selectionId: string) => axios.get(`/v2/selections/${selectionId}`),
  getByTarget: (docId: string) =>
    axios.get("/v2/selections", { params: { doc_id: docId } }),
  delete: (selectionId: string) =>
    axios.delete(`/v2/selections/${selectionId}`),
};
