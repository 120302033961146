import { RepoIcon } from "source/components/shared/Icons/RepoIcon";
import { RepoContextMenu } from "source/components/sidebar/components/RepoContextMenu";
import React, { useState } from "react";
import { RepoType } from "source/Types";
import { ShowMoreButton } from "./ShowMoreButton";
import {
  getFilterSearchKey,
  getReportFilterKey,
  getTargetAppliedFilters,
} from "source/redux/advanced";
import { DOCUMENT_SECTION_TITLE } from "..";
import { useSelector } from "react-redux";
import { AppliedRow } from "../../AppliedRow";
import { RepoRow } from "../../RepoRow";
import { AddFolderButton } from "./AddFolder/AddFolderButton";
import { ModalTypes } from "source/components/modals/modals.types";
import { useModal } from "source/components/modals/useModal";

type AllReposProps = {
  bookmarkedRepos: RepoType[];
  repos: RepoType[];
  handleRepoClick?: (repoId: string) => void;
  handleRename?: (newTitle: string, id: string) => void;
  header: React.ReactNode;
  onAddFolder?: (name?: string) => void;
};

export const AllRepos = ({
  bookmarkedRepos,
  repos,
  handleRepoClick,
  handleRename,
  header,
  onAddFolder,
}: AllReposProps) => {
  const sectionKey = getReportFilterKey(DOCUMENT_SECTION_TITLE);
  const searchKey = getFilterSearchKey("repo");
  const [numToShow, setNumToShow] = useState(3);
  const moreToShow = repos.length - numToShow > 0;
  const addFolderModal = useModal(ModalTypes.AddFolderModal);
  const appliedFilters = useSelector(getTargetAppliedFilters);
  const appliedRepos = repos
    .concat(bookmarkedRepos)
    .filter((repo) => appliedFilters.some((f) => f.value === repo.id));

  const filteredBookmarkedRepos = bookmarkedRepos.filter(
    (repo) => !appliedRepos.some((r) => r.id === repo.id)
  );
  const filteredAllRepos = repos.filter(
    (repo) => !appliedRepos.some((r) => r.id === repo.id)
  );

  const handleShowMore = () => {
    setNumToShow(numToShow + 3);
  };
  return (
    <>
      <div className="mr-2.5 flex items-center justify-between">
        {header}
        {onAddFolder && (
          <AddFolderButton
            handleClick={() =>
              addFolderModal.open({
                onAddFolder,
              })
            }
          />
        )}
      </div>
      <div className="flex w-full flex-col">
        {appliedRepos.map((repo, idx) => {
          const matchingFilter = appliedFilters.find(
            (filter) => filter.value === repo.id
          );
          const isBookmarked = bookmarkedRepos.some((r) => r.id === repo.id);
          return (
            <RepoContextMenu repo={repo} key={`${repo.id}${idx}`}>
              <AppliedRow
                title={repo.name}
                filter={matchingFilter}
                id={repo.id}
                sectionKey={sectionKey}
                searchKey={searchKey}
                icon={<RepoIcon repo={repo} fontSize="small" />}
                handleClick={handleRepoClick}
                showContextMenu={true}
                tooltipText={"Folder filter is auto-applied"}
                bookmarked={isBookmarked}
                autoApplied={true}
              />
            </RepoContextMenu>
          );
        })}
        {filteredBookmarkedRepos.map((repo, idx) => (
          <RepoRow
            repo={repo}
            key={idx}
            handleRename={handleRename}
            handleRepoClick={handleRepoClick}
            bookmarked={true}
            showContextMenu={true}
            showChildren={true}
          />
        ))}
        {filteredAllRepos.slice(0, numToShow).map((repo, idx) => (
          <RepoRow
            repo={repo}
            key={idx}
            handleRename={handleRename}
            handleRepoClick={handleRepoClick}
            bookmarked={false}
            showContextMenu={true}
            showChildren={true}
          />
        ))}
        {moreToShow && <ShowMoreButton handleClick={handleShowMore} />}
      </div>
    </>
  );
};
