import { HoverPopover } from "source/components/shared/SearchMenu/components/HoverPopover";
import React, { useMemo, useState } from "react";
import { useQueryDocsByIds } from "source/api/docs/useQueryDocs";
import { useQueryReposByIds } from "source/api/repos/useQueryRepos";
import { DocRow } from "../../DocRow";
import { RepoRow } from "../../RepoRow";
import ActivityIndicator from "react-loader-spinner";

type SelectedFilesDropdownProps = {
  repoIds: string[];
  docIds: string[];
  trigger: React.ReactElement;
};

export const SelectedFilesDropdown = ({
  repoIds,
  docIds,
  trigger,
}: SelectedFilesDropdownProps) => {
  const [dropdownOpen, setDropDownOpen] = useState(false);

  // We want the targets we render to reset every time a user
  // enters a new dropdown hover, so we memoize on dropDownOpen
  const memoRepos = useMemo(() => repoIds, [dropdownOpen]);
  const memoDocs = useMemo(() => docIds, [dropdownOpen]);
  const repos = useQueryReposByIds(memoRepos);
  const docs = useQueryDocsByIds(memoDocs);

  const isLoading = docs.some(({ isLoading }) => isLoading);
  const disabled = !repos.length && !docs.length;

  return (
    <div
      onMouseEnter={() => setDropDownOpen(true)}
      onMouseLeave={() => setDropDownOpen(false)}
    >
      <HoverPopover
        id="selected-files-dropdown"
        triggerContent={trigger}
        disabled={disabled}
        triggerStyles={{ cursor: "pointer" }}
        popoverContent={
          isLoading ? (
            <div
              className="gap-y-.05 flex flex-row items-center justify-center border bg-white py-2 drop-shadow-md"
              style={{ maxWidth: 230, minWidth: 210 }}
            >
              <ActivityIndicator
                type="TailSpin"
                color="var(--text-light)"
                height={30}
                width={30}
              />
            </div>
          ) : (
            <div
              className="gap-y-.05 border bg-white py-2 drop-shadow-md"
              style={{ maxWidth: 230, minWidth: 210 }}
            >
              {repos.map(
                ({ data: repo }, idx) =>
                  repo && (
                    <RepoRow
                      repo={repo}
                      key={idx}
                      showChildren={false}
                      bookmarked={false}
                      searchValue={" "}
                      styleOverride={"bg-white hover:bg-blue3 active:bg-gray1"} // Force search value to remove sidebar input
                    />
                  )
              )}
              {docs.map(
                ({ data: doc }, idx) =>
                  doc && (
                    <DocRow
                      doc={doc.doc}
                      idx={idx}
                      showChildren={false}
                      key={idx}
                      depth={0}
                      searchValue={" "}
                      styleOverride={"bg-white hover:bg-blue3 active:bg-gray1"} // Force search value to remove sidebar input
                    />
                  )
              )}
            </div>
          )
        }
        align={"start"}
      ></HoverPopover>
    </div>
  );
};
