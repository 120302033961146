import { callbackUrl } from "source/constants";
import {
  BuildFlags,
  DeleteRepoResponse,
  DocumentType,
  RepoRoleType,
  RepoType,
  ResultType,
  UpdateRepoResponse,
  SynthesisContentsType,
  FastApiResponseType,
} from "source/Types";
import axios from "../axios";
import { CreateRepoPayload } from "./useQueryRepos";
import { DocDeletionResponse } from "../docs/types";
import { FindoxHolding } from "source/redux/findoxHoldings";
import { MergeFileStorageData } from "@mergeapi/react-merge-link/dist/types";
import { MergeIntegrationType } from "source/components/add-files/shared/MergeIntegrationCard";
import logger from "source/utils/logger";

interface SynthesisFeedbackRequestProps {
  score: number;
  synthesis: SynthesisContentsType;
  query: string;
  fromReports: boolean;
}

interface SummaryCardRequestProps {
  repoId: string;
  score: number;
  result: ResultType;
  query: string;
  summary?: string;
  fromReports: boolean;
}

type ParentDocsResponse = {
  docs: DocumentType[];
  failed_docs: DocumentType[];
};

type TargetPreviewDocsResponse = {
  docs: DocumentType[];
};

type CreateFolderResponse = {
  docs: DocumentType[];
};
export const Repos = {
  getRepoById: (repoId: string): Promise<RepoType> =>
    axios.get(`/v2/repos/${repoId}`).then(({ data }) => data.repo),

  getReposByIds: (repoIds: string[]): Promise<RepoType[]> =>
    axios
      .post(`/v2/repos/multiple`, { repo_ids: repoIds })
      .then(({ data }) => data.repos),

  getReposByTickers: (tickers: string[]): Promise<RepoType[]> =>
    axios.post("/v2/repos/tickers", { tickers }).then(({ data }) => data.repos),

  getSnpRepos: (): Promise<RepoType[]> =>
    axios.get("/v2/repos/top-100-s7p-repos").then(({ data }) => data.repos),

  getParentDocs: (repoId: string, params: { page?: number; size?: number }) =>
    axios
      .get(`/v2/repos/${repoId}/parents`, { params })
      .then(({ data }): ParentDocsResponse => data),

  getReports: (repoId: string, params: { orgId?: string }) =>
    axios.get(`/v2/repos/${repoId}/reports`, { params }),

  getTargetPreviewDocs: (
    repoId: string,
    report_id: string,
    doc_ids: string[]
  ): Promise<TargetPreviewDocsResponse> =>
    axios
      .post(`/v2/repos/${repoId}/target-preview`, {
        report_id: report_id,
        doc_ids: doc_ids,
      })
      .then(({ data }) => data.docs),

  // getAllDocs: (repoId: string): Promise<DocumentType[]> =>
  //   axios.get(`/v2/repos/${repoId}/docs`).then(({ data }) => data.docs),

  //  Not using rnn -- checko on this
  // getUnsupportedDocs: (repoId: string) =>
  //   axios.get(`/v2/repos/${repoId}/unsupported-docs`),

  getUserRepos: (): Promise<RepoType[]> =>
    axios.get(`/v2/repos`).then(({ data }) => data.repos), // gets the user's repos

  update: (
    repoId: string,
    repo: Partial<RepoType>
  ): Promise<UpdateRepoResponse> =>
    axios.put(`/v2/repos/${repoId}`, repo).then(({ data }) => data),

  create: ({
    name,
    is_private,
    organization_id,
  }: CreateRepoPayload): Promise<RepoType> =>
    axios
      .post(`/v2/repos`, {
        name,
        private: is_private,
        organization_id,
      })
      .then(({ data }) => data.repo),

  build: (repoId: string, buildFlags?: BuildFlags) =>
    axios.post(`/v2/repos/${repoId}/build`, { build_flags: buildFlags }),
  tableBuild: (repoId: string) =>
    axios.post(`/v2/repos/${repoId}/table-build`, {}),
  follow: (repoId: string, viewing: boolean) =>
    axios.post(`/v2/repos/${repoId}/follow`, {
      viewing: viewing,
    }),

  invite: (repoId: string, invitee_email: string, role: RepoRoleType) =>
    axios.post(`/v2/repos/${repoId}/members`, {
      invitee_email,
      role,
    }),

  getStatus: (repoId: string) => axios.get(`/v2/repos/${repoId}/status`),

  batchStatus: (
    repoIds: string[],
    includeCompleted?: boolean,
    fetchAll?: boolean,
    orgId?: string
  ) =>
    axios.post("/v2/repos/batch-status", {
      repo_ids: repoIds,
      include_completed: includeCompleted,
      org_id: orgId,
      fetch_all: fetchAll,
    }),

  getGDriveTokens: (repoId: string, code: string) =>
    axios.post(`/v2/repos/${repoId}/gdrive`, {
      code: code,
      redirect_uri: "postmessage",
    }),

  createFolder: (
    repoId: string,
    title: string,
    targetParentId?: string
  ): Promise<CreateFolderResponse> =>
    axios
      .post(`/v2/repos/${repoId}/folder`, {
        title,
        target_parent_id: targetParentId,
      })
      .then(({ data }) => data),

  directoryDownload: (repoId: string) =>
    axios.get(`/v2/repos/${repoId}/directory`),

  download: (repoId: string, flat?: boolean, docIds?: string[]) =>
    axios.post(`/v2/repos/${repoId}/download`, { flat: flat, doc_ids: docIds }),

  getDownloadStatus: (repoId: string, params: { build_id: string }) =>
    axios.get(`/v2/repos/${repoId}/download/status`, { params }),

  uploadBox: (
    repoId: string,
    fileIds: string[],
    accessToken: string,
    refreshToken: string,
    targetParentId?: string
  ) =>
    axios.post(`/v2/repos/${repoId}/box/upload`, {
      file_ids: fileIds,
      access_token: accessToken,
      refresh_token: refreshToken,
      target_parent_id: targetParentId,
    }),

  uploadGDrive: (
    repoId: string,
    fileIds: string[],
    refreshToken: string,
    targetParentId?: string
  ) =>
    axios.post(`/v2/repos/${repoId}/gdrive/upload`, {
      file_ids: fileIds,
      refresh_token: refreshToken,
      target_parent_id: targetParentId,
    }),

  uploadDropbox: (
    repoId: string,
    fileIds: string[],
    code: string,
    targetParentId?: string
  ) =>
    axios.post(`/v2/repos/${repoId}/dropbox/upload`, {
      file_ids: fileIds,
      code: code,
      target_parent_id: targetParentId,
      redirect_uri: callbackUrl,
    }),

  uploadEarnings: async (
    repoId: string,
    entities: string[],
    startDate?: string,
    endDate?: string,
    numberOfForms?: number,
    targetParentId?: string
  ) =>
    axios.post(`/v2/repos/${repoId}/earnings`, {
      entities: entities,
      start_date: startDate,
      end_date: endDate,
      last_k: numberOfForms,
      target_parent_id: targetParentId,
    }),

  uploadInternationalFilings: async (
    repoId: string,
    tickers: string[],
    exchange: string,
    numberOfForms: number,
    targetParentId?: string
  ) =>
    axios.post(`/v2/repos/${repoId}/international-filings`, {
      tickers: tickers,
      exchange: exchange,
      last_k: numberOfForms,
      target_parent_id: targetParentId,
    }),

  uploadOneNote: (repoId: string, code: string, targetParentId?: string) =>
    axios.post(`/v2/repos/${repoId}/onenote`, {
      code: code,
      redirect_uri: callbackUrl,
      target_parent_id: targetParentId,
    }),

  uploadLocal: async (
    repoId: string,
    files: Array<File>,
    targetParentId?: string
  ) => {
    const form = new FormData();

    for (let i = 0; i < files.length; i++) {
      const f = files[i];
      if (f) form.append("files", f);
    }
    if (targetParentId) form.set("target_parent_id", targetParentId);

    return axios.post(`/v2/repos/${repoId}/local`, form, {
      headers: { "Content-Type": "multipart/form-data" },
    });
  },

  uploadSec: async (
    repoId: string,
    secEntityType: string,
    entities: string[],
    forms: string[],
    startDate?: string,
    endDate?: string,
    numberOfForms?: number,
    targetParentId?: string
  ) =>
    axios.post(`/v2/repos/${repoId}/sec`, {
      id_type: secEntityType,
      entities: entities,
      forms: forms,
      start_date: startDate,
      end_date: endDate,
      last_k: numberOfForms,
      target_parent_id: targetParentId,
    }),

  uploadWebSearch: async (
    repoId: string,
    query: string,
    sites: string[],
    mimes: string[],
    count: number,
    targetParentId?: string
  ) =>
    axios.post(`/v2/repos/${repoId}/web-search`, {
      query,
      sites,
      mimes,
      count,
      target_parent_id: targetParentId,
    }),

  uploadMergeIntegration: (
    integrationType: MergeIntegrationType,
    repoId: string,
    orgId: string,
    selections: MergeFileStorageData[],
    targetParentId?: string
  ): Promise<FastApiResponseType<{ docs: DocumentType[] }>> =>
    axios.post(`/v2/repos/${repoId}/merge_integration`, {
      org_id: orgId,
      selections: selections,
      target_parent_id: targetParentId,
      integration_type: integrationType,
    }),

  uploadMagicLink: (
    repoId: string,
    urls: string[],
    recurse: number,
    targetParentId?: string
  ) =>
    axios.post(`/v2/repos/${repoId}/magic-link`, {
      urls: urls,
      recurse: recurse,
      target_parent_id: targetParentId,
    }),

  uploadRSSFeed: (repoId: string, url: string, doc_id?: string) =>
    axios.post(`/v2/repos/${repoId}/rss-feed`, {
      url: url,
      doc_id: doc_id,
    }),

  uploadAffinity: (repoId: string, key: string, targetParentId?: string) =>
    axios.post(`/v2/repos/${repoId}/affinity`, {
      key: key,
      target_parent_id: targetParentId,
    }),
  uploadTegus: (
    repoId: string,
    email: string,
    password: string,
    companyUrl: string,
    targetParentId?: string
  ) =>
    axios.post(`/v2/repos/${repoId}/tegus`, {
      email: email,
      password: password,
      company_url: companyUrl,
      target_parent_id: targetParentId,
    }),
  uploadPlaintext: (
    repoId: string,
    title: string,
    text: string,
    targetParentId?: string
  ) =>
    axios.post(`/v2/repos/${repoId}/plaintext`, {
      title: title,
      text: text,
      target_parent_id: targetParentId,
    }),

  // can't type return for this method bc Patrick can't figure it out
  upvoteDownvote: ({
    repoId,
    score,
    result,
    query,
    summary,
    fromReports,
  }: SummaryCardRequestProps) => {
    const answer = JSON.parse(JSON.stringify(result));
    if (summary) answer.summary = summary;

    axios.post(`/v2/feedback`, {
      query,
      score,
      result: JSON.stringify(answer),
      repo_id: repoId,
      from_reports: fromReports,
    });
  },

  // can't type return for this method bc Patrick can't figure it out
  synthesisUpvoteDownvote: ({
    score,
    synthesis,
    query,
    fromReports,
  }: SynthesisFeedbackRequestProps) => {
    axios
      .post(`/v2/feedback`, {
        query,
        score,
        result: synthesis,
        from_reports: fromReports,
      })
      .catch((err) => {
        logger.error("Unable to submit feedback", err);
      });
  },

  delete: (repoId: string): Promise<DeleteRepoResponse> =>
    axios.post(`/v2/repos/${repoId}/delete`).then(({ data }) => data),

  deleteMultipleDocs: (
    repoId: string,
    docIds: string[]
  ): Promise<DocDeletionResponse> =>
    axios
      .post(`/v2/repos/${repoId}/doc-multi-delete`, { doc_ids: docIds })
      .then(({ data }): DocDeletionResponse => data),

  updateMember: (repoId: string, userId: string, role: RepoRoleType) =>
    axios.put(`/v2/repos/${repoId}/members`, {
      user_id: userId,
      role,
    }),

  removeMember: (repoId: string, userId: string) =>
    axios.post(`/v2/repos/${repoId}/members/remove`, {
      user_id: userId,
    }),

  getMembers: (repoId: string) => axios.get(`/v2/repos/${repoId}/members`),

  addFindox: (orgId: string, holdings: string[]) =>
    axios.post(`/v2/repos/findox-add`, {
      holdings,
      org_id: orgId,
      sync: true,
    }),

  getFindoxHoldings: (orgId?: string): Promise<{ holdings: FindoxHolding[] }> =>
    axios
      .get(`/v2/repos/findox-holdings`, { params: { org_id: orgId } })
      .then(({ data }) => data),

  requestFindoxSync: (repoId: string) =>
    axios.post(`/v2/repos/${repoId}/request-findox-sync`),

  getSearchDisabled: (repoId: string, docIds: string[]): Promise<boolean> =>
    axios
      .post(`/v2/repos/${repoId}/get-search-disabled`, { doc_ids: docIds })
      .then(({ data }) => data.disabled),

  getChildrenCount: (repoId: string): Promise<number> =>
    axios
      .get(`/v2/repos/${repoId}/count_children`)
      .then(({ data }) => data.count),
};
