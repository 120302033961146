import axios from "../axios";
import {
  BackendReportFilter,
  DocumentType,
  FilterType,
  RepoType,
  SearchParams,
} from "source/Types";
import { EmojiData } from "emoji-mart";

export enum UnsupportedReason {
  PRIVATE = "Private Company",
  INACTIVE = "Inactive Company",
}

type FilterSuggestParams = {
  keys: string[];
  query: string;
};

type SearchFilterParams = {
  repo_ids: string[];
  query: string;
};

export type BatchKeysReposParams = {
  repo_ids: string[];
  doc_ids: string[];
  filters: FilterType[];
  page: number;
  size: number;
  toggled?: boolean;
};

export type DocCountParams = {
  repo_ids: string[];
  doc_ids: string[];
  filters: FilterType[];
};

type ListFiltersByOrgIdResponse = {
  filters: Record<string, string[]>;
};

type FilterPublicReposResponse = {
  company_repos: RepoType[];
  public_repos: RepoType[];
};

type SearchDocsResponse = {
  docs: DocumentType[];
  ancestors: DocumentType[];
};

type RecommendedDocsResponse = {
  count: number;
  records: CompanySearchResponseRecord[];
};

export type CompanySearchResponseRecord = {
  cik?: string;
  emoji?: EmojiData;
  company_id: number;
  tickers: string[];
  exchange_names: string[];
  name: string;
  integration?: string;
};

export type UnsupportedCompanySearchHit = {
  company_id: number;
  name: string;
  tickers: string[];
  exchange_names: string[];
  emoji?: EmojiData;
  failure_reason?: UnsupportedReason;
};

export type FilterCompaniesResponse = {
  count: number;
  records: CompanySearchResponseRecord[];
  closest_unsupported?: UnsupportedCompanySearchHit;
};

export type CompanySearchParams = {
  query: string;
  org_id?: string;
  limit?: number;
};

export type SingleCompanyParams = {
  company_id: number;
};

export const Filters = {
  filterPublicRepos: (
    params: Partial<SearchParams>
  ): Promise<FilterPublicReposResponse> =>
    axios.post("/v2/filter/repos/public", params).then(({ data }) => data),
  filterDocs: (params: Partial<SearchParams>): Promise<SearchDocsResponse> =>
    axios.post("/v2/filter/docs", params).then(({ data }) => data),
  filterSubstringDocs: (
    params: Partial<SearchParams>
  ): Promise<SearchDocsResponse> =>
    axios
      .post("/v2/filter/substring-match-docs", params)
      .then(({ data }) => data),
  filterRecommendedCompanies: (): Promise<RecommendedDocsResponse> =>
    axios.get("/sheets/docs/recommended-companies").then(({ data }) => data),
  filterPublicCompanies: (
    params: CompanySearchParams
  ): Promise<FilterCompaniesResponse> =>
    axios.get("/sheets/docs/companies", { params }).then(({ data }) => data),
  filterSingleCompany: async (
    params: Partial<SingleCompanyParams>
  ): Promise<CompanySearchResponseRecord | undefined> => {
    const response = await axios.get(
      `/sheets/docs/single-company/${params.company_id}`
    );
    return response.data.records.length == 1
      ? response.data.records[0]
      : undefined;
  },
  listRepoFilters: (repo_id: string) =>
    axios.post(`/v2/filter/${repo_id}/list`),
  listFilterDocs: (
    repo_id: string,
    filters: FilterType[],
    intersection_doc_ids: string[],
    page = 0,
    start_date?: number,
    end_date?: number,
    size = 8
  ) =>
    axios.post(`/v2/filter/${repo_id}/matching-docs-paginated`, {
      filters,
      page,
      size,
      intersection_doc_ids,
      start_date,
      end_date,
    }),
  listFiltersByOrgId: (org_id?: string): Promise<ListFiltersByOrgIdResponse> =>
    axios
      .get(`/v2/filter/batch-filters`, { params: { org_id: org_id } })
      .then(({ data }) => data),
  searchRepoFilters: (params: SearchFilterParams) =>
    axios.post(`/v2/filter/repo-filters-search`, params),
  searchDocFilters: (params: SearchFilterParams) =>
    axios.post(`/v2/filter/doc-filters-search`, params),
  listDocsByRepoIdsAndKeys: (
    params: BatchKeysReposParams
  ): Promise<DocumentType[]> =>
    axios
      .post(`/v2/filter/repo-filter-docs`, params)
      .then(({ data }) => data.docs),
  countReposDocs: (params: DocCountParams): Promise<CountReposDocsResponse> =>
    axios.post("/v2/filter/repo-doc-counts", params),
};

type CountReposDocsResponse = {
  data: {
    counts: { [repoId: string]: number };
  };
};

export const getTickersFromFilter = (filter: BackendReportFilter): string[] => {
  let valuesList: string[] = [];
  if (filter.key === "ticker") {
    // Base case: if the key matches, add the value to the list
    valuesList.push(String(filter.values));
  } else if (Array.isArray(filter.values)) {
    // Recursive case: iterate over nested filters
    filter.values.forEach((nestedFilter: BackendReportFilter) => {
      valuesList = valuesList.concat(getTickersFromFilter(nestedFilter));
    });
  }
  return valuesList;
};
