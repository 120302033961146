import { useDispatch } from "react-redux";
import api from "source/api";
import { ActionType, DocumentType, Meta, ReportType } from "source/Types";
import { useCurrentRepo } from "./repos/useCurrentRepo";
import { upsertActivities } from "source/redux/activityFeed";
import logger from "source/utils/logger";

export const useUpdateActivityFeed = () => {
  const repo = useCurrentRepo(true);
  const dispatch = useDispatch();

  const handleDocUpdate = async (docs: DocumentType[]) => {
    if (!repo) return;
    api.activityFeed
      .addBatchActivities(docs, repo.id)
      .then(({ data }) => {
        dispatch(upsertActivities(data.activities));
      })
      .catch((err) => {
        logger.error("Error in batch adding to activity feed:", err);
      });
  };

  const handleReportUpdate = async (report: ReportType) => {
    if (!repo) return;
    const { created_at, id, title } = report;
    const action_type: ActionType = "generate_report";

    // Set metadata to properly load in each entry in activity feed
    const meta: Meta = {};
    meta["report_id"] = id;
    meta["title"] = title;

    api.activityFeed
      .addActivity(created_at, action_type, meta, repo.id)
      .then(({ data }) => {
        dispatch(upsertActivities(data.activities));
      })
      .catch((err) => {
        logger.error("Error uploading report to activity feed:", err);
      });
  };

  return { handleDocUpdate, handleReportUpdate };
};
