import { useDispatch, useSelector } from "react-redux";
import api from "source/api";
import { openPrettyAlert } from "source/utils/helpers";
import {
  getNumDocsFailed,
  getFinishedDocs,
  getNumDocsRemaining,
} from "source/redux/build";
import { insertActiveBuild } from "source/redux/activeBuilds";
import { BuildFlags, RepoType } from "source/Types";
import { useQueryDocCounts } from "source/api/docs/useQueryDocs";
import { setRepo } from "source/api/repos/useQueryRepos";
import {
  getTargetDocs,
  getTargetNonPredefinedFilters,
} from "source/redux/advanced";

export const useBuildStatus = (repo?: RepoType) => {
  const dispatch = useDispatch();

  const numDocsFailed = useSelector(getNumDocsFailed);
  const numDocsRemaining = useSelector(getNumDocsRemaining);
  const numFinishedDocs = useSelector(getFinishedDocs);
  const allFailedDocs =
    numDocsFailed === numDocsRemaining + numFinishedDocs && numDocsFailed != 0;

  const isBuilding = (repo?.status_percentage ?? 100) !== 100 && repo?.has_docs;
  const firstBuild = isBuilding && !repo?.last_built;
  const notSearchable =
    !repo?.last_built || // allow search if a repo has an old build
    !repo?.has_docs;

  const docTargets = useSelector(getTargetDocs);
  const filterTargets = useSelector(getTargetNonPredefinedFilters);
  const countsForDocTargets = useQueryDocCounts(
    docTargets,
    filterTargets,
    repo?.id
  );

  const isBuildingStatic =
    countsForDocTargets?.building && !countsForDocTargets?.success;

  const handleBuild = async (buildFlags?: BuildFlags) => {
    try {
      if (!repo) return;
      const { data } = await api.repos.build(repo.id, buildFlags);
      setRepo({ ...data.repo, status_percentage: 0 });
      dispatch(
        insertActiveBuild({
          action: "repo_build",
          repoId: data.repo.id,
          status: "processing",
        })
      );
    } catch (e: any) {
      console.error(e);
      openPrettyAlert({
        title: "Build Error",
        text: "Unable to index this project at this time. Please try again later or contact support@hebbia.ai if the problem persists.",
        icon: "error",
        error: e,
        url: window.location.href,
      });
    }
  };

  return {
    allFailedDocs,
    isBuilding, // using status endpoint
    isBuildingStatic, // point in time, not using /status endpoint
    notSearchable,
    firstBuild,
    handleBuild,
  };
};
