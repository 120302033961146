import { ColumnIdToToolMapType } from "source/redux/matrix";
import {
  ReportCellType,
  ReportTableRow,
} from "../../components/matrix/types/cells.types";

export const updateRowsAfterDelete = (
  reportRows: ReportTableRow[],
  columnsToDelete: string[]
) => {
  const newReportRows = reportRows.map((row) => {
    columnsToDelete.forEach((columnId) => {
      delete row[columnId];
    });
    return row;
  });

  return newReportRows;
};

export const updateToolsAfterDelete = (
  tools: ColumnIdToToolMapType,
  columnsToDelete: string[]
) => {
  columnsToDelete.forEach((columnId) => {
    delete tools[columnId];
  });
  return tools;
};

export const updateCellsAfterDelete = (
  tabCells: { [columnId: string]: { [y: string]: ReportCellType } },
  columnsToDelete: string[]
) => {
  columnsToDelete.forEach((columnId) => {
    delete tabCells[columnId];
  });
  return tabCells;
};

export const updateGridConfigurationAfterDelete = <T extends { colId: string }>(
  items: T[],
  deletedColumnIds: string[]
): T[] => {
  const deletedColsSet = new Set(deletedColumnIds);
  return items.filter((item) => !deletedColsSet.has(item.colId));
};
