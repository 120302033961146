import _ from "lodash";
import React from "react";
import { CitationListPopover } from "../../components/library/Citations/CitationListPopover";

// Matches citations in the form of [1] [1 , 2] [1, 2,3,42, 1], [{1}], [{{4}}], {1}, etc.
export const matchCitation =
  /(?:\s*,\s*|\s+)?(?:\[|\{)+\s*([0-9]+(?:\s*,\s*[0-9]+)*)\s*(?:\]|\})+/g;

// Matches range citations in the form of [1-10], [5 - 10], [{1-100}], [{{10-20}}], {1-10}.
export const matchRangeCitation =
  /(?:\[|\{)\s*\{?\s*(?:[0-9]+)\s*-\s*(?:[0-9]+)\s*\}?\s*(?:\]|\})/g;

// Removes brackets from the citation text
export const removeBrackets = /[0-9]+/g;

// Replace brackets and curly braces with empty string
export const removeBracketsAndBraces = (text: string) => {
  return text
    ?.replace("[", "")
    .replace("{", "")
    .replace("]", "")
    .replace("}", "");
};

export const convertRangeCitationsToIndividualCitations = (text: string) => {
  if (text.match(matchRangeCitation)) {
    text = text.replace(matchRangeCitation, (match) => {
      const start = parseInt(
        removeBracketsAndBraces(match.split("-")[0]?.trim() ?? "")
      );
      const end = parseInt(
        removeBracketsAndBraces(match.split("-")[1]?.trim() ?? "")
      );
      if (isNaN(start) || isNaN(end)) return match;
      if (end <= start) return match;
      return `[${start}][${end}]`;
    });
  }
  return text;
};

export const getCitationNumbers = (str: string) => {
  const matches = Array.from(str.matchAll(matchCitation)).map((i) => {
    const contents = i[1];
    if (contents === undefined) return undefined;
    return contents.split(",").map((i) => i.trim());
  });
  return _.flatten(matches).filter((i) => i !== undefined) as string[];
};

export const replaceCitationBrackets = (text: string): string[] => {
  const matches = Array.from(text.matchAll(removeBrackets));
  const citations: string[] = [];
  for (const match of matches) {
    if (match.index) {
      citations.push(match[0]);
    }
  }
  return citations;
};

export const replaceTextWithCitations = (
  text: string,
  createCitationLink: (
    citationNumber: string,
    isInDropdown: boolean
  ) => React.ReactNode,
  hideCitations?: boolean
) => {
  const components: React.ReactNode[] = [];
  const matches = Array.from(text.matchAll(matchCitation));

  let offset = 0;
  let consecutiveCitationCount = 0;
  let visibleCitations: string[] = [];
  let extraCitations: string[] = [];

  const flushCitations = () => {
    if (visibleCitations.length > 0) {
      components.push(
        ...visibleCitations.map((num) => createCitationLink(num, false))
      );

      // If we have more than 5 citations, show them in a popover
      if (extraCitations.length === 1) {
        components.push(createCitationLink(extraCitations[0]!, false));
      } else if (extraCitations.length > 1) {
        components.push(
          <CitationListPopover size="medium">
            {extraCitations.map((num) => createCitationLink(num, true))}
          </CitationListPopover>
        );
      }

      visibleCitations = [];
      extraCitations = [];
      consecutiveCitationCount = 0; // Reset consecutive citation count
    }
  };

  for (const match of matches) {
    if (match.index) {
      if (offset !== match.index) {
        // Flush any citations before adding raw text
        flushCitations();

        components.push(<>{text.slice(offset, match.index) + " "}</>);
      }

      offset = match.index + match[0].length;

      if (!hideCitations) {
        const citations = replaceCitationBrackets(match[0]);
        for (const citation of citations) {
          // Sanity check
          if (!citation) continue;

          if (consecutiveCitationCount < 4) {
            visibleCitations.push(citation);
          } else {
            extraCitations.push(citation);
          }

          consecutiveCitationCount++;
        }
      }
    }
  }

  // Flush ending citations
  flushCitations();

  // Add remaining text (generally a .)
  if (offset < text.length) {
    components.push(<>{text.slice(offset)}</>);
  }
  return components;
};

export const removeCitations = (answer: string) => {
  return answer.replaceAll(matchCitation, "");
};
