import { BookmarkType, RepoType } from "source/Types";
import axios from "../axios";

type GetBookmarkType = {
  bookmarks: BookmarkType[];
  repos: RepoType[];
};

export const Bookmarks = {
  getBookmarks: (): Promise<GetBookmarkType> =>
    axios.get("/v2/bookmarks/repo/").then(({ data }) => data),
  bookmark: (repo_id: string): Promise<BookmarkType> =>
    axios
      .post(`/v2/bookmarks/${repo_id}/bookmark`)
      .then(({ data }) => data.bookmark),
  delete: (repo_id: string) =>
    axios.delete(`/v2/bookmarks/${repo_id}/delete`).then(({ data }) => data),
};
