import _ from "lodash";
import { MittEmitter } from "next/dist/shared/lib/mitt";
import { RouterEvent, useRouter } from "next/router";
import { useCallback } from "react";
import { DemoModeType, GroupByType, SortKey } from "source/Types";

export type NextRouter = {
  query: QueryParams;
  pathname: string;
  route: string;
  asPath: string;
  prefetch: (url: string) => void;
  push: (url: any, as?: any | undefined, options?: any) => Promise<boolean>;
  /**
   * Go back in history
   */
  back(): void;
  events: MittEmitter<RouterEvent>;
};

export type QueryParams = {
  /**
   * pathname i.e. current URL
   */
  pathname?: string; // hydrated by next/router, the full URL path

  /**
   * the doc or repo id, hydrated by next/router bc the folder structure [id]
   */
  id?: string;

  /**
   * search query string. Missing/undefined if not searching.
   */
  query?: string;

  /**
   * Grid report id. Renders the grid report page within a repo.
   */
  report?: string;

  /**
   * Template id. Renders template page within a repo.
   */
  template?: string;

  /**
   * Pin selection id, but for what again? (TODO)
   */
  selection?: string;

  /**
   * For search debugging: whether to use SpiceBERT reranker in search or just display results as sorted by Vespa.
   */
  should_rerank?: boolean;

  /**
   * For search debugging: use staging SpiceBERT.
   */
  hit_staging_reranker?: boolean;

  /**
   * For search debugging: whether to use document title in query-passage similarity scoring.
   */
  use_titles?: boolean;

  /**
   * For search evaluation: return results directly from ColBERT without reranking or
   * postprocessing to switch ordering of results (display as-is as returned by ColBERT)
   */
  raw_colbert_results?: boolean;

  /**
   * Use hyde searcher on the backend.
   */
  use_hyde?: boolean;

  /**
   * Hyde Prompt prefix
   */
  hyde_prefix?: string | null;

  /**
   * Disable company detection, a feature which tries to extract a company name from the user query at search time.
   */
  disable_company_detection?: boolean;

  /**
   * Whether to include a generative QA response to the user query.
   */
  gen_web_answer?: boolean;

  /**
   * Displays side-by-side comparison mode: compare Hebbia results to BM25 keyword search.
   */
  demo_mode_type?: DemoModeType;

  /**
   * Sort results by this criteria.
   */
  sort_by?: SortKey;

  /**
   * Group results based on this criteria.
   */
  group_by?: GroupByType;

  /**
   * Don't return keyword results to the user query (i.e. only retrieve semantic results from Vespa).
   */
  no_keywords?: boolean;

  /**
   * Disable spellcheck for this user query.
   */
  disable_spell_check?: boolean;

  /**
   * Don't try to suggest a date range for this user query.
   */
  disable_date_suggestion?: boolean;

  /**
   * Only search documents from after this start date.
   */
  start_date?: string;

  /**
   * Only search documents from before this start date.
   */
  end_date?: string;

  /**
   * TODO: explain
   */
  title?: string;

  /**
   * TODO: typed DateRange
   */
  date_range?: string;

  /**
   * Metadata filters: a JSON-encoded dictionary mapping arbitrary filter keys to values
   */
  filters?: string;

  use_rephrase?: boolean;

  /**
   * Metadata filters: a JSON-encoded list storing repo ids
   */
  repo_ids?: string;

  /**
   * Metadata filters: a JSON-encoded list storing doc ids
   */
  doc_ids?: string;

  /**
   * Current table report (sheet) ID
   */
  matrix_id?: string;

  /**
   * Current matrix tab ID
   */
  tab_id?: string;

  /**
   * The active org when the search was made
   */
  org_id?: string;

  /**
   * The doc id of the document for which we want to show the modal
   */
  doc_id?: string;

  /**
   * The repo id of the document for which we want to show the modal
   */
  repo_id?: string;

  /**
   * The cell id of the document for which we want to show the modal
   */
  cell_id?: string;

  /**
   * Whether to show the explore templates section of matrix home by default
   */
  explore_templates?: boolean;

  /**
   * If true, will trigger loading all the cells in the matrix. This is used
   * when a matrix is initialized with a preset list of documents to load all
   * the cell content associated with them
   */
  add_docs_on_load?: boolean;

  open_share_modal?: boolean;

  matrix_template_id?: string;
};

type MyNullable<T> = { [K in keyof T]: T[K] | null };
export type QueryParamsNullable = MyNullable<QueryParams>;

type UpdateQueryParamsOptions = {
  paramUpdates?: QueryParamsNullable;
  clearAllParams?: boolean;
};

interface GetRouter {
  router: NextRouter;
  updateQueryParams: (options: UpdateQueryParamsOptions) => void;
  clearParams: (filters: string[]) => void;
}

/**
 *  Hook to get a typed version of NextJS's Router object: https://nextjs.org/docs/api-reference/next/router.
 *  Only a subset of router fields are returned, e.g. query and pathname.
 */
export const useGetRouter = (): GetRouter => {
  const router = useRouter();

  /**
   * Updates query params with new ones. If a new param is set to null, removes this param from query.
   * Optionally clears all query params (except id, which is specially set by the router)
   */
  const updateQueryParams = useCallback(
    ({
      paramUpdates = {},
      clearAllParams = false,
    }: UpdateQueryParamsOptions): void => {
      if (clearAllParams) {
        router.query = { id: router.query.id };
        return;
      }

      const mergedParams: QueryParamsNullable = {
        ...(router.query || {}),
        ...paramUpdates,
      };

      const newQueryParams: Record<string, string> = _.reduce(
        Object.keys(mergedParams),
        (newParams, key) => {
          if (mergedParams[key] !== null) {
            newParams[key] = mergedParams[key];
          }
          return newParams;
        },
        {}
      );

      const query = new URLSearchParams(newQueryParams);
      router.replace(`${router.pathname}?${query.toString()}`);
    },
    [router]
  );

  /**
   * Clears all query params passed in `filters`.
   */
  const clearParams = (filters: string[]) => {
    const paramUpdates: QueryParamsNullable = {};
    filters.forEach((filter) => {
      paramUpdates[filter] = null;
    });

    updateQueryParams({ paramUpdates });
  };

  return {
    router: {
      query: router.query as QueryParams,
      pathname: router.pathname,
      prefetch: router.prefetch,
      asPath: router.asPath,
      route: router.route,
      push: router.push,
      back: router.back,
      events: router.events,
    },
    updateQueryParams,
    clearParams,
  };
};
