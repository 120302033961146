import axios from "axios";
import {
  clearAuthToken,
  getAuthToken,
  setAuthToken,
} from "source/auth/localStorage";
import fetchAccessToken from "source/auth/fetchAccessToken";
import logger from "source/utils/logger";

const authToken = async () => {
  const accessToken = getAuthToken();
  if (accessToken) return accessToken;

  const response = await fetchAccessToken();
  const fetchedToken = response.accessToken;

  if (!accessToken && typeof window !== "undefined")
    window.location.href = "/api/auth/logout";
  else setAuthToken(fetchedToken);

  return fetchedToken;
};

const authenticatedAxiosFactory = () => {
  const instance = axios.create({
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "Access-Control-Max-Age": 86400,
    },
  });

  // Assumes that requests are coming from the browser
  instance.interceptors.request.use(
    async (config) => {
      let accessToken = getAuthToken();
      if (!accessToken) {
        const response = await fetchAccessToken();
        accessToken = response.accessToken;

        if (!accessToken && typeof window !== "undefined")
          window.location.href = "/api/auth/logout";
        else setAuthToken(accessToken);
      }
      if (accessToken) {
        config.headers = config.headers || {};
        config.headers.Authorization = `Bearer ${accessToken}`;
      }
      return config;
    },
    async (error) => Promise.reject(error)
  );

  instance.interceptors.response.use(
    (response) => response,
    async (error) => {
      // If the error response is a 401/403, our auth token is invalid
      // For now we just clear, but if we have a refresh token, we could try to refresh it.
      const status = error?.response?.status;
      if (status === 401) {
        clearAuthToken();
      }
      return Promise.reject(error);
    }
  );

  return instance;
};

export const api = authenticatedAxiosFactory();

export const fetchWithAuth = async (url: string): Promise<Response> => {
  const token = await authToken();
  return fetch(url, {
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

// TODO: we can infer the object's mime type by looking at the X-Amz-Meta-Mime
// header attached to the response payload, but to access this from a CORS request we
// need to do something with Access-Control-Allow-Headers in the request response.
export const getObjectUrl = async (url: string): Promise<string> => {
  const resp = await fetchWithAuth(url);
  if (!resp.ok) {
    logger.error("Got error while performing authenticated fetch", {
      url: url,
      status: resp.status,
      statusText: resp.statusText,
    });
    return "";
  }

  const blob = await resp.blob();
  return URL.createObjectURL(blob);
};
