export const FiltersConfig = {
  BAD_KEYS: ["key", "Date", "Suggested Category"],

  CUSTOM_FILTER_KEY_COMPANY: "_company_",
  CUSTOM_FILTER_KEY_REPO: "_repo_",
  CUSTOM_FILTER_KEY_DOC: "_doc_",
  CUSTOM_FILTER_KEY_FILTER: "_filter_",
  CUSTOM_FILTER_KEY_VDR: "_vdr_", // filter to VDRs

  EXCHANGE_FILTER_KEY: "exchange",

  FILTER_KEY_SIC_INDUSTRY: "sicIndustry",
  FILTER_KEY_SIC_SECTOR: "sicSector",
  FILTER_KEY_NAICS_3DIG_DESCR: "naicsCode3DigitDescription",
  FILTER_KEY_MARKET_CAP: "market_cap_tier_midJan23",
  FILTER_VALUES_MARKET_CAP: [
    "micro-cap",
    "small-cap",
    "mid-cap",
    "large-cap",
    "mega-cap",
  ],
  FILTER_MARKET_CAP_TOOLTIP_TITLE_MAP: {
    "micro-cap": "Under 250M",
    "small-cap": "250M - 2B",
    "mid-cap": "2B - 10B",
    "large-cap": "10B - 200B",
    "mega-cap": "Over 200B",
  },
  FILTER_VALUES_CLEANED_MARKET_CAP: [
    "Micro Cap",
    "Small Cap",
    "Mid Cap",
    "Large Cap",
    "Mega Cap",
  ],

  INTEGRATION_FILTER_KEY: "integration",
  INTEGRATION_FILTER_NAME: "Source",
  DOC_FILTER_KEYS: ["integration"],

  MIME_FILTER_KEY: "mime",
  UNSUPPORTED_FILTER_CONTENTS_KEYS: ["date"],

  FILING_TYPE_FILTER_KEY: "filing_type",
  TICKER_FILTER_KEY: "ticker",

  INCLUDE_KEYWORD_FILTER_KEY: "includeKeyword",
  EXCLUDE_KEYWORD_FILTER_KEY: "excludeKeyword",

  DOC_ID_FILTER_KEY: "id", // The key that represents docs and folders
  REPO_ID_FILTER_KEY: "repoId", // The key that represents a repo as a filter
  FILTER_KEY_DATE: "timestamp", // The key that represents a date as a filter
  DOC_TITLE_FILTER_KEY: "title",
  DOC_TYPE_FILTER_KEY: "type",
};
