import { Citation } from "../../../../library/Citations/Citation";
import { classNames } from "core";
import React from "react";
import { useAppDispatch } from "../../../../../redux";
import {
  getSelectedQuoteId,
  setActiveHighlight,
  setSelectedQuoteId,
} from "../../../../../redux/docViewer";
import { ChatCitation } from "../../../../../types/agents/agents.types";
import { useSelector } from "react-redux";
import {
  PosthogAction,
  PosthogObject,
  useLogPosthog,
} from "../../../../../hooks/tracking/usePosthogTracking";

interface Props {
  citation: ChatCitation;
  number: number;
  className?: string;
  isSingleDocChat?: boolean;
}

export const DocViewerChatCitation = ({
  citation,
  number,
  className,
  isSingleDocChat,
}: Props) => {
  const dispatch = useAppDispatch();
  const selectedQuoteId = useSelector(getSelectedQuoteId);
  const { logPosthog } = useLogPosthog();

  return (
    <Citation
      citationText={String(number)}
      styleOverrides={classNames(
        "inline text-center rounded h-4 min-w-[16px] text-[10px]",
        selectedQuoteId === String(citation.id) && "bg-brand-500 text-white",
        className
      )}
      onClick={() => {
        dispatch(setSelectedQuoteId(String(citation.id)));
        dispatch(setActiveHighlight(citation));

        if (isSingleDocChat) {
          logPosthog(
            PosthogObject.SINGLE_DOC_CHAT,
            PosthogAction.VIEW_CITATIONS
          );
        }
      }}
    />
  );
};
