import React from "react";
import { useSidebarRow } from "source/hooks/useSidebarRow";
import ChevronRightSharpIcon from "@mui/icons-material/ChevronRightSharp";
import KeyboardArrowDownSharpIcon from "@mui/icons-material/KeyboardArrowDownSharp";

type Props = {
  useSidebarRow: ReturnType<typeof useSidebarRow>;
  style?: any;
};

export const ToggleIconSidebar = ({
  useSidebarRow,
  style: styleOverride,
}: Props) => {
  const { toggle, isOpen } = useSidebarRow;

  const handleOpenClick = (e) => {
    e.stopPropagation();
    toggle();
  };

  return (
    <ToggleIcon
      isOpen={isOpen ?? false}
      handleOpenClick={handleOpenClick}
      styleOverride={styleOverride}
    />
  );
};

type VisualProps = {
  isOpen: boolean;
  isHover?: boolean;
  handleOpenClick?: (e?: React.MouseEvent) => void;
  styleOverride?: any;
};

export const ToggleIcon = ({
  isOpen,
  isHover,
  handleOpenClick,
}: VisualProps) => (
  <div
    onClick={(e?: React.MouseEvent) => handleOpenClick && handleOpenClick(e)}
    className={`mb-0.5 cursor-pointer text-secondary ${
      isHover && "text-bluePurple"
    }`}
  >
    {isOpen ? (
      <KeyboardArrowDownSharpIcon fontSize="small" />
    ) : (
      <ChevronRightSharpIcon fontSize="small" />
    )}
  </div>
);

type StatelessVisualProps = {
  isOpen: boolean;
  handleOpenClick?: (e?: React.MouseEvent) => void;
};

export const ToggleStatelessIcon = ({
  isOpen,
  handleOpenClick,
}: StatelessVisualProps) => (
  <div
    onClick={(e?: React.MouseEvent) => handleOpenClick && handleOpenClick(e)}
    className={`mb-0.5 cursor-pointer text-secondary hover:text-hebbiaBlue`}
  >
    {isOpen ? (
      <KeyboardArrowDownSharpIcon fontSize="small" />
    ) : (
      <ChevronRightSharpIcon fontSize="small" />
    )}
  </div>
);
