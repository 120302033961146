import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { UserDownloadType } from "source/Types";
import { ReduxState } from ".";

export type DownloadExactMatchSearchReduxType = {
  activeDownload: UserDownloadType | null;
};

const initialState: DownloadExactMatchSearchReduxType = {
  activeDownload: null,
};

const getDownloadExactMatchSearchState = (state: ReduxState) =>
  state.downloadExactMatchSearch;

export const getDownloadExactMatchSearchInfo = createSelector(
  getDownloadExactMatchSearchState,
  (downloadExactMatchSearch) => ({
    activeDownload: downloadExactMatchSearch.activeDownload,
  })
);

const downloadExactMatchSearchSlice = createSlice({
  name: "downloadExactMatchSearch",
  initialState,
  reducers: {
    setExactMatchSearchDownload: (
      state,
      action: PayloadAction<UserDownloadType | null>
    ) => {
      state.activeDownload = action.payload;
      return state;
    },
  },
});

export const { setExactMatchSearchDownload } =
  downloadExactMatchSearchSlice.actions;
export const downloadExactMatchSearchReducer =
  downloadExactMatchSearchSlice.reducer;
