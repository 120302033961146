import { BASE_URL } from "source/constants";

interface AuthTokenResponse {
  accessToken: string;
}

export default async (): Promise<AuthTokenResponse> => {
  const res = await fetch(`${BASE_URL}/api/auth/token`, {
    cache: "no-store",
  });
  return await res.json();
};
