import { OrgType } from "source/Types";
import {
  FastBuildSearchFilterMap,
  SortDirection,
  SourceType,
} from "./shared/types";
import {
  MOCK_INTEGRATIONS_SOURCE_TAB_MAPPING,
  MOCK_INTEGRATION_SOURCE_TAB_ORGS,
  SOURCE_TYPE_CONFIG_MAPPING,
  SourceConfig,
} from "./shared/config";
import {
  FastBuildNode,
  SharepointFastBuildNode,
  ThirdBridgeParentIdentifier,
  ThirdBridgeDisplayData,
  SelectedThirdBridgeResource,
} from "source/types/matrix/fastBuild.types";
import {
  THIRD_BRIDGE_COMPANY_MAP,
  THIRD_BRIDGE_PDF_BUCKET_NAME,
} from "source/constants/fastBuild";
import { getS3FileIDFromPath } from "./DocumentListPanel/S3/utils";
import { setSelectedThirdBridgeResource } from "source/redux/addDocs";

export const getTabName = (
  item: { value: SourceType; name: string },
  currentOrg: OrgType | null
) => {
  if (item.value === SourceType.ORGANIZATION) {
    return currentOrg?.name ?? "";
  }

  if (item.value === SourceType.AZURE_FILE_SHARE) {
    return `${currentOrg?.name} Secure Drive`;
  }

  return item.name;
};

export const getSourceTabs = ({
  currentOrgId,
  disableAllSharedContent,
  enablePublicSources,
  enableSharePoint,
  showAzureFileShare,
  enableS3FastBuild,
  enableBoxFastBuildIntegration,
  enableThirdBridgeIntegration,
  disablePersonalUploads,
  enableDocumentLists,
}: {
  currentOrgId?: string;
  disableAllSharedContent?: boolean;
  enablePublicSources: boolean;
  enableSharePoint?: boolean;
  showAzureFileShare?: boolean;
  enableS3FastBuild?: boolean;
  enableBoxFastBuildIntegration?: boolean;
  enableThirdBridgeIntegration?: boolean;
  disablePersonalUploads?: boolean;
  enableDocumentLists?: boolean;
}): SourceConfig[] => {
  const isIntegrationSourceTabOrg =
    currentOrgId && MOCK_INTEGRATION_SOURCE_TAB_ORGS.includes(currentOrgId);
  const mergedSourceConfig = {
    ...SOURCE_TYPE_CONFIG_MAPPING,
    ...(isIntegrationSourceTabOrg ? MOCK_INTEGRATIONS_SOURCE_TAB_MAPPING : {}),
  };

  return Object.values(mergedSourceConfig).filter((item) => {
    const isOrgDisabled =
      item.value === SourceType.ORGANIZATION &&
      (!currentOrgId || disableAllSharedContent);
    const isPublicCompaniesDisabled =
      item.value === SourceType.PUBLIC_COMPANIES && !enablePublicSources;
    const isSharedWithMeDisabled =
      item.value === SourceType.SHARED_WITH_ME &&
      (disableAllSharedContent || enableDocumentLists);
    const isSharePointDisabled =
      item.value === SourceType.SHAREPOINT && !enableSharePoint;
    const isAzureFileShareDisabled =
      item.value === SourceType.AZURE_FILE_SHARE && !showAzureFileShare;
    const isS3Disabled = item.value === SourceType.S3 && !enableS3FastBuild;
    const isBoxDisabled =
      item.value === SourceType.BOX && !enableBoxFastBuildIntegration;
    const isThirdBridgeDisabled =
      item.value === SourceType.THIRD_BRIDGE && !enableThirdBridgeIntegration;

    const isPersonalUploadsDisabled =
      item.value === SourceType.PERSONAL_UPLOADS &&
      (disablePersonalUploads || enableDocumentLists);

    return !(
      isOrgDisabled ||
      isPublicCompaniesDisabled ||
      isSharedWithMeDisabled ||
      isSharePointDisabled ||
      isAzureFileShareDisabled ||
      isS3Disabled ||
      isBoxDisabled ||
      isThirdBridgeDisabled ||
      isPersonalUploadsDisabled
    );
  });
};

export const getKeyForSharepointDoc = ({
  node_identifier,
}: SharepointFastBuildNode) => {
  return (
    (node_identifier["site_id"] ?? "") + (node_identifier["remote_id"] ?? "")
  );
};

export const sortAndFilterThirdBridgeDocs = (
  docs:
    | FastBuildNode<ThirdBridgeParentIdentifier, ThirdBridgeDisplayData>[]
    | undefined,
  activeSort: SortDirection,
  fastBuildSearchFilters: FastBuildSearchFilterMap
) => {
  if (!docs) return [];

  const timestampFilter = fastBuildSearchFilters.timestamp?.value;
  const now = new Date();
  let filterDate;

  if (timestampFilter === "month") {
    filterDate = new Date(now.setMonth(now.getMonth() - 1));
  } else if (timestampFilter === "3 months") {
    filterDate = new Date(now.setMonth(now.getMonth() - 3));
  } else if (timestampFilter === "year") {
    filterDate = new Date(now.setFullYear(now.getFullYear() - 1));
  }

  const filteredDocs = docs.filter((doc) => {
    if (timestampFilter && filterDate) {
      return new Date(doc.last_modified).getTime() >= filterDate.getTime();
    }
    return true;
  });

  return filteredDocs.sort((a, b) => {
    const aTime = new Date(a.last_modified).getTime();
    const bTime = new Date(b.last_modified).getTime();
    return activeSort === "asc" ? bTime - aTime : aTime - bTime;
  });
};

export const selectThirdBridgeCompany = (company, dispatch) => {
  let companyName: string;
  if (!company.isThirdBridgeName) {
    companyName = THIRD_BRIDGE_COMPANY_MAP[company.name];
  } else {
    companyName = company.name;
  }

  if (companyName) {
    const fileId =
      getS3FileIDFromPath(THIRD_BRIDGE_PDF_BUCKET_NAME, `${companyName}/`) ??
      "";
    const selectedResource: SelectedThirdBridgeResource = {
      node_identifier: { id: fileId },
      title: company.name,
      third_bridge_company_key: companyName,
    };
    dispatch(setSelectedThirdBridgeResource(selectedResource));
  }
};
