import React, {
  DetailedHTMLProps,
  forwardRef,
  ReactNode,
  TableHTMLAttributes,
} from "react";
import { classNames } from "../../index";
import { TableContextProvider } from "./TableContext";

export interface TableProps
  extends DetailedHTMLProps<
    TableHTMLAttributes<HTMLTableElement>,
    HTMLTableElement
  > {
  children?: ReactNode;
}

const Table = forwardRef<HTMLTableElement, TableProps>(
  (props: TableProps, ref) => {
    const { children, className, ...rest } = props;
    return (
      <TableContextProvider>
        <table
          {...rest}
          ref={ref}
          className={classNames("w-full border-collapse", className)}
        >
          {children}
        </table>
      </TableContextProvider>
    );
  }
);

export default Table;
