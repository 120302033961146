import { createSelector, createSlice } from "@reduxjs/toolkit";
import { ReduxState } from ".";

export type TableStatusReduxType = {
  isLoading: boolean;
  isFailed: boolean;
};

const getTableStatusState = (state: ReduxState) => state.tableStatus;

export const getTableStatus = createSelector(
  getTableStatusState,
  (tableStatus) => ({
    isLoading: tableStatus.isLoading,
    isFailed: tableStatus.isFailed,
  })
);

export const tableStatusSlice = createSlice({
  name: "tableStatus",
  initialState: {
    isLoading: false,
    isFailed: false,
  },
  reducers: {
    setLoading: (state: TableStatusReduxType) => {
      state.isLoading = true;
      return state;
    },
    setFinished: (state: TableStatusReduxType) => {
      state.isLoading = false;
      return state;
    },
    setSuccess: (state: TableStatusReduxType) => {
      state.isFailed = false;
      return state;
    },
    setFailure: (state: TableStatusReduxType) => {
      state.isFailed = true;
      return state;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setLoading, setFinished, setSuccess, setFailure } =
  tableStatusSlice.actions;

export const tableStatusReducer = tableStatusSlice.reducer;
