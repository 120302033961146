import { TabType } from "source/components/tab-bar/tabs/Tabs";
import { useFlags } from "launchdarkly-react-client-sdk";
import { SharepointResourceType } from "source/components/matrix/menu/AddDocumentsModal/shared/types";

export type FeatureFlagName =
  | "hebbiaBanner"
  | "showSsrError"
  | "showMaintenanceBanner"
  | "hebbiaChat"
  | "synonymsEnabled"
  | "canParty"
  | "integrationsLocalUploads"
  | "integrationsPublicDocs"
  | "integrationsGoogleDrive"
  | "integrationsSharePoint"
  | "integrationsSalesforceEnabled"
  | "integrationsDropbox"
  | "integrationsBox"
  | "integrationsMagicLink"
  | "integrationsFindox"
  | "bannerSearchDown"
  | "bannerBuildSlow"
  | "bannerChatDown"
  | "hideSidebar"
  | "useCustomLogo"
  | "chatPlus"
  | "reportsChatReadOnly"
  | "showBulkReportsButton"
  | "homepage"
  | "enableFastBuildDropzones"
  | "reportsModelOptionsBlacklist"
  | "reportsFeedback"
  | "bannerMatrixDown"
  | "gigaEnabled"
  | "matrixColumnLimit"
  | "matrixDocumentLimit"
  | "showShareMatrixButton"
  | "disableAllSharedContent"
  | "disableMemberRepoCreation"
  | "showPublicTemplates"
  | "showPublicPrompts"
  | "enableSharePointFastBuild"
  | "enableRerunCell"
  | "enableDetectNonText"
  | "enablePageBlocking"
  | "enableMatrixFullEditorMode"
  | "viewerOnlyMatrixes"
  | "matrixChatDropdown"
  | "enableAzureFileShare"
  | "enableRetryFastBuild"
  | "adminMatrixDocumentLimit"
  | "sharepointSiteAllowList"
  | "agGridDebug"
  | "publicsKeywordSearch"
  | "showInvestorPresentations"
  | "enableSharePointCrawl"
  | "enableS3FastBuild"
  | "enableS3SelectAll"
  | "singleSelectColumnType"
  | "currencyColumnType"
  | "v2NumberOutputFormatEnabled"
  | "showBanner"
  | "enableBoxFastBuildIntegration"
  | "enableMatrixSidebar"
  | "enableSharePointMetadataFiltering"
  | "enableExportRowAndMessage"
  | "disablePersonalUploads"
  | "enableMatrixChatScoping"
  | "enableMatrixChatUndo"
  | "enableMatrixChatCharts"
  | "enableDocumentsLists"
  | "enableBoxSelectAll"
  | "enableWebSearch"
  | "enableHighlightFiltering"
  | "enableFastbuildURLUpload"
  | "singleLineListAndEmojiOutputTypesEnabled"
  | "llamaEnabled"
  | "enableAdvancedAGGridFeatures"
  | "enableMatrixRowResizing"
  | "enableHomePageRedesign"
  | "showDevEnvironment"
  | "enableThirdBridgeIntegration"
  | "enableMatrixDocPermissionsChecks"
  | "hiddenSharepointSectionsForScopedOrgs"
  | "showInsiderTradingDisclosures"
  | "isTreasureHunter"
  | "o1Enabled"
  | "matrixDefaultFindToolModel"
  | "enableImageUploads"
  | "enableExcelIngest"
  | "enableDocumentListDevMode"
  | "enableDocumentListBrowseApp"
  | "enableMatrixChatPromptPinning"
  | "enableMatrixChat200Rows"
  | "enableDocViewerGlowUp"
  | "enableSingleDocChat"
  | "enableCellReviewers"
  | "greenEnvironment"
  | "enableDocViewerCellEdit"
  | "enableBetaEnvironment";

export type FeatureFlags = {
  hebbiaBanner: { message: "string" };
  showSsrError: boolean;
  showMaintenanceBanner: boolean;
  hebbiaChat: boolean;
  synonymsEnabled: boolean;
  chatPlus: boolean;
  integrationsLocalUploads: boolean;
  integrationsPublicDocs: boolean;
  integrationsGoogleDrive: boolean;
  integrationsSharePoint: boolean;
  integrationsSalesforceEnabled: boolean;
  integrationsDropbox: boolean;
  integrationsBox: boolean;
  integrationsMagicLink: boolean;
  integrationsFindox: boolean;
  bannerSearchDown: boolean;
  bannerBuildSlow: boolean;
  bannerChatDown: boolean;
  hideSidebar: boolean;
  useCustomLogo: boolean;
  reportsChatReadOnly: boolean;
  showBulkReportsButton: boolean;
  homepage: string;
  enableFastBuildDropzones: boolean;
  reportsModelOptionsBlacklist: string[] | undefined;
  reportsFeedback: boolean;
  bannerMatrixDown: boolean;
  gigaEnabled: boolean;
  matrixColumnLimit: number;
  matrixDocumentLimit: number;
  showShareMatrixButton: boolean;
  disableAllSharedContent: boolean;
  disableMemberRepoCreation: boolean;
  showPublicTemplates: boolean;
  showPublicPrompts: boolean;
  enableSharePointFastBuild: boolean;
  enableDetectNonText: boolean;
  enableRerunCell: boolean;
  canParty: boolean;
  enablePageBlocking: boolean;
  enableMatrixFullEditorMode: boolean;
  viewerOnlyMatrixes: string[] | undefined;
  matrixChatDropdown: boolean;
  enableRetryFastBuild: boolean;
  adminMatrixDocumentLimit: number;
  enableAzureFileShare: boolean;
  hideSharepointFiles: boolean;
  sharepointSiteAllowList: { sites: string[] | null };
  agGridDebug: boolean;
  publicsKeywordSearch: boolean;
  showInvestorPresentations: boolean;
  enableSharePointCrawl: boolean;
  enableS3FastBuild: boolean;
  enableS3SelectAll: boolean;
  singleSelectColumnType: boolean;
  currencyColumnType: boolean;
  v2NumberOutputFormatEnabled: boolean;
  showBanner: { title?: string; description?: string; pages?: TabType[] };
  enableBoxFastBuildIntegration: boolean;
  enableMatrixSidebar: boolean;
  enableSharePointMetadataFiltering: boolean;
  enableExportRowAndMessage: boolean;
  disablePersonalUploads: boolean;
  enableMatrixChatScoping: boolean;
  enableMatrixChatUndo: boolean;
  enableMatrixChatCharts: boolean;
  enableDocumentsLists: boolean;
  enableBoxSelectAll: boolean;
  enableWebSearch: boolean;
  enableHighlightFiltering: boolean;
  enableFastbuildURLUpload: boolean;
  singleLineListAndEmojiOutputTypesEnabled: boolean;
  llamaEnabled: boolean;
  enableAdvancedAGGridFeatures: boolean;
  enableMatrixRowResizing: boolean;
  enableHomePageRedesign: boolean;
  showDevEnvironment: boolean;
  enableThirdBridgeIntegration: boolean;
  enableMatrixDocPermissionsChecks: boolean;
  hiddenSharepointSectionsForScopedOrgs: {
    hidden_sections: SharepointResourceType[] | null;
  };
  showInsiderTradingDisclosures: boolean;
  isTreasureHunter: boolean;
  o1Enabled: boolean;
  matrixDefaultFindToolModel: string;
  enableImageUploads: boolean;
  enableExcelIngest: boolean;
  enableDocumentListDevMode: boolean;
  enableDocumentListBrowseApp: boolean;
  enableMatrixChatPromptPinning: boolean;
  enableMatrixChat200Rows: boolean;
  enableDocViewerGlowUp: boolean;
  enableSingleDocChat: boolean;
  enableCellReviewers: boolean;
  greenEnvironment: boolean;
  enableDocViewerCellEdit: boolean;
  enableBetaEnvironment: boolean;
};

export const useFeatureFlag = <T extends FeatureFlagName>(
  flagName: T
): FeatureFlags[T] | undefined => {
  const flags = useFlags<FeatureFlags>();
  return flags[flagName];
};
