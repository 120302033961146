import { useSelector } from "react-redux";
import { getCurrentOrg } from "source/redux/organization";
import { OrgType, RepoType } from "source/Types";
import { PUBLIC_SOURCES } from "source/constants";
import { useQueryUserRepos } from "source/api/repos/useQueryRepos";
import { useQueryBookmarks } from "source/api/bookmarks/useQueryBookmarks";
import { useMemo } from "react";

export const useSidebarRepos = () => {
  const reposQuery = useQueryUserRepos();
  const bookmarksQuery = useQueryBookmarks();
  const currentOrg = useSelector(getCurrentOrg);

  const filtered = useMemo(() => {
    const bookmarkedRepos = bookmarksQuery.data?.repos ?? [];
    const repos = reposQuery.data ?? [];
    return filterSidebar(repos, bookmarkedRepos, currentOrg);
  }, [reposQuery.data, bookmarksQuery.data, currentOrg]);

  if (reposQuery.isLoading || reposQuery.isError) return [];

  return filtered;
};

export const filterSidebar = (
  repos: RepoType[],
  bookmarkedRepos: RepoType[],
  currentOrg?: OrgType | null
) =>
  repos.filter(
    (repo) =>
      // public repos
      ((PUBLIC_SOURCES.includes(repo.id) &&
        (currentOrg?.config?.enable_public_sources || !currentOrg)) ||
        // bookmarked org repos
        bookmarkedRepos.find((b) =>
          currentOrg?.id && b.organization_id
            ? b.id === repo.id && b.organization_id === currentOrg.id
            : b.id === repo.id
        ) ||
        // personal repos
        ((repo?.role === "viewer" ||
          repo?.role === "editor" ||
          repo?.role === "owner") &&
          repo.private &&
          !repo.organization_id) ||
        // org repos
        (repo.organization_id && currentOrg?.id === repo.organization_id)) &&
      !repo.tags?.includes("hidden")
  );
