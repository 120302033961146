import { ReportTableRow } from "source/components/matrix/types/cells.types";
import { CellOverflowMethod } from "source/components/matrix/types/reports.types";

export const ROW_INDEX_COL_WIDTH = 33;
export const ADD_COLUMN_COL_WIDTH = 36;
export const AGGRID_SIDE_BUTTONS_WIDTH = 34;

export const MIN_COL_WIDTH_DOC_TITLE = 200;
export const MIN_COL_WIDTH_DOC_REPO = 150;
export const MIN_COL_WIDTH_DOC_META = 120;
export const MIN_COL_WIDTH_ANSWER = 150;

/**
 * @deprecated This variable is deprecated. Please use getRetrieveColumnId instead
 */
export const DOC_TITLE_COL_ID = "column-0";
export const FIND_COL_PREFIX = "column-";
export const ROW_INDEX_COL_ID = "rowIndex";
export const REPO_COL_ID = "repoId";
export const TIMESTAMP_COL_ID = "timestamp";
export const ADD_COL_ID = "addColumn";
export const AGGRID_GROUP_COLUMN_ID = "ag-Grid-AutoColumn";
export const FILING_TYPE_COL_ID = "filing_type";
export const TICKER_COL_ID = "ticker";
export const ADD_COLUMN_COL_ID = "add-column";

// Sets a default value for how many rows should appear in the reports table,
// regardless of whether or not they're populated. Gives the look of a spreadsheet.
export const THREE_LINES_INITIAL_NUM_ROWS = 7;
export const ONE_LINE_INITIAL_NUM_ROWS = 14;
export const ONE_LINE_INITIAL_NUM_ROWS_LARGE_SCREEN = 19;
export const ONE_LINE_ROW_HEIGHT = 35;
export const TWO_LINE_ROW_HEIGHT = 41;
export const FOUR_LINE_ROW_HEIGHT = 72;

export const INITIAL_TABLE_DATA: ReportTableRow[] = [];
export const N_DOCS_TO_PREFETCH = 100;

export const DEFAULT_CELL_OVERFLOW_METHOD = CellOverflowMethod.FOUR_LINES;
export const DEFAULT_MARKDOWN_RENDERED = true;

export const DEFAULT_ROW_RUN_LIMIT = 5;
export const DEFAULT_EMPTY_ROWS = 5;

export const DOCUMENT_ERROR_LABEL = "Unable to read document";

export const DEFAULT_COLUMN_WIDTHS = {
  docTitleColWidth: 260,
  dateColWidth: 260,
  answerColWidth: 310,
};

export const RETRIEVE_COL_MAGIC_VALUE = "RETRIEVE_COL";
