export type TemplateType = "all" | "org";

export type MatrixSidebarVariant = "home" | "matrix";

export enum MatrixGroup {
  PINNED = "pinned",
  TODAY = "today",
  YESTERDAY = "yesterday",
  PAST_7_DAYS = "past_7_days",
  PAST_30_DAYS = "past_30_days",
}

export enum MatrixHomePanelType {
  HOME = "home",
  TEMPLATE_LIBRARY = "template_library",
}
