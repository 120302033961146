import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  BackendReportFilter,
  FilterType,
  ReportFilter,
  ReportFilterOperation,
  ReportFilterValueType,
} from "source/Types";
import { ReduxState } from ".";
import {
  DOCUMENT_SECTION_TITLE,
  FAST_BUILD_DOCUMENT_SECTION_TITLE,
} from "source/components/gigabar/components/FileSection";
import { COMPANY_SECTION_TITLE } from "source/components/gigabar/components/CompanySection";
import _ from "lodash";
import { FiltersConfig } from "source/constants/filters";

export type AdvancedTargetReduxType = {
  target: ReportFilter;
  noDocsFilters: BackendReportFilter | null;
  appliedFilters: FilterType[];
  suggestedFilters: FilterType[];
  disabledFilters: FilterType[];
};

export const getReportFilter = (state: ReduxState): ReportFilter =>
  state.advancedTarget.target;

export const getReportFilterSection =
  (sectionKey: string) =>
  (state: ReduxState): ReportFilter | undefined =>
    state.advancedTarget.target.values[sectionKey];

const getFilterValuesForKey = (
  reportFilter: ReportFilter,
  filterSectionTitle: string,
  filterKey?: string
): string[] => {
  const sectionKey = getReportFilterKey(filterSectionTitle);
  const reportFilters: ReportFilter[] = Object.values(
    reportFilter.values[sectionKey]?.values || {}
  );

  const filteredFilters = filterKey
    ? reportFilters.filter((f) => f.key === filterKey)
    : reportFilters;
  if (!filteredFilters.length) return [];

  const filterValues = filteredFilters.map((f) => f.values);
  // Only get the strings -- wtf am i even doing at this point
  const ids = filterValues
    .flat()
    .filter((f) => typeof f === "string") as string[];
  return ids;
};

export const getTargetStringValuesBySectionKey = (sectionKey: string) =>
  createSelector(getReportFilter, (reportFilter) =>
    getFilterValuesForKey(reportFilter, sectionKey)
  );

export const getTargetSectionsNonPredefined =
  (includePredefined = false) =>
  (state: ReduxState): ReportFilter[] => {
    const predefinedSections = getPredefinedSectionKeys();
    const allSections = state.advancedTarget.target.values;
    const sectionKeys = Object.keys(allSections);
    const nonPredefinedSectionKeys = sectionKeys.filter(
      (key) => includePredefined || !predefinedSections.includes(key)
    );
    const nonPredefinedSections: ReportFilter[] = nonPredefinedSectionKeys
      .map((key) => allSections[key])
      .filter((p) => p);
    return nonPredefinedSections;
  };

const getDateFilters = (reportFilter: ReportFilter): string[] =>
  getFilterValuesForKey(reportFilter, "Dates", FiltersConfig.FILTER_KEY_DATE);

/** Helper selector to return target dates */
export const getTargetDates = createSelector(getReportFilter, getDateFilters);

const getRepoFilters = (reportFilter: ReportFilter): string[] =>
  getFilterValuesForKey(
    reportFilter,
    DOCUMENT_SECTION_TITLE,
    FiltersConfig.REPO_ID_FILTER_KEY
  );

/** Helper selector to return target repos */
export const getTargetRepos = createSelector(getReportFilter, getRepoFilters);

const getDocFilters = (reportFilter: ReportFilter): string[] =>
  getFilterValuesForKey(
    reportFilter,
    DOCUMENT_SECTION_TITLE,
    FiltersConfig.DOC_ID_FILTER_KEY
  );

/** Helper selector to return target docs */
export const getTargetDocs = createSelector(getReportFilter, getDocFilters);

export const getCompanyFilters = (reportFilter: ReportFilter): string[] =>
  getFilterValuesForKey(reportFilter, COMPANY_SECTION_TITLE);

/** Helper selector to return target companies */
export const getTargetCompanies = createSelector(
  getReportFilter,
  getCompanyFilters
);

/** Returns key, value pairs of non predefined sections as FilterType[] */
export const getTargetNonPredefinedFilters = createSelector(
  getTargetSectionsNonPredefined(),
  (reportFilters: ReportFilter[]) => {
    const filters: FilterType[] = [];
    reportFilters.forEach((reportFilter) => {
      // Report Filter values is an array containing ReportFilter where
      // ReportFilter is an actual key, value pair type of report filter (not a group)
      if (
        typeof reportFilter.values === "string" ||
        typeof reportFilter.values === "number" ||
        typeof reportFilter.values === "boolean"
      )
        return;
      const reportFilterValues = Object.values(reportFilter.values);
      // Iterate through this sections individual filters and build filter types
      reportFilterValues.forEach((filter) => {
        if (!filter?.key || typeof filter.values !== "string") return;
        const newFilter: FilterType = {
          key: filter.key,
          value: filter.values,
        };
        filters.push(newFilter);
      });
    });
    return filters;
  }
);

/** Returns key, value pairs of all filters in advanced targets FilterType[] */
export const getTargetFilters = createSelector(
  getTargetSectionsNonPredefined(true),
  (reportFilters: ReportFilter[]) => {
    const filters: FilterType[] = [];
    reportFilters.forEach((reportFilter) => {
      // Report Filter values is an array containing ReportFilter where
      // ReportFilter is an actual key, value pair type of report filter (not a group)
      if (
        typeof reportFilter.values === "string" ||
        typeof reportFilter.values === "number" ||
        typeof reportFilter.values === "boolean"
      )
        return;
      const reportFilterValues = Object.values(reportFilter.values);
      // Iterate through this sections individual filters and build filter types
      reportFilterValues.forEach((filter) => {
        if (!filter?.key || typeof filter.values !== "string") return;
        const newFilter: FilterType = {
          key: filter.key,
          value: filter.values,
        };
        filters.push(newFilter);
      });
    });
    return filters;
  }
);

/** Currying function returns true if sectionKey and rowKey are selected */
export const getTargetFilterExists =
  (sectionKey: string, rowKey: string) => (state: ReduxState) =>
    state.advancedTarget.target.values[sectionKey]?.values[rowKey] !==
    undefined;

export const getTargetAppliedFilters = (state: ReduxState) =>
  state.advancedTarget.appliedFilters;
export const getTargetSuggestedFilters = (state: ReduxState) =>
  state.advancedTarget.suggestedFilters;
export const getTargetDisabledFilters = (state: ReduxState) =>
  state.advancedTarget.disabledFilters;
export const getNoDocsFilters = (state: ReduxState) =>
  state.advancedTarget.noDocsFilters;

const initialState: AdvancedTargetReduxType = {
  target: {
    key: null,
    values: {},
    operation: ReportFilterOperation.AND,
  },
  noDocsFilters: null,
  appliedFilters: [] as FilterType[],
  suggestedFilters: [] as FilterType[],
  disabledFilters: [] as FilterType[],
};

const advancedTargetSlice = createSlice({
  name: "filterGroups",
  initialState,
  reducers: {
    /**
     * Clears out all report filters to reset global report filter to the given state,
     * or to the default empty state if nothing is provided.
     */
    resetReportFilterState(
      state: AdvancedTargetReduxType,
      action: PayloadAction<ReportFilterValueType | undefined>
    ) {
      state.target = {
        key: null,
        values: action.payload ? action.payload : {},
        operation: ReportFilterOperation.AND,
      };
      return state;
    },
    /** Clears out a report filters values given a section key*/
    clearReportFilterValues(
      state: AdvancedTargetReduxType,
      action: PayloadAction<{ sectionKey: string }>
    ) {
      const section = state.target.values[action.payload.sectionKey];
      if (!section) return state;
      section.values = {};
      return state;
    },
    /**Clears all report filter sections' values */
    clearAllReportFilterSectionsValues(state) {
      const section = state.target.values;
      if (
        typeof section === "string" ||
        typeof section === "boolean" ||
        typeof section === "number"
      )
        return state;
      Object.values(section).forEach(
        (sectionReport) => (sectionReport.values = {})
      );

      return state;
    },
    /** Adds a row ReportFilter to the values of a section key's report filter */
    addReportFilter(
      state: AdvancedTargetReduxType,
      action: PayloadAction<{
        sectionKey: string;
        rowKey?: string;
        reportFilter: ReportFilter;
        operation?: ReportFilterOperation;
      }>
    ) {
      // If the section doesn't exists, don't fret, we'll create it !!
      if (!state.target.values[action.payload.sectionKey]) {
        state.target.values[action.payload.sectionKey] = {
          key: action.payload.sectionKey,
          values: {},
          operation: action.payload.operation ?? ReportFilterOperation.OR,
        };
      }
      if (!action.payload.rowKey) {
        action.payload.rowKey = getReportFilterKey(
          action.payload.sectionKey,
          action.payload.reportFilter.values as string
        );
      }
      state.target.values[action.payload.sectionKey].values[
        action.payload.rowKey
      ] = action.payload.reportFilter;
      return state;
    },
    /** Bulk adds ReportFilters */
    addReportsFilters(
      state: AdvancedTargetReduxType,
      action: PayloadAction<
        {
          sectionKey: string;
          rowKey?: string;
          reportFilter: ReportFilter;
        }[]
      >
    ) {
      // Generate a row key if not provided for each report filter
      action.payload.forEach((payloadElem) => {
        // If the section doesn't exist, create it using first report filter
        if (!state.target.values[payloadElem.sectionKey]) {
          state.target.values[payloadElem.sectionKey] = {
            key: payloadElem.sectionKey,
            values: {},
            operation: ReportFilterOperation.OR,
          };
        }

        if (!payloadElem.rowKey) {
          payloadElem.rowKey = getReportFilterKey(
            payloadElem.sectionKey,
            payloadElem.reportFilter.values as string
          );
        }
        // Add the report filter to the specified section and row
        state.target.values[payloadElem.sectionKey].values[payloadElem.rowKey] =
          payloadElem.reportFilter;
      });
      return state;
    },
    /** Removes a ReportFilter section's values */
    removeReportFilterSectionValues(
      state: AdvancedTargetReduxType,
      action: PayloadAction<{
        sectionKey: string;
      }>
    ) {
      if (!state.target.values[action.payload.sectionKey]) return state;
      state.target.values[action.payload.sectionKey].values = {};
      return state;
    },
    /** Removes a ReportFilter from a section's values */
    removeReportFilter(
      state: AdvancedTargetReduxType,
      action: PayloadAction<{
        sectionKey: string;
        rowKey: string;
      }>
    ) {
      if (!state.target.values[action.payload.sectionKey]) return state;
      if (
        !state.target.values[action.payload.sectionKey].values[
          action.payload.rowKey
        ]
      )
        return state;
      delete state.target.values[action.payload.sectionKey].values[
        action.payload.rowKey
      ];
      if (
        Object.keys(state.target.values[action.payload.sectionKey].values)
          ?.length === 0
      ) {
        delete state.target.values[action.payload.sectionKey];
      }
      return state;
    },
    setTargetAppliedFilters: (
      state: AdvancedTargetReduxType,
      action: PayloadAction<FilterType[]>
    ) => {
      state.appliedFilters = action.payload;
      return state;
    },
    upsertTargetAppliedFilters: (
      state: AdvancedTargetReduxType,
      action: PayloadAction<FilterType[]>
    ) => {
      if (action.payload.length) {
        // Push to array if there are existing filters
        if (state.appliedFilters) {
          action.payload.forEach((newFilter) => {
            // Avoid duplicating filters
            if (
              state.appliedFilters &&
              !state.appliedFilters.some(
                (filter) =>
                  filter.key === newFilter.key &&
                  filter.value === newFilter.value
              )
            )
              state.appliedFilters.push(newFilter);
          });
        } else state.appliedFilters = action.payload;
      }
      return state;
    },
    removeTargetAppliedFilter: (
      state: AdvancedTargetReduxType,
      action: PayloadAction<FilterType>
    ) => {
      state.appliedFilters = state.appliedFilters.filter(
        (filter) =>
          !(
            filter.key === action.payload.key &&
            filter.value === action.payload.value
          )
      );
      return state;
    },
    setTargetSuggestedFilters: (
      state: AdvancedTargetReduxType,
      action: PayloadAction<FilterType[]>
    ) => {
      state.suggestedFilters = action.payload;
      return state;
    },
    upsertTargetSuggestedFilters: (
      state: AdvancedTargetReduxType,
      action: PayloadAction<FilterType[]>
    ) => {
      if (action.payload.length) {
        // Push to array if there are existing filters
        if (state.suggestedFilters) {
          action.payload.forEach((newFilter) => {
            // Avoid duplicating filters
            if (
              state.suggestedFilters &&
              !state.suggestedFilters.some(
                (filter) =>
                  filter.key === newFilter.key &&
                  filter.value === newFilter.value
              )
            )
              state.suggestedFilters.push(newFilter);
          });
        } else state.suggestedFilters = action.payload;
      }
      return state;
    },
    removeTargetDisabledFilter: (
      state: AdvancedTargetReduxType,
      action: PayloadAction<FilterType>
    ) => {
      // Remove filter from array
      state.disabledFilters =
        state.disabledFilters?.filter(
          (filter) => filter.value !== action.payload.value
        ) ?? null;
      return state;
    },
    upsertTargetDisabledFilters: (
      state: AdvancedTargetReduxType,
      action: PayloadAction<FilterType[]>
    ) => {
      // Push to array if there are existing filters
      if (state.disabledFilters) {
        action.payload.forEach((newFilter) => {
          // Avoid duplicating filters
          if (
            state.disabledFilters &&
            !state.disabledFilters.some(
              (filter) =>
                filter.key === newFilter.key && filter.value === newFilter.value
            )
          )
            state.disabledFilters.push(newFilter);
        });
      } else state.disabledFilters = action.payload;
      return state;
    },
    setTargetDisabledFilters: (
      state: AdvancedTargetReduxType,
      action: PayloadAction<FilterType[]>
    ) => {
      state.disabledFilters = action.payload;
      return state;
    },
    setNoDocsFilters: (
      state: AdvancedTargetReduxType,
      action: PayloadAction<BackendReportFilter | null>
    ) => {
      state.noDocsFilters = action.payload;
    },
    clearAllAppliedFiltersByKey: (state, action: PayloadAction<string>) => {
      state.appliedFilters = state.appliedFilters.filter(
        (filter) => !(filter.key === action.payload)
      );
      state.disabledFilters = state.disabledFilters.filter(
        (filter) => !(filter.key === action.payload)
      );
      state.suggestedFilters = state.suggestedFilters.filter(
        (filter) => !(filter.key === action.payload)
      );
      return state;
    },
    clearRelevantResults: (state: AdvancedTargetReduxType) => {
      state.suggestedFilters = [];
      state.appliedFilters = [];
      state.disabledFilters = [];
      return state;
    },
    setGlobalOperand: (
      state: AdvancedTargetReduxType,
      action: PayloadAction<
        ReportFilterOperation.AND | ReportFilterOperation.OR
      >
    ) => {
      // Don't allow switching to AND if there's a fast-built doc in filters
      if (
        state.target.values[FAST_BUILD_DOCUMENT_SECTION_TITLE] &&
        action.payload === ReportFilterOperation.AND
      )
        return state;

      state.target.operation = action.payload;
      return state;
    },
    setFilter: (
      state: AdvancedTargetReduxType,
      action: PayloadAction<{
        sectionKey: string;
        rowKey: string;
        newKey?: string;
        value?: string | number | boolean;
        operand?: ReportFilterOperation;
        smart?: boolean;
        clearValue?: boolean;
      }>
    ) => {
      const reportFilter =
        state.target.values[action.payload.sectionKey]?.values[
          action.payload.rowKey
        ];
      if (!reportFilter) return state;
      if (action.payload.value) reportFilter.values = action.payload.value;
      if (action.payload.clearValue) reportFilter.values = undefined;
      if (action.payload.operand)
        reportFilter.operation = action.payload.operand;
      if (action.payload.newKey) reportFilter.key = action.payload.newKey;

      // If smart we will try to coerce the section into a pre-defined section -- oOo00o ahhhHhhH
      if (action.payload.smart) {
        const predefinedSectionKeys = [
          "Documents",
          "Files",
          "Companies",
          "Type",
        ];
        const predefinedRowKeys = [
          ["id"],
          ["repoId"],
          ["ticker"],
          ["integration", "mime"],
        ];

        const sectionFilter = state.target.values[action.payload.sectionKey];

        // If the section is already a pre-defined section, we don't need to do anything
        if (predefinedSectionKeys.includes(sectionFilter.key)) return state;

        // If the section is not a pre-defined section, we will try to coerce it into one
        // Check the diff key types
        for (let i = 0; i < predefinedRowKeys.length; ++i) {
          const rowKeys = predefinedRowKeys[i];

          // Check first value in section
          const values = Object.values(sectionFilter.values) as ReportFilter[];

          if (!values.length) break;

          const firstValue = values[0] as ReportFilter;

          const firstValueKey = firstValue.key;
          if (!firstValueKey) break;

          // If this key is inside rowKeys, we must make sure that every other value in the section is also in rowKeys
          if (rowKeys?.includes(firstValueKey)) {
            // If no other values, or if every value is the same key as this one we can change section to match this
            if (
              values.length === 1 ||
              values.every((v) => rowKeys?.includes(v.key as string))
            ) {
              // Change the section key
              const newSectionKey = predefinedSectionKeys[i];

              // Now that we have the potential new section key, we must make sure this section doesn't already exist; if it does continue
              const sections = state.target.values as Record<
                string,
                ReportFilter
              >;
              const sectionFilters = Object.values(sections);
              if (sectionFilters.find((v) => v.key && v.key === newSectionKey))
                break;

              // We need to now update the row key for each other value (where row key is the literal key into the sections.values object)
              // need to update this bc row keys are calculated using the section key, and we are changing the section key
              // first get the section values
              const sectionObjectValues = sectionFilter.values as Record<
                string,
                ReportFilter
              >;
              // Now iterate through the section values and update each row key
              // since we are changing the key, we need to create a new key and delete the old one
              Object.entries(sectionObjectValues).forEach(([key, value]) => {
                // Get new row key using the new section key
                const newRowKey = getReportFilterKey(
                  newSectionKey ?? "",
                  value.values as string
                );
                // Create deep clone of value using the new key
                sectionFilter.values[newRowKey] = _.cloneDeep(value);
                // Delete out old value
                delete sectionFilter.values[key];
              });
              // Similarly, we need to set the new section key
              sectionFilter.key = newSectionKey;
              // Create the new section with the new key in the object using a deep clone of the section filter
              if (newSectionKey)
                state.target.values[newSectionKey] = _.cloneDeep(sectionFilter);
              // Delete out old section which was keyed on the old section key
              delete state.target.values[action.payload.sectionKey];
            }
          }
        }
      }
      return state;
    },
    // Clear out empty sections
    clearEmptySections: (state: AdvancedTargetReduxType) => {
      const values = state.target.values;

      Object.entries(values).forEach(([key, value]) => {
        // If section has no values, delete it
        if (Object.keys(value.values).length === 0) delete values[key];
      });
      return state;
    },
    createSectionsFromBackendFilters: (
      state: AdvancedTargetReduxType,
      action: PayloadAction<BackendReportFilter[]>
    ) => {
      const sections = action.payload;
      sections.forEach((section) => {
        const sectionKey = section.key;
        if (!sectionKey) return state;

        const newReportFilter: ReportFilter = {
          key: sectionKey,
          values: {},
          operation: section.operation,
        };

        const backendFilters = section.values as BackendReportFilter[];
        backendFilters.forEach((filter) => {
          const rowSearchKey = filter.key as string;
          const rowKey = getReportFilterKey(
            sectionKey,
            filter.values as string
          );
          const rowFilter: ReportFilter = {
            key: rowSearchKey,
            values: filter.values as string | number | boolean,
            operation: filter.operation,
          };
          newReportFilter.values[rowKey] = rowFilter;
        });
        state.target.values[sectionKey] = newReportFilter;
      });
      return state;
    },
  },
});

export const {
  resetReportFilterState,
  clearReportFilterValues,
  clearAllReportFilterSectionsValues,
  addReportFilter,
  addReportsFilters,
  removeReportFilterSectionValues,
  removeReportFilter,
  setTargetAppliedFilters,
  upsertTargetAppliedFilters,
  removeTargetAppliedFilter,
  setTargetSuggestedFilters,
  upsertTargetSuggestedFilters,
  removeTargetDisabledFilter,
  upsertTargetDisabledFilters,
  setTargetDisabledFilters,
  clearAllAppliedFiltersByKey,
  clearRelevantResults,
  setGlobalOperand,
  setFilter,
  setNoDocsFilters,
  clearEmptySections,
  createSectionsFromBackendFilters,
} = advancedTargetSlice.actions;

export const advancedTargetReducer = advancedTargetSlice.reducer;

export const getRandomTitle = () => {
  const randomNum = Math.floor(Math.random() * 100);
  const sectionTitle = sectionTitles[randomNum];
  return sectionTitle;
};

export const getRandomSectionTitle = () => {
  const randomSectionTitle = getRandomTitle();
  const anotherRandomNum = Math.floor(Math.random() * 10000);
  const oneMore = Math.floor(Math.random() * 10000);
  return `${randomSectionTitle}-${anotherRandomNum}-${oneMore}`;
};

/** Helper function to generate unique keys for all report filters.
 * Given the same input, will always produce the same key again
 */
export const getReportFilterKey = (sectionTitle: string, rowId?: string) => {
  if (rowId) return `${sectionTitle}|${rowId}`;
  return `${sectionTitle}`;
};

// Reverse of the function above :/
export const getReportFilterSectionKeyAndRowKeyFromFilterKey = (
  filterKey: string
) => {
  const split = filterKey.split("|");
  const sectionKey = split[0];
  const rowKey = `${sectionKey}|${split[1]}`;
  return { sectionKey, rowKey };
};

export type PredefinedSearchKeyType =
  | "company"
  | "document"
  | "fastbuildfiles"
  | "repo"
  | "date";

/** Hard-coded mappings to retrieve filter keys as used by search.
 * TODO: De-ape this, also hollah at addy to update backend to expect these keys
 */
export const getFilterSearchKey = (sectionType: PredefinedSearchKeyType) => {
  // forCompany
  if (sectionType === "company") return FiltersConfig.TICKER_FILTER_KEY;
  if (sectionType === "document") return FiltersConfig.DOC_ID_FILTER_KEY;
  if (sectionType === "fastbuildfiles") return FiltersConfig.DOC_ID_FILTER_KEY;
  if (sectionType === "date") return FiltersConfig.FILTER_KEY_DATE;
  return FiltersConfig.REPO_ID_FILTER_KEY;
};

export const getPredefinedSectionKeys = () => [
  getReportFilterKey(DOCUMENT_SECTION_TITLE),
  getReportFilterKey(COMPANY_SECTION_TITLE),
];

/**
return reports.ReportFilter(
  key=None,
  values={
      "repo_section" : reports.ReportFilter(
          key="repo_section",
          values={
           "{repo-section}-{row-id}" :  reports.ReportFilter(
                  key="repo_id",
                  values="23904898912810",
                  operation=reports.ReportFilterOperation.IS,
              ),
          "{repo-section}-{row-id2}":    reports.ReportFilter(
                  key="repo_id",
                  values="23984375283",
                  operation=reports.ReportFilterOperation.IS,
              ),
          },
          operation=reports.ReportFilterOperation.OR,
      ),
      "doc_section":  reports.ReportFilter(
          key="doc_section",
          values=[
              reports.ReportFilter(
                  key="doc_id",
                  values="894782823",
                  operation=reports.ReportFilterOperation.IS,
              ),
          ],
          operation=reports.ReportFilterOperation.OR,
      ),
  },
  operation=reports.ReportFilterOperation.AND,
)

*/

const sectionTitles = [
  "braveLion",
  "playfulMonkey",
  "sneakyFox",
  "wiseOwl",
  "energeticCheetah",
  "grumpyBear",
  "swiftGazelle",
  "cleverRaccoon",
  "majesticEagle",
  "tinyMouse",
  "fierceTiger",
  "curiousCat",
  "gracefulSwan",
  "sillyPenguin",
  "gentleLamb",
  "chattyParrot",
  "sleepySloth",
  "happyDolphin",
  "shyDeer",
  "daringHawk",
  "agileSquirrel",
  "stubbornMule",
  "cleverOctopus",
  "wittyFox",
  "speedyRabbit",
  "wiseElephant",
  "funnyOtter",
  "loyalDog",
  "carefulTortoise",
  "eagerBeaver",
  "adorableKoala",
  "wildWolf",
  "sensitiveButterfly",
  "fierceShark",
  "cheekyChimpanzee",
  "regalHorse",
  "mightyGorilla",
  "quickHummingbird",
  "curiousFerret",
  "elegantGiraffe",
  "proudPeacock",
  "happyGoLuckyDog",
  "mysteriousPanther",
  "cuddlyPanda",
  "soaringFalcon",
  "slinkySnake",
  "friendlyDolphin",
  "agileKangaroo",
  "alertMeerkat",
  "colorfulParrot",
  "strongBuffalo",
  "busyBee",
  "curiousPlatypus",
  "charmingFlamingo",
  "wilyWeasel",
  "majesticLioness",
  "swiftCheetah",
  "cleverFox",
  "playfulKitten",
  "sleepyKoala",
  "energeticPuppy",
  "fierceDragon",
  "wiseTurtle",
  "sillyGoose",
  "sneakySpider",
  "bashfulBunny",
  "regalLion",
  "happyGoLuckyMonkey",
  "braveFalcon",
  "gracefulSwan",
  "mightyElephant",
  "gentleSeahorse",
  "sassyCat",
  "curiousRaccoon",
  "loyalLabrador",
  "daringHawk",
  "craftyFox",
  "stubbornDonkey",
  "chattyMagpie",
  "swiftCheetah",
  "wittyParrot",
  "shyMouse",
  "sensitiveDeer",
  "playfulOtter",
  "elegantSwan",
  "eagerBeaver",
  "fearlessLion",
  "gracefulGazelle",
  "huggableBear",
  "quirkyPenguin",
  "wiseOwl",
  "cleverFox",
  "cheekyMonkey",
  "fierceTiger",
  "slinkySnake",
  "curiousSquirrel",
  "agilePanther",
  "happyHedgehog",
  "sneakyFox",
  "majesticEagle",
];
