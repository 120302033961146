import { ActionType, DocumentType, Meta } from "source/Types";
import axios from "./axios";

export const ActivityFeed = {
  // Used for everything else (reports, pins)
  addActivity: (
    timestamp: string,
    action_type: ActionType,
    meta: Meta,
    repo_id?: string
  ) =>
    axios.post(`/v2/activity-feed/${repo_id}/add-activity`, {
      timestamp: timestamp,
      action_type: action_type,
      meta: meta,
    }),
  // Batch is used for all documents uploads (all types)
  addBatchActivities: (docs: DocumentType[], repo_id: string) =>
    axios.post(`/v2/activity-feed/${repo_id}/add-batch-activities`, {
      docs: docs,
    }),
  repoActivities: (repo_id: string, params: { show_all: boolean }) =>
    axios.get(`/v2/activity-feed/${repo_id}/repo-activities`, { params }),
  allActivities: (params: { show_all: boolean }) =>
    axios.get(`/v2/activity-feed/org/all-activities`, {
      params,
    }),
  // Takes in a report_id, deletes the corresponding activity, and returns its id
  deleteActivityByReportId: (report_id: string) =>
    axios.delete(`/v2/activity-feed/personal/${report_id}`),
  updateActivity: (repo_id: string, report_id: string, updated_at: string) =>
    axios.put(`/v2/activity-feed/${repo_id}/update-activity`, {
      report_id: report_id,
      updated_at: updated_at,
    }),
  deletePin: (repo_id: string, pin_id: string) =>
    axios.delete(`/v2/activity-feed/${repo_id}/delete-pin/${pin_id}`),
  updateRepo: (repo_id: string, repo_name: string) =>
    axios.put(`/v2/activity-feed/${repo_id}/update-repo`, { repo_name }),
  updateDoc: (repo_id: string, doc_id: string, doc_name: string) =>
    axios.put(`/v2/activity-feed/${repo_id}/update-doc`, { doc_id, doc_name }),
};
