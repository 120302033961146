import $ from "jquery";
import React, { useState } from "react";

type SidebarRowInputProps = {
  title: string;
  id: string;
  handleRename?: (newTitle: string, id: string) => void;
};

export const SidebarRowInput = ({
  title,
  id,
  handleRename,
}: SidebarRowInputProps) => {
  const [currTitle, setCurrTitle] = useState(title);

  const handleEdit = () => {
    $(`#sidebar-${id}`).attr("disabled", true as any);
    if (handleRename) handleRename(currTitle, id);
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") (document.activeElement as any)?.blur();
  };

  return (
    <input
      id={`sidebar-${id}`}
      className="pointer-events-none mr-2 w-full select-none overflow-hidden text-ellipsis whitespace-nowrap bg-transparent text-start text-xs"
      value={currTitle}
      style={{
        WebkitUserSelect: "none",
        MozUserSelect: "none",
      }}
      onChange={(e) => setCurrTitle(e.target.value)}
      onBlur={handleEdit}
      onKeyPress={handleKeyPress}
    />
  );
};
