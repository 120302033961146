import { FilterType, IDocumentMime } from "source/Types";
import { FiltersConfig } from "source/constants/filters";
import { getHumanMime } from "source/utils/documents";
import { ALLOWED_MIME_FILTERS } from "source/constants";
import { DATE_KEY_NAME, isDateRange } from "source/redux/search";
import { defaultDateRanges } from "source/api/dates/useQueryDates";

/** Some filters on the front (e.g. Excel) actually map to mulitple types
 * This functions takes this set of values and dedups them based on their human readable value
 */
const dedupFilters = (filterKey: string, filterValues: string[]): string[] => {
  const dedupedFilters: string[] = [];
  if (filterKey == FiltersConfig.MIME_FILTER_KEY) {
    filterValues.forEach((filterValue) => {
      const humanReadable = getHumanMime(filterValue as IDocumentMime);
      if (
        !dedupedFilters.find(
          (v) => getHumanMime(v as IDocumentMime) === humanReadable
        )
      ) {
        dedupedFilters.push(filterValue);
      }
    });
  } else {
    dedupedFilters.push(...filterValues);
  }
  return dedupedFilters;
};

/** Collapses duplicated mime filters */
const getMimeValues = (
  filtersKeyValueMap?: Record<string, string[]>
): string[] => {
  if (!filtersKeyValueMap) return [];
  const mimeValues = filtersKeyValueMap[FiltersConfig.MIME_FILTER_KEY];
  if (!mimeValues) return [];

  const dedupedFilters = dedupFilters(
    FiltersConfig.MIME_FILTER_KEY,
    filtersKeyValueMap[FiltersConfig.MIME_FILTER_KEY] ?? []
  ).filter((value: string) =>
    ALLOWED_MIME_FILTERS.includes(value as IDocumentMime)
  );

  return dedupedFilters;
};

export const INTEGRATION_FILTER_KEYS = ["earnings", "sec"];

/** Builds a map of filter keys to filter values */
export const buildFilterMap = (repoFilters?: Record<string, string[]>) => {
  const filterMap = {};

  // Add hard-coded earnings/sec filters
  const descr = INTEGRATION_FILTER_KEYS;
  filterMap[FiltersConfig.INTEGRATION_FILTER_KEY] = descr;

  // Collapse duplicate mime filters
  const mimeValues = getMimeValues(repoFilters);
  filterMap[FiltersConfig.MIME_FILTER_KEY] = mimeValues;

  return filterMap;
};

/**Given a filter key and value, transforms it to a human-readable form */
export const transformFilter = (key: string, value: string) => {
  if (key === FiltersConfig.INTEGRATION_FILTER_KEY) {
    if (value === "sec") return "Filings";
    if (value === "earnings") return "Earnings Transcripts";
    if (value === "earnings_transcript") return "Earnings Transcripts"; // Added this for parity with the deleted fn copy, not sure if we want it?
    if (value === "yahoo_finance_description") return "Company Descriptions";
    if (value === "gdrive") return "Google Drive";
    if (value === "hebbia_folder") return "Folder";
    if (value === "websearch") return "Web Search";
    if (value.length > 1)
      // Capitalize first letter for doc type
      return value.charAt(0).toUpperCase() + value.slice(1);
  }
  if (key === DATE_KEY_NAME) {
    const dateRangeValue = isDateRange(value)
      ? (defaultDateRanges[value]?.label ?? value)
      : value;
    return dateRangeValue;
  }
  if (FiltersConfig.FILTER_VALUES_MARKET_CAP.indexOf(value) > -1)
    return FiltersConfig.FILTER_VALUES_CLEANED_MARKET_CAP[
      FiltersConfig.FILTER_VALUES_MARKET_CAP.indexOf(value)
    ];
  if (key === FiltersConfig.EXCHANGE_FILTER_KEY && value === "L") return "LSE";
  if (key === FiltersConfig.MIME_FILTER_KEY) {
    const humanMime = getHumanMime(value as IDocumentMime);
    // May be undefined
    if (humanMime) return humanMime;
  }
  if (key === FiltersConfig.FILING_TYPE_FILTER_KEY) return getFilingName(value);
  return value;
};

/** Get human readable name from filing_type filter value */
const getFilingName = (value: string) => {
  // split by underscore
  const tokens = value.split("_");
  if (tokens.length > 1) {
    // Remove first word and join with space
    const title = tokens.slice(1).join(" ");
    return title.toUpperCase();
  }
  return value;
};

export const getTargetFilterValuesMap = (filters: FilterType[]) =>
  new Set(filters.map((filter) => filter.value));
