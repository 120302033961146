import { useSelector } from "react-redux";
import { useQueryDoc } from "source/api/docs/useQueryDocs";
import { getDocModalVisible } from "source/redux/ui";
import { DocumentType } from "source/Types";

/** Helper hook to get the current doc in the doc modal. If doc modal is closed, currentDoc returns undefined */
export const useCurrentDoc = (): DocumentType | undefined => {
  const docModalOpen = useSelector(getDocModalVisible);
  const docId = docModalOpen?.docId;

  const docQuery = useQueryDoc(docId);

  return docQuery.data?.doc;
};
