import { createSlice, Dispatch, PayloadAction } from "@reduxjs/toolkit";
import api from "source/api";
import { ReduxState } from ".";

export type Metadata = {
  [key: string]: string[];
};

export type Filter = {
  key: string;
  value: string;
};

export type MetaReduxType = {
  allMeta: Metadata;
  repoMeta: { [id: string]: Metadata };
  isLoaded: boolean;
  primaryFilter: Filter | null;
};

const initialState: any = {
  allMeta: {},
  repoMeta: {},
  isLoaded: false,
  primaryFilter: null,
};

export const getMeta = (state: ReduxState) => state.meta;

export const getPrimaryFilter = (state: ReduxState) => state.meta.primaryFilter;

export const getHedgeFunds = (state: ReduxState) =>
  [...(state.meta.allMeta["hedge_fund:name"] || [])].sort((a, b) =>
    a.localeCompare(b)
  );

export const fetchMetaRepo = async (dispatch: Dispatch, repoId: string) => {
  dispatch(setIsLoading());
  const { data } = await api.meta.getRepo(repoId);
  dispatch(setMetaRepo({ repoId, data }));
  dispatch(setIsLoaded());
};

const metaSlice = createSlice({
  name: "meta",
  initialState,
  reducers: {
    setMetaRepo: (state, { payload: { repoId, data } }) => {
      state.repoMeta[repoId] = { ...state.repoMeta[repoId], ...data };
      state.isLoaded = true;
    },
    setIsLoading: (state) => ({ ...state, isLoaded: false }),
    setIsLoaded: (state) => ({ ...state, isLoaded: true }),
    setPrimaryFilter: (state, action: PayloadAction<Filter | null>) => {
      state.primaryFilter = action.payload;
      return state;
    },
  },
});

export const { setMetaRepo, setIsLoaded, setIsLoading, setPrimaryFilter } =
  metaSlice.actions;
export const metaReducer = metaSlice.reducer;
