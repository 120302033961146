import { modalConfig } from "./modalConfig";
import { ModalTypes } from "./modals.types";

export interface CurrentModal {
  type: ModalTypes;
  props: any;
}

type Handler = (modal: CurrentModal | null) => void;

const subs: Set<Handler> = new Set();
let modal: CurrentModal | null;

export const currentModal = {
  subscribe(handler: Handler): () => void {
    if (typeof handler === "function") {
      subs.add(handler);
    }

    return () => {
      subs.delete(handler);
    };
  },
  set(modalIn: CurrentModal | null) {
    modal = modalIn;
    subs.forEach((handler) => {
      handler(modal);
    });
  },
  get(modal: ModalTypes) {
    return modalConfig[modal] ?? null;
  },
  isOpen(modalInput: ModalTypes) {
    return modal?.type === modalInput;
  },
};
