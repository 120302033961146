import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ReduxState } from ".";

export type SearchDropdownReduxType = {
  selected: number;
};

export const getSelected = (state: ReduxState) => state.searchDropdown.selected;

const initialState: SearchDropdownReduxType = { selected: -1 };

export const searchDropdownSlice = createSlice({
  name: "searchDropdown",
  initialState,
  reducers: {
    setSearchSelected: (
      state: SearchDropdownReduxType,
      action: PayloadAction<number>
    ) => {
      state.selected = action.payload;
      return state;
    },
  },
});

export const { setSearchSelected } = searchDropdownSlice.actions;

export const searchDropdownReducer = searchDropdownSlice.reducer;
