import { Card } from "./ui";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "source/components/shared/Popover";
import React, { useState } from "react";

interface HoverPopoverProps {
  id: string;
  triggerContent: React.ReactNode;
  popoverContent: React.ReactNode;
  triggerStyles?: React.CSSProperties;
  contentStyles?: React.CSSProperties;
  isActive?: boolean;
  cardStyles?: React.CSSProperties;
  disabled?: boolean;
  align?: "center" | "start" | "end";
}

export const HoverPopover = ({
  id,
  popoverContent,
  triggerContent,
  contentStyles,
  isActive,
  disabled,
  triggerStyles,
  cardStyles,
  align = "center",
}: HoverPopoverProps) => {
  const [popoverTriggered, setPopoverTriggered] = useState(false);
  const [contentHovered, setContentHovered] = useState(false);

  const handlePopoverButtonLeave = () => {
    setTimeout(() => {
      setPopoverTriggered(false);
    }, 5);
  };

  const handlePopoverMenuLeave = () => {
    setTimeout(() => {
      setContentHovered(false);
    }, 100);
  };

  const open = popoverTriggered || contentHovered;

  return (
    <Card
      style={{
        // todo: create constants for all the colors
        color: (open || isActive) && !disabled ? "var(--text-blue)" : undefined,
        fontWeight: isActive ? 500 : undefined,
        ...cardStyles,
        cursor: disabled ? "default" : "pointer",
      }}
      onMouseOver={() => setPopoverTriggered(true)}
      onMouseLeave={() => handlePopoverButtonLeave()}
    >
      <Popover open={open && !disabled}>
        <PopoverTrigger
          style={{
            cursor: disabled ? "default" : "pointer",
            fontSize: 13,
            ...triggerStyles,
          }}
          id={id}
          onClick={() => {
            if (contentHovered) {
              setPopoverTriggered(false);
              setContentHovered(false);
            }
          }}
        >
          {triggerContent}
        </PopoverTrigger>
        <PopoverContent
          style={{
            // boxShadow:
            //   "0px 4px 17px rgba(var(--shadow), .7), 0px 0px 7px rgba(var(--shadow), .7)",
            ...contentStyles,
          }}
          className="text-body"
          onMouseOver={() => setContentHovered(true)}
          onMouseLeave={() => handlePopoverMenuLeave()}
          align={align}
        >
          {popoverContent}
        </PopoverContent>
      </Popover>
    </Card>
  );
};
