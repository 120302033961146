import React, {
  DetailedHTMLProps,
  forwardRef,
  ReactNode,
  TableHTMLAttributes,
} from "react";
import { classNames } from "../../index";
import { useTableContext } from "./TableContext";

export interface TableCellProps
  extends DetailedHTMLProps<
    TableHTMLAttributes<HTMLTableCellElement>,
    HTMLTableCellElement
  > {
  children?: ReactNode;
}

const TableCell = forwardRef<HTMLTableCellElement, TableCellProps>(
  (props: TableCellProps, ref) => {
    const { children, className, ...rest } = props;
    const { isHead } = useTableContext();

    if (isHead) {
      return (
        <th
          {...rest}
          ref={ref}
          className={classNames(
            "border-b border-gray-200 px-4 py-2 text-left text-xs font-normal leading-4 text-gray-500",
            className
          )}
        >
          {children}
        </th>
      );
    }

    return (
      <td
        {...rest}
        ref={ref}
        className={classNames(
          "border-b border-gray-200 px-4 py-3 text-left text-sm font-normal leading-5 text-gray-900",
          className
        )}
      >
        {children}
      </td>
    );
  }
);

export default TableCell;
