import { DocumentType, RepoType } from "source/Types";
import {
  ContextMenu,
  ContextMenuContent,
  ContextMenuItem,
  ContextMenuTrigger,
  ContextMenuLabel,
} from "./ContextMenu";
import React, { useState } from "react";
import { BASE_URL, SLACK_WEBHOOK_URL } from "source/constants";
import { Divider } from "@mui/material";
import { useDocContextMenu } from "source/hooks/doc-viewer/useDocContextMenu";
import moment from "moment";
import { isFailedDoc, isShortDoc } from "source/utils/documents";
import { useSelector } from "react-redux";
import { useCanEditRepo } from "source/hooks/permissions/useCanEditRepo";
import { getUser } from "source/redux/user";
import { useSidebar } from "source/hooks/useSidebar";
import AddchartSharpIcon from "@mui/icons-material/AddchartSharp";
import EditSharpIcon from "@mui/icons-material/EditSharp";
import OpenInNewSharpIcon from "@mui/icons-material/OpenInNewSharp";
import FolderSharpIcon from "@mui/icons-material/FolderSharp";
import RefreshSharpIcon from "@mui/icons-material/RefreshSharp";
import PersonAddAlt1SharpIcon from "@mui/icons-material/PersonAddAlt1Sharp";
import FileDownloadDoneSharpIcon from "@mui/icons-material/FileDownloadDoneSharp";
import FolderOpenSharpIcon from "@mui/icons-material/FolderOpenSharp";
import ArticleSharpIcon from "@mui/icons-material/ArticleSharp";
import VisibilitySharpIcon from "@mui/icons-material/VisibilitySharp";
import DeleteSharpIcon from "@mui/icons-material/DeleteSharp";
import FileUploadSharpIcon from "@mui/icons-material/FileUploadSharp";
import MailOutlineSharpIcon from "@mui/icons-material/MailOutlineSharp";
import LockOpenSharpIcon from "@mui/icons-material/LockOpenSharp";
import { sendSlackAlert } from "source/utils/slack";
import { getObjectUrl } from "source/api/authenticatedAxios";
import { DocPasswordEntryModal } from "./DocPasswordEntryModal";
import { useFeatureFlag } from "source/hooks/useFeatureFlag";

type DocContextMenuProps = {
  doc: DocumentType;
  repo?: RepoType;
  children?: React.ReactNode;
  unsupportedDoc?: boolean;
  setIsEditing?: (isEditing: boolean) => void;
};

export const DocContextMenu = ({
  doc,
  repo,
  unsupportedDoc,
  setIsEditing,
  children,
}: DocContextMenuProps) => {
  const [notifyDisabled, setNotifyDisabled] = useState(false);
  const user = useSelector(getUser);
  const canEdit = useCanEditRepo(repo ?? null);
  const { focus } = useSidebar();
  const {
    showShare,
    handleDelete,
    handleRefresh,
    handleReupload,
    handleDownloadDoc,
    handleBuildClick,
    handleForceOCR,
    handleAddFolder,
    handleForceTableExtraction,
  } = useDocContextMenu(doc, repo);
  const passwordPromptEligible =
    canEdit &&
    doc.failure_reason &&
    doc.failure_reason === "Document is password protected";
  const [passwordModalOpen, setPasswordModalOpen] = useState(false);
  const disableAllSharedContent = useFeatureFlag("disableAllSharedContent");
  const disablePersonalUploads = useFeatureFlag("disablePersonalUploads");

  const showAddSubfolder =
    canEdit && doc?.mime === "hebbia/folder" && !disablePersonalUploads;

  const openInNewTab = (doc_id: string) => {
    const url = `${BASE_URL}/repos/${doc.repo_id}?doc_ids=%5B%22${doc_id}%22%5D`;
    window.open(url, "_blank")?.focus();
  };

  const onIconClick = async () => {
    if (!doc.source) return;
    if (doc.type === "sec") window.open(doc.data?.url, "_blank")?.focus();
    else {
      const url = await getObjectUrl(doc.source);
      window.open(url, "_blank")?.focus();
    }
  };

  return (
    <>
      <ContextMenu>
        <ContextMenuTrigger>{children}</ContextMenuTrigger>
        <ContextMenuContent onCloseAutoFocus={(e) => e.preventDefault()}>
          {!unsupportedDoc && (
            <>
              <ContextMenuItem
                title="Open in New Tab"
                icon={<OpenInNewSharpIcon fontSize="small" />}
                onSelect={() => openInNewTab(doc.id)}
              />
              {showAddSubfolder && (
                <ContextMenuItem
                  title="Add Sub-Folder"
                  icon={<FolderSharpIcon fontSize="small" />}
                  onSelect={handleAddFolder}
                />
              )}
              {canEdit && doc?.type === "rssfeed" && (
                <ContextMenuItem
                  title="Refresh RSS Feed"
                  icon={<RefreshSharpIcon fontSize="small" />}
                  onSelect={handleRefresh}
                />
              )}
              {!disableAllSharedContent && (
                <ContextMenuItem
                  title="Share"
                  icon={<PersonAddAlt1SharpIcon fontSize="small" />}
                  onSelect={showShare}
                />
              )}
              {canEdit && setIsEditing && (
                <ContextMenuItem
                  title="Rename"
                  icon={<EditSharpIcon fontSize="small" />}
                  onSelect={() => {
                    setIsEditing(true);
                    focus(`d${doc.id}`);
                  }}
                />
              )}
              <Divider />
              <ContextMenuItem
                title="Download with Folders"
                icon={<FolderOpenSharpIcon fontSize="small" />}
                onSelect={() => handleDownloadDoc(doc, false)}
              />
            </>
          )}
          <ContextMenuItem
            title={"Download"}
            icon={<FileDownloadDoneSharpIcon fontSize="small" />}
            onSelect={() => {
              handleDownloadDoc(doc, true);
            }}
          />
          {unsupportedDoc && !notifyDisabled && (
            <ContextMenuItem
              title="Notify Hebbia Support"
              icon={<MailOutlineSharpIcon fontSize="small" />}
              onSelect={() => {
                setNotifyDisabled(true);
                sendSlackAlert(
                  `${user?.email} notified us that document search.hebbia.ai/repos/${doc.repo_id}?doc_ids=%5B%22${doc.id}%22%5D is not readable`,
                  SLACK_WEBHOOK_URL
                );
              }}
            />
          )}
          {!unsupportedDoc && (
            <ContextMenuItem
              title="Open Original"
              icon={<ArticleSharpIcon fontSize="small" />}
              onSelect={onIconClick}
            />
          )}
          <Divider />
          {passwordPromptEligible && (
            <ContextMenuItem
              title="Enter password"
              icon={<LockOpenSharpIcon fontSize="small" />}
              onSelect={() => setPasswordModalOpen(true)}
            />
          )}
          {(user?.platform_role === "admin" ||
            user?.platform_role === "labeler") &&
            !!doc?.doc_raw_content_json && (
              <ContextMenuItem
                title="Soft Build"
                icon={<RefreshSharpIcon fontSize="small" />}
                onSelect={() => handleBuildClick(false)}
              />
            )}
          {(user?.platform_role === "admin" ||
            user?.platform_role === "labeler") && (
            <ContextMenuItem
              title="Hard Build"
              icon={<RefreshSharpIcon fontSize="small" />}
              onSelect={() => handleBuildClick(true)}
            />
          )}
          {doc?.mime === "application/pdf" && (
            <ContextMenuItem
              title="Build with OCR"
              icon={<VisibilitySharpIcon fontSize="small" />}
              onSelect={handleForceOCR}
            />
          )}
          {user?.platform_role === "admin" &&
            doc?.mime === "application/pdf" && (
              <ContextMenuItem
                title="Extract All Tables"
                icon={<AddchartSharpIcon fontSize="small" />}
                onSelect={handleForceTableExtraction}
              />
            )}
          {canEdit && (
            <ContextMenuItem
              title="Delete"
              icon={<DeleteSharpIcon fontSize="small" />}
              onSelect={handleDelete}
            />
          )}
          {canEdit && (isFailedDoc(doc) || isShortDoc(doc)) && (
            <ContextMenuItem
              title="Delete & Replace"
              icon={<FileUploadSharpIcon fontSize="small" />}
              onSelect={handleReupload}
            />
          )}
          {doc.last_built && (
            <ContextMenuLabel>
              Last updated {moment.utc(doc.last_built).fromNow()}
            </ContextMenuLabel>
          )}
        </ContextMenuContent>
      </ContextMenu>
      {passwordPromptEligible && (
        <div onClick={(e) => e.stopPropagation()}>
          <DocPasswordEntryModal
            doc={doc}
            open={passwordModalOpen}
            onClose={() => setPasswordModalOpen(false)}
            canEdit={canEdit}
          />
        </div>
      )}
    </>
  );
};
