import { useMemo } from "react";
import { useCachedRepoDocs } from "source/api/docs/useQueryDocs";
import { DocumentType } from "source/Types";

/** Hook that returns all currently fetched docs inside a repo */
export const useRepoDocsDict = (
  repoId?: string
): Record<string, DocumentType> => {
  const repoDocs: DocumentType[] = useCachedRepoDocs(repoId);

  const repoDocsDict: Record<string, DocumentType> = useMemo(() => {
    const dict = {};
    repoDocs.forEach((doc) => (dict[doc.id] = doc));
    return dict;
  }, [repoDocs]);

  return repoDocsDict;
};
