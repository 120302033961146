import { IDocumentMime } from "source/Types";

export const FASTBUILD_UPLOAD_BATCH_SIZE = 10;
export const FASTBUILD_CHUNK_SIZE_LIMIT = 10485760;

export const FAST_BUILD_SHAREPOINT_MUTATION_KEY =
  "/sheets/build/async-ingest-drive-docs";
export const CRAWL_SHAREPOINT_FOLDER_MUTATION_KEY =
  "/sheets/build/crawl-sharepoint-folder";
export const EARNINGS_FAST_BUILD_MUTATION_KEY =
  "/sheets/build/async-ingest-transcripts";
export const INVESTOR_PRESENTATIONS_FAST_BUILD_MUTATION_KEY =
  "/sheets/build/async-ingest-investor-presentations";
export const SEC_FILINGS_FAST_BUILD_MUTATION_KEY =
  "/sheets/build/async-ingest-sec";
export const AZURE_FAST_BUILD_MUTATION_KEY =
  "/sheets/build/async-ingest-file-share-docs";
export const NEW_FAST_BUILD_MUTATION_KEY = "/fastbuild/ingest-docs";

// These are the mime types supported in the old fast build, still used by SharePoint
export const FAST_BUILD_ALLOWED_MIMES_OLD: IDocumentMime[] = [
  "application/pdf",
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  "application/vnd.openxmlformats-officedocument.presentationml.presentation",
  "text/html",
  "application/vnd.ms-outlook",
];
export const FAST_BUILD_ALLOWED_MIMES_SET_OLD = new Set(
  FAST_BUILD_ALLOWED_MIMES_OLD
);

export const EXTENSION_TO_MIME_TYPE: Record<string, string> = {
  pdf: "application/pdf",
  docx: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  doc: "application/msword",
  pptx: "application/vnd.openxmlformats-officedocument.presentationml.presentation",
  ppt: "application/vnd.ms-powerpoint",
  msg: "application/vnd.ms-outlook",
  html: "text/html",
  jpeg: "image/jpeg",
  jpg: "image/jpeg",
  png: "image/png",
  webp: "image/webp",
  xlsx: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
};

export const FAST_BUILD_ERROR_PARSING = "Error while parsing file";

export const MAX_ROWS_IN_BULK_RUN = 100;
export const TEXT_20_MEGABYTES = 20 * 1024 * 1024;
export const MAX_FAST_BUILD_FILE_SIZE = 125 * 1024 * 1024; // 250 KB per page * 500 = 125 MB

export const MS_GRAPH_BUILD_CLIENT_ID = "29615c28-3d00-4a1d-a856-a349469fe14f";
export const MS_GRAPH_SCOPED_CLIENT_ID = "1f55ef4a-6a64-4cfb-ba24-c721c6b512fd";

export const BOX_CLIENT_ID = "lszb2re8jwbibeyfm32eol3vgl0lrzdu"; // TODO: Shouldnt this be a secret
export const BOX_DEFAULT_SCOPE = "root_readwrite";
export const ERROR_MESSAGE_URL_LIMIT = 3;

export const THIRD_BRIDGE_PDF_BUCKET_NAME =
  "proda-forum-data-delivery-hebbia-pdf";

export const THIRD_BRIDGE_COMPANY_MAP = {
  "Apple Inc.": "Apple Inc",
  "Microsoft Corporation": "Microsoft Corp",
  "Alphabet Inc.": "Alphabet Inc",
  "Amazon.com, Inc.": "Amazon.com Inc",
  "NVIDIA Corporation": "NVIDIA Corp",
  "Berkshire Hathaway Inc.": "Berkshire Hathaway Inc",
  "Meta Platforms, Inc.": "Meta Platforms Inc",
  "Tesla, Inc.": "Tesla Inc",
  "Eli Lilly and Company": "Eli Lilly & Co",
  "Visa Inc.": "Visa Inc",
  "UnitedHealth Group Incorporated": "UnitedHealth Group Inc",
  "Johnson & Johnson": "Johnson & Johnson",
  "Exxon Mobil Corporation": "Exxon Mobil Corp",
  "JPMorgan Chase & Co.": "JPMorgan Chase & Co",
  "Walmart Inc.": "Walmart Inc",
  "Mastercard Incorporated": "Mastercard Inc",
  "The Procter & Gamble Company": "Procter & Gamble CoThe",
  "Broadcom Inc.": "Broadcom Inc",
  "The Home Depot, Inc.": "Home Depot IncThe",
  "Oracle Corporation": "Oracle Corp",
  "Chevron Corporation": "Chevron Corp",
  "Merck & Co., Inc.": "Merck & Co Inc",
  "AbbVie Inc.": "AbbVie Inc",
  "The Coca-Cola Company": "Coca-Cola CoThe",
  "PepsiCo, Inc.": "PepsiCo Inc",
  "Costco Wholesale Corporation": "Costco Wholesale Corp",
  "Adobe Inc.": "Adobe Inc",
  "Bank of America Corporation": "Bank of America Corp",
  "Cisco Systems, Inc.": "Cisco Systems Inc",
  "Pfizer Inc.": "Pfizer Inc",
  "Thermo Fisher Scientific Inc.": "Thermo Fisher Scientific Inc",
  "McDonald's Corporation": "McDonald's Corp",
  "Accenture plc": "Accenture PLC",
  "Salesforce, Inc.": "Salesforce Inc",
  "Comcast Corporation": "Comcast Corp",
  "Danaher Corporation": "Danaher Corp",
  "Linde plc": "Linde PLC",
  "Abbott Laboratories": "Abbott Laboratories",
  "Netflix, Inc.": "Netflix Inc",
  "Advanced Micro Devices, Inc.": "Advanced Micro Devices Inc",
  "NIKE, Inc.": "NIKE Inc",
  "T-Mobile US, Inc.": "T-Mobile US Inc",
  "The Walt Disney Company": "Walt Disney CoThe",
  "Wells Fargo & Company": "Wells Fargo & Co",
  "Texas Instruments Incorporated": "Texas Instruments Inc",
  "Philip Morris International Inc.": "Philip Morris International Inc",
  "United Parcel Service, Inc.": "United Parcel Service Inc",
  "Morgan Stanley": "Morgan Stanley",
  ConocoPhillips: "ConocoPhillips",
  "Amgen Inc.": "Amgen Inc",
  "Caterpillar Inc.": "Caterpillar Inc",
  "Verizon Communications Inc.": "Verizon Communications Inc",
  "Union Pacific Corporation": "Union Pacific Corp",
  "NextEra Energy, Inc.": "NextEra Energy Inc",
  "Intel Corporation": "Intel Corp",
  "The Boeing Company": "Boeing CoThe",
  "Intuit Inc.": "Intuit Inc",
  "Bristol-Myers Squibb Company": "Bristol-Myers Squibb Co",
  "International Business Machines Corporation":
    "International Business Machines Corp",
  "Lowe's Companies, Inc.": "Lowe's Cos Inc",
  "RTX Corporation": "RTX Corp",
  "Honeywell International Inc.": "Honeywell International Inc",
  "QUALCOMM Incorporated": "QUALCOMM Inc",
  "General Electric Company": "General Electric Co",
  "S&P Global Inc.": "S&P Global Inc",
  "Applied Materials, Inc.": "Applied Materials Inc",
  "American Express Company": "American Express Co",
  "Deere & Company": "Deere & Co",
  "Prologis, Inc.": "Prologis Inc",
  "Lockheed Martin Corporation": "Lockheed Martin Corp",
  "Starbucks Corporation": "Starbucks Corp",
  "Booking Holdings Inc.": "Booking Holdings Inc",
  "Elevance Health, Inc.": "Elevance Health Inc",
  "Medtronic plc": "Medtronic PLC",
  "The Charles Schwab Corporation": "Charles Schwab CorpThe",
  "The Goldman Sachs Group, Inc.": "Goldman Sachs Group IncThe",
  "Stryker Corporation": "Stryker Corp",
  "Automatic Data Processing, Inc.": "Automatic Data Processing Inc",
  "The TJX Companies, Inc.": "TJX Cos IncThe",
  "Intuitive Surgical, Inc.": "Intuitive Surgical Inc",
  "AT&T Inc.": "AT&T Inc",
  "BlackRock, Inc.": null,
  "Mondelez International, Inc.": "Mondelez International Inc",
  "Gilead Sciences, Inc.": "Gilead Sciences Inc",
  "Marsh & McLennan Companies, Inc.": "Marsh & McLennan Cos Inc",
  "Vertex Pharmaceuticals Incorporated": "Vertex Pharmaceuticals Inc",
  "Analog Devices, Inc.": "Analog Devices Inc",
  "Regeneron Pharmaceuticals, Inc.": "Regeneron Pharmaceuticals Inc",
  "Lam Research Corporation": "Lam Research Corp",
  "CVS Health Corporation": "CVS Health Corp",
  "Eaton Corporation plc": "Eaton Corp PLC",
  "Zoetis Inc.": "Zoetis Inc",
  "Schlumberger Limited": "Schlumberger NV",
  "American Tower Corporation": "American Tower Corp",
  "Chubb Limited": "Chubb Ltd",
  "The Cigna Group": "Cigna GroupThe",
  "Citigroup Inc.": "Citigroup Inc",
  "Becton, Dickinson and Company": "Becton Dickinson & Co",
};

export const ADD_DOCS_SEARCH_DEBOUNCE_MS = 250;
export const TEMPORARY_EXCEL_FILE_SIZE_LIMIT = 10 * 1024 * 1024; // 10 MB
