import { Popover } from "@headlessui/react";
import { classNames } from "core";
import { usePopper } from "react-popper";
import { createPortal } from "react-dom";
import { MoreHoriz } from "@mui/icons-material";
import React, { forwardRef, useState } from "react";

export type CitationListPopoverProps = {
  size: "small" | "medium" | "large";
  styleOverrides?: {
    Popover?: string;
    PopoverPanel?: string;
    PopoverPanelChildren?: string;
  };
  PopoverButtonComponent?: React.ForwardRefExoticComponent<
    React.RefAttributes<HTMLButtonElement>
  >;
  children: React.ReactNode[];
};

const DefaultPopoverButton = forwardRef<HTMLButtonElement>((_props, ref) => (
  <Popover.Button
    ref={ref}
    className="m-0 h-4 min-w-[16px] overflow-clip rounded bg-blue2 align-top font-medium leading-[17px] text-hebbiaBlue hover:bg-hebbiaBlue hover:text-white"
  >
    <MoreHoriz className="-mt-0.5" style={{ width: 10, height: 10 }} />
  </Popover.Button>
));

export const CitationListPopover = ({
  size,
  styleOverrides,
  PopoverButtonComponent,
  children,
}: CitationListPopoverProps) => {
  const [referenceElement, setReferenceElement] =
    useState<HTMLButtonElement | null>(null);
  const [popperElement, setPopperElement] = useState<HTMLDivElement | null>(
    null
  );
  const { styles, attributes } = usePopper(referenceElement, popperElement);

  return (
    <Popover
      className={classNames(
        "ml-0.5 mr-[1px] inline-block w-[17px] align-top",
        styleOverrides?.Popover
      )}
    >
      {PopoverButtonComponent ? (
        <PopoverButtonComponent ref={setReferenceElement} />
      ) : (
        <DefaultPopoverButton ref={setReferenceElement} />
      )}
      <Popover.Overlay className="fixed inset-0" />
      {createPortal(
        <Popover.Panel
          ref={setPopperElement}
          style={styles.popper}
          className={classNames(
            "absolute z-50 rounded border border-gray-200 bg-white p-1 shadow-lg",
            styleOverrides?.PopoverPanel
          )}
          {...attributes.popper}
        >
          <div
            className={classNames(
              "flex flex-row flex-wrap gap-0.5",
              children?.length > 5 && "grid grid-cols-6",
              size === "small" &&
                (children?.length > 50 ? "max-w-[340px]" : "max-w-[112px]"),
              size === "medium" &&
                (children?.length > 50 ? "max-w-[442px]" : "max-w-[146px]"),
              size === "large" &&
                (children?.length > 50 ? "max-w-[518px]" : "max-w-[206px]"),
              styleOverrides?.PopoverPanelChildren
            )}
          >
            {children}
          </div>
        </Popover.Panel>,
        document.body
      )}
    </Popover>
  );
};
