/* eslint-disable arrow-body-style */
const noopStorage: Storage = {
  getItem: () => null,
  setItem: () => {
    return;
  },
  removeItem: () => {
    return;
  },
  clear: () => {
    return;
  },
  key: () => null,
  keys: () => [],
  length: 0,
};

export const getSessionStorage = (): Storage => {
  return typeof window !== "undefined" ? window.sessionStorage : noopStorage;
};

export const getLocalStorage = (): Storage => {
  return typeof window !== "undefined" ? window.localStorage : noopStorage;
};
