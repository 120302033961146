import { useMutation } from "@tanstack/react-query";
import { queryClient } from "pages/_app";
import { useDispatch } from "react-redux";
import { BuildActionType, DocumentType } from "source/Types";
import { useGlobalNavigator } from "source/hooks/useSetRouter";
import { useSidebar } from "source/hooks/useSidebar";
import { useUploadSourceSuccess } from "source/hooks/useUploadSourceSuccess";
import { insertActiveBuild } from "source/redux/activeBuilds";
import api from "..";
import { setRepo } from "../repos/useQueryRepos";
import { docsKeys } from "./docsKeyFactory";
import { DocByIdResponse } from "./types";

//** Prime a repo into the react query cache */
export const setDoc = (doc: DocumentType) => {
  // We need to preserve previous ancestors, repo if it was there
  const previousDoc: DocByIdResponse | undefined = queryClient.getQueryData(
    docsKeys.doc(doc.id)
  );

  const newDoc = {
    doc,
    ancestors: previousDoc?.ancestors,
    repo: previousDoc?.repo,
  };
  queryClient.setQueryData(docsKeys.doc(doc.id), newDoc);
};

type CreateFolderPayload = {
  repoId: string;
  docId?: string;
  name?: string;
};

/** Mutation to optimistically create folders () */
export const useCreateFoldersMutation = () => {
  const { handleSuccess } = useUploadSourceSuccess();
  const { goToRepoAddDocs } = useGlobalNavigator();
  const { focus } = useSidebar();

  const createFolderMutation = useMutation({
    mutationFn: ({ repoId, docId, name }: CreateFolderPayload) =>
      api.repos.createFolder(repoId, name ?? "Untitled Folder", docId),
    // When mutate is called:
    onMutate: async ({ repoId, docId }: CreateFolderPayload) => {
      await queryClient.cancelQueries({ queryKey: docsKeys.all });
    },
    onSuccess(data, payload, context) {
      handleSuccess(data.docs);
      const repoId = data?.docs?.[0]?.repo_id;
      if (data.docs.length && repoId) goToRepoAddDocs(repoId, data.docs[0]?.id);
      focus(`d${data.docs[0]?.id}`);
    },
    // Always refetch after error or success:
    onSettled: (resp, error, { docId, repoId }) => {
      queryClient.invalidateQueries({
        queryKey: docId
          ? docsKeys.childrenDocs(docId, repoId)
          : docsKeys.parentDocs(repoId),
      });
      if (repoId)
        queryClient.invalidateQueries({
          queryKey: [{ repoId }],
        });
    },
  });

  return createFolderMutation;
};

type DeleteDocPayload = {
  docId: string;
  parentId?: string;
  repoId?: string;
  unsupported?: boolean;
};

/** Mutation to optimistically delete docs from the cache -- will also delete related children docs and refetch corresponding repo.
 * Will match parentDocs if repoId is specified, otherwise match childrenDocs
 */
export const useDeleteDocsMutation = () =>
  useMutation({
    mutationFn: ({ docId, repoId }: DeleteDocPayload) =>
      repoId
        ? api.repos.deleteMultipleDocs(repoId, [docId])
        : api.docs.delete(docId),
    // When mutate is called:
    onMutate: async ({ docId, repoId }: DeleteDocPayload) => {
      await queryClient.cancelQueries({ queryKey: docsKeys.all });
      // Cancel all refetches related to this docId
      queryClient.cancelQueries({ queryKey: [{ docId }] });

      // Snapshot the previous values
      const previousParentdocs = queryClient.getQueryData(
        docsKeys.parentDocs(repoId)
      );
      const previousChildrenDocs = queryClient.getQueryData(
        docsKeys.childrenDocs(docId, repoId)
      );

      // Optimistically delete all things related to this docId
      queryClient.removeQueries({ queryKey: [{ docId }] });
      // Optimistically remove children docs
      queryClient.removeQueries(docsKeys.childrenDocs(docId, repoId));

      // Return a context object with the snapshotted value
      return { previousChildrenDocs, previousParentdocs };
    },
    onSuccess(data, { docId, repoId, parentId }, context) {
      // Remove all relevant docs deleted (children docs), and refetch the repo
      // can do this in one operation but need to check documentation
      data.docs.forEach((doc) => {
        queryClient.removeQueries({ queryKey: [{ docId: doc.id }] });
      });
      // If this doc is a child doc, refetch its parent docs so this one gets removed
      if (parentId) {
        queryClient.invalidateQueries(docsKeys.childrenDocs(docId, repoId));
      }
    },
    // If the mutation fails,
    // use the context returned from onMutate to roll back
    onError: (err, { docId, repoId }, context) => {
      if (context?.previousChildrenDocs) {
        queryClient.setQueryData(
          docsKeys.childrenDocs(docId, repoId),
          context.previousChildrenDocs
        );
      }
      if (context?.previousParentdocs) {
        queryClient.setQueryData(
          docsKeys.parentDocs(repoId),
          context.previousChildrenDocs
        );
      }
    },
    // Always refetch after error or success:
    onSettled: (resp, error, { repoId, unsupported }) => {
      if (unsupported)
        queryClient.invalidateQueries(docsKeys.unsupportedDocs(repoId));
      // Refetch docs for this repo
      queryClient.invalidateQueries({ queryKey: [{ repoId }] });
      // queryClient.invalidateQueries({ queryKey: docsKeys.all });
    },
  });

type UpdateDocPayload = {
  docId: string;
  doc: Partial<DocumentType>;
  repoId?: string;
};

/** Mutation to optimistically update docs */
export const useUpdateDocMutation = () => {
  const updateMutation = useMutation({
    mutationFn: ({ docId, doc, repoId }: UpdateDocPayload) =>
      api.docs.update(docId, doc),
    // When mutate is called:
    onMutate: async ({ docId, doc, repoId }: UpdateDocPayload) => {
      await queryClient.cancelQueries({ queryKey: docsKeys.all });
      // Cancel all refetches related to this docId
      queryClient.cancelQueries({ queryKey: [{ docId }] });

      // Snapshot the previous values
      const previousDoc: DocumentType | undefined = queryClient.getQueryData(
        docsKeys.doc(docId)
      );
      const previousParentDocs = queryClient.getQueryData(
        docsKeys.parentDocs(repoId)
      );
      const previousChildrenDocs = queryClient.getQueryData(
        docsKeys.childrenDocs(docId, repoId)
      );

      // Return a context object with the snapshotted value
      return { previousDoc, previousParentDocs, previousChildrenDocs };
    },
    // If the mutation fails,
    // use the context returned from onMutate to roll back
    onError: (err, { docId, repoId }, context) => {
      if (context?.previousDoc) {
        queryClient.setQueryData(docsKeys.doc(docId), context.previousDoc);
      }
      if (context?.previousChildrenDocs) {
        queryClient.setQueryData(
          docsKeys.childrenDocs(docId, repoId),
          context.previousChildrenDocs
        );
      }
      if (context?.previousParentDocs) {
        queryClient.setQueryData(
          docsKeys.parentDocs(repoId),
          context.previousChildrenDocs
        );
      }
    },
    // Always refetch after error or success:
    onSettled: (resp, error, { repoId, docId }) => {
      // Refetch everything related to this docId, and repoId
      queryClient.invalidateQueries({ queryKey: [{ docId }] });
      // If parent doc, invalidate parent docs
      if (repoId) {
        queryClient.invalidateQueries(docsKeys.parentDocs(repoId));
      }
      // Invalidate children docs
      queryClient.invalidateQueries(docsKeys.childrenDocs(docId, repoId));
    },
  });

  return updateMutation;
};

type UpdateDocDataPayload = {
  docId: string;
  data: any;
  repoId?: string;
  message?: BuildActionType;
};

/** Mutation to optimistically update docs */
export const useUpdateDocDataMutation = () => {
  const dispatch = useDispatch();

  const docDataMutation = useMutation({
    mutationFn: (payload: UpdateDocDataPayload) =>
      api.docs.updateData(payload.docId, payload.data),
    // When mutate is called:
    onMutate: async (payload: UpdateDocDataPayload) => {
      const { docId, repoId } = payload;
      // Cancel any outgoing refetches
      // (so they don't overwrite our optimistic update)
      // Cancel all refetches related to docs (so we don't fetch the newly deleted doc)
      await queryClient.cancelQueries({ queryKey: docsKeys.all });
      // Cancel all refetches related to this docId
      queryClient.cancelQueries({ queryKey: [{ docId }] });

      // Snapshot the previous values
      const previousDoc: DocumentType | undefined = queryClient.getQueryData(
        docsKeys.doc(docId)
      );

      // Optimistically delete all things related to this docId
      queryClient.removeQueries({ queryKey: [{ docId }] });

      // Return a context object with the snapshotted value
      return { previousDoc };
    },
    onSuccess(data, variables, context) {
      dispatch(
        insertActiveBuild({
          docId: data.doc.id,
          action: variables.message ?? "build",
          repoId: variables.repoId,
          status: "processing",
        })
      );
    },
    // If the mutation fails,
    // use the context returned from onMutate to roll back
    onError: (err, data, context) => {
      if (context?.previousDoc)
        queryClient.setQueryData(docsKeys.doc(data.docId), context.previousDoc);
    },
    // Always refetch after error or success:
    onSettled: (resp, error, variables) => {
      // Refetch everything related to docs
      queryClient.invalidateQueries({ queryKey: docsKeys.all });
      // Refetch everything related to this docId, and repoId
      queryClient.invalidateQueries({ queryKey: [{ docId: variables.docId }] });
      if (variables.repoId)
        queryClient.invalidateQueries({
          queryKey: [{ repoId: variables.repoId }],
        });
    },
  });

  return docDataMutation;
};

type MoveDocPayload = {
  docId: string;
  targetParentId?: string;
  repoId?: string;
};

/** Mutation to optimistically move docs  */
export const useMoveDocMutation = () =>
  useMutation({
    mutationFn: (payload: MoveDocPayload) =>
      api.docs.move(payload.docId, payload.targetParentId),
    // When mutate is called:
    onMutate: async (payload: MoveDocPayload) => {
      const { docId, repoId } = payload;
      // Cancel any outgoing refetches
      // (so they don't overwrite our optimistic update)
      // Cancel all refetches related to docs (so we don't fetch the newly deleted doc)
      await queryClient.cancelQueries({ queryKey: docsKeys.all });
      // Cancel all refetches related to this docId
      queryClient.cancelQueries({ queryKey: [{ docId }] });
      queryClient.cancelQueries({
        queryKey: [{ docId: payload.targetParentId }],
      });

      // Snapshot the previous values
      const previousDoc: DocumentType | undefined = queryClient.getQueryData(
        docsKeys.doc(docId)
      );

      // Optimistically delete all things related to this docId
      queryClient.removeQueries({ queryKey: [{ docId }] });
      queryClient.removeQueries({
        queryKey: [{ docId: payload.targetParentId }],
      });

      // Return a context object with the snapshotted value
      return { previousDoc };
    },
    // If the mutation fails,
    // use the context returned from onMutate to roll back
    onError: (err, data, context) => {
      if (context?.previousDoc)
        queryClient.setQueryData(docsKeys.doc(data.docId), context.previousDoc);
    },
    // Always refetch after error or success:
    onSettled: (resp, error, variables) => {
      // Refetch everything related to docs
      queryClient.invalidateQueries({ queryKey: docsKeys.all });
      // Refetch everything related to this docId, and repoId
      queryClient.invalidateQueries({ queryKey: [{ docId: variables.docId }] });
      queryClient.invalidateQueries({
        queryKey: [{ docId: variables.targetParentId }],
      });

      if (variables.repoId)
        queryClient.invalidateQueries({
          queryKey: [{ repoId: variables.repoId }],
        });
    },
  });

type BuildDocMutation = {
  docId: string;
  hard?: boolean;
  repoId?: string;
};

/** Mutation to build docs */
export const useBuildDocMutation = () => {
  const dispatch = useDispatch();

  const buildMutation = useMutation({
    mutationFn: (payload: BuildDocMutation) =>
      payload.hard
        ? api.docs.hardBuild(payload.docId)
        : api.docs.softBuild(payload.docId),
    // When mutate is called:
    onMutate: async (payload: BuildDocMutation) => {
      const { docId, repoId } = payload;
      // Cancel any outgoing refetches
      // (so they don't overwrite our optimistic update)
      // Cancel all refetches related to docs (so we don't fetch the newly deleted doc)
      await queryClient.cancelQueries({ queryKey: docsKeys.all });
      // Cancel all refetches related to this docId
      queryClient.cancelQueries({ queryKey: [{ docId }] });

      // Snapshot the previous values
      const previousDoc: DocumentType | undefined = queryClient.getQueryData(
        docsKeys.doc(docId)
      );

      // Optimistically delete all things related to this docId
      queryClient.removeQueries({ queryKey: [{ docId }] });

      // Return a context object with the snapshotted value
      return { previousDoc };
    },
    onSuccess(data, variables, context) {
      if (data.repo) {
        setRepo({ ...data.repo, status_percentage: 0 });
        dispatch(
          insertActiveBuild({
            docId: data.doc.id,
            action: "build",
            repoId: data.repo.id,
            status: "processing",
          })
        );
      }
    },
    // If the mutation fails,
    // use the context returned from onMutate to roll back
    onError: (err, data, context) => {
      if (context?.previousDoc)
        queryClient.setQueryData(docsKeys.doc(data.docId), context.previousDoc);
    },
    // Always refetch after error or success:
    onSettled: (resp, error, variables) => {
      // Refetch everything related to docs
      queryClient.invalidateQueries({ queryKey: docsKeys.all });
      // Refetch everything related to this docId, and repoId
      queryClient.invalidateQueries({ queryKey: [{ docId: variables.docId }] });

      if (variables.repoId)
        queryClient.invalidateQueries({
          queryKey: [{ repoId: variables.repoId }],
        });
    },
  });

  return buildMutation;
};
