import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { SearchHistoryType } from "source/Types";
import { ReduxState } from ".";
import { Dispatch } from "redux";
import api from "source/api";

export type SearchHistoryReduxType = SearchHistoryType[];

const initialState: SearchHistoryReduxType = [];

export const getSearchHistory = (state: ReduxState) => state.searchHistory;

export const fetchSearchHistory = async (dispatch: Dispatch) => {
  const { data } = await api.search.getHistory();
  dispatch(setSearchHistory(data.history));
};

const searchHistorySlice = createSlice({
  name: "searchHistory",
  initialState,
  reducers: {
    addSearchHistory: (
      state: SearchHistoryReduxType,
      action: PayloadAction<SearchHistoryType>
    ) => {
      state.push(action.payload);
      return state;
    },
    setSearchHistory: (
      _state: SearchHistoryReduxType,
      action: PayloadAction<SearchHistoryType[]>
    ) => action.payload,
  },
});

export const { addSearchHistory, setSearchHistory } =
  searchHistorySlice.actions;
export const searchHistoryReducer = searchHistorySlice.reducer;
